import { useEffect, useState } from "react"
import InspectionRequestTable from "@src/view/inspection/common/InspectionRequestTable"
import { useTranslation } from "react-i18next"
import InspectionRequestFilter from "@src/view/inspection/common/InspectionRequestFilter"
import TopActionBtn from "@src/view/common/TopActionBar";
import { Tab, Nav } from 'react-bootstrap';
import FullPageLoader from "@src/view/common/FullPageLoader";
import { useDispatch} from "react-redux"
import { getInspectionRequest, getinspectionConfig, setInspectionFilter, requestUser,  getCeList, inspectionCityList, requestCityList, getassignTo} from "@src/store/slices/inspection"

const ManageInspection = (props) => {
    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(false)
    const [inspectionListResponse, SetInspectionListResponse] = useState([])
    const [userlistFilter, setUserListFilter] = useState({callonpage :"new"})
    const [assignTo, setAssignTo] = useState([]);
    const [inspectionFilterList, setInspectionFilterList ] = useState([]);
    const[callonpage, setCallonpage] = useState('new');
    const [requestUserList, setRequestUserList] = useState([]);
    const [ceList, setCeList] = useState([]);
    const [inspectionCity, setInspectionCity] = useState([]);
    const [requestCity, setRequestCity] = useState([]);
    const [resetOnChange, setResetOnChange] = useState(0);
   
    const dispatch = useDispatch()


    // Calling All Filters Dropdown List
    useEffect(() => {
        getFilterListing(props)
        getRequestUser()
        getinspectionCityList()
        getRequestCityList()
        getAssignTo();
      
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Calling Inspection Dashboard List 
    useEffect(() => {
        getInspectionListing()
    }, [userlistFilter]) // eslint-disable-line react-hooks/exhaustive-deps


    //   All Inspection Dashboard List
    const getInspectionListing = (params={}) => {
        setLoading(true)
        dispatch(getInspectionRequest({ ...params.searchFilter||{}, ...userlistFilter, page_no: params.page_no ? params.page_no : 1  })).then((res) => {
            setLoading(false)
            if (res && res.status === 200) {
                const inspectionListResponse = res
                SetInspectionListResponse(inspectionListResponse)
            }
        }).catch(err => {
            setLoading(false)
        })
    }

    
    //Static Filter Listing
    const getFilterListing = (params) => {  
        getinspectionConfig(params.searchFilters).then((res) => {   
            if (res) {
                setInspectionFilterList(res);
            }
        }).catch(err => {  
        })
    }

    //Set All Filter Value
    const updateFilter = (params) => {
        setUserListFilter(params)
    }

    //Use For Pgination Formation For Table Data
    const fetchInspectionListing = (params) => {
        getInspectionListing(params)
    }

    //During Tab Change Api Call With   ' All-new ' Request
    const tabChange = (type) => {
        type = (type === "first") ? "new" : "all";

        dispatch(setInspectionFilter({}));
        setUserListFilter({callonpage:type});
        setCallonpage(type)

        if(type === 'all'){
            getCeLists();
        }  
        setResetOnChange((prev)=>prev+1);

        // navigate('/inspection/request/'+type)
    }


    // Request User Listing
    const getRequestUser = () => {
        requestUser().then((res) => {
            if (res && res.status === 200) {
                setRequestUserList(res.data);
            }
        }).catch(err => {
            
        })
    }


    //Ce Listing
    const getCeLists = () => {
        getCeList().then((res) => {
            if (res && res.status === 200) {
                setCeList(res.data);
            }
        }).catch(err => {
       
        })
    }


    // Inspection City List
    const getinspectionCityList = () => {     
        inspectionCityList().then((res) => {
            if (res && res.status === 200) {
                let updatedInspectionList = [];
                for(let el of res.data) {
                    if(el.city_name){
                        updatedInspectionList.push(el);
                    }
                }

                res.data = updatedInspectionList;
                setInspectionCity(res.data);
            
            }
        }).catch(err => {
        })
    }

    // Request City List
    const getRequestCityList = () => {      
        requestCityList().then((res) => {     
            if (res && res.status === 200) {
                setRequestCity(res.data);
            }
        }).catch(err => {
           
        })
    }


    //Assign To
    const getAssignTo = () => {      
        getassignTo().then((res) => {    
            if (res && res.status === 200) {  
               setAssignTo(res.data);
           
            }
        }).catch(err => {
       
        })
    }

    return (
        <>
            <div className={"container-fluid-full"}>
                <div className="top-sec">
                    {/* Excel Button */}
                    <TopActionBtn heading={t("COMMON.INSPECTION_REQUEST")}  
                    callonpage = {callonpage} 
                    pageType={'Inspection'} 
                    List={inspectionListResponse.data}
                    />
              
                </div>
                <div className="payout-summary-tabs">
                    <Tab.Container id="left-tabs-example" defaultActiveKey={"first"}>
                       {/* Sub Menu Link */}
                        <Nav variant="pills" className="flex-column tab-line"
                        onSelect={(k) => tabChange(k)} >
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" >{t("COMMON.NEW_REQUEST")} </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" >{t("COMMON.ALL_REQUEST")} </Nav.Link>
                                </Nav.Item>
                              
                            </div>
                          
                        </Nav>
                        <Tab.Content>
                             {/* Filters - Table For First Tab */}
                            <Tab.Pane eventKey="first">
                                <div className="vehicle-listing-box">
                                    <InspectionRequestFilter
                                        updateFilter={updateFilter}
                                        hiddenFilters={['ceType']}
                                        newInspectionRequest={'new'}
                                        assignTo = {assignTo}                      
                                        inspectionFilterList = {inspectionFilterList}
                                        inspectionCity = {inspectionCity}
                                        requestUserList = {requestUserList}                    
                                        requestCity = {requestCity}
                                        resetOnChange = {resetOnChange}                     
                                    />
                                </div>
                                   {/* For Loader */}
                                <FullPageLoader show={loading} />

                                <div className="vechile-table-data">
                                    <InspectionRequestTable                               
                                        apiResponse={inspectionListResponse}
                                        getInspectionListing={fetchInspectionListing}
                                        loading={loading}
                                        searchFilters={userlistFilter}
                                        filterData={inspectionListResponse.data}
                                        newInspectionRequest={'new'}                           
                                    />
                                </div>
                            </Tab.Pane>
                               
                               {/* Filters - Table For Second Tab */}
                            <Tab.Pane eventKey="second">
                                <div className="vehicle-listing-box">
                                    <InspectionRequestFilter                      
                                        updateFilter={updateFilter}
                                        hiddenFilters={['ceType']}
                                        allInspectionRequest={'all'}              
                                        assignTo = {assignTo}     
                                        inspectionFilterList = {inspectionFilterList}
                                        ceList = {ceList}
                                        requestUserList = {requestUserList}
                                        inspectionCity = {inspectionCity}
                                        requestCity = {requestCity}
                                        resetOnChange = {resetOnChange}                        
                                    />
                                </div>

                                {/* For Loader */}
                                <FullPageLoader show={loading} />

                                <div className="vechile-table-data">
                                    <InspectionRequestTable
                                        apiResponse={inspectionListResponse}
                                        getInspectionListing={fetchInspectionListing}
                                        loading={loading}
                                        searchFilters={userlistFilter}
                                        filterData={inspectionListResponse.data}
                                        allInspectionRequest={'all'}                               
                                    />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>

                    </Tab.Container>
                </div>
            </div>
        </>
    )
}


export default ManageInspection