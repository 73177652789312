import { useState, useEffect } from 'react';

import WatermarkImg from "@src/webroot/images/watermark-inspection-image@3x.png";
import BannerPreview from "@src/view/cards/BannerPreview";
import FullPageLoader from "@src/view/common/FullPageLoader"

import { getDocumentReportData } from "@src/store/slices/dealerQc";

function InspectionDocumentManager(props) {

    const [loading, setLoading] = useState(false);

    const [reportData, setReportData] = useState({});

    const [showImageSlider, setShowImageSlider] = useState(false);
    const [imageSliderHtml, setImageSliderHtml] = useState([]);

    let { itemData } = props;

    useEffect(() => {
        getDocumentReport();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    //FETCH REASON LISTS
    const getDocumentReport = () => {

        setLoading(true)

        getDocumentReportData({ vccID: itemData.hashed_vccID }).then((res) => {
            setLoading(false)

            if (res && res.status === 200) {
                setReportData(res.data)

                loadImageSliderData(res.data)
            }
        }).catch(err => {
            setLoading(false)
        })


    }

    const loadImageSliderData = (imagesArray) => {
        const totalCount = imagesArray.length;

        if (imagesArray.length)
            imagesArray = imagesArray.slice(0, 4);

        let html = []

        for (let [index, img] of imagesArray.entries()) {

            html.push(<li className={(!index) ? "gal-imgLarge " : "gal-imgSmall "} onClick={() => setShowImageSlider(Math.random(0, 99))} key={index}>
                <div className="gal-img" style={{ "position": "relative" }}>
                    <img src={img.image_path} alt={img.tag_name} title={img.tag_name} className="img-responsive" />
                    <span style={{ "position": "absolute", "bottom": "5px", zIndex: "999", "right": 0, "width": "20%" }}>
                        <img src={WatermarkImg} alt="inspection-oto-watermark" className="cdwatermark-large" />
                    </span>
                </div>
                {
                    (index === imagesArray.length - 1) && <div className="gal-overlay">
                        <span className="tm_sprite gal-icon"></span>
                        <span className="gal-txt">View {totalCount} Photos</span>
                    </div>
                }
            </li>

            );


        }
        setImageSliderHtml([...html]);

    }

    return (

        <div className='inspection-report-html-content m-xl-t'>
            <FullPageLoader show={loading} />

            <div className="container">
                <div className="col-md-12">
                    {
                        imageSliderHtml.length
                            ?
                            <div className="gal-wrapper">
                                <ul className="clearfix hidden-xs cur-zoom">
                                    {imageSliderHtml}
                                </ul>
                            </div>
                            :
                            <p className="no-data">No Documents Found</p>
                    }

                </div>
            </div>


            {showImageSlider ? <BannerPreview signedImage={true} element={{ image: reportData }} defaultShow={showImageSlider} /> : ''}

        </div>
    )
}

export default InspectionDocumentManager