import { components } from "react-select";

const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children;
  
    if (Array.isArray(values)) {
      const plural = values.length === 1 ? "" : "s";
      values = `${values.length} item${plural} selected`;
    }
  
    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };
export default { ValueContainer } // eslint-disable-line