import React from 'react' 
import { Tab } from 'react-bootstrap';
import loanbox_Illustration from "@src/webroot/images/login_bg.svg";
import logo from "@src/webroot/images/logo.png";
import RemoveInfoForm from '@src/view/auth/Remove_info_form'

import { useTranslation } from "react-i18next";

function RemoveInfo(){
    const { t } = useTranslation('common');
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="login-outer">
                    <div className="login-left-panel">
                        <div className="login-bg">
                            <div className="logo-img">
                                {<img src={logo} className="" alt='logo' />}
                            </div>

                            <div className="login-txt">
                                <span></span>
                            </div>
                            {<img src={loanbox_Illustration} className="login-bg-img" alt='logo-bg' />}
                        </div>
                    </div>
                    <div className="login-right-panel">
                        <div className="login-heading">
                            <h2>{t('COMMON.DELETION_INSPECTION')}</h2>

                        </div>
                        <Tab.Container id="" defaultActiveKey="first">
                            <div className="login-tab"> 
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                    <RemoveInfoForm />
                                    </Tab.Pane>  
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default RemoveInfo