const React = require("react");
const { useState, useRef } = React;
const { default: ReactSelect, components } = require("react-select");

const MultiSelect = (props) => {
  const [selectInput, setSelectInput] = useState("");
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options, input) =>
    options?.filter(({ label }) =>
      label.toLowerCase().includes(input.toLowerCase())
    );

  const comparator = (v1, v2) => (v1.value - v2.value);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props) => (
    <components.Option {...props}>
      {props.value === "*" &&
        !isAllSelected.current &&
        filteredSelectedOptions?.length > 0 ? (
        <input
          key={props.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected.current}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: "5px", color: "black" }}>{props.label}</label>
    </components.Option>
  );

  const Input = (props) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
          {props.children}
        </components.Input>
      ) : (
        <div style={{ border: "1px dotted gray" }}>
          <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const ValueContainer = (props) => {
    let selectedValues =
      props && props.selectProps && props.selectProps.value && props.selectProps.value.length;

    let values = `${selectedValues} selected`;

    return (
      <>
        {selectedValues > 0 ? (
          <components.ValueContainer autoFocus={props.selectProps.menuIsOpen} {...props}>
            <div style={{ color: "black", fontSize: ".82em" }}>
              {props.children}
              {values}
            </div>
          </components.ValueContainer>
        ) : (
          <components.ValueContainer autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.ValueContainer>
        )}
      </>
    );
  };

  const customFilterOption = ({ value, label }, input) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const handleChange = (selected) => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) ===
          JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange([
        ...(props.value ?? []),
        ...props.options.filter(
          ({ label }) =>
            label.toLowerCase().includes(selectInput?.toLowerCase()) &&
            (props.value ?? []).filter((opt) => opt.label === label).length === 0
        ),
      ].sort(comparator));
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !== JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }) => !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };

  const customStyles = {
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    multiValueRemove: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "65px",
      overflow: "auto",
    }),
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isSelected && !isFocused ? null : isFocused && !isSelected
          ? styles.backgroundColor
          : isFocused && isSelected
          ? "#DEEBFF"
          : null,
        color: isSelected ? null : null,
      };
    },
    menu: (def) => ({ ...def, zIndex: 9999 }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        "fontSize": "0.82em",
      };
    },
  };

  if (props.isSelectAll && props.options.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) === JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `All (${filteredOptions.length}) selected`;
      else
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
    } else selectAllLabel.current = "Select all";

    allOption.label = selectAllLabel.current;

    return (
      <ReactSelect
        {...props}
        inputValue={selectInput}
        onInputChange={onInputChange}
        options={[allOption, ...props.options]}
        onChange={handleChange}
        components={{
          Input: Input,
          Option: Option,
          ValueContainer,
          ...props.components,
        }}
        placeholder={(props.value && props.value.length) ? "" : props.placeholder}
        filterOption={customFilterOption}
        menuPlacement={"bottom"}
        styles={customStyles}
        isMulti={true}
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
       // instanceId={useId()}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{
        Input: Input,
        ValueContainer,
        ...props.components,
      }}
      placeholder={(props.value && props.value.length) ? "" : props.placeholder}
      menuPlacement={"bottom"}
      hideSelectedOptions={true}
      controlShouldRenderValue={false}
     // instanceId={useId()}
    />
  );
};

export default MultiSelect
//module.exports = MultiSelect;
