
const ToggleSwitch = (props) => {

    const onToggleSwitchChange = () => {
        let { item } = props
        if(props.onChange){
            props.onChange(item)
        }
    }

    let { status } = props
    return (
        <div className='ToggleSwitch ToggleSwitch__rounded'>
            <div className='ToggleSwitch__wrapper'>
                <div className={`Slider ${status && 'isChecked'}`} onClick={onToggleSwitchChange}></div>
            </div>
        </div>
    );
}

export default ToggleSwitch