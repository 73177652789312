import { useEffect, useState } from "react";
import AddQcImageModal from "./AddQcImageModal";
import AlertImg from "@src/webroot/images/alert.svg";
import { useTranslation } from "react-i18next"

const TopDetailsBar = (props) => {

    let { vehicleData, imageTagList, callFrom } = props;

    const [showImageModal, setShowImageModal] = useState(false);
    const [imgTagData, setImgTagData] = useState({});
    const [showErrorImageTags, setShowErrorImageTags] = useState(false)
    const { t } = useTranslation("common")

    useEffect(() => {
        if (imageTagList) {
            for (let tag of imageTagList) {
                const tagOptions = tag.options

                if (tagOptions) {
                    for (let data of tagOptions) {
                        imgTagData['' + data.id] = data.tag_name;
                    }
                }
            }

            setImgTagData(imgTagData);
        }
    }, [imageTagList]) // eslint-disable-line react-hooks/exhaustive-deps

    let liClass = (callFrom === "correctionPanel") ? "w-15" : (callFrom === "ReQcPanel" ? "w-15" : "");

    return (
        <div className="m-xl-t">


            <div className="top-search-bar inspection-car-details-header">
                <div className="top-ribben-search">
                    <ul>
                        {
                            callFrom === "qcPanel" && vehicleData && <li className="">
                                <div className="pq-sub-heading" title={`${vehicleData.make && vehicleData.make + ' ' + vehicleData.modelVersion && vehicleData.modelVersion}`}>
                                    {`${(vehicleData.make ? vehicleData.make : '') + ' ' + (vehicleData.modelVersion ? vehicleData.modelVersion : '')}`}
                                </div>
                                <div className="pq-sub-content"> {t("BACK_OFFICE.MAKE_MODEL")} </div>
                            </li>
                        }

                        <li className={`${liClass}`}>
                            <div className="pq-sub-heading">{vehicleData && vehicleData.reg_no}</div>
                            <div className="pq-sub-content">{t("BACK_OFFICE.REGISTRATION_NO")}</div>
                        </li>

                        {
                            (callFrom === "correctionPanel" || callFrom === 'ReQcPanel') && vehicleData ? <li className={`${liClass}`}>
                                <div className="pq-sub-heading" title={`${vehicleData.make + ' ' + vehicleData.modelVersion}`}>
                                    {/* {`${vehicleData.make + ' ' + vehicleData.modelVersion}`} */}
                                    {vehicleData.evaluationDate || vehicleData.ce_date_updated}
                                </div>
                                <div className="pq-sub-content">{t("BACK_OFFICE.EVALUATION_DATE")} </div>
                            </li> : ''
                        }

                        <li className={`${liClass}`}>
                            <div className="pq-sub-heading">{vehicleData && vehicleData.dealerName}</div>
                            <div className="pq-sub-content"> {t("BACK_OFFICE.TABLE_HEADER.dealer")} </div>
                        </li>

                        <li className={`${liClass}`}>
                            <div className="pq-sub-heading">{vehicleData && (vehicleData.ce || vehicleData.ceName)}</div>
                            <div className="pq-sub-content"> {t("BACK_OFFICE.TABLE_HEADER.ce")} </div>
                        </li>
                        {
                            callFrom === "qcPanel" && 
                            <li className="">
                                <div className="pq-sub-heading">{vehicleData && vehicleData.productType}</div>
                                <div className="pq-sub-content"> {t("INSPECTION.PRODUCT_TYPE")} </div>

                            </li>
                        }
                        {
                            callFrom === "qcPanel" &&
                            <li className="">
                                <div className="pq-sub-heading">{vehicleData && vehicleData.catSubcat}</div>
                                <div className="pq-sub-content">{t("BACK_OFFICE.CAT_SUB_CATEGORY")}</div>

                            </li>
                        }
                        <li className={`${liClass}`}>
                            <div className="pq-sub-heading">{ vehicleData && vehicleData.totalImageCount}</div>
                            <div className="pq-sub-content">{t("BACK_OFFICE.TOTAL_IMAGES")}
                                {
                                    callFrom === "qcPanel" &&

                                    <span className="addimg-btn m-xl-l" onClick={() => setShowImageModal(true)}>{t("DO_QC.ADD_IMG")}</span>
                                }
                            </div>
                            <div className="gallery-control add-image-icon pull-left" >
                                <div className="add-img"></div>
                            </div>



                        </li>

                        {
                            callFrom === "qcPanel" && vehicleData && vehicleData.notUploadedImageTags && vehicleData.notUploadedImageTags.length ?
                                <li className="">
                                    <div className="pq-sub-content">
                                        <span className="imagescheck-btn" title="Click here to show not uploaded mandatory images " onClick={() => setShowErrorImageTags(true)}>
                                            <img src={AlertImg} alt="alert" /><i className="fa fa-exclamation-triangle"></i>
                                        </span>
                                    </div>
                                </li> : ''
                        }

                    </ul>
                </div>
            </div>

            {
                showErrorImageTags && <div className="mandatory_images_hideshow top-search-bar inspection-car-details-header">
                    <div className="top-ribben-search">
                        <i className="ic-clearclose" onClick={() => setShowErrorImageTags(false)}></i>
                        <ul>
                            {
                                vehicleData.notUploadedImageTags.map((tag, key) =>
                                    <li className="alert-danger" key={key}>
                                        <div className="pq-sub-heading">
                                            {imgTagData[tag]}
                                        </div>
                                    </li>
                                )
                            }

                        </ul>
                    </div>

                </div>
            }


            <div className="qc-bulk-image-uploader">
                <AddQcImageModal show={showImageModal} setShowUpdatePopup={() => setShowImageModal(false)} setReloadFromChild={props.setReloadFromChild} />
            </div>

        </div>

    )
}


export default TopDetailsBar;