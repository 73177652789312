import React from 'react'
import AddInspectionForm from '@src/view/inspection/common/AddInspectionForm'

function AddInspection() {

    return (
        <div>
            <AddInspectionForm />
        </div>
    )
}

export default AddInspection;