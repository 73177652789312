import { toast } from "react-toastify"
import * as HELPER from "@src/config/helper"
import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next"
import secureStorage from '@src/config/encrypt';
import InputField from '@src/view/element/InputField';
import BannerPreview from "@src/view/cards/BannerPreview"
import { AsyncPaginate } from 'react-select-async-paginate'
import FullPageLoader from "@src/view/common/FullPageLoader"
import Select from "react-select";
import { getMenus, getDealers, getInspectionFilters, getAllClientsData } from "@src/store/slices/common"
import MultiSelect from '@src/view/common/MultiSelect';
import { getUserAccess, getAllUsers, updateUserData, addNewUser, removeUserImage } from "@src/store/slices/user"
import { default as MultiSelectCheckboxOptions } from './MultiSelectCheckboxOptions';
import _ from 'lodash';
import ConfirmationModel from '@src/view/common/ConfirmationModel';

const UserForm = (props) => {

    const { t } = useTranslation("common")
    const { userDetails, cityList, stateList, callFrom } = props
    const [loading, setLoading] = useState(false)
    const [isDealerSearchActive, setIsDealerSearchActive] = useState(false)
    const [menuList, setMenuList] = useState([])
    const [accessList, setAccessList] = useState([])
    const [parent, setParents] = useState([])
    const [dealerCity, setDealerCity] = useState([])
    const [selectedDealer, setSelectedDealer] = useState([])
    const [dealerList, setDealerList] = useState([])
    const [paginatedDealerList, setPaginatedDealerList] = useState([])
    const [paginatedDealerListPage, setPaginatedDealerListPage] = useState(0)

    const [file, setFile] = useState()
    const [userRoles, setUserRoles] = useState([])
    const [formInputs, setFormInputs] = useState({})

    const [statusList, setStatusList] = useState([])
    const [corporate, setCorporate] = useState([])
    const [jobRole, setJobRole] = useState([])
    const [userFor, setUserFor] = useState([])
    const [assignedType, setAssignedType] = useState([])
    const [zonelist, setZonelist] = useState([])
    const [clientList, setClientList] = useState([])

    const [errors, setErrors] = useState({})

    const [isDealerCityUpdate, setIsDealerCityUpdate] = useState(false);

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const fetchUserDetails = secureStorage.getItem('authUserInfo')
    const loggedInUserDetails = fetchUserDetails && fetchUserDetails.user_data

    const commonState = useSelector(state => state.common)

    const [totalValidMenuCount, setTotalValidMenuCount] = useState(0);

    const [validAndCheckedMenus, setValidAndCheckedMenus] = useState([])
    const [activeField, setActiveField] = useState('');

    const [picDeleteConfirm, setPicDeleteConfirm] = useState(false);
    /**
      * fetches list of static dropdowns
      * @param {object} params 
      */
    const getConfig = async (params) => {
        dispatch(getInspectionFilters(params)).then((response) => {
            if (response) {
                setStatusList(response.STATUSLIST)
                setCorporate(response.CORPORATE)
                setJobRole(response.JOB_ROLE)
                setUserFor(response.USER_FOR)
                setAssignedType(response.ASSIGNED_TYPE)
                setZonelist(response.ZONELIST)

            }
        }).catch((error) => {
        })
    }

    /**
     * fetches list of static dropdowns
     * @param {object} params 
     */
    const getClientsDropdown = async () => {
        getAllClientsData().then((response) => {

            if (response && response.data) {
                const data = response.data
                setClientList(data)
            }
        }).catch((error) => {
        })
    }


    /**
      * fetches list of user access
      * @param {object} params 
      */
    const getUserAccessList = async (params) => {
        try {
            const userAccessList = await getUserAccess(params)

            //let updatedList = [{ label: "Select All", id: '*' }]
            let updatedList = []

            if (userAccessList && userAccessList.data) {
                for (let ele of userAccessList.data) {
                    updatedList.push({ label: ele.access_title, id: ele.id, name: ele.access_title, value: ele.access_title })
                }
            }

            setAccessList(updatedList)
        } catch (error) {
        }
    }


    /**
     * fetches list of menus
     * @param {object} params 
     */
    const getMenuList = async (params) => {
        try {
            const menuList = await getMenus(params)
            const data = menuList && menuList.data
            let updatedMenu = data

            let getAllMenuValues = [];

            let totalMenu = 0;
            for (let parent of updatedMenu) {
                if (!parent.disabled) {
                    // getAllMenuValues.push(parent);
                    for (let child of parent.options) {
                        if (!child.disabled) {
                            totalMenu++;
                            child['id'] = child['value']
                            getAllMenuValues.push(child);
                        }

                    }
                }
            }

            setValidAndCheckedMenus(getAllMenuValues)

            setTotalValidMenuCount(totalMenu);

            // updatedMenu = [{ label: 'Select All', allValues: [{ value: 'all' }, ...getAllMenuValues], value: 'all' }, ...updatedMenu];
            // console.log(getAllMenuValues)
            setMenuList(getAllMenuValues);
        } catch (error) {
        }
    }

    /**
     * function for auto filling input fields when component is called
     */

    const setUserDetails = async (userData) => {

        let { access_param, user_mapped_city, parent_role_id, dealerDetails, profile_pic, job_role, roleID, user_for, user_mapped_type, owner_city_id } = userDetails

        //if parent_role_id is found then auto select user's current parent
        if (parent_role_id) {
            getUserWithRoles({ role_id: parent_role_id, status: 1 })
        }

        //if assigned type is 2, then fetch statelist from user's previously select city list response
        if (+user_mapped_type === 2) {
            const previousSelectedCities = user_mapped_city ? user_mapped_city.split(',') : []

            const updatedStates = cityList && cityList.reduce((filteredStates, element) => {
                if (element && previousSelectedCities && previousSelectedCities.indexOf(`${element.id}`) !== -1) {
                    if (!filteredStates.includes(`'${element.state_id}'`)) {
                        filteredStates.push(`${element.state_id}`)

                    }
                }
                return filteredStates
            }, [])

            userData.statelist = updatedStates
        }

        //user for 
        if (user_for) {
            user_for === 'Dealer' ? user_for = 2 : user_for = 1
        }

        //job role
        if (job_role) {
            job_role === 'on-roll' ? job_role = 1 : job_role = 2
        }

        //filtering role id 
        if (roleID || +roleID === 0) {
            const updatedRole = props.userRoles && props.userRoles.filter((e) => +e.id === +roleID)
            setUserRoles(updatedRole)

            if (+roleID === 5) userData.corporate = 1
        }

        //if dealer city is present and dealerDetails is not, then set dealer city in state, else dealercity is pushed in below condition
        if (owner_city_id && !dealerDetails) {
            const ownerCityArray = owner_city_id.split(',')
            setDealerCity(ownerCityArray)
        }


        // if dealer is present, then store dealer in state
        if (dealerDetails) {

            let dealer = []
            let dealerCities = (owner_city_id && owner_city_id.split(',')) || []

            //using city id from response to fetch dealer details from getDetailsDetails API call
            for (let ele of dealerDetails) {
                if (!dealerCities.includes(`${ele.city_id}`)) {
                    dealerCities.push(`${ele.city_id}`)
                }
                if (!dealer.includes(ele.id)) {
                    dealer.push(ele.id)
                }
            }

            setDealerCity(dealerCities)
            setSelectedDealer(dealer)
        }


        //previewing profile pic
        if (profile_pic) {
            //const imageURL = `${CONFIG.IMAGE_URL}${profile_pic}`
            userData.profile_pic = profile_pic
        }

        const accessParams = (access_param && access_param!=='') ? JSON.parse(access_param) : ""
        const menu = (accessParams && access_param!=='') ? (accessParams.menu)? accessParams.menu.map(v=> +v):{} : ""

        setFormInputs({ ...userData, menu, user_mapped_city: user_mapped_city && user_mapped_city.split(','), job_role, user_for, zonelist: ['1'], gpsLocation: userData.gps_location })

    }


    /**
     * function to auto selet menu and access based on roles in Add User Page
     * @param {Array} menu 
     * @param {Array} access 
     */
    const autoSelectMenuAndAccess = (menu, access) => {
        let updatedMenu = []
        let menuArray = []
        let updatedAccess = []

        let responseObj = {}

        responseObj['selectedMenus'] = updatedMenu
        responseObj['menu'] = menuArray
        responseObj['access'] = updatedAccess

        if (menu && menu.length) {
            for (let ele of menuList) {
                if (ele.options && ele.options.length) {
                    for (let item of ele.options) {
                        const isChildMenuIncluded = menu.includes(`${item.value}`)
                        if (isChildMenuIncluded) {
                            if (!menuArray.includes('' + item.parent_id)) menuArray.push(item.parent_id.toString())

                            updatedMenu.push(item)
                            menuArray.push(+item.value)
                        }
                    }

                }
            }
        }

        if (access && access.length) {
            for (let ele of accessList) {
                if (access.includes(`${ele.id}`)) {
                    updatedAccess.push(ele.id.toString())
                }
            }
        }

        responseObj['selectedMenus'] = updatedMenu
        responseObj['menu'] = menuArray
        responseObj['access'] = updatedAccess

        return responseObj;
    }

    /**
     * function to auto select default inputs on Add User Page
     */

    const setDefaultProps = () => {
        let defaultInputs = {}

        if (statusList && statusList.length) {
            const defaultStatus = statusList.filter((e) => +e.value === 1)
            defaultInputs['status'] = defaultStatus && defaultStatus[0] && defaultStatus[0].value
        }

        if (jobRole && jobRole.length) {
            const defaultJobRole = jobRole.filter((e) => +e.value === 2)
            defaultInputs['job_role'] = defaultJobRole && defaultJobRole[0] && defaultJobRole[0].value
        }

        if (userFor && userFor.length) {
            const defaultUserFor = userFor.filter((e) => +e.value === 2)
            defaultInputs['user_for'] = defaultUserFor && defaultUserFor[0] && defaultUserFor[0].value
        }

        if (assignedType && assignedType.length) {
            const defaultAssignedType = assignedType.filter((e) => +e.value === 3)
            defaultInputs['user_mapped_type'] = defaultAssignedType && defaultAssignedType[0] && defaultAssignedType[0].value
        }

        //if component called from ce_add, then prefill role input to CE
        if (callFrom === 'user/ce_add') {
            const defaultUserRole = props.userRoles.filter((e) => +e.label === 'CE')
            defaultInputs['roleID'] = defaultUserRole && defaultUserRole[0] && defaultUserRole[0].id

            //calling for loading Parent List
            defaultInputs['roleID'] && getUserWithRoles({ role_id: defaultUserRole[0].parent_role_id, status: 1 })
        }

        setFormInputs(defaultInputs)
    }




    /**
     * handler function for input change
     * @param {string, number, object} value 
     * @param {string} name 
     */
    const changeHandler = (value = null, name = null, event = null) => {
        let formInputsObj = JSON.parse(JSON.stringify(formInputs));
        let newError=errors;
        if (["job_role", "user_for", "corporate_id", "client"].includes(name)) {
            formInputsObj[name] = (value && value.value) || null
        }

        else if (name === 'status') {
            const input = value ? value.value : ''
            formInputsObj['status'] = (value && `${input}`) || null
        }

        else if (["city_id", "parent_id", "roleID"].includes(name)) {

            formInputsObj[name] = (value && value.id) || null

            if (name === 'roleID') {
                if (value) {
                    //reseting parent input field and state
                    setParents([])
                    formInputsObj[`parent_id`] = null

                    const params = value.param ? JSON.parse(value.param) : {}

                    //if user is on ADD User Page and selects a role, then menu and access permissions are autofilled based on selected role permissions
                    if (callFrom === 'user/add' && Object.keys(params).length) {
                        const menu = params.menu
                        const access = params.access

                        const menuAccessInputs = autoSelectMenuAndAccess(menu, access)

                        formInputsObj = {
                            ...formInputsObj,
                            ...menuAccessInputs
                        }

                    }
                    //calling API for rendering parents
                    getUserWithRoles({ role_id: value.parent_role_id, status: 1 })
                }
                //reseting menu and access, if role selection is cleared on ADD User page
                else if (!value && callFrom === 'user/add') {
                    formInputsObj['menu'] = []
                    formInputsObj['access'] = []
                    formInputsObj['selectedMenus'] = []
                    formInputsObj[`parent_id`] = null
                    setParents([])
                }
                delete newError['userType'];
            }
            if (name === 'parent_id') {
                delete newError['userParent'];
            }
            
        }

        else if (name === 'assigned_type') {
            formInputsObj = { ...formInputsObj, user_mapped_type: (value && value.value) || null, user_mapped_city: null, zonelist: null, statelist: null }
        }

        else if (name === 'menu') {
            const menu = value.map((pages)=> [pages.value, pages.parent_id]).flat();
            formInputsObj['menu']=[...new Set(menu)]
            
        }

        else if (name === 'menu1') {
            //IF LABEL CLICK THEN DO NOTHING
            if (event?.action === 'set-value') {
                return false;
            }

            let selectedArray = []
            let selectedMenus = []

            if (value && value.length) {
                for (let ele of value) {
                    if (name === 'menu') {
                        selectedArray.push(ele.value.toString())

                        if (ele.parent_id && !selectedArray.includes('' + ele.parent_id)) selectedArray.push(ele.parent_id.toString())

                    }

                    else {
                        selectedArray.push(ele.id.toString())
                    }

                }
            }


            let getOnlyChilds = []
            selectedMenus = value

            if (event?.action === 'select-option') {
                if (event.option.value === 'all') {
                    let getAllValues = [...value, ...event.option.allValues]
                    getAllValues = _.uniqBy(getAllValues, 'value');

                    formInputsObj['selectedMenus'] = getAllValues
                    selectedArray = getAllValues.map(v => '' + v.value);
                    getOnlyChilds = getAllValues.filter(el => el.parent_id)
                    selectedMenus = getAllValues;

                } else {
                    formInputsObj['selectedMenus'] = value;
                    getOnlyChilds = value.filter(el => el.parent_id)

                }
            } else if (event?.action === 'deselect-option') { //deselect-all

                if (event.option.value === 'all') {
                    formInputsObj['selectedMenus'] = selectedMenus.filter(elm => elm.disabled);
                    selectedArray = formInputsObj['selectedMenus'].map(v => '' + v.value);

                } else {
                    formInputsObj['selectedMenus'] = value.filter((e) => e.value !== 'all');
                    getOnlyChilds = value.filter(el => el.parent_id);

                }
            } else if (event?.action === 'clear') { //deselect-all
                formInputsObj['selectedMenus'] = selectedMenus
                selectedArray = selectedMenus.map(v => '' + v.value);
                getOnlyChilds = [];
            }

            // getOnlyChilds = getOnlyChilds
            let getValidEnabledChilds = getOnlyChilds.filter(elm => !elm.disabled);

            if (totalValidMenuCount === getValidEnabledChilds.length) {
                selectedMenus = selectedMenus.filter(elm => elm.value !== 'all');

                selectedMenus = [{ label: 'Select All', allValues: [{ value: 'all' }, ...validAndCheckedMenus], value: 'all' }, ...selectedMenus];

                formInputsObj['selectedMenus'] = selectedMenus
            }

            formInputsObj[name] = selectedArray;
        }

        else if (["zonelist", "statelist", "user_mapped_city", "access", "dealer_city"].includes(name)) {

            if (value) {

                let selectedArray = []

                if (value.length) {

                    for (let ele of value) {
                        if (!selectedArray.includes(ele.id.toString())) {
                            selectedArray.push(ele.id.toString())
                        }

                    }
                }
                if (name === 'dealer_city') {
                    //if dealer city is empty, clear selected dealer array
                    if (!selectedArray || !selectedArray.length) {
                        setPaginatedDealerList([])
                        setSelectedDealer([])
                        setDealerList([])
                    }

                    let updatedSelectedDealer = selectedDealer

                    if (selectedDealer.length) {

                        // selectedDealer.map((el) => {
                        for (let el of selectedDealer) {
                            let dealer = [];
                            if (dealerList) { dealer = dealerList.find((e) => e.id === el) }
                            if (dealer && dealer.city && !selectedArray.includes(`${dealer.city}`)) {
                                updatedSelectedDealer = updatedSelectedDealer.filter((e) => e !== dealer.id)
                            }
                        }
                    }

                    setSelectedDealer(updatedSelectedDealer)
                    setDealerCity(selectedArray)
                    setIsDealerCityUpdate(true);
                }

                //for handling select all for User Access Dropdown
                if (name === 'access') {
                    if (event && event.action === 'select-option') {
                        //if select all option is checked, then select all the elements
                        if (event.option.id === '*') {
                            for (let access of accessList) {
                                if (!access.disabled || (formInputs.access && formInputs.access.includes(`${access.id}`))) {
                                    selectedArray.push(access.id.toString())
                                }
                            }
                        }
                        //if any item other than select all is checked
                        else {
                            let isAllOptionChecked = true
                            //checking if all item checks are equal to all accessible access and if so, then auto check select ALL option
                            for (let access of accessList) {

                                if (selectedArray.includes(`${access.id}`) || (access.disabled && formInputs.access && formInputs.access.includes(`${access.id}`))) {
                                    selectedArray.push(access.id.toString())
                                }
                                else if (!access.disabled && access.id !== '*' && !selectedArray.includes(`${access.id}`)) {
                                    isAllOptionChecked = false
                                }
                            }

                            if (isAllOptionChecked) {
                                selectedArray.push('*')
                            }
                            //if any item is checked except select all, then remove SELECT ALL if checked
                            else {
                                selectedArray = selectedArray.filter((e) => e !== '*')
                            }
                        }
                    }

                    //clear or unselect ALL case for user accesss dropdown
                    if (event && (event.action === 'deselect-option' || event.action === 'clear')) {
                        if (event.action === 'clear' || event.option.id === '*') {
                            let disabledAccessArray = []
                            for (let e of accessList) {

                                if (e.disabled && formInputs.access && formInputs.access.includes(`${e.id}`)) {
                                    disabledAccessArray.push(`${e.id}`)
                                }
                            }
                            //pushing already existing disabled array on clear
                            selectedArray = disabledAccessArray
                        }
                        else {
                            //if any other item except select all is unchecked
                            for (let access of accessList) {

                                if (selectedArray.includes(`${access.id}`) || (access.disabled && formInputs.access && formInputs.access.includes(`${access.id}`))) {
                                    if (!selectedArray.includes(access.id.toString())) {
                                        selectedArray.push(access.id.toString())
                                    }
                                }
                            }
                            selectedArray = selectedArray.filter((e) => e !== '*')
                        }
                    }
                }
                formInputsObj[name] = selectedArray;

            }
        }

        else if (name === 'selected_dealer') {
            if (value) {
                let selectedArray = []

                if (value.length) {

                    for (let ele of value) {
                        if (!selectedArray.includes(ele.id)) {
                            selectedArray.push(ele.id)
                        }
                    }
                }
                setIsDealerSearchActive(false)
                setSelectedDealer(selectedArray)

            }
        }

        else if (name === 'user_exp') {
            const input = value.target.value;
            if ((input === '' || (input && input.length <= 2)) && Number(input) < 100) {
                formInputsObj[name] = input;
            }
        }
        else {
            if (value) {
                const input = value.target.value
                formInputsObj[name] = input;
            }
        }
       
        delete newError[name];
        setFormInputs({ ...formInputsObj });
        setErrors({...newError})

    }


    /**
     * function to load dealers using lazy loading
     * @param {String} search 
     * @returns 
     */
    const loadDealerMenuOptions = (search) => {
        //reseting state
        setIsDealerSearchActive(false)

        const paginatedLength = 20;

        let dealerListTotalPages = Math.ceil(dealerList.length / paginatedLength);

        if (search.trim()) {
            setIsDealerSearchActive(true);
            const letterToSearch = search.toLowerCase();
            const filteredArray = dealerList.filter((ele) => {
                const label = ele.label.toLowerCase();
                return label.includes(letterToSearch);
            });

            return {
                options: filteredArray,
                hasMore: false,
            };
        }

        const paginatedListLength = paginatedDealerList && paginatedDealerList.length

        let updatedList = []

        if (!paginatedListLength) {
            updatedList = dealerList.slice(0, paginatedLength);
            setIsDealerCityUpdate(false);
        } else {
            updatedList = dealerList.slice(
                paginatedLength * paginatedDealerListPage,
                paginatedLength * paginatedDealerListPage + paginatedLength
            );

            if (isDealerCityUpdate) {
                updatedList = dealerList.slice(0, paginatedLength * paginatedDealerListPage + paginatedLength);
                setIsDealerCityUpdate(false);
            }
        }

        setPaginatedDealerListPage(prev => prev + 1);

        setPaginatedDealerList(updatedList)

        return {
            options: updatedList,
            hasMore: dealerListTotalPages !== paginatedDealerListPage,
        };
    };

    /**
     * handler function for resetting all the input fields or redirection on cancel button
     */

    const handleResetBtn = (e) => {
        e.preventDefault()

        if (callFrom === 'user/add' || callFrom === 'user/ce_add') {
            setFormInputs({})
            setDealerCity([])
            setSelectedDealer([])
            setErrors({})
        }

        else if (callFrom === 'user/ce_edit') {
            navigate('/user/ce_list')
        }

        else {
            navigate('/user/list')
        }

    }


    /**
     * runs when form submit
     */

    const handleSubmitBtnClick = async (e) => {
        e.preventDefault()
        let { firstname, lastname, email, password, mobile, status, employee_code, job_role, city_id, user_for, user_mapped_type, user_mapped_city, roleID, parent_id, id, zonelist, statelist, menu, access, user_exp, ins_count, corporate_id, gpsLocation, } = formInputs

        //preparing data
        const formData = new FormData()

        //adding file to request
        if (file) formData.append('files', file)

        let updateObj = {
            firstname,
            lastname,
            email,
            mobile,
            status: (status || +status === 0) ? status.toString() : '',
            employee_code,
            job_role,
            city_id,
            password,
            user_mapped_type,
            userFor: user_for,
            userType: roleID,
            userParent: parent_id,
            userID: id,
            dealer: selectedDealer,
            menuID: menu,//menu ? menu.filter((e) => e !== 'all') : [],
            accessID: access ? access.filter((e) => e !== '*') : [],
            dealerCity,
            user_exp,
            ins_count,
            statelist,
            zonelist,
            user_mapped_city,
            corporate_id,
            gpsLocation

        }

        //validation
        let requiredFields = ['firstname', 'email', 'mobile', 'status', 'job_role', 'city_id', 'userFor', 'user_mapped_type', 'userType']

        //if userType(user selected role is not 1, not Admin), then userParent is required
        if (+updateObj.userType !== 1) {
            requiredFields.push('userParent')
        }

        //if user role is CE, then validate user experience and inspection count
        if (+updateObj.userType === 5) {
            let condtionalRequiredFields = ['user_exp', 'ins_count']
            requiredFields.push(...condtionalRequiredFields)
        }

        //validation specifc for add CE
        if (callFrom === 'user/ce_add') {
            let condtionalRequiredFields = ['corporate_id', 'user_exp', 'ins_count', 'password']
            requiredFields.push(...condtionalRequiredFields)
        }

        //adding validation specific for add user
        if (callFrom === 'user/add') {
            requiredFields.push('password')
        }

        if (callFrom === 'user/edit' && password) {
            requiredFields.push('password')
        }

        if (callFrom === 'user/ce_edit' && password) {
            requiredFields.push('password')
        }

        let errorFlag = validateInputs(updateObj, requiredFields)

        //preparing formdata
        for (let ele of Object.keys(updateObj)) {
            const isArray = Array.isArray(updateObj[ele])

            //if not an array then directly append in formdata
            if (updateObj[ele] && !isArray) {
                formData.append(ele, updateObj[ele])
            }
            //if key is an array then convert into string
            else if (updateObj[ele] && isArray) {
                let appendObj = ele

                //changing key name for user_mapped_city
                if (ele === 'user_mapped_city') appendObj = 'city_cluster'

                formData.append(appendObj, updateObj[ele].toString())
            }
        }

        try {
            setLoading(true)
            let redirectTo = '/user/list'

            if (!errorFlag && (callFrom === 'user/add' || callFrom === 'user/ce_add')) {
                if (callFrom === 'user/ce_add') redirectTo = '/user/ce_list'

                const addUser = await addNewUser(formData)
                if (addUser && addUser.status === 201) navigate(redirectTo)
            }
            else if (!errorFlag && (callFrom === 'user/edit' || callFrom === 'user/ce_edit')) {
                if (callFrom === 'user/ce_edit') redirectTo = '/user/ce_list'

                const updateUser = await updateUserData(formData)
                if (updateUser && updateUser.status === 200) navigate(redirectTo)

            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error);
        }
    }

    /**
     * fetch users of specific role using role id
     * fetching parents on the basis of role selected
     * @param {object} params 
     */
    const getUserWithRoles = async (params) => {

        dispatch(getAllUsers(params)).then((res) => {
            if (res && res.status === 200) {
                const data = res.data
                const users = data && data.map((ele) => {
                    return { id: ele.id, label: ele.name }
                })
                setParents(users)
            }
        }).catch(err => {
        })
    }

    /**
     * fetches dealers on the basis of city ids provided
     * @param {Array} cityId 
     */

    const getDealerDetails = async (cityId) => {
        try {
            setDealerList([])
            let updatedDealers = []
            if(cityId.length){
                setLoading(true)
                const fetchDealers = await getDealers({ filter: { city_ids: cityId } })
                updatedDealers = fetchDealers.data && fetchDealers.data.map((ele) => {
                    return { id: ele.id, label: ele.organization, city: ele.city_id }
                })
                setDealerList(updatedDealers)
                if (updatedDealers) setLoading(false)

            }else{
                setDealerList([])
                setSelectedDealer([])
               
            }
        } catch (error) {
            setLoading(false)
        }
    }



    //handles file attachment
    const handlefileInput = async (e) => {
        let selectedFile = e.target.files[0]

        if (selectedFile) {
            const base64PreviewImg = await HELPER.toBase64(selectedFile)
            const filetype = await HELPER.detectMimeType(base64PreviewImg)
            const fileSize = selectedFile.size
            let errorFlag = false

            if (!filetype) {
                toast.error(t("COMMON.PLEASE_SELECT_VALID_FILE"))
                e.target.value = ''
                errorFlag = true
            } else if (fileSize < 25000 || fileSize > 8000000) {

                toast.error(t("COMMON.FILE_SIZE"))
                e.target.value = ''
                errorFlag = true
            }
            let updatedFile = !errorFlag ? selectedFile : ''
            const updatedPreview = !errorFlag ? base64PreviewImg : ''

            if (updatedPreview) {
                setFile(updatedFile)
                setFormInputs({ ...formInputs, profile_pic_preview: updatedPreview })
            }
        }

    }

    /**
     * validate Inputs against fields
     * @param {Object} formInput 
     * @param {Array} requiredFields 
     * @returns error true or false
     */
    const validateInputs = (inputFields, requiredFields) => {

        //reseting error
        setErrors({})
        let newErrors = {}
        let errorFlag = false

        for (const key of requiredFields) {

            let updateKeyName = key.replace(/_/g, "").toUpperCase()

            if (!inputFields.hasOwnProperty(key) || !inputFields[key]) {

                let createError = t(`VALIDATION.${updateKeyName}_REQUIRED`)
                newErrors[key] = createError
                errorFlag = true
            }

            //if firstname is less than 3, greater than 30 , is alphanumeric or contains white spaces then give error
            if (key === 'firstname' && (!inputFields[key] || inputFields[key].length < 3 || inputFields[key].length > 30 || (/\d/.test(inputFields[key])) || !(/^\S*$/).test(inputFields[key]))) {

                let createError = t(`VALIDATION.${updateKeyName}_VALIDATION`)
                newErrors[key] = createError
                errorFlag = true
            }

            //if mobile number is not 8-12 digits and does not start from 8
            if (key === 'mobile') {

                if (inputFields[key] && ((inputFields[key].length < 8 || inputFields[key].length > 12) || !((/^[8]\d{7,11}$/).test(inputFields[key])))) {
                    let createError = t(`VALIDATION.${updateKeyName}_VALIDATION`)
                    newErrors[key] = createError
                    errorFlag = true
                }
            }

            //check valid email
            if (key === 'email') {
                const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g // eslint-disable-line
                const checkEmail = inputFields[key] && inputFields[key].match(isValidEmail)

                if (!checkEmail) {
                    let createError = t(`VALIDATION.VALID_EMAIL`)
                    newErrors[key] = createError
                    errorFlag = true
                }
            }

            //strong password valdation
            if (key === 'password') {
                const isValidPassword = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,}$/ // eslint-disable-line
                const checkPassword = inputFields[key] && inputFields[key].match(isValidPassword)

                if (!checkPassword) {
                    let createError = t(`VALIDATION.VALID_PASSWORD`)
                    newErrors[key] = createError
                    errorFlag = true
                }

            }

            //for assigned type errors
            if (key === 'user_mapped_type' && inputFields[key]) {
                const mappedType = inputFields[key]

                switch (mappedType) {
                    case 1: {
                        delete inputFields['user_mapped_city']
                        delete inputFields['statelist']

                        //if assigned type is 1 and zone is not selected, then give error
                        if (!formInputs.zonelist || !formInputs.zonelist.length) {
                            newErrors['zonelist'] = t("VALIDATION.ZONE_REQUIRED")
                            errorFlag = true
                        }
                        break
                    }

                    case 2: {
                        delete inputFields['user_mapped_city']
                        delete inputFields['zonelist']

                        //if assigned type is 2 and state is not selected, then give error
                        if (!formInputs.statelist || !formInputs.statelist.length) {
                            newErrors['statelist'] = t("VALIDATION.STATE_REQUIRED")
                            errorFlag = true
                        }
                        break
                    }

                    case 3: {
                        delete inputFields['zonelist']
                        delete inputFields['statelist']

                        //if assigned type is 3 and city is not selected, then give error
                        if (!formInputs.user_mapped_city || !formInputs.user_mapped_city.length) {
                            newErrors['user_mapped_city'] = t("VALIDATION.ASSIGNEDCITY_REQUIRED")
                            errorFlag = true
                        }
                        break
                    }

                    default:
                        break
                }

            }
        }
        setErrors(newErrors)
        return errorFlag
    }

    useEffect(() => {
        if (props.userDetails && Object.keys(props.userDetails).length) {
            const userRole = userDetails.roleID
            //only super admin can edit admin data
            if (+userRole === 1 && +loggedInUserDetails.id !== 1) {
                toast.error(t("VALIDATION.EDIT_ACCESS_ERROR"))
                navigate('/user/list')
            }

            setFormInputs(props.userDetails)

            if (callFrom === 'user/edit' || callFrom === 'user/ce_edit') setUserDetails(props.userDetails)
        }


        else if (callFrom === 'user/add' || callFrom === 'user/ce_add') {
            setUserRoles(props.userRoles)
            setDefaultProps()
        }
    }, [props.userDetails, props.userRoles, statusList]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        //loading only relevant menu items
        const menuAccessParams = {
            status: '1'
        }

        if (callFrom !== 'user/ce_edit') {
            getMenuList(menuAccessParams)
            getUserAccessList(menuAccessParams)
        }

        //fetching clients list if loggedIN user is superadmin
        if (loggedInUserDetails && +loggedInUserDetails.id === 1) {
            getClientsDropdown()
        }

        if (commonState && !commonState.inspectionFilters) {
            getConfig()
        }
        else {
            let inspectionFilters = commonState.inspectionFilters

            setStatusList(inspectionFilters.STATUSLIST)
            setCorporate(inspectionFilters.CORPORATE)
            setJobRole(inspectionFilters.JOB_ROLE)
            setUserFor(inspectionFilters.USER_FOR)
            setAssignedType(inspectionFilters.ASSIGNED_TYPE)
            setZonelist(inspectionFilters.ZONELIST)
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        getDealerDetails(dealerCity)
    }, [dealerCity]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (callFrom === 'user/edit') {

            const { access_param } = props.userDetails

            const accessParams = access_param ? JSON.parse(access_param) : {}
            const menu = accessParams ? accessParams.menu : {}
            const access = accessParams ? accessParams.access : {}
            let updatedMenu = []

            //if menu is present for selected User then auto select previous menu items
            if (menu && menu.length) {
                let checkIfAllMenuExist = true

                if (menuList && menuList.length) {

                    for (let ele of menuList) {
                        if (ele.options && ele.options.length) {
                            //is parent menu present in user's previous menu array
                            // const isMenuParentIncluded = menu.includes(`${ele.value}`)

                            // if(isMenuParentIncluded) updatedMenu.push({label: ele.label, value: ele.value})
                            for (let item of ele.options) {

                                const isChildMenuIncluded = menu.includes(`${item.value}`)
                                if (isChildMenuIncluded) {
                                    updatedMenu.push(item)
                                }
                                if (!isChildMenuIncluded && !item.disabled) {
                                    checkIfAllMenuExist = false
                                }
                            }

                        }
                    }

                    if (checkIfAllMenuExist) {
                        updatedMenu.push(menuList[0])
                    }

                }

            }

            //if all options are selected of user access, then select all should be checked
            if (access && accessList && accessList.length) {
                let checkIfAllAccessExist = true
                for (let value of accessList) {
                    if (!value.disabled && value.id !== '*' && !access.includes(`${value.id}`)) {
                        checkIfAllAccessExist = false
                    }
                }

                if (checkIfAllAccessExist) {
                    access.push('*')
                }
            }

            let getSelectedValues = [];
            if (updatedMenu) {
                getSelectedValues = updatedMenu.filter(el => el && el['parent_id']);
            }
            if (getSelectedValues.length) {
                setTimeout(() => {
                    if (document.querySelector('.user-menu-list div button span:first-child')) {
                        document.querySelector('.user-menu-list div button span:first-child').innerHTML = `${getSelectedValues.length} item${getSelectedValues.length > 1 ? 's' : ''} selected`
                    }
                }, 100);
            }

            setFormInputs((prev) => {
                return { ...prev, selectedMenus: updatedMenu, access }
            })

        }

    }, [accessList, menuList, props.userDetails]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
    * DELETE USER PICTURE
    */
    const handlePicDeleteConfirmation = async (response) => {
        if (response.response) {

            const picResponse = await removeUserImage({ userID: props.hashId })
            if (picResponse.status === 200) {
                setFormInputs({ ...formInputs, profile_pic: "", profile_pic_preview: "" })
                document.getElementById("upload-image-input").value = null
                toast.success(t("USER_MODULE.DELETE_IMAGES"))
                setFile()
            }

            setPicDeleteConfirm(false);

        } else {
            setPicDeleteConfirm(false);
        }
    }

    return (
        <>
            <div className="container-fluid-full">
                <FullPageLoader show={props.loading || loading} />

                <div className="heading-txt">
                    <h3>{t("USER_MODULE.BASIC_DETAILS")}</h3>
                </div>


                <div className="edit-user-sec">
                    <div className="edit-user-list">
                        <div className="edit-user-detail-form">
                            <div className="edit-form row">

                                <fieldset className="datepicer col-md-4">
                                    <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                                id: "firstname",
                                                type: "text",
                                                name: "firstname",
                                                value: formInputs['firstname'] || '',
                                                placeholder: " ",
                                                className: 'form-input',
                                                maxLength: "50",
                                            }}
                                            onChange={(val) => changeHandler(val, "firstname")}
                                        />
                                        <label data-label={t("FORM.FIRST_NAME") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.firstname
                                                ?
                                                <span className="error-txt">{errors.firstname}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="datepicer col-md-4">
                                    <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                                id: "lastname",
                                                type: "text",
                                                name: "lastname",
                                                placeholder: " ",
                                                value: formInputs.lastname || '',
                                                className: 'form-input',
                                                maxLength: "50"
                                            }}
                                            onChange={(val) => changeHandler(val, "lastname")}
                                        />
                                        <label data-label={t("FORM.LAST_NAME")} className="form-label"></label>

                                    </div>
                                </fieldset>

                                <fieldset className="datepicer col-md-4">
                                    <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                                id: "email",
                                                type: "text",
                                                name: "email",
                                                placeholder: " ",
                                                value: formInputs.email || '',
                                                className: 'form-input',
                                                maxLength: "50",
                                                disabled: (callFrom === 'user/edit' || callFrom === 'user/ce_edit') ? true : false
                                            }}
                                            onChange={(val) => changeHandler(val, "email")}
                                        />
                                        <label data-label={t("FORM.EMAIL") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.email
                                                ?
                                                <span className="error-txt">{errors.email}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="datepicer col-md-4 long-txt-error">
                                    <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                                id: "password",
                                                type: "password",
                                                name: "password",
                                                placeholder: " ",
                                                value: formInputs.password || '',
                                                className: 'form-input',
                                                maxLength: "50"
                                            }}
                                            onChange={(val) => changeHandler(val, "password")}
                                        />
                                        <label data-label={callFrom === 'user/edit' ? t("FORM.PASSWORD") : t("FORM.PASSWORD") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.password
                                                ?
                                                <span className="error-txt long-error">{errors.password}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="datepicer col-md-4 long-txt-error">
                                    <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                                id: "mobile",
                                                type: "text",
                                                name: "mobile",
                                                placeholder: " ",
                                                value: formInputs.mobile || '',
                                                className: 'form-input',
                                                maxLength: "12"
                                            }}
                                            onChange={(val) => changeHandler(val, "mobile")}
                                        />
                                        <label data-label={t("FORM.MOBILE") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.mobile
                                                ?
                                                <span className="error-txt">{errors.mobile}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>

                                <fieldset className="form-select-bx col-md-4">
                                    <div className={(formInputs.status || +formInputs.status === 0) ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            value={statusList.filter((e) => +e.value === +formInputs.status)}
                                            onChange={(val) => changeHandler(val, "status")}
                                            options={statusList}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown'
                                            isClearable={true}
                                            getOptionValue={(option) => option.value}
                                            isSearchable={false}

                                        />
                                        <label data-label={t("FORM.STATUS") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.status
                                                ?
                                                <span className="error-txt">{errors.status}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="datepicer col-md-4">
                                    <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                                id: "emp_code",
                                                type: "text",
                                                name: "emp_code",
                                                placeholder: " ",
                                                value: formInputs.employee_code || '',
                                                className: 'form-input',
                                                maxLength: "50"
                                            }}
                                            onChange={(val) => changeHandler(val, "employee_code")}
                                        />
                                        <label data-label={t("FORM.EMP_CODE")} className="form-label"></label>
                                        {
                                            errors && errors.employee_code
                                                ?
                                                <span className="error-txt">{errors.employee_code}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="form-select-bx col-md-4">
                                    <div className={(formInputs.job_role || activeField === 'job_role') ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            id="job_role"
                                            name="job_role"
                                            onChange={(val) => changeHandler(val, 'job_role')}
                                            options={jobRole}
                                            value={jobRole.filter((e) => +e.value === +formInputs.job_role)}
                                            isClearable={true}
                                            placeholder=' '
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('job_role') }}
                                        />
                                        <label data-label={t("FORM.JOB_ROLE") + (" *")} className="form-label"></label>
                                        {
                                            errors && errors.job_role
                                                ?
                                                <span className="error-txt">{errors.job_role}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="form-select-bx col-md-4">
                                    <div className={(formInputs.city_id || activeField === 'city_id') ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            options={cityList}
                                            onChange={(val) => changeHandler(val, 'city_id')}
                                            placeholder=''
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            isClearable={true}
                                            getOptionValue={(option) => option.id}
                                            value={cityList && cityList.filter((ele) => formInputs.city_id ? +ele.id === +formInputs.city_id : null)}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('city_id') }}
                                        />
                                        <label data-label={t("FORM.CITY") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.city_id
                                                ?
                                                <span className="error-txt">{errors.city_id}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="form-select-bx col-md-4">
                                    <div className={formInputs.user_for ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            id="user_for"
                                            onChange={(val) => changeHandler(val, 'user_for')}
                                            options={userFor}
                                            value={userFor.filter((ele) => formInputs.user_for ? '' + ele.value === '' + formInputs.user_for : null)}
                                            isClearable={true}
                                            placeholder=''
                                            getOptionValue={(option) => option.value}
                                            isSearchable={false}
                                        />
                                        <label data-label={t("FORM.USER_FOR") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.userFor
                                                ?
                                                <span className="error-txt">{errors.userFor}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="form-select-bx col-md-4">
                                    <div className={"material animation-effect "}>
                                        <span className="label">{t("FORM.UPLOAD_PIC")} </span>
                                        <input
                                            type="file"
                                            name="image"
                                            id="upload-image-input"
                                            className="upload-box"
                                            accept="image/*"
                                            onChange={(e) => handlefileInput(e)}
                                        />
                                        {(formInputs && (formInputs.profile_pic || formInputs.profile_pic_preview)) ? <BannerPreview 
                                        element={{ image: [{ "image_path": formInputs.profile_pic, "image_preview_path": formInputs.profile_pic_preview }] }} 
                                        title={formInputs.profile_pic ? t("USER_MODULE.VIEW_PIC") : ""} 
                                        deleteTitle={formInputs.profile_pic ? t("USER_MODULE.DELETE_PIC") : ""} 
                                        deleteImageConfirm={() => setPicDeleteConfirm(true)} previewTitle={formInputs.profile_pic_preview ? t("USER_MODULE.PREVIEW_PIC") : ""} /> : ''}

                                        {
                                            errors && errors.profile_pic
                                                ?
                                                <span className="error-txt">{errors.profile_pic}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>

                                {/* If superadmin then show client dropdown */}
                                {
                                    loggedInUserDetails && +loggedInUserDetails.id !== 1 ? ''
                                        : <fieldset className="form-select-bx col-md-4">
                                            <div className={formInputs.client ? "active-label material animation-effect" : "material animation-effect "}>
                                                <Select
                                                    className="react-select"
                                                    classNamePrefix='inspection-dropdown react-select'
                                                    id="client"
                                                    onChange={(val) => changeHandler(val, 'client')}
                                                    options={clientList}
                                                    value={clientList.filter((ele) => formInputs.client ? '' + ele.value === '' + formInputs.client : null)}
                                                    isClearable={true}
                                                    placeholder=''
                                                    getOptionValue={(option) => option.value}
                                                    isSearchable={false}
                                                />
                                                <label data-label={t("FORM.CLIENT") + " *"} className="form-label"></label>
                                                {
                                                    errors && errors.userFor
                                                        ?
                                                        <span className="error-txt">{errors.userFor}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>
                                }


                                <fieldset className="form-select-bx col-md-4">
                                    <div className={formInputs.user_mapped_type ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            id="assigned_type"
                                            onChange={(val) => changeHandler(val, 'assigned_type')}
                                            options={assignedType}
                                            value={assignedType.filter((ele) => formInputs.user_mapped_type ? '' + ele.value === '' + formInputs.user_mapped_type : null)}
                                            isClearable={true}
                                            placeholder=''
                                            getOptionValue={(option) => option.value}
                                            isSearchable={false}
                                        />
                                        <label data-label={t("FORM.ASSIGNED_TYPE") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.user_mapped_type
                                                ?
                                                <span className="error-txt">{errors.user_mapped_type}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                {formInputs && formInputs.user_mapped_type && +formInputs.user_mapped_type === 1 ?

                                    <fieldset className="multiselect-dropDown col-md-4">
                                        <div className={formInputs.zonelist && formInputs.zonelist.length ? "active-label material animation-effect" : "material animation-effect "}>


                                            <MultiSelect
                                                options={zonelist}
                                                isSelectAll={true}
                                                value={zonelist && zonelist.filter((e) => formInputs && formInputs.zonelist && formInputs.zonelist.indexOf(`${e.id}`) !== -1)}
                                                onChange={(val) => changeHandler(val, 'zonelist')}
                                                placeholder=''

                                            />


                                            <label data-label={t("FORM.ASSIGNED_ZONE") + " *"} className="form-label"></label>
                                            {
                                                errors && errors.zonelist
                                                    ?
                                                    <span className="error-txt">{errors.zonelist}</span>
                                                    :
                                                    ''
                                            }

                                        </div>
                                    </fieldset>
                                    : formInputs && formInputs.user_mapped_type && +formInputs.user_mapped_type === 2 ?
                                        <fieldset className="multiselect-dropDown col-md-4">
                                            <div className={formInputs.statelist && formInputs.statelist.length ? "active-label material animation-effect" : "material animation-effect "}>


                                                <MultiSelect
                                                    options={stateList}
                                                    isSelectAll={true}
                                                    value={stateList && stateList.filter((e) => formInputs && formInputs.statelist && formInputs.statelist.indexOf(`${e.id}`) !== -1)}
                                                    onChange={(val) => changeHandler(val, 'statelist')}
                                                    placeholder=''

                                                />

                                                <label data-label={t("FORM.ASSIGNED_STATE") + " *"} className="form-label"></label>
                                                {
                                                    errors && errors.statelist
                                                        ?
                                                        <span className="error-txt">{errors.statelist}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset> :
                                        formInputs && formInputs.user_mapped_type && +formInputs.user_mapped_type === 3 ?
                                            <fieldset className="multiselect-dropDown col-md-4">
                                                <div className={formInputs.user_mapped_city && formInputs.user_mapped_city.length ? "active-label material animation-effect" : "material animation-effect "}>


                                                    <MultiSelect
                                                        options={cityList}
                                                        isSelectAll={true}
                                                        value={cityList && cityList.filter((e) => formInputs && formInputs.user_mapped_city && formInputs.user_mapped_city.indexOf(`${e.id}`) !== -1)}
                                                        onChange={(val) => changeHandler(val, 'user_mapped_city')}
                                                        placeholder=''

                                                    />

                                                    <label data-label={t("FORM.ASSIGNED_CITY") + " *"} className="form-label"></label>

                                                    {
                                                        errors && errors.user_mapped_city
                                                            ?
                                                            <span className="error-txt">{errors.user_mapped_city}</span>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </fieldset> : ''
                                }



                                <fieldset className="form-select-bx col-md-4">
                                    <div className={(formInputs.roleID || activeField === 'roleID') ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            id="user_role"
                                            onChange={(val) => changeHandler(val, 'roleID')}
                                            options={userRoles}
                                            value={userRoles && userRoles.filter((e) => formInputs && formInputs.roleID ? +e.id === +formInputs.roleID : '')}
                                            isClearable={true}
                                            placeholder=''
                                            getOptionValue={(option) => option.id}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => setActiveField('roleID')}
                                        />
                                        <label data-label={t("FORM.USER_ROLE") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.userType
                                                ?
                                                <span className="error-txt">{errors.userType}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>



                                <fieldset className="form-select-bx col-md-4">
                                    <div className={(formInputs.parent_id || activeField === 'parent_id') ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            id="parent"
                                            onChange={(val) => changeHandler(val, 'parent_id')}
                                            options={parent}
                                            value={parent && parent.filter((ele) => formInputs.parent_id ? +ele.id === +formInputs.parent_id : '')}
                                            isClearable={true}
                                            placeholder=''
                                            getOptionValue={(option) => option.id}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => setActiveField('parent_id')}
                                        />
                                        <label data-label={t("FORM.PARENT") + " *"} className="form-label"></label>
                                        {
                                            errors && errors.userParent
                                                ?
                                                <span className="error-txt">{errors.userParent}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>

                                {/* if CE is selected then display inspection count and experience input fileds */}
                                {
                                    (+formInputs.roleID === 5 || callFrom === 'user/ce_add' || callFrom === 'user/ce_edit') ?
                                        <>
                                            {(callFrom === 'user/edit' || callFrom === 'user/ce_add' || callFrom === 'user/ce_edit') ?
                                                <fieldset className="form-select-bx col-md-4">
                                                    <div className={formInputs.corporate_id ? "active-label material animation-effect" : "material animation-effect "}>
                                                        <Select
                                                            className="react-select"
                                                            classNamePrefix='inspection-dropdown react-select'
                                                            id="corporate_name"
                                                            onChange={(val) => changeHandler(val, 'corporate_id')}
                                                            options={corporate}
                                                            value={corporate && corporate.filter((ele) => formInputs.corporate_id ? +ele.value === +formInputs.corporate_id : '')}
                                                            placeholder={''}
                                                            getOptionValue={(option) => option.id}
                                                            isSearchable={false}
                                                        />
                                                        <label data-label="Select Corporate *" className="form-label"></label>
                                                        {
                                                            errors && errors.corporate_id
                                                                ?
                                                                <span className="error-txt">{errors.corporate_id}</span>
                                                                :
                                                                ''
                                                        }
                                                    </div>
                                                </fieldset> : ''}



                                            <fieldset className="datepicer col-md-4">
                                                <div className={"material"}>
                                                    <InputField
                                                        inputProps={{
                                                            id: "user_experience",
                                                            type: "text",
                                                            name: "user_experience",
                                                            placeholder: " ",
                                                            value: formInputs.user_exp || '',
                                                            className: 'form-input',
                                                            maxLength: "2",
                                                        }}
                                                        onChange={(val) => changeHandler(val, "user_exp")}
                                                    />
                                                    <label data-label={t("FORM.USER_EXP") + " *"} className="form-label"></label>
                                                    {
                                                        errors && errors.user_exp
                                                            ?
                                                            <span className="error-txt">{errors.user_exp}</span>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </fieldset>


                                            <fieldset className="datepicer col-md-4">
                                                <div className={"material"}>
                                                    <InputField
                                                        inputProps={{
                                                            id: "ins_count",
                                                            type: "text",
                                                            name: "ins_count",
                                                            placeholder: " ",
                                                            value: formInputs.ins_count || '',
                                                            className: 'form-input',

                                                            maxLength: "50",
                                                        }}
                                                        onChange={(val) => changeHandler(val, "ins_count")}
                                                    />
                                                    <label data-label={t("FORM.INS_COUNT") + " *"} className="form-label"></label>
                                                    {
                                                        errors && errors.ins_count
                                                            ?
                                                            <span className="error-txt">{errors.ins_count}</span>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </fieldset>
                                        </> : ''
                                }


                                <fieldset className="multiselect-dropDown col-md-4">
                                    <div className={dealerCity.length ? "active-label material animation-effect" : "material animation-effect "}>


                                        <MultiSelect
                                            options={cityList}
                                            isSelectAll={true}
                                            value={cityList ? cityList.filter((ele) => {
                                                return dealerCity ? dealerCity.indexOf(`${ele.id}`) !== -1 : ''
                                            }) : ''}
                                            onChange={(val) => changeHandler(val, 'dealer_city')}
                                            placeholder={''}
                                        />

                                        <label data-label={t("FORM.SELECT_DEALER_CITY")} className="form-label"></label>
                                        {
                                            errors && errors.dealerCity
                                                ?
                                                <span className="error-txt">{errors.dealerCity}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>


                                <fieldset className="multiselect-dropDown col-md-4">
                                    <div className={isDealerSearchActive || (selectedDealer && selectedDealer.length) ? "active-label material animation-effect" : "material animation-effect "}>

                                        <AsyncPaginate
                                            id="selected_dealer"
                                            value={dealerList ? dealerList.filter((ele) => {
                                                return selectedDealer ? selectedDealer.indexOf(ele.id) !== -1 : ''
                                            }) : ''}
                                            loadOptions={(e) => loadDealerMenuOptions(e)}
                                            onChange={(val) => changeHandler(val, 'selected_dealer')}
                                            getOptionValue={option => option.id}
                                            isClearable={true}
                                            isMulti={true}
                                            placeholder=''
                                            cacheUniqs={[dealerCity]}
                                            components={MultiSelectCheckboxOptions}
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={false}
                                            onMenuOpen={() => { setPaginatedDealerListPage(0) }} 
                                        />
                                        <label data-label={t("FORM.SELECT_DEALER")} className="form-label"></label>
                                        {
                                            errors && errors.seleted_dealer
                                                ?
                                                <span className="error-txt">{errors.seleted_dealer}</span>
                                                :
                                                ''
                                        }
                                    </div>
                                </fieldset>

                                {/* if role is not CE then only show these input fields AND if user has UPDATE PERMISSION ACCESS  */}
                                {(callFrom === 'user/ce_add' || callFrom === 'user/ce_edit') ||
                                    (+formInputs.roleID === 5) ||
                                    (callFrom === 'user/edit' && !HELPER.isUserHasAccessPage('49') && loggedInUserDetails.users_role_id !== 1)
                                    ? ""
                                    :
                                    <>
                                        <fieldset className="multiselect-dropDown col-md-4">
                                            <div className={formInputs.access && formInputs.access.length ? "active-label material animation-effect" : "material animation-effect "}>


                                                <MultiSelect
                                                    options={accessList}
                                                    isSelectAll={true}
                                                    value={accessList && accessList.filter((e) => formInputs && formInputs.access && formInputs.access.indexOf(`${e.id}`) !== -1)}
                                                    onChange={(val, e) => changeHandler(val, 'access', e)}
                                                    placeholder={''}
                                                />

                                                <label data-label={t("FORM.SELECT_USER_ACCESS")} className="form-label"></label>

                                                {
                                                    errors && errors.access
                                                        ?
                                                        <span className="error-txt">{errors.access}</span>
                                                        :
                                                        ''
                                                }
                                            </div>


                                        </fieldset>

                                        <fieldset className="multiselect-dropDown col-md-4">
                                            <div className={formInputs.menu && formInputs.menu.length ? "active-label material animation-effect user-menu-list" : "material animation-effect user-menu-list"}>

                                                <MultiSelect
                                                    options={menuList}
                                                    isSelectAll={true}
                                                    value={menuList && menuList.filter((e) => formInputs && formInputs.menu && formInputs.menu.includes(+e.value) )}
                                                    onChange={(val) => changeHandler(val, 'menu')}
                                                    placeholder=""

                                                />
                                                <label data-label={t("FORM.SELECT_MENU_ACCESS")} className="form-label"></label>

                                                {
                                                    errors && errors.menu
                                                        ?
                                                        <span className="error-txt">{errors.menu}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>
                                    </>
                                }

                                {
                                    (callFrom === 'user/ce_add' || callFrom === 'user/ce_edit') ?
                                        <fieldset className="datepicer col-md-4">
                                            <div className={"material"}>
                                                <InputField
                                                    inputProps={{
                                                        id: "gps_location",
                                                        type: "decimal",
                                                        name: "gps_location",
                                                        placeholder: " ",
                                                        value: formInputs.gpsLocation || '',
                                                        className: 'form-input',
                                                        maxLength: "50"
                                                    }}
                                                    onChange={(val) => changeHandler(val, "gpsLocation")}
                                                />
                                                <label data-label={t("FORM.GPS_LOCATION")} className="form-label"></label>

                                            </div>
                                        </fieldset>
                                        : ''

                                }

                            </div>

                            <div className="btn-submit-cancel">
                                <button type="button" className="btn-primary" onClick={(e) => handleSubmitBtnClick(e)}>{(callFrom === 'user/add' || callFrom === 'user/ce_add') ? t("USER_MODULE.ADD_USERS") : (callFrom === 'user/edit' || callFrom === 'user/ce_edit') ? t("COMMON.SAVE_BTN") : ''}</button>
                                <button className="btn-line" onClick={(e) => handleResetBtn(e)}>{(callFrom === 'user/add' || callFrom === 'user/ce_add') ? t("COMMON.RESET_BTN") : t('COMMON.CANCEL_BTN')}</button>
                            </div>


                            {
                                picDeleteConfirm ?
                                    <ConfirmationModel confirmationData={{
                                        show: picDeleteConfirm,
                                        confirmationText: t("USER_MODULE.DELETE_PIC_CONFIRMATION")
                                    }}
                                        confirmationModalResponse={(data) => {
                                            handlePicDeleteConfirmation(data)
                                        }
                                        }
                                    /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserForm;