import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useDispatch } from 'react-redux'
import { userFilters } from "@src/store/slices/user"


const UserListFilter = (props) => {

    const [selectedFilters, setSelectedFilters] = useState(props.searchFilters || {})
    const [activeField, setActiveField] = useState('');

    const { t } = useTranslation("common")

    const dispatch = useDispatch()

    /**
     * updates search filters with changed values
     * @param {object} value 
     * @param {string} name 
     */

    const changeHandler = (value, name) => {

        let updatedFilters = { ...selectedFilters }

        if (name  ===  'name' || name  ===  'email') {
            updatedFilters[name] = (value && value.target.value) || null
        }

        if (name  ===  "role_id" || name  ===  'city_id') {
            updatedFilters[name] = (value && value.id) || null
        }

        if (name  ===  "status") {
            updatedFilters[name] = (value && value.value.toString()) || null

        }

        if (name  ===  "user_for") {
            updatedFilters[name] = (value && value.value.toString()) || null
        }

        setSelectedFilters({ ...updatedFilters })
    }


    /**
     * updates filter object and pass filters to parent 
     */

    const searchFilter = () => {

        //deleting empty/undefined keys
        Object.keys(selectedFilters).forEach((ele) => {
            if (!selectedFilters[ele]) {
                delete selectedFilters[ele]
            }
        })


        if (Object.keys(selectedFilters).length) {
            let params = { ...selectedFilters }

            //removing unnessary payload from params
            const removeKeys = ["role", "city", "status_name", "userFor_name"]
            removeKeys.forEach((e) => delete params[e])

            //store in redux
            dispatch(userFilters(selectedFilters))

            //updating parent filter object
            props.updateFilter(params)

        }
        else {
            toast.error(t("COMMON.SEARCH_FILTER_ERROR"))
        }
    }

    /**
     * resets all selected filters
     */
    const resetFilter = () => {
        //clear filter from state
        dispatch(userFilters({}))

        setSelectedFilters({})
        props.updateFilter({})
    }

    useEffect(() => {
        //clear filter from state
        dispatch(userFilters({}))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div className="top-search-bar">
                <div className="top-ribben-search">
                    <div className="inspection-list-form">

                        <fieldset className='search-bx'>
                            <div className="material">
                                <input
                                    type="text"
                                    placeholder=' '
                                    className='form-input'
                                    maxLength={200}
                                    value={selectedFilters.name ? selectedFilters.name : ''}
                                    onChange={(val) => changeHandler(val, "name")}
                                />

                                <label data-label={t("COMMON.ENTER_NAME")} className="form-label"></label>

                            </div>
                        </fieldset>

                        <fieldset className='search-bx'>
                            <div className="material">
                                <input
                                    type="text"
                                    placeholder=' '
                                    className='form-input'
                                    maxLength={200}
                                    value={selectedFilters.email ? selectedFilters.email : ''}
                                    onChange={(val) => changeHandler(val, "email")}
                                />
                                <label data-label={t("COMMON.ENTER_EMAIL")} className="form-label"></label>

                            </div>
                        </fieldset>

                        <fieldset className="seller-opt multiselect-dropDown">
                            <div className={(selectedFilters.city_id || activeField  ===  'city_id') ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.city_id ? props.cityListing.filter((ele) => ele.id === selectedFilters.city_id) : ''}
                                    onChange={(val) => changeHandler(val, "city_id")}
                                    options={props.cityListing}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown'
                                    isClearable={true}
                                    getOptionValue={(option) => option.label}
                                    onMenuClose={() => setActiveField('')}
                                    onInputChange={() => { setActiveField('city_id') }}

                                />
                                <label data-label={t("COMMON.SELECT_CITY")} className="form-label"></label>

                            </div>
                        </fieldset>

                        {
                            props.hiddenFilters && props.hiddenFilters.includes('role') ? '' :
                                <fieldset className="seller-opt multiselect-dropDown">
                                    <div className={(selectedFilters.role_id || activeField === 'role_id') ? "active-label material animation-effect" : "material animation-effect "}>

                                        <Select
                                            value={selectedFilters.role_id ? props.userRoles.filter((ele) => ele.id === selectedFilters.role_id) : ''}
                                            onChange={(val) => changeHandler(val, "role_id")}
                                            options={props.userRoles}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isClearable={true}
                                            getOptionValue={(option) => option.label}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('role_id') }}
                                        />
                                        <label data-label={t("COMMON.SELECT_ROLE")} className="form-label"></label>

                                    </div>
                                </fieldset>
                        }

                        {
                            props.hiddenFilters && props.hiddenFilters.includes('ceType') ? '' :
                                <fieldset className="seller-opt multiselect-dropDown">
                                    <div className={(selectedFilters.user_for || activeField === 'user_for') ? "active-label material animation-effect" : "material animation-effect "}>

                                        <Select
                                            value={selectedFilters.user_for ? props.ceTypes.filter((ele) => +ele.value === +selectedFilters.user_for) : ''}
                                            onChange={(val) => changeHandler(val, "user_for")}
                                            options={props.ceTypes}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix="inspection-dropdown"
                                            isClearable={true}
                                            getOptionValue={(option) => option.value}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('user_for') }}
                                        />
                                        <label data-label={t("COMMON.SELECT_CE_TYPE")} className="form-label"></label>

                                    </div>
                                </fieldset>
                        }

                        <fieldset className="seller-opt multiselect-dropDown">
                            <div className={(selectedFilters.status || activeField === 'status') ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.status ? props.statusList.filter((ele) => +ele.value === +selectedFilters.status) : ''}
                                    onChange={(val) => changeHandler(val, "status")}
                                    options={props.statusList}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isClearable={true}
                                    getOptionValue={(option) => option.label}
                                    onMenuClose={() => setActiveField('')}
                                    onInputChange={() => { setActiveField('status') }}

                                />
                                <label data-label={t("COMMON.SELECT_STATUS")} className="form-label"></label>

                            </div>
                        </fieldset>

                        <div className="btn-submit-reset">
                            <button className="btn-primary" onClick={searchFilter}>{t("COMMON.SEARCH_BTN")}</button>
                            <button className="btn-reset" onClick={resetFilter} style={{ cursor: 'pointer' }}>{t("COMMON.RESET_BTN")}</button>

                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}


export default UserListFilter