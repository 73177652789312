import React, {useState,useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { CurrentWeek,NextWeek } from "@src/config/helper";
import { rosterTemplate,uploadCsv } from "@src/store/slices/roster";
import { toast } from "react-toastify";
import FullPageLoader from "@src/view/common/FullPageLoader"

const RosterTemplate = () => {
    const { t } = useTranslation("common");
    const [currentWeek, setCurrentWeek] = useState();
    const [nextWeek, setNextWeek] = useState();
    const [loading, setLoading] = useState(false)
    const [selectedFile,setSelectedFile]=useState()
    
    useEffect(() => {
       let currentweek = CurrentWeek();
       let nextweek = NextWeek();
        setCurrentWeek(currentweek);
        setNextWeek(nextweek);
    }, []);

    /*
    *Download Roster Template
    */
    const downloadRosterTemplate=(week)=>{
        setLoading(true);
        rosterTemplate({type:week}).then((response) =>{
            if(response.status===200 && response.data!==undefined){
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = response.data;
                a.download = 'roster_report.xlsx';
                document.body.appendChild(a);
                a.click();
               toast.success(response.message);
            }else{
                toast.error(response.message);
            }
           setLoading(false); 
        }).catch((error) => {
            setLoading(false);
        });

    }
    
    /*
    *Upload a file
    */
    const uploadFile=(e)=>{
        e.preventDefault();
        let file = e.target && e.target.files ? e.target.files[0] : null;
        if (file) {
            let fileType = file.type.split('/')[0];
            if (fileType !== "image") {
                console.log("Not an image file"); 
            }
            setSelectedFile(file);
        }
    }
    /*
    *Submit upload file
    */
    const submitUpload=async(event)=>{
        setLoading(true);
        let fileName = (selectedFile) ? selectedFile.name : '';
        const data = new FormData();
        data.append("filename", fileName);
        data.append('upload_type','inspection_docs')
        data.append("images", selectedFile);
        const formdata = data;
        let headers = {
            'Content-Type': `multipart/form-data; boundary=${formdata._boundary}`,
        }
        if (selectedFile && fileName && (/\.(csv)$/.test(fileName))) {
            uploadCsv(formdata, headers).then((response) =>{
                if(response.status===200 && response.data!==undefined){
                   toast.success(response.message);
                }
                setSelectedFile();
                document.getElementById('rosterFile').value='';
               setLoading(false); 
            }).catch((error) => {
                setLoading(false);
            });
        }else{
            toast.error(t("ROSTER.TEMPLATE.ERROR.PLEASE_SELECT_FILE_TO_UPLOAD"));
            setLoading(false); 
            setSelectedFile();
        }
    }
    
    return (
        <div className="container-fluid-full">
            <FullPageLoader show={loading} />
            <div className="top-heading-action-sec p-md-t">
                <h1>{t("ROSTER.TEMPLATE.HEADING.ROSTER_TEMPLATE")} </h1>
            </div>
            <div className='payout-summary-tabs p-md roster-temp'>
                <div className="top-heading-action-sec p-md-t">
                    <h3>{t("ROSTER.TEMPLATE.HEADING.VIEW")} </h3>
                </div>
                <h4 style={{"color":"red" }} ><strong>{t("ROSTER.TEMPLATE.HEADING.NOTES")} :</strong></h4>
                <ul className="p-xl-l">
                    <li>{t("ROSTER.TEMPLATE.NOTES.NOTE1")}</li>
                    <li>{t("ROSTER.TEMPLATE.NOTES.NOTE2")}</li>
                    <li>{t("ROSTER.TEMPLATE.NOTES.NOTE3")}</li>
                    <li>{t("ROSTER.TEMPLATE.NOTES.NOTE4")}</li>
                </ul>
                <div className ='row p-md-t roaster-btn'>
                    <div className="col-md-4 ">
                        <button type="submit" className="btn btn-primary col-md-10" name="submit" id="current_roster" value="current_roster" onClick={()=>downloadRosterTemplate(0)}>
                        <p>{t("ROSTER.TEMPLATE.VIEW_BTN")}</p>
                        <p> {currentWeek}</p>           
                        </button>
                    </div>
                    <div className="col-md-4">
                        <button type="submit" className="btn btn-primary col-md-10" name="submit" id="roster_template" value="roster_template" onClick={()=>downloadRosterTemplate(1)}>
                        <p>{t("ROSTER.TEMPLATE.DOWNLOAD_BTN")}</p>
                        <p>{nextWeek}</p>
                                            
                        </button>
                    </div>
                </div>

                <div className="top-heading-action-sec p-xl-t">
                    <h3>{t("ROSTER.TEMPLATE.HEADING.UPLOAD_ROSTER")} </h3>
                </div>
                 
                <div className ='row'>
                    <fieldset className="form-select-bx col-md-4">
                        <div className={"material animation-effect "}>
                            <span className="label">{t("ROSTER.TEMPLATE.UPLOAD_FILE")} </span>
                            <input
                                type="file"
                                name="rosterFile"
                                id="rosterFile"
                                className="upload-box"
                                onChange={(event) => uploadFile(event)}
                                
                            />
                            
                        </div>
                    </fieldset>
                </div> 
                <div className='row p-md-t'>
                    <div className="col-md-4">
                        <button type="submit" className="btn btn-primary col-md-10" name="submit" id="updated_roster" value="updated_roster" onClick={(event)=>submitUpload(event)}>
                       {t("ROSTER.TEMPLATE.ROSTER_SCHEDULE")}     
                        </button>
                    </div>  
                </div>  

            </div>    
        </div>
    );
}


export default RosterTemplate;