import PopupBox from '@src/view/common/PopupBox';
import InspectionDocumentManager from "./InspectionDocumentManager";

function ReportDocumentModal(props) {

    let { itemData } = props;


    return (
        <div>
            <div className={props.reportType === "html" ? "inspection-report-document-popup" : "inspection-report-document-images-popup"}>
                <PopupBox show={props.showDocumentReportPopup}
                    setShowUpdatePopup={props.updateDocumentReportPopupStatus} >
                    {
                        props.reportType === "html"
                            ?
                            <div className="modal-body">
                                {
                                    <iframe title='certification-html' width={"100%"} frameBorder="0" id="iframe-body" src={`/inspection/certification-report-html/${itemData.hashed_vccID}`} />
                                }
                            </div>
                            :
                            <>
                                <div className="modal-header">
                                    <h3>{("Document Images")} </h3>

                                </div>
                                <div className="modal-body">
                                    <InspectionDocumentManager itemData={itemData} />
                                </div>
                            </>
                    }
                </PopupBox>
            </div>


        </div>
    )
}

export default ReportDocumentModal