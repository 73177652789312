import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { checkAuth } from "@src/store/slices/user";

const Dashboard = () => {

    const { t } = useTranslation("common");

    useEffect(() => {
        checkAuth()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <>
            <div className="dashboard-outer">
                <div className="container m-lg-t">
                    <div className="top-heading">
                        <h1>{t("PAGES.DASHBOARD.INSPECTION_DASHBOARD")}</h1>
                    </div>


                    <div className="row">
                        <div className="col-lg-12 welcome-blk">
                            We provide a comprehensive used car evaluation, to help determine the quality of the car. Our qualified engineers take care to physical inspection of the vehicle and provide a detailed evaluation report, including vehicle & documents details with images and chassis imprint along with refurbishment cost and the valuation of the evaluated car. <br /><br />
                            <p className="wel-blk-h">OTO Inspections</p>
                            <p><i className="ti-location-pin"></i>Menara citicon 10th floor Letjen S. Parman St No.Kav 72, RT.4/RW.3, Slipi, Palmerah, West Jakarta City, Jakarta 11410, Indonesia </p>
                            <p><i className="ti-headphone"></i>  01246825757 </p>
                            <p><i className="ti-email"></i> info@oto.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
