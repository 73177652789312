import secureStorage from '@src/config/encrypt';
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllUsers, getUserRoles } from "@src/store/slices/user"
import { getCityList, getInspectionFilters } from "@src/store/slices/common"
import UserListTable from "@src/view/user/common/UserListTable"
import FullPageLoader from "@src/view/common/FullPageLoader"
import { useTranslation } from "react-i18next"
import UserListFilter from "@src/view/user/common/UserListFilter"
import TopActionBtn from "@src/view/common/TopActionBar";



const CeList = (props) => {

    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(false)
    const [userlistResponse, setUserlistResponse] = useState([])
    const [cityList, setCityList] = useState([])
    const [ceRole, setCERole] = useState()
    const [statusList, setStatusList] = useState([])
    const [ceTypeList, setCeTypeList] = useState([])
    const [editAccess, setEditAccess] = useState(false)
    const [userlistFilter, setUserListFilter] = useState(props.userlistFilter)


    const fetchUserDetails = secureStorage.getItem('authUserInfo')
    const userDetails = fetchUserDetails && fetchUserDetails.user_data

    const commonState = useSelector(state => state.common)
    const userState = useSelector(state => state.auth)

    const dispatch = useDispatch()

    /**
     * calling city list, user role API and setting current user access in state when component is mounted
     */
    useEffect(() => {
        const userID = userDetails && userDetails.hash_id

        if (userState && !userState.userRoles) {
            getCERoleID({ userID })
        }
        else {
            //adding roles fom redux 
            const roles = userState && userState.userRoles && userState.userRoles.data
            const fetchCERole = roles.filter((ele) => ele.role_title === 'CE')
            const ceRole = fetchCERole && fetchCERole[0] && fetchCERole[0].id

            setCERole(ceRole)
        }

        if (commonState && !commonState.inspectionFilters) {
            getConfig()
        }
        else {
            //adding status list & ce type from redux
            const config = commonState && commonState.inspectionFilters

            if (config) {
                setStatusList(config.STATUSLIST)
                setCeTypeList(config.USER_FOR)
            }

        }

        if (commonState && !commonState.cityList) {
            getCityListing(props)
        }
        else {
            //adding city list from redux
            const cityList = commonState && commonState.cityList
            const data = cityList && cityList.data

            const updatedCities = data && data.map((ele) => {
                return { label: ele.name, id: ele.id, name: ele.name }
            })

            setCityList(updatedCities)
        }

        //function call to set User permission
        setUserAccess()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * fetching ce listing and updating component when CE role and user list is updated
     */
    useEffect(() => {
        if (ceRole) {
            getCEListing(props)
        }
    }, [ceRole, userlistFilter]) // eslint-disable-line react-hooks/exhaustive-deps


    /**
     * fetching user details from storage and setting permissions in state
     */

    const setUserAccess = () => {
        const userDetails = fetchUserDetails.user_data

        if (userDetails) {
            const accessParams = userDetails && userDetails.access_param ? JSON.parse(userDetails.access_param) : {}
            const access = accessParams && accessParams.access ? accessParams.access : ''
           
            //checking if user has access to edit CE
            if (access && access.includes('35')) {
                setEditAccess(true)
            }
        }

    }

    /**
     * fetches list of CE users
     * @param {object} params 
     */
    const getCEListing = (params) => {
        setLoading(true)

        let apiParams = {
            ...params.searchFilter,
            ...userlistFilter,
            role_id: ceRole,
            page_no: params.page_no ? params.page_no : 1
        }
        //passing role id of CE
        dispatch(getAllUsers(apiParams)).then((res) => {
            setLoading(false)
            if (res && res.status === 200) {
                const userlistResponse = res
                setUserlistResponse(userlistResponse)
            }
        }).catch(err => {
            setLoading(false)
        })
    }

    /**
     * function for child component for updating CE user list
     * @param {object} params 
     */
    const fetchUserListing = (params) => {
        getCEListing(params)
    }

    /**
     * fetches list of cities
     * @param {object} params 
     */
    const getCityListing = (params) => {
        dispatch(getCityList(params.searchFilters)).then((res) => {
            setLoading(false)
            if (res && res.status === 200) {

                const updatedCities = res.data.map((ele) => {
                    return { label: ele.name, id: ele.id, name: ele.name }
                })

                setCityList(updatedCities)
            }
        }).catch(err => {
        })
    }

    /**
     * function for child component to update search filters used for user listing
     * @param {object} params 
     */
    const updateFilter = (params) => {
        setUserListFilter(params)
    }

    /**
     * fetches CE Role id from API and assign it to state
     * @param {object} params 
     */

    const getCERoleID = (params) => {
        dispatch(getUserRoles(params)).then((res) => {
            if (res && res.status === 200) {

                const fetchCERole = res.data.filter((ele) => ele.role_title === 'CE')
                const ceRoleFound = fetchCERole && fetchCERole[0] && fetchCERole[0].id

                setCERole(ceRoleFound)
            }
        }).catch(err => {
        })
    }

    /**
    * fetches list of static dropdowns
    * @param {object} params 
    */
    const getConfig = async (params) => {
        try {
            const response = await dispatch(getInspectionFilters(params))

            if (response) {
                setStatusList(response.STATUSLIST)
                setCeTypeList(response.USER_FOR)

            }
        } catch (error) {
        }
    }

    /**
     * take updated table data from user list table and updates data when gallery is updated via UserListTable on CE lsting page
     * @param {Array} tableData 
     */
    const updateTableData = async (tableData) => {
        let updatedResponse = { ...userlistResponse, data: tableData }
        setUserlistResponse(updatedResponse)
    }

    return (
        <>
            <div className="container-fluid-full">
                <div className="top-sec">
                    <TopActionBtn heading={t("COMMON.CE_MODULE")}
                        callonpage={'1'}
                        searchFilterList={{ role_id: ceRole, exportType: 2 }}
                        pageType={'UserListing'}
                        List={userlistResponse.data} />
                </div>

                <div className="vehicle-listing-box">
                    <UserListFilter
                        cityListing={cityList}
                        statusList={statusList}
                        ceTypes={ceTypeList}
                        updateFilter={updateFilter}
                        hiddenFilters={['role']}
                        callFrom="CEList"
                    />
                </div>

                <FullPageLoader show={loading} />

                <div className="vechile-table-data">
                    <UserListTable callFrom={"CEList"} apiResponse={userlistResponse} getUserListing={fetchUserListing} loading={loading} searchFilters={userlistFilter} filterData={userlistResponse.data} editAccess={editAccess} updateTableData={updateTableData} />
                </div>

            </div>
        </>
    )
}


export default CeList