import React, { useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";
import Dateformat from 'dateformat';
import FullPageLoader from "@src/view/common/FullPageLoader"
import InspectionRequest from "@src/view/inspection/AssignCe/InspectionRequest"
import InspectionAssign from "@src/view/inspection/AssignCe/InspectionAssign"
import AssignCeCalender from "@src/view/inspection/AssignCe/calenderHtml/AssignCeCalender"
import { getinspectionBookingData,getAssignCeData,updateConvence,assignCeForInspection } from "@src/store/slices/inspection";


const CeAssignment = (props) => {
    const [loading, setLoading] = useState(false);
    const [inspectionBookingData, setInspectionBookingData] = useState({});
    const [calenderData, setCalenderData] = useState([]);
    const [celistData, setCelistData] = useState([]);
    const [bookingDate, setBookingDate] = useState('');
    const [type, setType] = useState('');
    const queryParams = useParams();
    const { t } = useTranslation("common")


    useEffect(() => {
        setLoading(true);
        getInspectionData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    /*
    * Get Inspection Booking Data
    */
    const getInspectionData = async () => {
        let booking_id=(queryParams.bookingId)?queryParams.bookingId:'';
        let type=(queryParams.type)?queryParams.type:'';
        setType(type);
        bookingInspectionData({bookingId:booking_id});
    }
    
    const bookingInspectionData = async (params) => {
          getinspectionBookingData(params).then(async(response) =>{
            if(response.status===200){
                if(Object.keys(response.data).length>0){
                    setLoading(false);

                    setInspectionBookingData(response.data);
                        let data=response.data;
                        let now = new Date();
                        let booking_date=Dateformat(now,'yyyy-mm-dd');
                        let inspection_prefered_time=new Date(data.inspection_prefered_time);
                        if(inspection_prefered_time.getTime()>now.getTime()){
                            booking_date=Dateformat(inspection_prefered_time,'yyyy-mm-dd');
                        }
                        setBookingDate(booking_date);

                        let assignceparams={
                            city_id:data.city_id,
                            booking_date:booking_date,
                            lat:data.address_lat,
                            long:data.address_lang
                        };
                        await AssignCeData(assignceparams);
                }else{
                    toast.error(response.message);
                    setTimeout(function(){// wait for 5 secs(2)
                        window.close(); // then reload the page.(3)
                    }, 5000);
                }
            }  
        }).catch((error) => {
            setLoading(false);
        })
    }
    
    /*
    * Get Assign Ce Data Date Wise
    */
    const assignGetCeData=async(params)=>{
        setLoading(true);
        setBookingDate(params.booking_date);
        await AssignCeData(params);
    }

    /*
    * Get Assign Ce Data
    */
    const AssignCeData = async (params) => {
        getAssignCeData(params).then((response)=>{
            if(response.status===200 && response.data){
                let data=response.data;
                if(data.celist && data.celist.length){
                    setCelistData(data.celist);
                }else{
                    toast.error("Sorry no CE available")
                }
                if(data.calendarData && data.calendarData.length){
                    setCalenderData(data.calendarData);
                }
            }else{
                toast.error(response.message)
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        })
    }

    /*
    *Assign Ce And Date for Inspection
    */
    const updateAssignCe = (params) => {
        if(!params.hasOwnProperty('assignDate') || (params.assignDate==='')){
            toast.error("Please Select Date");
        }else if(!params.hasOwnProperty('ce')|| (params.ce==='')){
            toast.error("Please Select CE");
        }else if(inspectionBookingData && inspectionBookingData.ce_id && (!params.reassign_reason)){
            toast.error("Please Select Reason");
        }else{
            setLoading(true);
            assignCeForInspection(params).then((response) =>{
                if(response.status===200){
                   toast.success(response.message);
                    setTimeout(function(){// wait for 5 secs(2)
                        window.close(); // then reload the page.(3)
                    }, 2000);
                }
               setLoading(false); 
            }).catch((error) => {
                setLoading(false);
            });
        }
    }

    /*
    *updateConvence KM
    * params{convence,booking_id}
    */
    const updateAssignConvence = (params) => {
        if(!params.hasOwnProperty('convence') || (params.convence==='')){
            toast.error("Please enter conveyance km");
        }else{
            setLoading(true);
            updateConvence(params).then((response) =>{
                if(response.status===200){
                   toast.success(response.message);
                   getInspectionData();
                }else{
                    toast.error(response.message)
                }
               setLoading(false); 
            }).catch((error) => {
                setLoading(false);
            });
        }
    }
    
    /*
    *Update Calender Data
    */
    const updateCalenderData = (params) => {
        let caldata=calenderData;
        let fromIndex = caldata.findIndex(el => el.id ===params.ce );
        const toIndex = 0;
        const element = caldata.splice(fromIndex, 1)[0];
        caldata.splice(toIndex, 0, element);
        setCalenderData([...caldata]);
        return true;
    }


    return (
        <div className='container p-md-t'>
            <FullPageLoader show={loading} />
            {
                 (Object.keys(inspectionBookingData).length>0) &&
                 <>
                    <div className="assignBox p-md-b" id="assignBox">
                        <div className="row">
                            <div className="col-md-9">
                                <InspectionRequest 
                                 updateAssignConvence={updateAssignConvence}
                                 inspectionBookingData={inspectionBookingData} 
                                heading={t("INSPECTION.HEADING.INSPECTION_REQUEST")} />
                            </div>
                            <div className="col-md-3">
                            <InspectionAssign 
                            updateAssignCe={updateAssignCe}
                            assignGetCeData={assignGetCeData}
                            inspectionBookingData={inspectionBookingData} 
                            celistData={celistData} 
                            bookingDate={bookingDate}
                            type={type}
                            updateCalenderData={updateCalenderData}
                            calenderData={calenderData}
                            heading={t("INSPECTION.HEADING.INSPECTION_ASSIGN")} />
                            </div>
                        </div>
                    </div>
                    {
                        calenderData.length > 0 && <div className="calanderBox" id='calanderBox'>
                        <AssignCeCalender 
                          calenderData={calenderData}
                          bookingDate={bookingDate}
                          inspectionBookingData={inspectionBookingData} 
                          assignGetCeData={assignGetCeData}
                        />
                        </div>
                    }
                 </>
            }
        </div>
         
    );


}


export default CeAssignment;