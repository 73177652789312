import { useState, useEffect } from 'react';

import FullPageLoader from "@src/view/common/FullPageLoader"

import { API_URL } from '@src/config/constant';
import { toast } from 'react-toastify';

function InspectionReports(props) {

    const [loading, setLoading] = useState(true);

    const queryParams = new URLSearchParams(props.location.search);
    const reportType = queryParams.get('reportType');
    const vccID = queryParams.get('vccID');
    const source = queryParams.get('source');
    const [loadReport, setLoadReport] = useState(false);

    useEffect(() => {

        if (!source || !vccID || !reportType) {
            toast.error("Required parameters not found")

            setTimeout(() => {

                window.close()
            }, 5000)
        } else if (reportType !== "pdf" || !["inspection", 'insurance', 'warranty'].includes(source)) {
            toast.error("Invalid parameters")

            setTimeout(() => {

                window.close()
            }, 3000)
        } else {
            setLoadReport(true)
            setTimeout(() => {

                setLoading(false)

            }, 3000)

        }

    }, [source, reportType, vccID]); // eslint-disable-line react-hooks/exhaustive-deps
    console.log(`${API_URL.GETWAY_DOCS_API}report/getCertificationReportPdf?vccID=${vccID}&reportType=${reportType}&source=${source}`)
    return (

        <div className='inspection-report-html-content'>
            <FullPageLoader show={loading} />
            {
                loadReport &&
                <iframe
                    style={{ width: "100%", height: "662px" }}
                    src={`${API_URL.GETWAY_DOCS_API}report/getCertificationReportPdf?vccID=${vccID}&reportType=${reportType}&source=${source}`}
                    type='application/pdf'
                    title='Inspection Report'
                    frameBorder="0"
                />
            }

        </div>
    )
}

export default InspectionReports