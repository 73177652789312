import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import Pagination from '@src/view/common/Pagination'
import { useNavigate } from 'react-router';
import { updateGallery } from "@src/store/slices/user"
import ConfirmationModel from '@src/view/common/ConfirmationModel'
import ToggleSwitch from "@src/view/element/ToggleSwitch";

const UserListTable = (props) => {

    const { apiResponse, callFrom } = props
    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(props.loading)
    const [tableData, setTableData] = useState([])
    const [paginationData, setPaginationData] = useState({})
    const page = 1
    const [userData, setUserData] = useState({})
    const [showGalleryPopup, setShowGalleryPopup] = useState(false)
    const totalLengthWithoutPagination = 0

    const navigate = useNavigate();

    /**
     * calls parent component for fetching updated user listing
     * @param {object} params 
     */
    const handleRequestData = (params) => {
        props.getUserListing(params)
    }

    /**
   * toggle gallery pop up display
   */
    const toggleGallery = (item) => {
        setShowGalleryPopup(true)
        setUserData(item)
    }

    /**
     * updating state for gallery pop up display
     */
    const handleGalleryClick = (response) => {
        if (response.response) updateGalleryAccess(userData)
        else {
            setShowGalleryPopup(false)
        }
    }


    /**
     * redirects to edit user page with selected user details
     * @param {object} params 
     * @param {string} callFrom 
     */

    const handleEditBtnClick = (params, callFrom) => {
        let redirectTo = `/user/edit/`

        if (callFrom === 'CEList') redirectTo = `/user/ce_edit/`

        navigate({
            pathname: `${redirectTo}${params.hash_id}`,
            state: { user_id: params.hash_id, from: callFrom }
        })
    }

    /**
     * Function to update Gallery flag on ce listing page
     * @param {Object} params 
     */
    const updateGalleryAccess = async (params) => {
        setLoading(true)

        let updateObj = {
            gallery: +params.gallery_opt === 0 ? '1' : '0',
            userID: params.id
        }

        const response = await updateGallery(updateObj)
        setShowGalleryPopup(false)

        //update updated gallery of access
        if (response && response.status && response.status === 200) {

            let currentTableData = JSON.parse(JSON.stringify(tableData))

            if (currentTableData && currentTableData.length) {
                for (let data of currentTableData) {
                    if (+data.id === +updateObj.userID) {
                        data.gallery_opt = updateObj.gallery
                    }
                }
            }
            props.updateTableData(currentTableData)
        }

        setLoading(false)

    }

    /**
     * Setting table data and paging data and updating component when user lisiting updates
     */
    useEffect(() => {
        setTableData(props.apiResponse.data)
        setPaginationData(props.apiResponse.pagination)

    }, [props.apiResponse])


    const runPaginationFunc = () => { };

    return (
        <>
            <div className='user-list-table'>
                <div className='data-table'>
                    <table>
                        <thead>
                            <tr>
                                {props.callFrom && props.callFrom === 'CEList' ?
                                    <>
                                        <th>#</th>
                                        <th>{t("COMMON.NAME")}</th>
                                        <th>{t("FORM.EMAIL")}</th>
                                        <th>{t("FORM.MOBILE")}</th>
                                        <th>{t("COMMON.CE_TYPE")}</th>
                                        <th>{t("FORM.CITY")}</th>
                                        <th>{t("FORM.STATUS")}</th>
                                        <th>{t("COMMON.GALLERY")}</th>
                                        <th>{t("COMMON.CREATE_REQUEST")}</th>
                                        {props.editAccess ? <th>{t("COMMON.ACTION")}</th> : ''}
                                    </> :
                                    <>
                                        <th>#</th>
                                        <th>{t("COMMON.NAME")}</th>
                                        <th>{t("FORM.EMAIL")}</th>
                                        <th>{t("COMMON.ROLE")}</th>
                                        <th>{t("FORM.CITY")}</th>
                                        <th>{t("FORM.STATUS")}</th>
                                        {props.editAccess ? <th>{t("COMMON.ACTION")}</th> : ''}
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                apiResponse && apiResponse.data && apiResponse.data.length
                                    ?
                                    apiResponse.data.map((item, key) => {

                                        if (props.callFrom && props.callFrom === 'CEList') {
                                            return (
                                                <tr key={key}>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{item.user_for}</td>
                                                    <td>{item.city_name}</td>
                                                    <td>{+item.status === 1 ?
                                                        <span className='active-dot'>Active</span>
                                                        :
                                                        <span className='inactive-dot'>InActive</span>
                                                    }
                                                    </td>
                                                    <td>

                                                        <div className="on-off-switch">
                                                            <ToggleSwitch
                                                                status={+item.gallery_opt}
                                                                item={item}
                                                                onChange={() => {
                                                                    toggleGallery(item);
                                                                }}
                                                            />
                                                        </div>

                                                    </td>
                                                    <td>
                                                        {

                                                            <div className="on-off-switch">
                                                                <ToggleSwitch
                                                                    status={+item.is_request_access}
                                                                    item={item}
                                                                />
                                                            </div>
                                                        }
                                                    </td>
                                                    {props.editAccess ? <td>
                                                        <ul className='ulaction-btn'>
                                                            <li title="Edit" onClick={() => { handleEditBtnClick(item, props.callFrom) }}>
                                                                <i className='ic-createmode_editedit'></i>
                                                            </li>
                                                        </ul>
                                                    </td> : ''}
                                                </tr>
                                            )
                                        }
                                        else if (props.callFrom && props.callFrom === 'manageUser') {
                                            return (
                                                <tr key={key} id={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.userType}</td>
                                                    <td>{item.city_name}</td>
                                                    <td>{+item.status === 1 ?

                                                        <span className='active-dot'>Active</span>
                                                        :
                                                        <span className='inactive-dot'>InActive</span>
                                                    }
                                                    </td>
                                                    {props.editAccess ? <td>
                                                        <ul className='ulaction-btn'>
                                                            <li title="Edit" onClick={() => { handleEditBtnClick(item, props.callFrom) }}>
                                                                <i className='ic-createmode_editedit'></i>
                                                            </li>
                                                        </ul>
                                                    </td> : ''}
                                                </tr>
                                            )
                                        }else {
                                            return ""
                                        }
                                    })

                                    :
                                    <tr><td className='t-center' colSpan={callFrom === 'CEList' ? 10 : 6}>{t("COMMON.NO_DATA")} </td></tr>
                            }
                        </tbody>
                    </table>

                    {
                        showGalleryPopup ?
                            <ConfirmationModel confirmationData={{
                                show: showGalleryPopup,
                                confirmationText: +userData.gallery_opt === 1 ? t("USER_MODULE.USER_GALLERY.HEADING_OFF") : t("USER_MODULE.USER_GALLERY.HEADING_ON")
                            }}
                                confirmationModalResponse={(data) => {
                                    handleGalleryClick(data)
                                }
                                }
                            /> : null
                    }
                </div>

                <div className="pagination-outer">
                    {!loading && paginationData && paginationData.total ? <Pagination
                        paginateData={{
                            page,
                            runPaginationFunc,
                            loading,
                            tableData: props.getUserListing,
                            totalLengthWithoutPagination,
                            paginationData,
                            filteredData: props.searchFilters
                        }}
                        sendRequestDataToParent={handleRequestData}
                    /> : ''}
                </div>

            </div>
        </>
    )
}



export default UserListTable