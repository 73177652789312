import DownloadExcelButton from './DownloadExcelButton'


const TopActionBtn = (props) => {

    return (
        <div>
            <div className="top-heading-action-sec">
                <h1>{props.heading} </h1>

                { props.callonpage && <div className="action-btn">
                        <DownloadExcelButton callonpage = {props.callonpage} searchFilterList={props.searchFilterList} pageType={props.pageType} List={props.List} />
                    </div>
                }
                                    
            </div>
            
        </div>
    )
}

export default TopActionBtn;