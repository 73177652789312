import { useEffect, useState } from "react"
import ImageSlider from '../common/ImageSlider';
import TopDetailsBar from '../common/TopDetailsBar';
import QcPanelRightBar from './QcPanelRightBar';
import { getQcPanelData } from "@src/store/slices/dealerQc";
import { getImageTags, getColorList } from "@src/store/slices/common";
import FullPageLoader from "@src/view/common/FullPageLoader";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';



const QcPanel = (props) => {
    const [loading, setLoading] = useState(false);
    const [vehicleData, setVehicleData] = useState({});

    const [imageTagList, setImageTagList] = useState([]);
    const [colorList, setColorList] = useState([]);
    const [imageRejectReasons, setImageRejectReasons] = useState([]);
    const reload = false;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { vccID } = useParams();

    useEffect(() => {

        // let vccID = props.match.params.vcc;
        setLoading(true);

        dispatch(getQcPanelData({ vccID })).then(res => {
            setLoading(false);
            setVehicleData(res.data);

            //preparing imageRejectReasonsArray
            if (res && res.status === 200 && res.data && res.data.imageData) {
                const imageData = res.data.imageData

                let updatedArray = []

                for (let data of imageData) {
                    const isTagExist = updatedArray && updatedArray.findIndex((e) => e.tag_id === data.tag_id)

                    if (isTagExist === -1) {
                        let obj = {
                            tag_id: data.tag_id,
                            imageStatus: 'Satisfactory',
                        }
                        updatedArray.push(obj)
                    }
                }

                setImageRejectReasons(updatedArray)
            }

            else if (res && res.status === 400) {
                navigate('/qc/dealer')
            }


        }).catch(err => setLoading(false))

        getImageTagDropdown();
        getColorDropdown();

    }, [reload]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
   * GET COLOR DATA
   */
    const getColorDropdown = async () => {
        dispatch(getColorList()).then((resp) => {
            if(resp && resp.data){
                setColorList(resp.data);
            }
        })
    }


    /**
     * GET IMAGE TAG DROPDOWN
    */
    const getImageTagDropdown = async () => {
        let payload = {
            "imageType": 'all',
            "vehicleType": "car"
        }
        dispatch(getImageTags(payload)).then((response) => {
            if(response && response.data){
                setImageTagList(response.data);
            }
        })
    }

    const setLoadingFromChild = (loading) => {
        setLoading(loading)
    }

    const setReloadFromChild = (flag) => {
        // setReload(flag) //NO NEED TO RELOAD
    }

    return (
        <div className="container m-xl-t">

            <FullPageLoader show={loading} />

            <TopDetailsBar vehicleData={vehicleData} imageTagList={imageTagList} setReloadFromChild={setReloadFromChild} callFrom={"qcPanel"} />

            {
                vehicleData && Object.keys(vehicleData).length > 0 &&
                <div className="col-md-12 edit-user-sec">
                    <div className="col-md-8 image-slider-box">
                        <ImageSlider vehicleData={vehicleData} imageTagList={imageTagList} setLoadingFromChild={setLoadingFromChild} />
                    </div>

                    <div className="col-md-4">
                        <QcPanelRightBar vehicleData={vehicleData} colorList={colorList} imageRejectReasons={imageRejectReasons} setLoadingFromChild={setLoadingFromChild} callFrom={'qcPanel'} />
                    </div>
                </div>
            }



        </div>

    )
}


export default QcPanel