import { createSlice } from '@reduxjs/toolkit'
import { toast } from "react-toastify";
import { DealerQcService } from '@src/services/DealerQcService';

const DealerQc = createSlice({
    name: 'dealerQc',
    initialState: {
        dealerQc: {},
        updatedFormfields: []
    },
    reducers: {
        _updateCorrenctionPanelData: (state, action) => {
            state.correctionPanelData = action.payload;
        },
        _qcImageSliderData: (state, action) => {
            state.qcImageSliderData = action.payload;
        },
        _updateQcPanelData: (state, action) => {
            state.qcPanelData = action.payload;
        },
        _updatedFormFields: (state, action) => {
            state.updatedFormfields = action.payload
        }
    },
});

export default DealerQc.reducer;


const { _qcImageSliderData, _updateQcPanelData, _updatedFormFields } = DealerQc.actions;
/**
 * API call to Upload QC Images
 * @param {Object} params 
 * @returns 
 */

export const uploadQcImages = (params) => {
    return new Promise((resolve, reject) => {
        DealerQcService.uploadQcImage(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data)
                }
                else {
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

/**
 * API call to update QC Data
 * @param {Object} params 
 * @returns 
 */
export const updateQCData = (params) => {
    return new Promise((resolve, reject) => {
        DealerQcService.updateQcData(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    toast.success(response.data.message);
                    resolve(response.data)
                }
                else {
                    let errorMsg = ((response && response.data && response.data.message)) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}



export const getSentForCorrectionPanelData = (params)  => {
    return new Promise((resolve, reject) => {
        DealerQcService.getSentForCorrectionPanelData(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data.data)
                }
                else {
                    let errorMsg = ((response && response.data && response.data.message)) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
                // resolve(response.data);
            },
            error => {
                reject(error);
            }
        );
    });
}



export const saveReQCData = (params) => {
    return new Promise((resolve, reject) => {
        DealerQcService.saveReQCData(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                   // toast.success(response.data.message);
                    resolve(response.data);
                }
                else {
                    let errorMsg = ((response && response.data && response.data.message)) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
                // resolve(response.data);
            },
            error => {
                reject(error);
            }
        );
    });
}



export const reQcImgData = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        DealerQcService.reQcImgData(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data);
                }
                else {
                    let errorMsg = ((response && response.data && response.data.message)) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
                // resolve(response.data);
            },
            error => {
                reject(error);
            }
        );
    });
}



export const getDocumentReportData = (params) => {
    return new Promise((resolve, reject) => {
        DealerQcService.getDocumentReportData(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data);
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

export const addFinishTime = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        DealerQcService.addFinishTime(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data);
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}


export const getCertificationReportPdf = (params) => {
    return new Promise((resolve, reject) => {
        DealerQcService.getCertificationReportPdf(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data.data);
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
} 

export const getQcPanelData = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        DealerQcService.getQcPanelData(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        dispatch(_updateQcPanelData(response.data.data))
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


export const checkUploadFinishStatus = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        DealerQcService.checkUploadFinishStatus(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data);
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}


export const getInspectionReportPdf = (params) => {
    return new Promise((resolve, reject) => {
        DealerQcService.getInspectionReportPdf(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data.data)
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

export const setQcImageSliderData = (params) => dispatch => {
    dispatch(_qcImageSliderData(params))
}


export const updateQcPanelData = (params) => {

    return async (dispatch, getState) => {
        const currentState = getState().dealerQc;

        let { slideIndex } = currentState.qcImageSliderData;
        let { activeFormData, qcPanelData } = params;
        let getQcPanelData = qcPanelData

        getQcPanelData['imageData'][slideIndex - 1]['formData'] = activeFormData

        dispatch(_updateQcPanelData(getQcPanelData))
    };


}

export const updatedFormfields = (params) => dispatch => {
    dispatch(_updatedFormFields(params))
}


export const getAllReQcPanelData = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        DealerQcService.getAllReQcPanelData(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        dispatch(_updateQcPanelData(response.data.data))
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}



export const modifyCerificationImage = (params) => {
    return new Promise((resolve, reject) => {
        DealerQcService.modifyCerificationImage(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}
