import MasterService from '@src/services/MasterService';


export const BackOfficeService = {
    ...MasterService,
         
    getbackOfficeConfig(params){
        return this.get('/backOffice/backOfficeConfig')
    },

    getbackOfficeListing(params){
        return this.post('/backOffice/getDealerEvaluation', params)
    },
    
    getCsvExport(params){
        return this.post('/backOffice/exportBackOfficeData', params)
    }
   
}