import React, { useState, useEffect} from "react";
import _ from 'lodash';


const CalenderBookingHtml = (props) => {
    const [slotArray, setSlotArray] = useState([])
    const timeRange = _.range(8,20);

    useEffect(() => {
        setSlotArray(props.slotArrayData)
    }, [props.slotArrayData]);

    return (
        <>
            {
                timeRange.map((num, key)=>
                    <li data-slot={"slot-"+(num<10?"0"+num:num)} key={num} id={"li-"+(num<10?"0"+num:num)} className={props.activeClasses && props.activeClasses[key]?"timeSlot":"timeSlot colnone"} dangerouslySetInnerHTML={{__html: slotArray[num]}}></li>
                )
            }
           
        </>
    );

}

export default CalenderBookingHtml;