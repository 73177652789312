import React, {useEffect, useState } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next"
import Select from "react-select"
import DatePicker from "react-datepicker";
import { DateFormat } from "../../config/helper";
import { getDealers } from "@src/store/slices/common"


const DealerQcFilters = (props) => {
    const { t } = useTranslation("common")
    const [selectedFilters, setSelectedFilters] = useState(props.searchFilterList || {})
    const [dealerDetail, setDealerDetail] = useState([]);
    const [activeField, setActiveField] = useState('');
    const [dealerLoading, setDealerLoading] = useState(false);

    const changeHandler = (value, name) => {
        let newVal = ""
        if (name === "reg_no") {  
          if(value && value.target.value){
            newVal = value.target.value;
          }      
        } else if (name === "date_from" || name === "date_to") {
            newVal = DateFormat(value);
        } else if (name === "invt_status") {
            newVal = value && value.value >= 0  ? value['value'] : undefined;
        } else if (name === "dealer_name") {
            if (value && typeof value === 'object') {
                newVal = value.value;
                setSelectedFilters({ ...selectedFilters, [name]: value.value });
            } else if (!value) {
                setSelectedFilters({ ...selectedFilters, [name]: undefined });
            }
            if (value && typeof value == 'string' && value.length > 2 && !dealerLoading) {
                setDealerLoading(true)
                let dealerBody = {"filter": {}};
                dealerBody['filter']['search_by_text'] = value;

                getDealers(dealerBody).then((dealers) => {
                    setDealerLoading(false)

                    if (dealers.status === 200 && dealers.data.length > 0) {
                        setDealerDetail(dealers.data);
                        setDealerLoading(false);
                    } else {
                        setDealerLoading(false);
                        setDealerDetail([]);
                    }
                }).catch(err=> setDealerLoading(false));
            }

        }
        else {
            newVal = value && value.value ? value['value'] : undefined;
        } 
        
        if(newVal === "" || newVal === undefined){
            delete selectedFilters[name];
            setSelectedFilters({ ...selectedFilters});
        }else{
            setSelectedFilters({ ...selectedFilters, [name]: newVal });
        }
    }

    /*update filters*/
    const searchFilter = () => {
        
        let newObj =  selectedFilters;
        newObj.page_no = 1;
        if (Object.keys(newObj).length) {
            props.updateFilter(newObj)
        } 
    }
    
    /*Reset all selected Filters*/
    const resetFilter = () => {
        let objdata={list_flag:Number(props.callonpage),invt_status:2,page_no:1}
        setSelectedFilters(objdata);
        props.updateFilter(objdata);
        setDealerDetail([]);

    }

    //WHEN TAB CHANGE RESET FUNCTION WILL CALL 
    useEffect(()=>{
        let objdata={list_flag:Number(props.callonpage),invt_status:2,page_no:1}
        setSelectedFilters(objdata);
    },[props.resetOnChange]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <> 
           <div className="top-search-bar">
                <div className="top-ribben-search">
                    <div className="inspection-list-form">
                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.vehicle_type || activeField === 'vehicle_type')  ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.vehicle_type && props.filterArrayList.VEHICLE_TYPE ? props.filterArrayList.VEHICLE_TYPE.filter((ele) => ''+ele.value === ''+selectedFilters.vehicle_type) : ''}
                                    id="vehicle_type"
                                    onKeyDown={
                                        (e) => setTimeout(() => { changeHandler(e.target.value, "vehicle_type"); setActiveField('vehicle_type') }, 100)
                                    }
                                    onChange={(val) => changeHandler(val, "vehicle_type")}
                                    options={props.filterArrayList.VEHICLE_TYPE}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown react-select'
                                    isClearable={true}
                                    getOptionValue={(option) => option.value}
                                    onMenuClose = {()=> (!selectedFilters.vehicle_type) ? setActiveField('') : ""}
                                />
                                <label data-label={t("BACK_OFFICE.DEALER_FILTER.vehicle_type")} className="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.dealer_name || activeField === 'dealer_name' ) ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.dealer_name  ? dealerDetail.filter((ele) => ele.value === selectedFilters.dealer_name) : ''}
                                    id="dealer_name"
                                    onKeyDown={
                                        (e) => setTimeout(() => { changeHandler(e.target.value, "dealer_name"); setActiveField('dealer_name') }, 100)
                                    }
                                    onChange={(val) => changeHandler(val, "dealer_name")}
                                    options = {dealerDetail}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown react-select'
                                    isClearable={true}
                                    getOptionValue={(option) => option.value}
                                    isLoading = {dealerLoading}
                                    onMenuClose = {()=> (!selectedFilters.dealer_name) ? setActiveField('') : ""}

                                />
                                <label data-label={t("BACK_OFFICE.DEALER_FILTER.dealer")} className="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.ce_name || activeField === 'ce_name') ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.ce_name && props.filterArrayList.CE_LIST ? props.filterArrayList.CE_LIST.filter((ele) => ele.value === selectedFilters.ce_name) : ''}
                                    id="ce_name"
                                    onKeyDown={
                                        (e) => setTimeout(() => { changeHandler(e.target.value, "ce_name"); setActiveField('ce_name') }, 100)
                                    }
                                    onChange={(val) => changeHandler(val, "ce_name")}
                                    options={props.filterArrayList.CE_LIST}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown react-select'
                                    isClearable={true}
                                    getOptionValue={(option) => option.value}
                                    onMenuClose = {()=> (!selectedFilters.ce_name) ? setActiveField('') : ""}

                                />
                                <label data-label={t("BACK_OFFICE.DEALER_FILTER.ce")} className="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.city_name || activeField === 'city_name') ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.city_name && props.filterArrayList.CITY_LIST? props.filterArrayList.CITY_LIST.filter((ele) => ele.value === selectedFilters.city_name) : ''}
                                    id="city_name"
                                    onKeyDown={
                                        (e) => setTimeout(() => { changeHandler(e.target.value, "city_name"); setActiveField('city_name') }, 100)
                                    }
                                    onChange={(val) => changeHandler(val, "city_name")}
                                    options={props.filterArrayList.CITY_LIST}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown react-select'
                                    isClearable={true}
                                    getOptionValue={(option) => option.value}
                                    onMenuClose = {()=> (!selectedFilters.city_name) ? setActiveField('') : ""}

                                />
                                <label data-label={t("BACK_OFFICE.DEALER_FILTER.city")} className="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset className='search-bx'>
                            <div className="material">
                                <input
                                    type="text"
                                    id="reg_no"
                                    name="reg_no"
                                    className="form-input"
                                    value= {(selectedFilters.reg_no)?selectedFilters.reg_no:''}
                                    onChange={(val) => changeHandler(val, "reg_no")}
                                    placeholder=" "
                                />
                                <label data-label={t("BACK_OFFICE.DEALER_FILTER.reg_no")} className="form-label"></label>

                            </div>
                        </fieldset>

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={selectedFilters.invt_status >= 0 ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={ selectedFilters.invt_status >= 0 && props.filterArrayList.INV_STATUS ? props.filterArrayList.INV_STATUS.filter((ele) => +ele.value === +selectedFilters.invt_status) : ''}
                                    id="invt_status"
                                    onChange={(val) => changeHandler(val, "invt_status")}
                                    options={props.filterArrayList.INV_STATUS}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown react-select'
                                    isClearable={false}
                                    getOptionValue={(option) => option.value}

                                />
                                <label data-label={t("BACK_OFFICE.DEALER_FILTER.invt_status")} className="form-label"></label>
                            </div>
                        </fieldset>
                        
                        <fieldset className="create-date" >
                            <div className={selectedFilters.date_from ? "active-label material" : "material "}>
                                <div className="datepicker">
                                    <DatePicker
                                        selected={selectedFilters.date_from ? new Date(selectedFilters.date_from) : null}
                                        onChange={(val) => changeHandler(val, "date_from")}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText=""
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />

                                </div>
                                <label data-label={t("BACK_OFFICE.DEALER_FILTER.date_from")} className="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset className="create-date" >
                            <div className={selectedFilters.date_to ? "active-label material" : "material "}>
                                <div className="datepicker">
                                    <DatePicker
                                        selected={selectedFilters.date_to ? new Date(selectedFilters.date_to) : null}
                                        onChange={(val) => changeHandler(val, "date_to")}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText=""
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />

                                </div>
                                <label data-label={t("BACK_OFFICE.DEALER_FILTER.date_to")} className="form-label"></label>
                            </div>
                        </fieldset>

                        <div className="btn-submit-reset">
                            <button className="btn-primary" onClick={searchFilter}>{t("COMMON.SEARCH_BTN")}</button>
                            <button className="btn-reset" onClick={resetFilter} style={{ cursor: 'pointer' }}>{t("COMMON.RESET_BTN")}</button>
                        </div>
                    </div>

                </div>
            </div>    
        </>
    )

}


export default DealerQcFilters;