import React, { useEffect, useState } from "react";
import { UserSlice } from "../../store/slices";

const Captcha = (props) => {

    const [captcha, setCaptcha] = useState('')
    const [captchaInvalid, setCaptchaInvalid] = useState('')
    const [captchaImg, setCaptchaImg] = useState({})

    useEffect(()=>{

        loadCaptchaImage()
        
    }, [props.reloadCaptcha])

    
    useEffect(()=> {
        setCaptchaInvalid(props.errField.captcha)
    }, [props.errField])


    /**
     *  Load Captcha Image
     */

    const loadCaptchaImage = (err = []) => {
        setCaptcha("");

        UserSlice.loadloginCaptcha().then(res=>{
            if(res && res.data && res.data.data){
                setCaptchaImg(res.data.data)
            }
        })
                
    };

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    const handleInputChange = async e => {
        let captchaVal = e.target.value;
        setCaptcha(captchaVal)

        props.onKeyPressCaptcha(captchaVal, captchaImg);        
    };


    const { captchaError } = props;
    
    return (
      <>
        <div className={captchaInvalid ? "material error": "material"}>
                <input
                    type="text"
                    placeholder=" "
                    name="captcha"
                    value={captcha}
                    className="form-input"
                    onChange={handleInputChange}
                />

                <i className="ic-survey icn-login"></i>

                <label data-label="Captcha" className="form-label"></label>
                <span className="erroe-txt">{captchaInvalid||''}</span>

                <span className="password-show" >
                  {captchaError ? (
                    ''
                  ) : (
                    <>
                      {/* <img src={captchaImg.image} className="captchaimg" alt="" style={{width:'73px'}}/> */}
                      {/* <div dangerouslySetInnerHTML={{ __html: captchaImg.svg }} className="captchaimg" style={{ width: '73px', height: '37px' }} /> */}
                      <img src={`data:image/svg+xml;base64,${window.btoa(captchaImg.svg)}`} className="captchaimg" alt=""  />
                      
                    </>
                  )}
                </span>
                <span
                        className="reload"
                        onClick={loadCaptchaImage}
                        title="reload captcha"
                      >
                        &#x21bb;
                      </span>
          
        </div>
      </>
    );
}

export default Captcha;
