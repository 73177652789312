
const PopupBox = ({ show, children, setShowUpdatePopup }) => {
    const showHideClassName = show ? "modal display-block " : "modal display-none ";

    const handleClose = () => {
        setShowUpdatePopup(false);
    }

    return (
        show &&
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default PopupBox;