import React, { useState, useEffect } from 'react'
import { getInspectionRqstReason, cancelInspectionRequest, rescheduleSFARequest } from "@src/store/slices/inspection"
import { useTranslation } from "react-i18next"
import PopupBox from '@src/view/common/PopupBox';
import DatePicker from "react-datepicker";
import moment from 'moment';

function ViewStatusModal(props) {
    const [cancelReason, setCancelReason] = useState([]);
    const [reInspectionReason, setReInspectionReason] = useState([])
    const { t } = useTranslation("common")
    const { modalType, updateData: selectedData } = props;
    const [selectedReason, setSelectedReason] = useState('');
    const [closePopup, setClosePopup] = useState(false);
    const [address, setAddress] = useState('');
    const [selectedDate, setSelectedDate] = useState('');


    useEffect(() => {
        props.setLoading(true)

        fetchInspectionRqstReason()
        if (selectedData) {
            setAddress(selectedData.address || "")
        }

        props.setLoading(false)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //fetch cancel or reschedule reasons
    const fetchInspectionRqstReason = async () => {
        const reasons = await getInspectionRqstReason()

        if (reasons && reasons.cancelReason) {
            setCancelReason(reasons.cancelReason)
        }
        if (reasons && reasons.reInspectionReason) {
            setReInspectionReason(reasons.reInspectionReason)
        }
    }

    const submitResponse = async () => {
        props.setLoading(true)

        if (modalType === 'cancel') {
            let params = {
                requestID: (selectedData && selectedData.bookingID) || '',
                reason: selectedReason
            }

            const cancelBooking = await cancelInspectionRequest(params)
            //if updated successfully then refresh listing
            if (cancelBooking && cancelBooking.status && cancelBooking.status === 'T') {
                props.updateStatusListing()
                setClosePopup(true)
            }
        }

        if (modalType === 'reschedule') {
            let params = {
                requestID: (selectedData && selectedData.bookingID) || '',
                reason: selectedReason,
                isAddressSame: (selectedData && selectedData.address === address) ? true : false,
                custAddress: address,
                date: selectedDate
            }

            const rescheduleBooking = await rescheduleSFARequest(params)
            //if updated successfully then refresh listing
            if (rescheduleBooking && rescheduleBooking.status && rescheduleBooking.status === 'T') {
                props.updateStatusListing()
                setClosePopup(true)
            }
        }

        props.setLoading(false)
    }

    const changeHandler = (event) => {
        if (modalType === 'cancel') {
            setSelectedReason(event.target.value)
        }

        if (modalType === 'reschedule') {
            setSelectedReason(event.target.value)
        }
    }

    return (
        <div>

            <div className='view-status-modal'>
                {/* FOR UPDATE MODAL */}
                <div className=''>
                    <PopupBox show={closePopup ? false : props.showUpdatePopup}
                        setShowUpdatePopup={props.setShowUpdatePopup}
                        updateAllData={props.updateData}
                    >
                        <div className="modal-header">

                        </div>
                        <div className="modal-body">
                            <div className='update-request-popup-content'>

                                {
                                    modalType === "cancel"
                                    &&
                                    <div className=''>
                                        <h3>{t("VIEW_STATUS.CANCEL_TAGLINE")}</h3>
                                        <h4>{(selectedData && selectedData.fromOwnerName) ? selectedData.fromOwnerName + " (GCD-" + selectedData.fromOwnerID + ")" : ''}</h4>


                                        <div className="cust-radio">
                                            {cancelReason && cancelReason.map((ele, index) => {
                                                return <div className="radio-main" key={index}>
                                                    <br />
                                                    <input type="radio" name={"reqcancel_" + index} id={index} className="reqcancel custom-checkbox" value={ele} checked={ele === selectedReason} onChange={changeHandler} />
                                                    <label htmlFor={"reqcancel_" + index}><span></span>{ele}</label>
                                                    <br />
                                                </div>
                                            })}
                                        </div>

                                        <div className='confirmation-btn m-md-t'>
                                            <button className='btn-primary' onClick={() => submitResponse(true)} disabled={modalType === 'cancel' && !selectedReason}>Submit</button>
                                        </div>
                                    </div>
                                }


                                {
                                    modalType === "reschedule"
                                    &&
                                    <div className=''>
                                        {
                                            !selectedReason &&
                                            <div>
                                                <h3>{t("VIEW_STATUS.RESCHEDULE_TAGLINE")}</h3>
                                                <h4>{(selectedData && selectedData.fromOwnerName) ? selectedData.fromOwnerName + " (GCD-" + selectedData.fromOwnerID + ")" : ''}</h4>

                                                {
                                                    reInspectionReason && reInspectionReason.map((ele, index) => {
                                                        return <div className="cust-radio" onChange={changeHandler} key={index}>
                                                            <div className="radio-main">
                                                                <br />
                                                                <input type="radio" name="reqcancel" id={`reqcancel_ ${index}`} className="reqcancel custom-checkbox" value={ele} />
                                                                <label htmlFor={`reqcancel_ ${index}`}><span></span>{ele}</label>
                                                                <br />
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        }

                                        {
                                            selectedReason && <div>
                                                <h4>{selectedReason}</h4>
                                                <h3>{(selectedData && selectedData.fromOwnerName) ? selectedData.fromOwnerName + " (GCD-" + selectedData.fromOwnerID + ")" : ''}</h3>

                                                <fieldset className="create-date" >
                                                    <div className={selectedDate ? "active-label material" : "material "}>
                                                        <div className="datepicker">
                                                            <DatePicker
                                                                dateFormat="dd/MM/yyyy"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                placeholderText=""
                                                                value={selectedDate}
                                                                onChange={(value) => {
                                                                    let dateValue = moment(value).format('YYYY-MM-DD')
                                                                    setSelectedDate(dateValue)
                                                                }}
                                                            />

                                                        </div>
                                                        <label data-label={'Date'} className="form-label"></label>
                                                    </div>
                                                </fieldset>

                                                <div className="address-chk m-md-t">
                                                    <div className="radio-main">
                                                        <input type="checkbox" name="sameaddress" id="sameaddress" className="custom-checkbox sameaddress" defaultChecked={true} value="1" onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setAddress(selectedData.address)
                                                            }
                                                            else {
                                                                setAddress('')
                                                            }
                                                        }} />
                                                        <label htmlFor="sameaddress"><span> </span>{t("VIEW_STATUS.SAME_ADDRESS")}</label>
                                                    </div>
                                                </div>
                                                <div className="material-design">

                                                    <fieldset className="form-filed m-md-t">
                                                        <div className="material">
                                                            <textarea placeholder=" " className="form-input"
                                                                maxLength="250"
                                                                value={address}
                                                                onChange={(e) => {
                                                                    setAddress(e.target.value)
                                                                }}
                                                            ></textarea>

                                                            <label data-label={"Address"} className="form-label"></label>

                                                        </div>
                                                    </fieldset>

                                                </div>
                                                <div className='confirmation-btn m-md-t'>
                                                    <button className='btn-primary' onClick={() => submitResponse(true)} disabled={(selectedDate && address) ? false : true}>Submit</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }

                            </div>
                        </div>

                    </PopupBox>
                </div>

            </div>
        </div>
    )
}

export default ViewStatusModal