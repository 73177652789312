import React, { useState, useEffect} from "react";
import secureStorage from '@src/config/encrypt';
import Select from "react-select";
import { useTranslation } from "react-i18next"
import Dateformat from 'dateformat';
import PopupBox from '@src/view/common/PopupBox';
import {inspectionCityList,updateAddress} from "@src/store/slices/inspection"
import { toast } from "react-toastify";


const InspectionRequest = (props) => {
    const { t } = useTranslation("common")
    const is_inhouse = secureStorage.getItem('authUserInfo').user_data.is_inhouse;
    const [covenceVal, setCovenceVal] = useState((props.inspectionBookingData.conveyance_charge)?props.inspectionBookingData.conveyance_charge:'');
    const [showAddressPopup, setShowAddressPopup] = useState(false);
    const [inspectionCity, setInspectionCity] = useState([]);
    const [bookingAddressData, setBookingAddressData] = useState({});

    useEffect(() => {
        getinspectionCityList();
        setbookingaddressData(); 
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    // Inspection City List
    const getinspectionCityList = () => {     
        inspectionCityList().then((res) => {
            if (res && res.status === 200) {
                let updatedInspectionList = [];
                for(let el of res.data) {
                    if(el.city_name){
                        updatedInspectionList.push(el);
                    }
                }
                res.data = updatedInspectionList;
                setInspectionCity(res.data);
            }
        }).catch(err => {
        })
    }
 
    const changeHandler = (value, name) => {
        let newVal = ""
        if (name === "covence_val") {
            newVal = value.target.value.slice(0,3);
        }else {
            newVal = value && value.value ? value['value'] : undefined;
        }
        if(newVal===undefined){
            setCovenceVal('');
        }else{
            setCovenceVal(newVal);
        }
    }
    
    /*
    *Set Booking Address Params
    */
    const setbookingaddressData=()=>{
        let addressParams={
            booking_id:props.inspectionBookingData.book_id,
            address:props.inspectionBookingData.address,
            pincode:props.inspectionBookingData.pin_code,
            city:props.inspectionBookingData.city_id
        }
        setBookingAddressData(addressParams);
    }

    /*
    *Update Convence KM
    */
    const updateConvence = () => {
        let params={convence:(covenceVal)?covenceVal:'',booking_id:props.inspectionBookingData.book_id}
        props.updateAssignConvence(params);
    }

    /*
    *Update address
    */
    const addresspopup = () => {
        setShowAddressPopup(true);
    }

    const updateBookingAddress = (value, name) => {
        let newVal = ""
        if (name === "address" || name === "pincode") {
            newVal = value.target.value;
        }else {
            newVal = value && value.value ? value['value'] : undefined;
        }
        if(!newVal){
            delete bookingAddressData[name];
            setBookingAddressData({ ...bookingAddressData});
        }else{
            setBookingAddressData({ ...bookingAddressData, [name]: newVal });
        }
    }
    
    /*
    *Save Booking Address
    */
    const bookingAddressUpdate=()=>{
        if(!bookingAddressData.hasOwnProperty('address') || (bookingAddressData.address==='')){
            toast.error("Please Enter Address");
        }else if(!bookingAddressData.hasOwnProperty('pincode')|| (bookingAddressData.pincode==='')){
            toast.error("Please Enter Pincode");
        }else{
            updateAddress(bookingAddressData).then((response) =>{
                if(response.status===200){
                   toast.success(response.message);
                   setShowAddressPopup(false);
                   window.location.reload();
                }
            })
        }
    }


    return (
        <>
            <div className="tab-content p-md-t">
                <h2 className="card-head col-sm-12">{props.heading}</h2>
                <div className="rows">
                    <div className="col-sm-9">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="dtList">
                                        <ul className="list-unstyled row">
                                            <li className="col-sm-5">
                                                <label>Contact Person</label>
                                                <p>{props.inspectionBookingData.user_name}</p>
                                            </li>
                                            <li className="col-sm-4">
                                                <label>Contact Number</label>
                                                <p>{props.inspectionBookingData.mobile}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="dtList">
                                        <ul className="list-unstyled row">
                                            {
                                                (is_inhouse!==0) &&
                                                <>
                                                <li className="col-sm-5">
                                                    <label>Inspection Requested By</label>
                                                    <p>{props.inspectionBookingData.owner_name}</p>
                                                </li>
                                                <li className="col-sm-4">
                                                    <label>Prefered Date/Time</label>
                                                    <p>{Dateformat(props.inspectionBookingData.inspection_prefered_time,'ddd, dd mmmm, yyyy/HH:MM tt')}</p>
                                                </li>
                                            </>
                                            }
                                            <li className="col-sm-3">
                                                <label>Product Type</label>
                                                <p>{props.inspectionBookingData.inspection_product}</p>
                                            </li>
                                        </ul>
                                        {   
                                            (props.inspectionBookingData.ce_id>0)?
                                            <div className="alert alert-success">
                                                Booking inspection already assign to  <strong>{props.inspectionBookingData.ce_name}</strong> on  {Dateformat(props.inspectionBookingData.inspection_schedule_time,'ddd, dd mmmm, yyyy/HH:MM tt')}
                                            </div>:''
                                        }
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="dtList">
                            <ul className="list-unstyled row">
                                <li className="">
                                    <label>Address</label>
                                    {
                                        ((is_inhouse!==0) && props.inspectionBookingData.inspection_location_type !== 'dealer' ) &&  
                                        <>
                                            <i className="ic-edit address-edit-icon" onClick={addresspopup} title={t("INSPECTION.EDIT")}></i>

                                            {/* <button type="button" className="btn edit-btn" id="editadress" onClick={addresspopup} >{t("INSPECTION.EDIT")}</button> */}
                                        </>
                                    }
                                    <p className="mrg-B15 adrsPara">{(props.inspectionBookingData.address||"") + (props.inspectionBookingData.city ? ", "+props.inspectionBookingData.city : "") + (props.inspectionBookingData.pin_code ? " - "+props.inspectionBookingData.pin_code : "")}</p>
                                </li>
                                {/* <li className="">
                                    {
                                        ((is_inhouse!==0) && props.inspectionBookingData.inspection_location_type!='dealer' ) &&  
                                        <>
                                            <button type="button" className="btn edit-btn" id="editadress" onClick={addresspopup} >{t("INSPECTION.EDIT")}</button>
                                        </>
                                    }
                                    
                                </li> */}
                                <li className="">
                                    <label>Conveyance(KM):</label>
                                    <input type="number" 
                                    id="covence_val" 
                                    name="covence_val" 
                                    className=" conveyanceWidth form-input"
                                    value={(covenceVal)?covenceVal:''}
                                    onChange={(val) => changeHandler(val, "covence_val")}
                                    max={3}  />
                                    <button type="button" className="btn btn-small" id="saveconvence" onClick={updateConvence} >{t("COMMON.SUBMIT_BTN")}</button>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        <div>
            {
               showAddressPopup?
               <div className='update-request-popup-popup'>
                    <PopupBox show={showAddressPopup}
                     setShowUpdatePopup={setShowAddressPopup} >
                        <div className="modal-header">{t("INSPECTION.HEADING.UPDATE_ADDRESS")}</div>
                        {/* <div className="container"> */}
                        <div className="modal-body ">
                            <div className='update-request-popup-content row'>
                                <fieldset className='search-bx col-md-12 m-md-t'>
                                    <div className="material">
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            className="form-input"
                                            value= {bookingAddressData.address}
                                            onChange={(val) => updateBookingAddress(val, "address")}
                                            placeholder=" "
                                        />
                                        <label data-label={t("INSPECTION.UPDATE_ADDRESS_FIELD.ADD")} className="form-label"></label>

                                    </div>
                                </fieldset>
                                <fieldset className='search-bx col-md-12 m-md-t'>
                                    <div className="material">
                                        <input
                                            type="text"
                                            id="pincode"
                                            name="pincode"
                                            className="form-input"
                                            value= {bookingAddressData.pincode}
                                            onChange={(val) => updateBookingAddress(val, "pincode")}
                                            placeholder=" "
                                        />
                                        <label data-label={t("INSPECTION.UPDATE_ADDRESS_FIELD.PINCODE")} className="form-label"></label>
                                    </div>
                                </fieldset>
                            
                                <fieldset className='seller-opt multiselect-dropDown col-sm-12 p-md-t'>
                                    <div className={"active-label material animation-effect"}>
                                        <Select
                                            id="city"
                                            placeholder={t("INSPECTION.UPDATE_ADDRESS_FIELD.CITY")}
                                            value={bookingAddressData.city ? inspectionCity.filter((ele) => ele.value === bookingAddressData.city) : ''}
                                            options={inspectionCity}
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            isClearable={true}
                                            getOptionValue={(option) => option.value}
                                            onChange={(val) => updateBookingAddress(val, "city")}
                                        />
                                    </div>
                                </fieldset>
                                
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="d-flex btnWrapper">
                            <button className="btn btn-primary"onClick={bookingAddressUpdate} >{t("INSPECTION.SAVE")}</button>                           
                            <button className="btn btn-default" onClick={()=>setShowAddressPopup(false)} >{t("INSPECTION.CLOSE")}</button>

                            </div>
                        

                        </div>
                        {/* </div> */}
                        
                </PopupBox>

               </div>:null 
            }
        </div>
        </>
    );

}



export default InspectionRequest;