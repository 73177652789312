import React, { useEffect,useState} from 'react';
import { useTranslation } from 'react-i18next';
import { timelineLog } from "@src/store/slices/inspection"

const BulkHistoryTable = (props) => {
    const { t } = useTranslation('common');
    const [timeLineLogData, setTimeLineLogData] = useState([]);


    useEffect(() => {
        //fetch all timline data
        timelineLog({ booking_id: props.bookingID }).then((res) => {
            if (res.status === 200) {
                setTimeLineLogData(res.data);
            }
        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
        <div className='bulk-history-sec'>
            <h3>{t("COMMON.TIMELINE")} </h3>

            <div className='bulk-history-table'>
                {
                    timeLineLogData.length
                    ?
                        <div className='data-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t('INSPECTION.STATUS')}</th>
                                        <th>{t('INSPECTION.CREATED_DATE')}</th>
                                        <th>{t('INSPECTION.MESSAGE')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        timeLineLogData.map((val, i) => {
                                            let dateObj;
                                            let formattedDate;
                        
                                            if (val.date_added && val.date_added !== '') {
                                                dateObj = new Date(val.date_added)
                                                formattedDate = `${dateObj.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })} ${dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
                        
                                            }
                                            return <tr key= {i}>
                                                <td>
                                                    In Process
                                                </td>
                                                <td>
                                                    {formattedDate} {val.comment_username ? `by ${val.comment_username}` : 'by Inspection System'}
                                                </td>
                                                <td>{val.comment}</td>

                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    :
                        <div className='history-no-data-txt'>
                            {t("COMMON.NO_HISTORY")}
                        </div>
                }
                
            </div>

        </div>
        </React.Fragment>
    )

}
export default BulkHistoryTable;