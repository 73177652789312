import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router";
import secureStorage from '@src/config/encrypt'
import { getStateCityList } from "@src/store/slices/common"
import { getUserRoles, getUserById } from "@src/store/slices/user"
import { useDispatch, useSelector } from "react-redux"
import UserForm from "@src/view/user/common/UserForm"
import TopActionBtn from "@src/view/common/TopActionBar";

import { useParams } from "react-router-dom";



const AddEditUser = (props) => {

    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(false)
    const [cityList, setCityList] = useState([])
    const [stateList, setStateList] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const [userDetails, setUserDetails] = useState({})
    const [currPage, setCurrPage] = useState('')


    const dispatch = useDispatch();
    const currLocation = useLocation()

    const fetchUserDetails = secureStorage.getItem('authUserInfo')
    const loggedInUserDetails = fetchUserDetails && fetchUserDetails.user_data

    const userState = useSelector(state => state.auth)
    const commonState = useSelector(state => state.common)

    const currentPage = currLocation && currLocation.pathname;

    let { userId } = useParams(); 


    const getUserDetails = async (params) => {
        try {
            setLoading(true)
            const response = await getUserById(params)

            if (response && response.data) {
                const data = response.data
                setUserDetails(data)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }


    /**
     * fetches list of CE users
     * @param {object} params 
     */
    const getStateCityListing = (params) => {
        dispatch(getStateCityList(params)).then((res) => {
            if (res && res.status === 200) {

                const cities = res.data.city
                const state = res.data.state

                const updatedCities = cities.map((ele) => {
                    return { label: ele.name, id: ele.id, name: ele.name, state_id: ele.state_id, value: ele.id }
                })

                const updatedStates = state.map((ele) => {
                    return { label: ele.name, id: ele.id, name: ele.name, value: ele.id }
                })

                setCityList(updatedCities)
                setStateList(updatedStates)

            }
        }).catch((err) => {
        })
    }

    /**
     * fetches list of user roles
     * @param {object} params 
     */
    const getUserRoleListing = (params) => {
        dispatch(getUserRoles(params)).then((res) => {
            if (res && res.status === 200) {

                const updatedRoles = res.data.map((ele) => {
                    return { label: ele.role_title, id: ele.id, param: ele.param, parent_role_id: ele.parent_role_id }
                })

                setUserRoles(updatedRoles)
            }
        }).catch((err) => {
        })
    }

    /**
     * calling city list, user roles, menu list and user access list API on component mount
     */
    useEffect(() => {
        
        const userID = loggedInUserDetails && loggedInUserDetails.hash_id

        setTimeout(() => {
            setCurrPage(currentPage)
        }, 100)

        if (currentPage) {
            if (currentPage && currentPage.indexOf('/user/edit')!==-1) {
                //validate if user has access
                //code
                // if (!callFrom || !encyptedUserID) {
                //     navigate('/dashboard')
                // }
                // else {
                    getUserDetails({ userID: userId })
                // }

           }

            if (userState && !(userState.userRoles || userState === undefined)) {
                getUserRoleListing({ userID })
            }
            else {
                let roles = userState.userRoles
                let rolesData = roles && roles.data

                const updatedRoles = rolesData.map((ele) => {
                    return { label: ele.role_title, id: ele.id, param: ele.param, parent_role_id: ele.parent_role_id }
                })
                setUserRoles(updatedRoles)
            }

            if (commonState && !commonState.stateCityList) {
                getStateCityListing()
            }
            else {
                //updating city and state list from redux
                const stateCityResponse = commonState && commonState.stateCityList
                const data = stateCityResponse && stateCityResponse.data

                const cities = data.city
                const state = data.state

                const updatedCities = cities && cities.map((ele) => {
                    return { label: ele.name, id: ele.id, name: ele.name, state_id: ele.state_id,value: ele.id }
                })

                const updatedStates = state && state.map((ele) => {
                    return { label: ele.name, id: ele.id, name: ele.name }
                })

                setCityList(updatedCities)
                setStateList(updatedStates)

            }
        }

        return (() => {
            setCityList([])
            setStateList([])
            setUserDetails({})
            setUserRoles([])
            setCurrPage('')
        })
    // }
    }, [currentPage]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div className={"container-fluid-full"}>


                <div className="top-sec">
                    <TopActionBtn heading={currPage.includes('/user/edit') ? t("USER_MODULE.EDIT_USER") : t("USER_MODULE.ADD_USERS")} />
                </div>

                {currPage && currPage.includes('user/edit') ?
                    <UserForm callFrom="user/edit" userDetails={userDetails} cityList={cityList} userRoles={userRoles} stateList={stateList} loading={loading} hashId={userId} /> :
                    (currPage && currPage.includes('user/add') ?
                        <UserForm callFrom="user/add" cityList={cityList} stateList={stateList} userRoles={userRoles} loading={loading} />
                        : '')
                }
            </div>


        </>


    )
}

export default AddEditUser