import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { useTranslation } from "react-i18next"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AssignScheduler from './AssignScheduler'
import { useSelector, useDispatch } from 'react-redux';
import { setInspectionFilter } from '@src/store/slices/inspection';
import { DateFormat } from "../../../config/helper";
import { setSchedulerData } from '@src/store/slices/common';

const InspectionRequestFilter = (props) => {

    const [selectedFilters, setSelectedFilters] = useState(props.searchFilters || {})
    const [regno, setRegno] = useState('');
    const [activeField, setActiveField] = useState('');
    const { t } = useTranslation("common")
    const dispatch = useDispatch();

    const commonState = useSelector(state=> state.common);

     // It Run During Every Filter On-change
    const changeHandler = (value, name) => {
        let newVal = "";

        if (name === "regnoSearch") {
            newVal = value.target.value;
            setRegno(value.target.value);
        } else if (name === "inspectionDate" || name === "requestDateFrom" || name === "requestDateTo") {
            newVal = DateFormat(value);

        } else {
            newVal = value && value.value ? value['value'] : undefined;
        }

        setSelectedFilters({ ...selectedFilters, [name]: newVal })
    }


    /**
     * updates filter object and pass filters to parent 
     */

    const searchFilter = () => {
        let callonpage;

        if (props.newInspectionRequest === 'new') {
            callonpage = 'new'
        }
        else if (props.allInspectionRequest === 'all') {
            callonpage = 'all'
        }

        if (Object.keys(selectedFilters).length) {

            let params = { ...selectedFilters, callonpage: callonpage }
            //remove empty keys            
            for(let elm of Object.keys(params)){
                if(!params[elm]) delete params[elm]
            }
            //updating parent filter object
            props.updateFilter(params)

            dispatch(setInspectionFilter(params));

        }
        else {
            props.updateFilter({ callonpage: callonpage })
        }
    }

    /**
     * resets all selected filters
     */
    const resetFilter = () => {
        setSelectedFilters({})

        let callonpage;
        // If Reset Press By New Page Tab The If Condition Run
         if(props.newInspectionRequest === 'new'){
            callonpage = 'new';
        } else if (props.allInspectionRequest === 'all') {
            callonpage = 'all';
        }

        props.updateFilter({ callonpage })
        setRegno('');
        dispatch(setInspectionFilter({}));
    }
    
    //When Tab Change Reset Function Will Call 
    useEffect(()=>{
      setSelectedFilters({})
      setRegno('');
      dispatch(setInspectionFilter({}));  
      dispatch(setSchedulerData([]));
      
    },[props.resetOnChange]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div className="top-search-bar">
                <div className="top-ribben-search">
                    <div className="inspection-list-form">

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.vehicleType|| activeField === 'vehicleType') ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.vehicleType ? props.inspectionFilterList.vehicleType.filter((ele) => ''+ele.value === ''+selectedFilters.vehicleType) : ''}
                                    onChange={(val) => changeHandler(val, "vehicleType")}
                                    options={props.inspectionFilterList.vehicleType}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown react-select'
                                    isClearable={true}
                                    getOptionValue={(option) => option.value}
                                    onMenuClose={() => setActiveField('')}
                                    onInputChange={() => { setActiveField('vehicleType') }}

                                />
                                <label data-label={t("INSPECTION.VEHICLE_TYPE")} className="form-label"></label>

                            </div>
                        </fieldset>

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.requestUser || activeField === 'requestUser' ) ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.requestUser ? props.requestUserList.filter((ele) => ''+ele.value === ''+selectedFilters.requestUser) : ''}
                                    onChange={(val) => changeHandler(val, "requestUser")}
                                    options={props.requestUserList}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown'
                                    isClearable={true}
                                    getOptionValue={(option) => option.label}
                                    onMenuClose={() => setActiveField('')}
                                    onInputChange={() => { setActiveField('requestUser') }}

                                />
                                <label data-label={t("INSPECTION.REQUEST_USER")} className="form-label"></label>

                            </div>
                        </fieldset>

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.requestCity || activeField === 'requestCity' ) ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.requestCity ? props.requestCity.filter((ele) => ele.value === selectedFilters.requestCity) : ''}
                                    onChange={(val) => changeHandler(val, "requestCity")}
                                    options={props.requestCity}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown'
                                    isClearable={true}
                                    getOptionValue={(option) => option.label}
                                    onMenuClose={() => setActiveField('')}
                                    onInputChange={() => { setActiveField('requestCity') }}

                                />
                                <label data-label={t("INSPECTION.REQUEST_CITY")} className="form-label"></label>

                            </div>
                        </fieldset>

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.inspectionCity || activeField === 'inspectionCity' ) ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.inspectionCity ? props.inspectionCity.filter((ele) => ele.value === selectedFilters.inspectionCity) : ''}
                                    onChange={(val) => changeHandler(val, "inspectionCity")}
                                    options={props.inspectionCity}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isClearable={true}
                                    getOptionValue={(option) => option.label}
                                    onMenuClose={() => setActiveField('')}
                                    onInputChange={() => { setActiveField('inspectionCity') }}

                                />
                                <label data-label={t("INSPECTION.INSPECTION_CITY")} className="form-label"></label>

                            </div>
                        </fieldset>


                        {/* for new Inspection reuqets show some extra filter */}
                        {
                            props.allInspectionRequest
                            &&

                            <>
                            <fieldset className='seller-opt multiselect-dropDown'>
                                    <div className={(selectedFilters.requestType || activeField === 'requestType' ) ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            value={selectedFilters.requestType ? props.inspectionFilterList.requestType.filter((ele) => ele.value === selectedFilters.requestType) : ''}
                                            onChange={(val) => changeHandler(val, "requestType")}
                                            options={props.inspectionFilterList.requestType}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isClearable={true}
                                            getOptionValue={(option) => option.label}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('requestType') }}

                                        />
                                        <label data-label={t("INSPECTION.REQUEST_TYPE")} className="form-label"></label>

                                    </div>
                                </fieldset>

                                <fieldset className='seller-opt multiselect-dropDown'>
                                    <div className={(selectedFilters.requestStatus || activeField === 'requestStatus'  )? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            value={selectedFilters.requestStatus ? props.inspectionFilterList.status.filter((ele) => ele.value === selectedFilters.requestStatus) : ''}
                                            onChange={(val) => changeHandler(val, "requestStatus")}
                                            options={props.inspectionFilterList.status}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isClearable={true}
                                            getOptionValue={(option) => option.label}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('requestStatus') }}

                                        />
                                        <label data-label={t("INSPECTION.STATUS")} className="form-label"></label>

                                    </div>
                                </fieldset>

                                <fieldset className='seller-opt multiselect-dropDown'>
                                    <div className={(selectedFilters.schedulerSearch || activeField === 'schedulerSearch'  ) ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            value={selectedFilters.schedulerSearch ? props.assignTo.find(obj => obj.options.filter((ele) => ele.value === selectedFilters.schedulerSearch) ) : ''}
                                            onChange={(val) => changeHandler(val, "schedulerSearch")}
                                            options={props.assignTo}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isClearable={true}
                                            getOptionValue={(option) => option.label}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('schedulerSearch') }}

                                        />
                                        <label data-label={t("INSPECTION.SELECT_SCHEDULER")} className="form-label"></label>

                                    </div>
                                </fieldset>

                                <fieldset className='seller-opt multiselect-dropDown'>
                                    <div className={(selectedFilters.ceList || activeField === 'ceList'  ) ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            value={selectedFilters.ceList ? props.ceList.filter((ele) => ele.value === selectedFilters.ceList) : ''}
                                            onChange={(val) => changeHandler(val, "ceList")}
                                            options={props.ceList}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isClearable={true}
                                            getOptionValue={(option) => option.label}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('ceList') }}

                                        />
                                        <label data-label={t("INSPECTION.SELECT_CE")} className="form-label"></label>

                                    </div>
                                </fieldset>

                            </>
                        }

                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.productType || activeField === 'productType'  ) ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.productType ? props.inspectionFilterList.productType.filter((ele) => ele.value === selectedFilters.productType) : ''}
                                    onChange={(val) => changeHandler(val, "productType")}
                                    options={props.inspectionFilterList.productType}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isClearable={true}
                                    getOptionValue={(option) => option.label}
                                    onMenuClose={() => setActiveField('')}
                                    onInputChange={() => { setActiveField('productType') }}

                                />
                                <label data-label={t("INSPECTION.PRODUCT_TYPE")} className="form-label"></label>

                            </div>
                        </fieldset>


                        <fieldset className='seller-opt multiselect-dropDown'>
                            <div className={(selectedFilters.imageType  || activeField === 'imageType' ) ? "active-label material animation-effect" : "material animation-effect "}>
                                <Select
                                    value={selectedFilters.imageType ? props.inspectionFilterList.imageType.filter((ele) => +ele.value === +selectedFilters.imageType) : ''}
                                    onChange={(val) => changeHandler(val, "imageType")}
                                    options={props.inspectionFilterList.imageType}
                                    placeholder=""
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    isClearable={true}
                                    getOptionValue={(option) => option.label}
                                    onMenuClose={() => setActiveField('')}
                                    onInputChange={() => { setActiveField('imageType') }}

                                />
                                <label data-label={t("INSPECTION.IMAGE_TYPE")} className="form-label"></label>

                            </div>
                        </fieldset>


                        <fieldset className="create-date" >
                            <div className={selectedFilters.inspectionDate ? "active-label material" : "material "}>
                                <div className="datepicker">
                                    <DatePicker
                                        selected={selectedFilters.inspectionDate ? new Date(selectedFilters.inspectionDate) : null}
                                        onChange={(val) => changeHandler(val, "inspectionDate")}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText=""
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                   />

                                </div>
                                <label data-label={t("INSPECTION.INSPECTION_DATE")} className="form-label"></label>
                            </div>
                        </fieldset>


                        <fieldset className="create-date" >
                            <div className={selectedFilters.requestDateFrom ? "active-label material" : "material "}>
                                <div className="datepicker">
                                    <DatePicker
                                        selected={selectedFilters.requestDateFrom ? new Date(selectedFilters.requestDateFrom) : null}
                                        onChange={(val) => changeHandler(val, "requestDateFrom")}
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText=""
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />

                                </div>
                                <label data-label={t("INSPECTION.REQUEST_DATE_FROM")} className="form-label"></label>
                            </div>
                        </fieldset>

                       

                        <fieldset className="create-date" >
                            <div className={selectedFilters.requestDateTo ? "active-label material" : "material "}>
                                <div className="datepicker">
                                    <DatePicker
                                        selected={selectedFilters.requestDateTo ? new Date(selectedFilters.requestDateTo) : null}
                                        onChange={(val) => changeHandler(val, "requestDateTo")}
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText=""
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />

                                </div>
                                <label data-label={t("INSPECTION.REQUEST_DATE_TO")} className="form-label"></label>
                            </div>
                        </fieldset>

                        <fieldset className='search-bx'>
                            <div className={"material"}>
                                <input
                                    type="text"
                                    name="regnoSearch"
                                    className="form-input"
                                    value={regno}
                                    onChange={(val) => changeHandler(val, "regnoSearch")}
                                    placeholder=" "
                                />
                                <label data-label={t("INSPECTION.ENTER_REG_NO")} className="form-label"></label>

                            </div>
                        </fieldset>

                        <div className="btn-submit-reset">
                            <button className="btn-primary" onClick={searchFilter}>{t("COMMON.SEARCH_BTN")}</button>
                            <button className="btn-reset" onClick={resetFilter} style={{ cursor: 'pointer' }}>{t("COMMON.RESET_BTN")}</button>
                        </div>

                    </div>

                    {(props.newInspectionRequest && commonState.schedulerData && commonState.schedulerData.length) > 0 &&

                        <div className='inspection-list-form'>
                            <AssignScheduler
                                assignTo={props.assignTo}
                                schedulerData={props.schedulerData}
                                searchFilter={searchFilter}

                            />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}


export default InspectionRequestFilter;