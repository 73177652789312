import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Dateformat from 'dateformat';
import { getinspectionConfig } from "@src/store/slices/inspection"
import { getDiffOfDaysInTwoDate } from "../../../config/helper";
import { toast } from "react-toastify";



const InspectionAssign = (props) => {
    const { t } = useTranslation("common")
    const [inspectionReason, setInspectionReason] = useState([]);
    const [assignData, setAssignData] = useState({});
    const [bookingDate, setBookingDate] = useState('');
    const [timeOverlappingState, setTimeOverlappingState] = useState(false);

    useEffect(() => {
        getInspectionConfig();
        AssignData();
    }, [props.inspectionBookingData, props.bookingDate]) // eslint-disable-line react-hooks/exhaustive-deps


    //Inspection Reason
    const getInspectionConfig = () => {
        getinspectionConfig().then((res) => {
            if (res && res.reschedule_reason && res.reschedule_reason.length > 0) {
                setInspectionReason(res.reschedule_reason);
            }
        }).catch(err => {
        })
    }

    /**
     * Set Default Assign Data
    */
    const AssignData = () => {
        if (props.inspectionBookingData && props.bookingDate) {
            let d = new Date();
            let bookingDate = new Date(props.bookingDate);
            bookingDate.setHours(d.getHours() + 2, '00');
            let updatedAssignData = {
                ...assignData,
                booking_id: props.inspectionBookingData.book_id,
                status: 2,
                assignDate: new Date(bookingDate).toISOString()
            }
            setAssignData({ ...updatedAssignData });
        }
    }

    const changeHandler = (value, name) => {
        let newVal = ""
        if (name === "assignDate") {
            newVal = new Date(value).toISOString();
        } else {
            newVal = value && value.value ? value['value'] : undefined;
        }
        // if(!newVal){
        //     delete assignData[name];
        //     setAssignData({ ...assignData});
        // }else{
        setAssignData({ ...assignData, [name]: newVal });
        // }
    }

    useEffect(() => {
        if (assignData.ce && assignData.assignDate) {
            assignCeToBooking(assignData.ce);
        }
    }, [assignData]) // eslint-disable-line react-hooks/exhaustive-deps

    /*
    *Set Assign Ce Date
    */
    const DateChange = (value, name) => {
        let futureTimeAllowd = 3;
        let newVal = new Date(value).toISOString();
        let selectedDate = new Date(value);
        let currentDate = new Date();
        let diff = getDiffOfDaysInTwoDate(selectedDate, currentDate);
        if (diff > futureTimeAllowd) {
            let message = `Sorry future date allow only for next ${futureTimeAllowd} days`
            toast.error(message);
            return false;
        } else {
            let booking_date = Dateformat(selectedDate, 'yyyy-mm-dd');
            let assignceparams = {
                city_id: props.inspectionBookingData.city_id,
                booking_date: booking_date,
                lat: props.inspectionBookingData.address_lat,
                long: props.inspectionBookingData.address_lang
            };
            if (bookingDate === '' || bookingDate !== booking_date) {
                setBookingDate(booking_date);
                props.assignGetCeData(assignceparams);
            }
            // delete assignData['ce'];
            setAssignData({ ...assignData, [name]: newVal });
        }
    }

    /*
    *Assign Ce And Date for Inspection
    */
    const updateAssignCe = () => {
        if(timeOverlappingState) {
            toast.error("Task time overlaping with other. Need to change time or date")
        }else{
            if (Object.keys(assignData).length > 0) {
                props.updateAssignCe(assignData)
            }
        }
    }

    /*
    * Assign Ce to booking 
    */
    const assignCeToBooking = (value) => {
        let regNo = props.inspectionBookingData.reg_no;
        let address = props.inspectionBookingData.address;
        let startTime = new Date(assignData.assignDate);
        let getAHr = +Dateformat(startTime, 'HH');
        let getAMi = +Dateformat(startTime, 'MM');
        let endTime = new Date(assignData.assignDate);
        endTime.setHours(endTime.getHours() + 1);
        let TimeStart = Dateformat(startTime, "HH:MM");
        let TimeEnd = Dateformat(endTime, "HH:MM");

        let id = '#div-' + value;
        let childid = '#li-' + (getAHr < 10 ? "0" + getAHr : getAHr);

        let liObjAllChilds = document.querySelectorAll('.preassign');
        //RESET STATE
        setTimeOverlappingState(false);


        //REMOVE ALREADY SELECTED
        if (liObjAllChilds) {
            for (let timeChild of liObjAllChilds) {
                timeChild.remove();
            }
        }

        let getPreSelectedTimes = props.calenderData ? props.calenderData.filter(cd => +cd.id === +value) : [];

        let checTimeOverLapping = false;

        //CHECK IF SELECTED TIME IS NOT OVERLAPPING WITH PREV SAVED TIMES
        for (let preSelectedTime of getPreSelectedTimes[0]['today_inspection']) {
            let selectedStartTime = new Date(preSelectedTime.inspection_schedule_time).getTime();
            let selectedStartTimeOneHrPluse = new Date(preSelectedTime.inspection_schedule_time).setHours(new Date(preSelectedTime.inspection_schedule_time).getHours() + 1);

            let currSelectedTime = new Date(assignData.assignDate).getTime();
            let currSelectedTimeOneHrPlus = new Date(assignData.assignDate).setHours(new Date(assignData.assignDate).getHours() + 1);

            //TIME CONDITION CHECK
            if ((currSelectedTime > selectedStartTime && currSelectedTime < selectedStartTimeOneHrPluse) || (currSelectedTimeOneHrPlus > selectedStartTime && currSelectedTimeOneHrPlus < selectedStartTimeOneHrPluse)) {
                checTimeOverLapping = true;
            } else if ((currSelectedTime === selectedStartTime && currSelectedTimeOneHrPlus === selectedStartTimeOneHrPluse)) {
                checTimeOverLapping = true;
            }

        }


        // if(liObj && ( (liObj.querySelectorAll('.schedule-box.preSelected')).length || (liObjPrevHr.querySelectorAll('.schedule-box.preSelected')).length || (liObjNextHr.querySelectorAll('.schedule-box.preSelected')).length )){
        if (checTimeOverLapping) {
            setTimeOverlappingState(true);
            toast.error("Task time overlaping with other. Need to change time or date")
        } else {
            let left = 'width:100%;';
            if (getAMi === 15) {
                left = 'left:25%;width:100%;';
            } else if (getAMi === 30) {
                left = 'left:50%;width:100%;';
            } else if (getAMi === 45) {
                left = 'left:75%;width:100%;';
            }

            let calenderstatus = props.updateCalenderData({ ce: value });
            if (calenderstatus) {
                setTimeout(() => {
                    if (getAHr < 10) {

                    } else if (getAHr > 15) {

                    }

                    let linewObj = document.querySelector(id).querySelector(childid);
                    if (linewObj) {

                        let html = `<div class="schedule-box preassign" style="${left}"><div class="tagBox"><span class="tag-confirm">Scheduled</span></div><p><strong>${TimeStart} - ${TimeEnd}</strong></p><p>${regNo}</p><p class="address-line" title="${address}">${address}</p></div>`;

                        linewObj.insertAdjacentHTML('beforeend', html);
                    }

                }, 500)
            }
        }
    }

    return (
        <div className="tab-content p-md-t p-md-b">
            <h2 className="card-head col-sm-12">{props.heading}</h2>
            <div className="row p-md-l p-md-r">
                {
                    (props.inspectionBookingData.ce_id > 0) &&
                    <>
                        <fieldset className='seller-opt multiselect-dropDown col-sm-12 p-md-t'>
                            <div className={"active-label material animation-effect"}>
                                <Select

                                    id="reassign_reason"
                                    placeholder={t("INSPECTION_ASSIGN.REASON")}
                                    options={inspectionReason}
                                    className="react-select"
                                    classNamePrefix='inspection-dropdown react-select'
                                    isClearable={true}
                                    getOptionValue={(option) => option.value}
                                    onChange={(val) => changeHandler(val, "reassign_reason")}

                                />
                                {/* <label data-label={t("INSPECTION_ASSIGN.REASON")} className="form-label"></label> */}
                            </div>
                        </fieldset>
                    </>
                }
                {
                    props.celistData.length > 0 && <fieldset className='seller-opt multiselect-dropDown col-sm-12 p-md-t'>
                        <div className={"active-label material animation-effect"}>
                            <Select
                                id="ce"
                                value={assignData.ce && props.celistData ? props.celistData.filter((ele) => ele.value === assignData.ce) : ''}
                                placeholder={t("INSPECTION_ASSIGN.CE")}
                                options={props.celistData}
                                className="react-select"
                                classNamePrefix='inspection-dropdown react-select'
                                isClearable={true}
                                getOptionValue={(option) => option.value}
                                onChange={(val) => changeHandler(val, "ce")}

                            />
                            {/* <label data-label={t("INSPECTION_ASSIGN.CE")} className="form-label"></label> */}
                        </div>
                    </fieldset>
                }
                <div className="col-sm-12 p-md-t">
                    <fieldset className="create-date ">
                        <div className="animation-effect active-label">
                            <div className="datepicker">
                                <DatePicker
                                    className="form-input"
                                    placeholderText={" "}
                                    dateFormat={"dd-MM-yyy HH:mm"}
                                    selected={assignData.assignDate ? new Date(assignData.assignDate) : null}
                                    minDate={new Date() || null}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    disabled={false}
                                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                                    maxTime={setHours(setMinutes(new Date(), 0), 18)}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    onChange={(val) => DateChange(val, "assignDate")}
                                />
                            </div>
                        </div>
                    </fieldset>

                </div>
                <div className="col-sm-12 p-md-t ">
                    <button type="button" className="btn btn-primary col-sm-12" id="assignCe" onClick={updateAssignCe} >{t("INSPECTION_ASSIGN.CE_BTN")}</button>
                </div>
            </div>
        </div>
    );

}



export default InspectionAssign;