import React, { useState, useEffect} from "react";
import Dateformat from 'dateformat';
import { getDiffOfDaysInTwoDate } from "../../../../config/helper";
import { toast } from "react-toastify";
import secureStorage from '@src/config/encrypt';
import { Nav } from 'react-bootstrap';
import CalenderBookingHtml from "@src/view/inspection/AssignCe/calenderHtml/CalenderBookingHtml";
import _ from 'lodash';

const AssignCeCalender = (props) => {
    const [bookingDate, setBookingDate] = useState();
    const [inspectionBookingData, setInspectionBookingData] = useState();
    const [calenderData, setCalenderData] = useState();
    const [startArrow, setStartArrow] = useState(2);
    const [endArrow, setEndArrow] = useState(7);
    const [activeClasses, setActiveClasses] = useState([]);
    // const [activeClasses, setActiveClasses] = useState([false,false,true,true,true,true,true,true,false,false,false,false]);
   
    const is_inhouse = secureStorage.getItem('authUserInfo').user_data.is_inhouse;

    const  timeRange = _.range(8, 20);

    useEffect(() => {
        setBookingDate(props.bookingDate)
        setInspectionBookingData(props.inspectionBookingData);
        setCalenderData([...props.calenderData]);

    }, [props.calenderData]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(()=>{
        if(!activeClasses.length) {
            let defaultVisiblity = [];
            for(let num of timeRange) {
                defaultVisiblity.push( (9 < num && num < 16) ? true: false )
            }
            setActiveClasses(defaultVisiblity); 
        }

    }, [timeRange]) // eslint-disable-line react-hooks/exhaustive-deps

    /*
    *Calculate Next Date
    */
    const nextDate =() => {
        let futureTimeAllowd=3;
        let selectedDate = new Date(bookingDate);
        let currentDate = new Date(Dateformat(new Date(),"yyyy-mm-dd"));
        let diff=getDiffOfDaysInTwoDate(selectedDate,currentDate);
        if(diff>futureTimeAllowd){
            let message=`Sorry future date allow only for next ${futureTimeAllowd} days`
            toast.error(message);
            return false;
        }else{
            let nextDay = new Date(bookingDate);			// CALCULATE NEXT DATE
            nextDay.setDate(selectedDate.getDate()+1);
            setBookingDate(nextDay);
            getAssignCeData(nextDay);
        }

    }

    /*
    *Calculate Prev Date
    */
    const prevDate =() => {
        let selectedDate = new Date(bookingDate);
        let currentDate = new Date(Dateformat(new Date(),"yyyy-mm-dd"));
        let prevDay     = new Date(bookingDate);
        prevDay.setDate(selectedDate.getDate()-1);
        if(prevDay.getTime()<currentDate.getTime()){
            toast.error("Sorry past date not allowed");
            return false;
        }else{
            setBookingDate(prevDay);
            getAssignCeData(prevDay);
        }
    }

    /*
    * Get AssignCe Data on Date change
    */
    const getAssignCeData=(booking_date)=>{
        let assignceparams={
            city_id:inspectionBookingData.city_id,
            booking_date:Dateformat(booking_date,'yyyy-mm-dd'),
            lat:inspectionBookingData.address_lat,
            long:inspectionBookingData.address_lang
        };
        props.assignGetCeData(assignceparams);
    }
    
    /*
    *show previous Time Slot 
    */
    const prearrow =(start,end) => {
        if(start>0){
            let activeClass=activeClasses;
            activeClass[start-1]=true;
            activeClass[end]=false;
            setStartArrow(start-1);
            setEndArrow(end-1);
            setActiveClasses(activeClass);
        }
    }
    
    /*
    *show next Time Slot 
    */
    const nextarrow =(start,end) => {
        if(end<11){
            let activeClass=activeClasses;
            activeClass[start]=false;
            activeClass[end+1]=true;
            setStartArrow(start+1);
            setEndArrow(end+1);
            setActiveClasses(activeClass);
        }

    }

    /*
    *get CE inspection Data
    */
    const ceInspectionData=(ceid)=>{
        let redirectTo = `/inspection/inspection-list/${ceid}`;
        window.open(redirectTo, '_blank');
    }

    return (
        <div className="tab-content p-md-t">
            <div className="calanderWraper">
                <div className="calDate">
                    <div className="datemover">
                        <span className="" onClick={() => prevDate()}><i className="ic-keyboard_arrow_left"></i></span>
                        <span id="curr-cal-date" className="currentdate">{Dateformat(bookingDate,'ddd, dd mmm yyyy')}</span>
                        <span className="" onClick={() => nextDate()}><i className="ic-keyboard_arrow_right"></i></span>
                    </div>
                </div>

                <div className="tableArea">
                    <div className="calanderTable" id="calanderTable">
                        <ul className="calanderRow calanderHeader">
                            <li className="prevarrow">
                                <div className="text-left">
                                    <span className="card-head">CEs Nearby</span>
                                </div>
                                <span className="tableArrow prearrow" onClick={()=>prearrow(startArrow,endArrow)} ><i className="ic-keyboard_arrow_left"></i></span>
                            </li>
                            {
                                // [8,9,10,11,12,1]
                                timeRange.map((num, key)=> {
                                    
                                    return <li className={activeClasses[key]?"timeSlot":"timeSlot colnone"} id={num} key={num}>{num<10 ? "0"+num : num}:00</li>
                                    
                                })
                            }
                            
                            <li>
                                {
                                  (is_inhouse!==0) && 
                                  <h3 className="card-head text-left">Dealer Visits</h3> 
                                }
                                <span className="tableArrow nextarrow" onClick={() => nextarrow(startArrow,endArrow)}><i className="ic-keyboard_arrow_right"></i></span>
                            </li>            
                        </ul>
                        {
                           calenderData && calenderData.length?
                           calenderData.map((item, key) => {
                                let classHide=(key>4)?"calanderRow displayNone":"calanderRow"; 
                                let slotArrayData=[];
                                let todayInspectionData=item.today_inspection;
                                
                                if(todayInspectionData.length>0){
                                    for(let data of todayInspectionData){
                                        let inspection_schedule_time=new Date(data.inspection_schedule_time);
                                        let timestampOne = new Date(data.inspection_schedule_time);
                                            timestampOne.setHours(timestampOne.getHours() + 1);
                                        let time= Dateformat(inspection_schedule_time,'h:MM tt');
                                        let timestamp= Dateformat(timestampOne,'h:MM tt');
                                        let time24= Dateformat(inspection_schedule_time,'HH');
                                        let timeMinute= Dateformat(inspection_schedule_time,'MM');
                                        let left="left:0%;width:100%;";
                        
                                        if(timeMinute==='15'){
                                            left="left:25%;width:100%;";
                                        }else if(timeMinute==='30'){
                                            left="left:50%;width:100%;";
                                        }else if(timeMinute==='45'){
                                            left="left:75%;width:100%;";
                                        }
                                        let html=`<div class="schedule-box preSelected" data-slot="${Dateformat(inspection_schedule_time,'HH:MM')} - ${Dateformat(timestampOne,'HH:MM')}" style="${left}"><div class="tagBox"><span class="tag-confirm">Scheduled</span></div><p><strong>${time} - ${timestamp}</strong></p><p>${data.regNo}</p><p class="address-line" title="${data.address}">${data.address}</p></div>`;

                                        slotArrayData[time24]=html;
                                    }
                                }
                              
                                return (
                                    <ul className={classHide} id={"div-"+item.id} key={key}>
                                        <li data-slot="">
                                            <div className="csnearme clearfix">
                                                <div className="col1">
                                                    <Nav.Link className="cename" eventKey={item.id} onClick={() => { ceInspectionData(item.dealer_hash_id) }} ><strong>{item.name}</strong>(Id- {item.id})</Nav.Link> 
                                                </div> 
                                            </div>
                                            {
                                               ((item.last_checkin).length>0) && 
                                                <> 
                                                    <div className="csnearme clearfix">
                                                        <div className="col1 morevisteBtn" data-trigger="hover">Distance</div>
                                                        <div className="col2"><span>{item.last_checkin[0].clean_distance}</span></div>
                                                    </div>  
                                                </>
                                            } 
                                        </li>
                                        {
                                            activeClasses.length>0 && <CalenderBookingHtml
                                            slotArrayData={slotArrayData} activeClasses={activeClasses} />
                                        }
                                        
                                        <li>
                                            <ol className="dealerVist">
                                            </ol>
                                        </li>
                                    </ul>
                                );
                           }):''
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}



export default AssignCeCalender;