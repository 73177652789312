import React, { useState, useEffect, Fragment } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from "react-accessible-accordion";
import { useDispatch } from "react-redux";
import { Tab } from "react-bootstrap";
import Select from 'react-select';
import InputField from '@src/view/element/InputField';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getCertificationData, getImageTags, getColorList, allMakeInfo, allModelInfoById, allVersionInfoById, submitCertificationData } from "@src/store/slices/common";
import TopActionBtn from "@src/view/common/TopActionBar"
import FullPageLoader from "@src/view/common/FullPageLoader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router';
import DropZoneImageUpload from "@src/view/element/DropZoneImageUpload";
import { uploadQcImages } from "@src/store/slices/dealerQc";

const EditInspectedCar = (props) => {

    const [formInputs, setInputForm] = useState([])
    const [activeTab, setActiveTab] = useState()
    const [files, setFiles] = useState([]);
    const [formArray, setFormArray] = useState([]);
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const [imageTags, setImageTags] = useState([]);
    const [colorList, setColorList] = useState([]);
    const [makeList, setMakeList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [versionList, setVersionList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false);
    const queryParams = useParams();
    const [parentErrors, setParentErrors] = useState({})

    const [certificationData, setCertificationData] = useState({});

    const [loadingMake, setLoadingMake] = useState(false);
    const [loadingModel, setLoadingModel] = useState(false);
    const [loadingVersion, setLoadingVersion] = useState(false);

    const [submitClick, setSubmitClick] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation("common");

    const [delImageIndex, setDelImageIndex] = useState(-1);
    const [showFinancerInput, setShowFinancerInput] = useState(false)

    /**
     * HANDLE PARENT LABEL CLICK
     * @param {*} e 
     */
    const handleParentClicks = (e, type = null) => {
        if (e[0] !== activeTab) {
            setActiveTab(e[0])
        } else {
            setActiveTab()
        }
    }

    /**
     * GET CERTIFICATION DATA
     */
    const getConfigData = async () => {
        setLoading(true);
        let inputField = {
            "vccID": queryParams ? queryParams.vcc : ""
        }
        dispatch(getCertificationData(inputField)).then((response) => {
            let addImageObj = {
                type: 'new_image',
                value: ''
            };
            setCertificationData(JSON.parse(JSON.stringify(response.data)))
            let data = JSON.parse(JSON.stringify(response.data.detail));
            if (data) {
                data.find(d => d.key === 'image_manager').formdata.push(addImageObj);
                let makeData = data.find(d => +d.id === 0).formdata.find(data => data.name === "make_id");
                if (makeData && makeData.value) {
                    modelInfoById(makeData.value);
                }
                let modelData = data.find(d => +d.id === 0).formdata.find(data => data.name === "model_id");
                if (modelData && modelData.value) {
                    versionInfoById(modelData.value);
                }
                //show financier input only when vehicle under hypothecation is set to Yes
                data.find(d => d.title === 'Documentation').formdata.forEach((ele) => {
                    if (ele.name === 'vehicle_under_hypothecation' && ele.value === 'Yes') {
                        setShowFinancerInput(true)
                        return
                    }
                })

            }
            setLoading(false);
            setFormArray(data);
        }).catch((error) => {
            setLoading(false);

        })
    }

    /**
     * GET COLOR DATA
     */
    const getColorDropdown = async () => {
        dispatch(getColorList()).then((resp) => {
            if(resp && resp.data) setColorList(resp.data);
        })
    }

    /**
     * GET IMAGE TAG DROPDOWN
     */
    const getImageTagDropdown = async () => {
        let payload = {
            "imageType": 'all',
            "vehicleType": (props && props.location && props.location.state && props.location.state.vehicle_type) ? props.location.state.vehicle_type : "car"
        }
        dispatch(getImageTags(payload)).then((response) => {
            if(response && response.data) setImageTags(response.data);
        })
    }

    /**
     * GET MAKE
     */
    const makeInfo = async () => {
        setLoadingMake(true);
        dispatch(allMakeInfo()).then((resp) => {
            setLoadingMake(false);
            setMakeList(resp.data && resp.data.make ? resp.data.make : []);
        }).catch(err => setLoadingMake(false))
    }

    /**
     * GET MODELS
     * @param {*} make_id 
     */
    const modelInfoById = async (make_id) => {
        setLoadingModel(true);
        dispatch(allModelInfoById({ "mk_id": make_id })).then((resp) => {
            setLoadingModel(false);
            setModelList(resp && resp.data ? resp.data : []);
        }).catch(err => setLoadingModel(false))
    }

    /**
     * GET VERSIONS
     * @param {*} model_id 
     */
    const versionInfoById = async (model_id) => {
        setLoadingVersion(true);
        dispatch(allVersionInfoById({ "md_id": model_id })).then((resp) => {
            setLoadingVersion(false);
            setVersionList(resp && resp.data ? resp.data : []);
        }).catch(err => setLoadingVersion(false))
    }

    /**
    * handler function for input change
    * @param {string, number, object} value 
    * @param {string} name 
    */

    const handleChange = (event, parent_title, item) => {
        const tempArr = formArray;
        let label = "";
        // Object.keys(tempArr).forEach((arr) => {
        for(let arr of Object.keys(tempArr)){
            if (tempArr[arr].title === parent_title) {
                // eslint-disable-next-line
                tempArr[arr].formdata.forEach((data) => {

                    if (data.name === item.name) {
                        if (item.type === "select" || item.type === "radio") {
                            if (item.name === "make_id" || item.name === "model_id" || item.name === "version_id") {
                                let modelItem = tempArr[arr].formdata.filter((md) => md.name === "model_id") ? tempArr[arr].formdata.filter((md) => md.name === "model_id")[0] : {};
                                let versionItem = tempArr[arr].formdata.filter((md) => md.name === "version_id") ? tempArr[arr].formdata.filter((md) => md.name === "version_id")[0] : {};
                                let fuelItem = tempArr[arr].formdata.filter((md) => md.name === "fuel_type") ? tempArr[arr].formdata.filter((md) => md.name === "fuel_type")[0] : {};
                                let transmissionItem = tempArr[arr].formdata.filter((md) => md.name === "transmission") ? tempArr[arr].formdata.filter((md) => md.name === "transmission")[0] : {};

                                if (item.name === "make_id") {
                                    data.value = event && event.id ? event.id : null;

                                    if (data.value) {
                                        modelInfoById(data.value);
                                    }
                                    setVersionList([])

                                    handleChange(null, parent_title, modelItem)
                                    handleChange(null, parent_title, versionItem)
                                    handleChange({ target: { value: null } }, parent_title, fuelItem)
                                    handleChange({ target: { value: null } }, parent_title, transmissionItem)
                                    label = event && event.id ? event.id : "";
                                } else if (item.name === "model_id") {

                                    data.value = event && event.id ? event.id : null;
                                    if (data.value) {
                                        versionInfoById(data.value);
                                    }

                                    //RESET VERSION
                                    if (!data.value) {
                                        handleChange(null, parent_title, versionItem)
                                        handleChange({ target: { value: null } }, parent_title, fuelItem)
                                        handleChange({ target: { value: null } }, parent_title, transmissionItem)
                                    }
                                    label = event && event.id ? event.id : "";
                                } else if (item.name === "version_id") {
                                    data.value = event && event.vn_id ? event.vn_id : "";

                                    let fType = "";
                                    let transmission = "";
                                    //SET FUEL & TRANSMISSION
                                    if (data.value) {
                                        fType = event.f_type;
                                        transmission = event.tms;
                                    }

                                    handleChange({ target: { value: fType } }, parent_title, fuelItem)
                                    handleChange({ target: { value: transmission } }, parent_title, transmissionItem)
                                    label = event && event.vn_id ? event.vn_id : "";
                                }
                            } else {
                                data.value = event && event.key ? event.key : null;
                                label = event && event.key ? event.key : "";

                                if (item.name === 'vehicle_under_hypothecation') {
                                    if (data.value === 'Yes') {
                                        setShowFinancerInput(true)
                                    }
                                    else {
                                        setShowFinancerInput(false)
                                    }
                                }

                                if(item.name === "color_id") {
                                    data.value = event.value
                                    label = event.value
                                }
                            }

                        } else if (item.type === "date") {
                            data.value = event ? new Date(event) : null;
                            label = event ? new Date(event) : null;
                        } else if (item.type === "text" || item.type === "label") {
                            data.value = event && event.target && event.target.value ? event.target.value : "";
                            label = event && event.target && event.target.value ? event.target.value : "";
                        }
                    }
                })
            }
        }

        let newObject = { id: item.id, name: item.name, value: '' + label };
        if (event && ['make_id', 'model_id'].includes(item.name)) {
            newObject = { id: item.id, name: item.name, value: '' + event.id };
        } else if (event && ['version_id'].includes(item.name)) {
            newObject = { id: item.id, name: item.name, value: '' + event.vn_id };
        }
        setInputForm(prevState => {
            const index = prevState.findIndex(obj => obj.id === item.id);
            if (index === -1) {
                return [...prevState, newObject];
            }
            const updatedArray = [...prevState];
            updatedArray[index] = { ...updatedArray[index], value: label };
            return updatedArray;
        });
        setFormArray([...tempArr]);

    }

    /**
     * IMAGE CHANGE HANDLER
     * @param {*} event 
     * @param {*} id 
     * @param {*} item 
     * @param {*} type 
     * @param {*} index 
     */
    const handleImageChange = (event, formId, item, type, index) => {
        let tempArr = formArray;

        let tags = tagList ? tagList : [];
        let updatedFiles = files;
        Object.keys(tempArr).forEach((arr) => {
            if (+tempArr[arr].id === +formId) {
                if (type === "update") {
                    tempArr[arr].formdata.forEach((data, indx) => {
                        if (data.image_id && data.image_id === item.image_id) {
                            data.tag_name = event && event.tag_name ? event.tag_name : null;
                            data.tag_id = event && event.id ? event.id : null;
                        } else if (data.image_path === item.image_path) {
                            data.tag_name = event && event.tag_name ? event.tag_name : null;
                            data.tag_id = event && event.id ? event.id : null;
                            tags[index] = event.id;
                        }
                    })
                }
                else if (type === "delete") {
                    tempArr[arr].formdata = tempArr[arr].formdata.filter(data => {
                        if (data.image_id) {
                            return data.image_id !== item.image_id
                        } else if (data.type === "new_image") {
                            return data;
                        } else {
                            delete tags[index];
                            updatedFiles = files.filter((key) => key.preview !== item.image_path);
                            return data.image_path !== item.image_path
                        }
                    })
                }
            }
        });
        if (tags) {
            setTagList(tags);
        }
        setFiles(updatedFiles);
        setFormArray([...tempArr]);
    }

    const filteredOptions = (item) => {
         let options = [];
        if (item.name === "color_id") {
            options = colorList;

            //CHECK IF VALUE NOT EXISTS THEN INSERT IN ARRAY
            let checkItemExists = colorList.filter(clr => clr.value === item.value);
            if(!checkItemExists.length){
                options = [...options, {label: item.value, value: item.value}]
            }

        } else {
            options = (!item.options || item.options !== "local") ? JSON.parse(item.options) : [];
         
        }
         return options;

    }


    useEffect(() => {
        getConfigData();
        getColorDropdown();
        getImageTagDropdown();
        makeInfo();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
    * CHECK MISSING REQUIRED TAGS
    */
    const addImageTags = (ids) => {
        let imageTagMsg = "";
        let tags = [];
        //ALL TAGS ARRAY
        for(let tag of imageTags){
            if(tag.options) tags.push(...tag.options);
        }
        //FILTER REQUIRED TAGS
        let taggedArr = tags.filter(function (tag) {
            return ids.indexOf(`${tag.id}`) !== -1;
        });
        imageTagMsg = taggedArr.map(function (tag) {
            return tag.tag_name;
        }).join(", * ");

        imageTagMsg = imageTagMsg ? "* " + imageTagMsg : "";
        return imageTagMsg;
    }

    /**
     * SUBMIT BTN HANDLER
     * @param {*} e 
     */
    const handleSubmitBtnClick = async (e) => {
        e.preventDefault();

        setLoader(true);
        setSubmitClick(true);

        //adding file to request
        const formData = new FormData();
        // Append each file to the form data object

        if (files) {
            files.forEach((file, index) => {
                formData.append(`files`, file);
            });
        }
        let getSelectedTagList = tagList.filter(tag => tag); //GET VALID TAGS 

        if (getSelectedTagList && getSelectedTagList.length > 0) {
            formData.append('imageTags', JSON.stringify(getSelectedTagList));
        }
        formData.append('vccID', queryParams.vcc);
        formData.append('isVerified', certificationData.is_verified);
        formData.append('evaluationVersion', certificationData.evaluation_version);
        formData.append('requestData', JSON.stringify(formInputs));

        try {
            let validationErr = await validateInputs(formArray, "submitBtn");
            if (validationErr) {
                setLoader(false);
                toast.error(t("INSPECTION.FILL_REQUIRED_FIELDS"));
                return false;
            }

            if ((files && Object.keys(files).length > 0) || (formInputs && formInputs.length > 0)) {

                const submitData = await submitCertificationData(formData);
                if (submitData && submitData.status === 200) {
                    // getConfigData();
                    setFiles([])
                    let redirectTo = `/qc/dealer`;
                    navigate(redirectTo)
                }
                setSubmitClick(false);
                setLoader(false);

            } else {
                setLoader(false);
                toast.error(t("INSPECTION.NOTHING_TO_UPDATE"));
            }
        } catch (error) {
            setLoader(false);
            toast.error(error);
        }
    }

    const dateValidation = (params) => {
        let isValid = false;
        // let documentData = params.find(d => d.id === 9);
        let mfgDate = params.find((key) => key.name === "mfg_date").value;
        let regDate = params.find((key) => key.name === "reg_date").value;
        if (mfgDate && regDate) {
            isValid = new Date(mfgDate) <= new Date(regDate) ? true : false;
        }
        return isValid;
    }

    /**
     * FORM VALIDATION 
     * @param {*} inputFields 
     * @param {*} reqFrom 
     * @returns 
     */
    const validateInputs = async (inputFields, reqFrom) => {

        return new Promise((resolve) => {

            let newParentErrors = parentErrors;
            let newErrors = errors;

            //reseting error
            if (reqFrom === "nextBtn") {
                delete newParentErrors[inputFields.id];

                let getAllChilds = inputFields.formdata.map(val => val.name);

                for (let errChild of getAllChilds) {
                    delete newErrors[errChild]
                }

                setParentErrors({ ...newParentErrors });

                setErrors({ ...newErrors })

            } else {
                setParentErrors({});
                setErrors({})
            }
            let errorFlag = false;
            let SubmitErrorFlag = false;

            for (let parent of formArray) {
                if (reqFrom === "nextBtn" && parent.id !== inputFields.id) continue;
                errorFlag = false;
                for (let data of parent.formdata) {
                    if (+data.isRequired) {
                        if (!data.value && !["fuel_type", "transmission"].includes(data.name)) {
                            if (data.name !== 'financier_name' || (data.name === 'financier_name' && showFinancerInput)) {
                                newErrors[data.name] = (t("INSPECTION.FIELD_EMPTY")).replace('{x}', data.title)
                                errorFlag = true;
                            }

                        } else if (data.name === "reg_number" && data.value && (data.value.length < 3 || data.value.length > 9)) {
                            newErrors[data.name] = t("INSPECTION.SELECT_REG_NO");
                            errorFlag = true;
                        } else if (data.name === "mfg_date" || data.name === "reg_date") {
                            const isDateValid = dateValidation(parent.formdata);
                            if (!isDateValid) {
                                newErrors[data.name] = data.name === "mfg_date" ? t("INSPECTION.MFG_DATE_LESS_THAN_REG_DATE") : t("INSPECTION.REG_DATE_GREATER_THAN_MFG_DATE");
                                errorFlag = true;
                            }
                        } else if (data['type'] && (data['type'] === "select" || data['type'] === "radio")) {
                            let fieldEmptycheck = false;
                            //SEPRATELY CHECK MMVC
                            if (data['options'] === "local") {
                                if (data['name'] === "make_id") {
                                    let isMakeSelected = makeList.filter(mk => +mk.id === +data["value"]);
                                    if (!isMakeSelected.length) {
                                        fieldEmptycheck = true;
                                    }
                                } else if (data["name"] === "model_id") {
                                    let isModelSelected = modelList.filter(md => +md.id === +data["value"]);
                                    if (!isModelSelected.length) {
                                        fieldEmptycheck = true;
                                    }
                                } else if (data["name"] === 'version_id') {
                                    let isVersionSelected = versionList.filter(vr => +vr.vn_id === +data["value"]);
                                    if (!isVersionSelected.length) {
                                        fieldEmptycheck = true;
                                    }
                                } else if (data["name"] === 'color_id' && !data["value"]) {
                                    // let isValueSelected = colorList.filter(cl => (''+cl.value === ''+data["value"] || ''+cl.key === ''+data["value"]));
                                    // if (!isValueSelected.length) {
                                        fieldEmptycheck = true;
                                    // }
                                }
                            } else {
                                //ELSE FOR OTHER IF OPTIONS AVAILABLE THEN MATCH
                                let getOptions = data['options'] && data['options'].trim() ? JSON.parse(data['options']) : []

                                if (getOptions.length) {
                                    let isValueMatched = getOptions.filter(op => (''+(op.key)).trim() === ''+(data.value).trim())
                                    if (!isValueMatched.length) {
                                        fieldEmptycheck = true;
                                    }
                                }
                            }

                            if (fieldEmptycheck) {
                                newErrors[data.name] = (t("INSPECTION.FIELD_EMPTY")).replace('{x}', data.title)
                                errorFlag = true;
                            }

                        }
                        // }
                    } else if (data['type'] && data['type'] === "image" && !data['tag_id']) {

                        newErrors[data.name] = t("INSPECTION.IMAGE_TAG_REQUIRED");
                        errorFlag = true;
                    }
                }

                if (errorFlag) {
                    newParentErrors[parent.id] = parent.title + " has some error";
                    SubmitErrorFlag = true;
                    setParentErrors({ ...newParentErrors });
                    setErrors({ ...newErrors });
                }
            }

            if (reqFrom === "nextBtn") {
                resolve(errorFlag);
            } else {
                resolve(SubmitErrorFlag);
            }
        })


    }

    /**
     * Trigger Next Accordion Open
     * @param {*} e 
     */
    const nextTabTrigger = async (e, formDetails) => {
        const errorFlag = await validateInputs(formDetails, "nextBtn");
        if (!errorFlag) {
            let nextTab = '' + (+activeTab + 1)
            handleParentClicks([nextTab])
        }
    }


    const delSelectedImage = (val, id, item, action, childKey) => {
        // delete logic start here     
        if(item.image_id){

            let newParam = {};
            newParam.vccID = queryParams.vcc;
            newParam.imageDetails = [{}]
            newParam.imageDetails[0].image_id = item.image_id
            newParam.imageDetails[0].tag_id = item.tag_id
            newParam.imageDetails[0].type = "delete"
            newParam.imageDetails = JSON.stringify(newParam.imageDetails);
            
            uploadQcImages(newParam).then(res => {
                if (res.status === 200) {
                    toast.success(t("BACK_OFFICE.DELETE_IMAGES"));
                    setLoading(false)
                }
            })
        }    

        handleImageChange(val, id, item, action, childKey)
            setDelImageIndex(-1)
        
        
    }

    const updateDropzoneSelectedImages = (dropzoneFiles) => {
        if (dropzoneFiles.length) {
            let uploadedFile = dropzoneFiles.map(file => {
                return {
                    image_path: file.preview,
                    tag_name: "",
                    tag_id: "",
                    type: "image"
                }
            })

            let count = formArray.find(d => d.key === 'image_manager').formdata.length - 1;

            formArray.find(d => d.key === 'image_manager').formdata.splice(count, 0, ...uploadedFile);
            setFiles([...files, ...dropzoneFiles]);
        }

    }

    return (

        <div className='container-fluid-full edit-inspected-cars'>
            <FullPageLoader show={loading || loader} />
            <div className="top-sec">
                <TopActionBtn heading={t("INSPECTION.INSPECTION")} callonpage={false} />
            </div>

            <div className="mandatory-image-err-box col-md-12">

                {(certificationData.notUploadedImageTags && certificationData.notUploadedImageTags.length) ?
                    <div className="alert alert-danger">
                        <div className="row">
                            <h4 className="col-md-12">{t("INSPECTION.MANDATORY_FIELD_ERR")}</h4>
                            <div className="col-md-12"><p>{addImageTags(certificationData.notUploadedImageTags)}</p></div>
                        </div>
                    </div>
                    : ""
                }

            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">


                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <div className="add-inspection-accordion">
                            <div>

                                <React.Fragment>
                                    <div className="container-fluid">
                                        <Accordion allowZeroExpanded onChange={(e) => handleParentClicks(e, 'label')} >
                                            {
                                                Object.keys(formArray).map((items, itemKey) => {
                                                    return (
                                                        <div key={itemKey}>
                                                            <AccordionItem uuid={items} dangerouslySetExpanded={+activeTab === itemKey ? true : false}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>

                                                                        <h2 className={(parentErrors && parentErrors[formArray[items].id]) ? "error-label" : ""} accordionstatus="close">{formArray[items].title}</h2>

                                                                        <i className={activeTab !== itemKey ? "ic-keyboard_arrow_up" : "ic-keyboard_arrow_down"}></i>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div className='basic-filter-outer'>
                                                                        <div className="basic-detail-filter">
                                                                            <div className="col-md-12 car-input-boxes">
                                                                                {
                                                                                    formArray[items].formdata.map((item, childKey) => {

                                                                                        if (item.type === 'select' || item.type === 'radio') {
                                                                                            if (item.name === "make_id") {
                                                                                                return (
                                                                                                    <div className="col-md-3 car-field-box" key={item.name} >
                                                                                                        <fieldset className="form-select-bx">
                                                                                                            <div className={item.value ? "active-label material animation-effect" : "material animation-effect"}>
                                                                                                                <Select
                                                                                                                    id={item.id}
                                                                                                                    value={makeList.filter((e) => +e.id === +item.value)}
                                                                                                                    onChange={(val) => handleChange(val, formArray[items].title, item)}
                                                                                                                    options={makeList}
                                                                                                                    placeholder=''
                                                                                                                    className="react-select"
                                                                                                                    classNamePrefix='inspection-dropdown react-select'
                                                                                                                    isClearable={true}
                                                                                                                    getOptionValue={(option) => option.id}
                                                                                                                    getOptionLabel={(option) => option.label}
                                                                                                                    isSearchable={false}
                                                                                                                    required={item.isRequired}
                                                                                                                    isLoading={loadingMake}
                                                                                                                />
                                                                                                                <label data-label={item.title} className="form-label"></label>
                                                                                                                {
                                                                                                                    errors && errors[item.name]
                                                                                                                        ?
                                                                                                                        <span className="error-txt">{errors[item.name]}</span>
                                                                                                                        :
                                                                                                                        ''
                                                                                                                }
                                                                                                            </div>
                                                                                                        </fieldset>
                                                                                                    </div>
                                                                                                )
                                                                                            } else if (item.name === "model_id") {
                                                                                                return (
                                                                                                    <div className="col-md-3 car-field-box" key={item.name} >
                                                                                                        <fieldset className="form-select-bx">
                                                                                                            <div className={item.value ? "active-label material animation-effect" : "material animation-effect"}>
                                                                                                                <Select
                                                                                                                    id={item.id}
                                                                                                                    value={modelList ? modelList.filter((e) => +e.id === +item.value) : []}
                                                                                                                    onChange={(val) => handleChange(val, formArray[items].title, item)}
                                                                                                                    options={modelList}
                                                                                                                    placeholder=''
                                                                                                                    className="react-select"
                                                                                                                    classNamePrefix='inspection-dropdown react-select'
                                                                                                                    isClearable={true}
                                                                                                                    getOptionValue={(option) => option.id}
                                                                                                                    getOptionLabel={(option) => option.label}
                                                                                                                    isSearchable={false}
                                                                                                                    required={item.isRequired}
                                                                                                                    isLoading={loadingModel}
                                                                                                                />
                                                                                                                <label data-label={item.title} className="form-label"></label>
                                                                                                                {
                                                                                                                    errors && errors[item.name]
                                                                                                                        ?
                                                                                                                        <span className="error-txt">{errors[item.name]}</span>
                                                                                                                        :
                                                                                                                        ''
                                                                                                                }
                                                                                                            </div>
                                                                                                        </fieldset>
                                                                                                    </div>
                                                                                                )
                                                                                            } else if (item.name === "version_id") {
                                                                                                return (
                                                                                                    <div className="col-md-3 car-field-box" key={item.name} >
                                                                                                        <fieldset className="form-select-bx">
                                                                                                            <div className={item.value ? "active-label material animation-effect" : "material animation-effect"}>
                                                                                                                <Select
                                                                                                                    id={item.id}
                                                                                                                    value={versionList ? versionList.filter((e) => +e.vn_id === +item.value) : []}
                                                                                                                    onChange={(val) => handleChange(val, formArray[items].title, item)}
                                                                                                                    options={versionList}
                                                                                                                    placeholder=''
                                                                                                                    className="react-select"
                                                                                                                    classNamePrefix='inspection-dropdown react-select'
                                                                                                                    isClearable={true}
                                                                                                                    getOptionValue={(option) => option.vn_id}
                                                                                                                    getOptionLabel={(option) => option.label}
                                                                                                                    isSearchable={false}
                                                                                                                    required={item.isRequired}
                                                                                                                    isLoading={loadingVersion}
                                                                                                                />
                                                                                                                <label data-label={item.title} className="form-label"></label>
                                                                                                                {
                                                                                                                    errors && errors[item.name]
                                                                                                                        ?
                                                                                                                        <span className="error-txt">{errors[item.name]}</span>
                                                                                                                        :
                                                                                                                        ''
                                                                                                                }
                                                                                                            </div>
                                                                                                        </fieldset>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            else {

                                                                                                return (
                                                                                                    <div className="col-md-3 car-field-box" key={item.name} >
                                                                                                        <fieldset className="form-select-bx">
                                                                                                            <div className={item.value ? "active-label material animation-effect" : "material animation-effect"}>
                                                                                                                <Select
                                                                                                                    id={item.id}
                                                                                                                    value={item.value && filteredOptions(item).filter((e) => (item.name === "color_id" ? ((''+e.value === ''+item.value) || (''+(e.key) === ''+item.value) ) : (''+(e.key)).trim() === ''+(item.value).trim()))}
                                                                                                                    onChange={(val) => handleChange(val, formArray[items].title, item)}
                                                                                                                    options={filteredOptions(item)}
                                                                                                                    placeholder=''
                                                                                                                    className="react-select"
                                                                                                                    classNamePrefix='inspection-dropdown react-select'
                                                                                                                    isClearable={true}
                                                                                                                    getOptionValue={(option) => option.key}
                                                                                                                    getOptionLabel={(option) => option.value}
                                                                                                                    isSearchable={false}
                                                                                                                    required={item.isRequired}
                                                                                                                />
                                                                                                                <label data-label={item.title} className="form-label"></label>
                                                                                                                {
                                                                                                                    errors && errors[item.name]
                                                                                                                        ?
                                                                                                                        <span className="error-txt">{errors[item.name]}</span>
                                                                                                                        :
                                                                                                                        ''
                                                                                                                }
                                                                                                            </div>
                                                                                                        </fieldset>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        } else if (item.type === 'text') {
                                                                                            if ((item.name === 'financier_name' && showFinancerInput) || item.name !== 'financier_name') {
                                                                                                return (
                                                                                                    <div className="col-md-3 car-field-box" key={item.name} >
                                                                                                        <fieldset>
                                                                                                            <div className={"material"}>
                                                                                                                <InputField
                                                                                                                    inputProps={{
                                                                                                                        id: item.id,
                                                                                                                        type: "text",
                                                                                                                        name: '',
                                                                                                                        placeholder: " ",
                                                                                                                        value: item.value || "",
                                                                                                                        className: 'form-input',
                                                                                                                        maxLength: "5000",
                                                                                                                        required: item.isRequired
                                                                                                                    }}
                                                                                                                    onChange={(val) => handleChange(val, formArray[items].title, item)}
                                                                                                                />
                                                                                                                <label data-label={item.title} className="form-label"></label>
                                                                                                                {
                                                                                                                    errors && errors[item.name]
                                                                                                                        ?
                                                                                                                        <span className="error-txt">{errors[item.name]}</span>
                                                                                                                        :
                                                                                                                        ''
                                                                                                                }
                                                                                                            </div>
                                                                                                        </fieldset>
                                                                                                    </div>
                                                                                                )
                                                                                            }else{
                                                                                                return ""
                                                                                            }

                                                                                        } else if (item.type === 'date') {
                                                                                            return (
                                                                                                <div className="col-md-3 car-field-box" key={item.name} >
                                                                                                    <fieldset className="create-date">
                                                                                                        <div className={item.value ? "active-label material animation-effect" : "material animation-effect "}>
                                                                                                            <div className="datepicker">
                                                                                                                <DatePicker
                                                                                                                    selected={item.value && item.value !== '0000-00-00' ? new Date(item.value) : null}
                                                                                                                    onChange={(val) => handleChange(val, formArray[items].title, item)}
                                                                                                                    dateFormat="yyyy-MM-dd"
                                                                                                                    placeholderText=""
                                                                                                                    showMonthDropdown
                                                                                                                    showYearDropdown
                                                                                                                    dropdownMode="select"
                                                                                                                    onKeyDown={(e) => {
                                                                                                                        e.preventDefault();
                                                                                                                    }}
                                                                                                                    required={item.isRequired}
                                                                                                                />
                                                                                                                <label data-label={item.title} className="form-label"></label>
                                                                                                                {errors && errors[item.name]
                                                                                                                    ?
                                                                                                                    <span className="error-txt">{errors[item.name]}</span>
                                                                                                                    :
                                                                                                                    ''
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </fieldset>
                                                                                                </div>
                                                                                            )
                                                                                        } else if (item.type === 'image') {
                                                                                            return (
                                                                                                <div className="col-md-3 car-image-container" key={item.image_id || item.image_path} >
                                                                                                    <div className="car-gallery">
                                                                                                        <div className="img-outer car-image-box" style={{ "background": ` url(${item.image_path}) no-repeat right 0 center / cover` }}>

                                                                                                            {
                                                                                                                (delImageIndex === childKey) &&
                                                                                                                <div className="del" >
                                                                                                                    <div className="del-txt clearfix">
                                                                                                                        <p>{t("COMMON.ARE_YOU_SURE_DELETE")}</p>
                                                                                                                        <ul>
                                                                                                                            <li onClick={(val) => delSelectedImage(val, formArray[items].id, item, "delete", childKey)}><div className="delbox del-yes"><b>{t("COMMON.YES")}</b></div></li>
                                                                                                                            <li onClick={() => setDelImageIndex(-1)}><div className="delbox del-no"><b>{t("COMMON.NO")}</b></div></li>
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }

                                                                                                            <i className="ic-clearclose"
                                                                                                                onClick={(val) => setDelImageIndex(childKey)}></i>
                                                                                                        </div>

                                                                                                        <fieldset className="single-select image-tag-select">
                                                                                                            <div className={`${submitClick && !item.tag_id ? "error-border" : "material"}`}>
                                                                                                                <Select
                                                                                                                    id={item.image_id}
                                                                                                                    placeholder={item.tag_name || 'select'}
                                                                                                                    className="react-select"
                                                                                                                    classNamePrefix="react-select"
                                                                                                                    isSearchable={false}
                                                                                                                    value={imageTags.filter((e) => +e.tag_name === +item.tag_id)}
                                                                                                                    options={imageTags}
                                                                                                                    getOptionValue={(option) => option.id}
                                                                                                                    getOptionLabel={(option) => option.tag_name}
                                                                                                                    onChange={(val) => handleImageChange(val, formArray[items].id, item, "update", childKey)}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </fieldset>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        } else if (item.type === 'new_image') {
                                                                                            return (
                                                                                                <Fragment key={`new_img_box_${childKey}`}>
                                                                                                    <div className="col-md-3 car-image-container" key={"new_img"}>
                                                                                                        <div className="">
                                                                                                            <DropZoneImageUpload title={t("INSPECTION.DROP_FILES")} updateDropzoneSelectedImages={updateDropzoneSelectedImages} />
                                                                                                            {/* <div {...getRootProps({ className: 'dropzone' })} className="add-new-image">
                                                                                                                <input type="file" accept="image/*" {...getInputProps()} className="upload-image-input" id="upload-image-input" />
                                                                                                                <i className="ic-add"> </i>
                                                                                                                <span>{t("INSPECTION.DROP_FILES")}</span>
                                                                                                            </div> */}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-md-12 btn-submit-cancel" key={"submit_btn"}>
                                                                                                        <button type="button" className="btn-primary" onClick={(e) => handleSubmitBtnClick(e)}>{t("INSPECTION.SUBMIT")}</button>
                                                                                                    </div>
                                                                                                </Fragment>
                                                                                            )
                                                                                        } else if (item.type === 'label') {
                                                                                            return (
                                                                                                <div className="col-md-3 car-field-box" key={item.name} >
                                                                                                    <fieldset>
                                                                                                        <div className={"material"}>
                                                                                                            <InputField
                                                                                                                inputProps={{
                                                                                                                    id: item.id,
                                                                                                                    type: "text",
                                                                                                                    name: '',
                                                                                                                    placeholder: " ",
                                                                                                                    value: item.value || "",
                                                                                                                    className: 'form-input',
                                                                                                                    maxLength: "50",
                                                                                                                    required: item.isRequired,
                                                                                                                    disabled: true
                                                                                                                }}
                                                                                                            />
                                                                                                            <label data-label={item.title} className="form-label"></label>
                                                                                                        </div>
                                                                                                    </fieldset>
                                                                                                </div>
                                                                                            )
                                                                                        } else {
                                                                                            return ""
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {formArray[items].key !== 'image_manager' && <div className="col-md-3 car-field-box" key={`${itemKey}_next`}>
                                                                                    <button type="button" className="btn-success" onClick={(e) => nextTabTrigger(e, formArray[items])} title={items.key}>{t("INSPECTION.NEXT")}</button>
                                                                                </div>}
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Accordion>
                                    </div>

                                </React.Fragment>
                            </div>
                        </div>

                    </Tab.Pane>

                </Tab.Content>
            </Tab.Container>

        </div>

    );
}

export default EditInspectedCar;