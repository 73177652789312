import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
    const { t } = useTranslation('common');
    return (
        <div className="container-fluid-full">
            <div className="page-not-found white-bg">

                <h1 className='t-center'>{t('COMMON.404_ERROR')} </h1>
                <h2 className='t-center'>{t('COMMON.PAGE_NOT_FOUND')} </h2>

            </div>

        </div>
    )

};

export default NotFoundPage
