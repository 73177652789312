import React from 'react';
import ReactDOM from 'react-dom/client';
import '@src/index.css';
import App from '@src/App';
import reportWebVitals from '@src/reportWebVitals';
import i18next from "i18next";
import secureStorage from "@src/config/encrypt";
import language_id from "@src/translations/id/language.json";
import language_en from "@src/translations/en/language.json";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "@src/store/store";

i18next.init({
	interpolation: { escapeValue: false },
	lng: secureStorage.getItem("lang") === "en" ? "en" : "id",
	resources: {
		en: {
			common: language_en,
		},
		id: {
			common: language_id,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.Fragment>
		<I18nextProvider i18n={i18next}>
			<Provider store={store}>
					<App />
			</Provider>
		</I18nextProvider>
	</React.Fragment>
);

reportWebVitals();
