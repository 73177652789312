import React from 'react';
import secureStorage from '@src/config/encrypt';
import * as HELPER from '@src/config/helper';
import Login from '@src/view/auth/Login'


const PrivateRoute = ({ component: Component, ...rest }) => {

    let { accessPageId } = rest;
    const authUserInfo = secureStorage.getItem('authUserInfo');
    const visibleMenus = authUserInfo && authUserInfo.user_data && authUserInfo.user_data.visibleMenu ? JSON.parse(authUserInfo.user_data.visibleMenu) : [];

    let currentPath = window.location.pathname;
   

    //CHECK USER IS AUTHORIZED
    let isAuthorized = false

    if (accessPageId) {
        if (accessPageId === 'dashboard') {
            isAuthorized = true;
        } else {
            isAuthorized = HELPER.isUserHasAccessPage(accessPageId)
        }
    }
    else {
        for (let menu of visibleMenus) {
            let childMenu = menu.childMenu
            for (let submenu of childMenu) {
                //removing slash from pathname
                if (submenu.action === currentPath.slice(1)) {
                    isAuthorized = true
                    break
                }
            }
        }
    }

    
    return (
        isAuthorized ? <rest.components  /> : <Login />
    );
};

export default PrivateRoute;