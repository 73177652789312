import { InspectionService } from '../../services';

import { createSlice } from '@reduxjs/toolkit'
import { toast } from "react-toastify";


const inspection = createSlice({
    name: 'inspection',
    initialState: {
        inspection: {},
        cities: []
    },
    reducers: {
        setInspectionAllFilters: (state, action) => {
            state.inspectionFilters = action.payload;

        },
        InspectionListing: (state, action) => {
            state.InspectionListing = action.payload;
        },
        inspectionBookingData: (state, action) => {
            state.inspectionBookingData = action.payload;
        },
    },
});

export default inspection.reducer;

const { setInspectionAllFilters, InspectionListing } = inspection.actions;

/**
 * API call to fetch list of all users
 * @param {object} params 
 * @returns 
 */
export const getInspectionRequest = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        InspectionService.getInspectionRequest(params)
            .then(
                response => {

                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                        dispatch(InspectionListing(response.data));
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


export const getinspectionConfig = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.getinspectionConfig(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}



export const getAssignScheduler = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.assignScheduler(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


// EXPORT CSV
export const getCsvExport = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.getCsvExport(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

// UPDATE INSPECTION REQUEST
export const updateRequestListing = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.updateRequestListing(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


export const updateRequest = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.updateRequest(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


export const timelineLog = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.timelineLog(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

//getCancelRequestDataByID
export const getCancelRequestDataByID = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.getCancelRequestDataByID(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


//requestUser
export const requestUser = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.requestUser(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


//ce list
export const getCeList = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.ceList(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

//inspectionCityList
export const inspectionCityList = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.inspectionCityList(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

//requestCityList
export const requestCityList = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.requestCityList(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

//ASSIGN TO
export const getassignTo = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.assignTo(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


//getVehicleDataByID
export const getVehicleDataByID = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.getVehicleDataByID(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


//saveInspectionRequest
export const saveInspectionRequest = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.saveInspectionRequest(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

//searchByRegNumber
export const searchByRegNumber = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.searchByRegNumber(params)
            .then(
                response => {
                    if (response && response.data && response.data.status === 200) {
                        resolve(response.data)
                    }
                    else {
                        if (response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

//Get Inspection Booking Data
export const getinspectionBookingData = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.inspectionBookingData(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data);
                } else {
                    if (response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

//Get Assign Ce Booking Data
export const getAssignCeData = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.getAssignCeData(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data);
                } else {
                    if (response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

/*
*updateConvence KM
*/
export const updateConvence = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.updateConvence(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data);
                } else {
                    if (response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

export const assignCeForInspection = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.assignCeForInspection(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data);
                } else {
                    if (response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

//Get Inspection Ce Data
export const inspectionCeData = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.inspectionCeData(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data);
                } else {
                    if (response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}


//Get Ce Inspection Booking Data
export const getCeBookingListOfAWekk = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.getCeBookingListOfAWekk(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data);
                } else {
                    if (response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

// update Booking Address
export const updateAddress = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.updateAddress(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data);
                } else {
                    if (response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

export const getInspectionRqstReason = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.getInspectionRqstReason(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


export const cancelInspectionRequest = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.cancelInspectionRequest(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}



export const rescheduleSFARequest = (params) => {
    return new Promise((resolve, reject) => {
        InspectionService.rescheduleSFARequest(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}



export const setInspectionFilter = (params) => dispatch => {
    dispatch(setInspectionAllFilters(params))
}
