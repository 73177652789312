import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useDispatch,useSelector} from 'react-redux';
import { Tab, Nav } from 'react-bootstrap';
import TopActionBtn from "@src/view/common/TopActionBar";
import {getBackOfficeListing,getBackOfficeFilters} from "@src/store/slices/backOffice"
import {getinspectionConfig,getCeList} from "@src/store/slices/inspection"
import {getInspectionFilters,getCityList} from "@src/store/slices/common"
import FullPageLoader from "@src/view/common/FullPageLoader"
import DealerQcFilters from "@src/view/back_office/DealerQcFilters"
import DealerQcTable from "@src/view/back_office/DealerQcTable"

const Dealerqc = (props) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [statusList, setStatusList] = useState([]);
    const [searchFilterList, setSearchFilterList] = useState({list_flag :0,invt_status:2,page_no:1})
    const [filterArrayList, setFilterArrayList ] = useState({});
    const backOfficeState = useSelector(state => state.backoffice)
    const commonState = useSelector(state => state.common)
    const[callonpage, setCallonpage] = useState('0');
    const [backOfficeListResponse, SetBackOfficeListResponse] = useState([])
    const [backOfficeListPagination, SetBackOfficeListPagination] = useState({})
    const [resetOnChange, setResetOnChange] = useState(0);
   

    // CALLING ALL FILTER DROPDOWN LIST
    useEffect(() => {
        if (backOfficeState && backOfficeState.backOfficeFilters && backOfficeState.backOfficeFilters.DEALER_STATUS) {
            setStatusList(backOfficeState.backOfficeFilters.DEALER_STATUS)
        }else{
            getFilterListing()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (commonState && commonState.inspectionFilters
            && commonState.inspectionFilters.STATUSLIST) {
                let invstatus=[{value:2,label:'All'}];
                for(let inv of commonState.inspectionFilters.STATUSLIST){
                    invstatus.push(inv);
                }
                if(invstatus.length>0){
                    let objectFilterData= Object.assign(filterArrayList, {INV_STATUS:invstatus});
                    setFilterArrayList({...objectFilterData})
                }
                
        }else{
            getInvStatus();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (commonState && commonState.cityList && commonState.cityList.data && commonState.cityList.data.length>0) {
            let cityList=commonState.cityList.data.map((el)=>({value:el.id,label:el.name}));

            let objectFilterData= Object.assign(filterArrayList,{CITY_LIST:cityList});
            setFilterArrayList(objectFilterData)    
        }else{
            getCityListing();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
            getOtherFilters();
            getCeLists();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


      // CALLING DEALER EVALUATION LIST 
    useEffect(() => {
        let params=searchFilterList;
        getbackOfficeListing(params)
    }, [searchFilterList]) // eslint-disable-line react-hooks/exhaustive-deps

    

  
   const getbackOfficeListing = (params) => {
    setLoading(true);
    dispatch(getBackOfficeListing(params)).then((res) => {
        setLoading(false)
        if (res && res.status === 200) {
            if(res && res.data && res.data.length>0){
                SetBackOfficeListResponse(res.data);
                SetBackOfficeListPagination(res.pagination)
            }else{
                SetBackOfficeListResponse([])
                SetBackOfficeListPagination({})
            }
        }
    }).catch(err => {
        SetBackOfficeListResponse([])
        setLoading(false)
    })
}
    

    // FILTER LISTING
    const getFilterListing = async() => {
        try {
            const response = await dispatch(getBackOfficeFilters())
            setStatusList(response.DEALER_STATUS);
        } catch (error) {
        }
    }
    
    /*Inventory Status list*/
    const getInvStatus = async() => {
        try {
            const response = await dispatch(getInspectionFilters())
            if(response && response.STATUSLIST.length>0){
                
                let invstatus=[{value:2,label:'All'}];
                for(let inv of response.STATUSLIST){
                    invstatus.push(inv);
                }
                if(invstatus.length>0){
                    let objectFilterData= Object.assign(filterArrayList, {INV_STATUS:invstatus});
                    setFilterArrayList({...objectFilterData})
                }
            }
        } catch (error) {
        }
    }

    /*Other Filter list*/
    const getOtherFilters = async() => {
        try {
            getinspectionConfig().then((res) => {
                if(res){
                    let objectFilterData= Object.assign(filterArrayList,{
                        VEHICLE_TYPE:res.vehicleType});
                    setFilterArrayList(objectFilterData)
                }
            });
        } catch (error) {
        }
    }
    
 
    /*get City List*/
    const getCityListing=()=>{
        dispatch(getCityList()).then((res) => {
            if (res && res.status === 200 && res.data.length>0) {
                 let cityList=res.data.map((el)=>({value:el.id,label:el.name}));
                if(cityList.length>0){
                    let objectFilterData= Object.assign(filterArrayList,{
                        CITY_LIST:cityList});
                    setFilterArrayList(objectFilterData)    
                }
            }
        }).catch(err => {
        })
    }

    //CE LISTING
    const getCeLists = () => {
        getCeList().then((res) => {
            if (res && res.status === 200) {
                let objectFilterData= Object.assign(filterArrayList,{CE_LIST:res.data});
                setFilterArrayList(objectFilterData) 
            }
        }).catch(err => {
        })
    }
   
    const tabChange = (type) => {
        let searchFilters={list_flag:type,invt_status:2,page_no:1};
        setSearchFilterList(searchFilters);
        setCallonpage(type);
        setResetOnChange(resetOnChange+1);
    }

    //SET ALL FILTER VALUE
    const updateFilter = (params) => {
        setSearchFilterList(params)
    }

    //USE FOR PGINATION FORMATION FOR TABLE DATA
    const fetchDealerQCListing = (params) => {
        let searchFilters=Object.assign(searchFilterList,params);
        getbackOfficeListing(searchFilters)
    }
    return (
        <>
            <FullPageLoader show={loading} />
            <div className="container-fluid-full">
                <div className="top-sec">
                    <TopActionBtn heading={t("BACK_OFFICE.HEADING")}  
                    callonpage = {callonpage} 
                    searchFilterList={searchFilterList} 
                    pageType={'DealerQc'} 
                    List={backOfficeListResponse} />
                </div>
                <div className="payout-summary-tabs">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                            {/* SUB MENU LINK */}
                        <Nav variant="pills" className="flex-column tab-line">
                            <div className="tab-list">
                                {
                                    statusList && statusList.length>0 && statusList.map((item) => 
                                    {
                                        return <Nav.Item key={item.value}>
                                            <Nav.Link eventKey={item.value} onClick={() => tabChange(item.value)} >{item.label} </Nav.Link>  
                                        </Nav.Item>
                                    })   
                                }
                            </div>
                        </Nav>
                        <div className="vehicle-listing-box">
                            <DealerQcFilters
                                updateFilter={updateFilter}
                                searchFilterList={searchFilterList}
                                filterArrayList={filterArrayList}
                                callonpage={callonpage}
                                resetOnChange = {resetOnChange} />
                        </div>
                        <div className="vechile-table-data">
                            <DealerQcTable
                                apiResponse={backOfficeListResponse}
                                apiResponsepagination={backOfficeListPagination}
                                getbackOfficeListing={fetchDealerQCListing}
                                loading={loading}
                                searchFilterList={searchFilterList}
                                callonpage={callonpage}

                            />
                        </div>
                    </Tab.Container>
                </div>
           </div>
        </>
    )
}

export default Dealerqc