import WhatsAppImage from "@src/webroot/images/oval.svg";
import { viewBookingRqstData } from "@src/store/slices/user";
import { useEffect, useState } from "react";
import FullPageLoader from "@src/view/common/FullPageLoader";
import { DateFormat } from "@src/config/helper";
import ViewStatusModal from '@src/view/inspection/common/ViewStatusModal';
import HeadphoneIcon from '@src/webroot/images/headphone.svg';
import { default as config } from '@src/config/config';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router';
import secureStorage from '../../config/encrypt';



const ViewStatus = (props) => {

    const { t } = useTranslation("common")
    const [requestData, setRequestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showActions, setShowActions] = useState(0);
    const [modalType, setModalType] = useState('');
    const [selectedData, setSelectedData] = useState({});
    const [decodedData, setDecodedData] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)

        const searchParams = new URLSearchParams(document.location.search)
        const queryString = searchParams && searchParams.get('q')

        if (queryString) {
            let decodedData = window.atob(queryString)
            let decodedValues = decodedData && decodedData.split('&')

            let decodedUserID = (decodedValues && decodedValues[0]) ? (decodedValues[0]).replace('user_id=', "") : ''
            let decodedSource = (decodedValues && decodedValues[1]) ? (decodedValues[1]).replace('source=', "") : ''
            let decodedToken = (decodedValues && decodedValues[2]) ? (decodedValues[2]).replace('token=', "") : ''

            //set token
            secureStorage.setItem('authUserInfo', { token: decodedToken });

            if (typeof (+decodedUserID) === 'number' && decodedSource === 'inspection') {
                let params = {
                    user_id: decodedUserID,
                    source: decodedSource
                }

                setDecodedData(params)
                getBookingData(params)
            }

            else {
                toast.error(t("INVALID_CREDENTIALS"))
                navigate("/dashboard")
            }
        }

        setLoading(false)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getRegNo = (elm) => {
        let html = [];
        let newRegNo = elm['reg_no'];
        let regNo = '';
        if (elm['reg_no'] !== elm['finalRegNo']) {
            regNo = elm['reg_no'];
            newRegNo = elm['finalRegNo'];
        }


        if (regNo !== '') { html.push(<span className="line-through">{regNo} </span>) }
        html.push(<span >{newRegNo} </span>)
        return html;
    }

    const getBookingData = async (params) => {
        const bookingData = await viewBookingRqstData(params)

        if (bookingData && bookingData.status && bookingData.status === 'T') {
            setRequestData(bookingData.bookingData)
        }
    }

    const statusClickHandler = (status, bookingID) => {
        setShowActions(0)

        if (status && status.toLowerCase() !== "completed" && !showActions) {
            setShowActions(bookingID)
        }
    }

    const cancelResceduleBtnHandler = (data, type) => {
        if (type === 'cancel') {
            setModalType('cancel')
        }
        else {
            setModalType('reschedule')
        }

        setSelectedData(data)
    }

    const updateStatusListing = () => {
        getBookingData(decodedData)
        setModalType("")
        setSelectedData("")
    }

    const setLoaderFromChild = (flag) => {
        setLoading(flag)
    }

    return (
        <>
            {/* <div className="ins-overlay"></div> */}

            <FullPageLoader show={loading} />
            <div id="inspection-request" className="col-md-12">

                <div>
                    {
                        requestData.map((elm, index) => {
                            return <div className="cards col-md-10" key={index}>
                                <div className={`requestStatus_${elm['bookingID']}  ${elm['statusTagClass']} `} onClick={() => { statusClickHandler(elm['statusTag'], elm['bookingID']) }}> {elm['statusTag']}</div>
                                <h3><span className="dealer-name">{elm['fromOwnerName']} </span> (GCD-{elm['client_reference_id']})</h3>
                                <div className="car-dtls">
                                    <div className="reg-dtls">
                                        {
                                            getRegNo(elm)
                                        }

                                    </div>

                                    {
                                        (+elm['is_rescheduled'] === 1) &&
                                        <div className="reschedule-label">{t("VIEW_STATUS.RESCHEDULED")}</div>
                                    }

                                    <div className="request-dtls">{t("VIEW_STATUS.RQST_ID")} :  {elm['bookingID']}

                                        {/* {(elm['ceComment'] !== '') &&
                                        <span className="shormore-btn">show more</span>
                                    } */}
                                    </div>
                                    {(elm['ceComment'] !== '') &&
                                        <div className="showmore-blk">
                                            <div>
                                                <p> {elm['ceComment']} </p>
                                                <p className="ins-done-time">{t("VIEW_STATUS.LAST_UPDATE_FROM")} :- {(elm['userRole']) + ", " + DateFormat(elm['commentDate'], '1')} </p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="inspect-status">
                                    <div className="status">{t("VIEW_STATUS.RQSTED_ON")} <span>{DateFormat(elm['bookingTime'], '1')} </span></div>

                                    <div className="status">{t("VIEW_STATUS.PREFERRED_ON")} <span>{DateFormat(elm['inspection_prefered_time'], '1')}</span></div>
                                    {elm['updateText'] !== '' &&
                                        <div className="status"> {elm['ceBookingStatus']} on: <span> {DateFormat(elm['updateBookingDate'], '1')}</span></div>
                                    }

                                    {
                                        elm.spocNo && <div className="img-cal-oval"><a style={{ "color": "#000", "opacity": ".87" }} href={`tel: ${elm.spocNo}`}><img src={WhatsAppImage} alt="whatsapp-img" /><br />{t("VIEW_STATUS.CALL_CE")} </a></div>
                                    }
                                </div>
                                {
                                    (elm['inspection_type'] !== 'transfer' && [0, 1, 2].includes(+elm['bookingStatus'])) && (showActions === elm['bookingID']) &&
                                    <div className="action-btn action-btn-<?php echo $data['bookingID']; ">
                                        <div className="actionbtn">
                                            <span className={"popCanBtn popCanBtn_" + elm['bookingID']} onClick={() => cancelResceduleBtnHandler(elm, 'cancel')}>{t("INSPECTION.CANCEL")}</span>
                                        </div>
                                        <div className="actionbtn">
                                            <span className={"popCanBtn popCanBtn_" + elm['bookingID']} onClick={() => cancelResceduleBtnHandler(elm, 'reschedule')}>{t("VIEW_STATUS.RESCHEDULE")}</span>
                                        </div>
                                    </div>
                                }
                            </div>

                        })
                    }

                </div>


                {modalType && <ViewStatusModal showUpdatePopup={true} setShowUpdatePopup={() => setModalType('')} updateData={selectedData} modalType={modalType} updateStatusListing={updateStatusListing} setLoading={setLoaderFromChild} />}


                <div className="un-call-btn text-center">
                    <a href={`tel:${config.constants.CARDEKHO_SPOC_NO}`}><img className="mrg-R5" src={HeadphoneIcon} alt="headphone" /> {t("VIEW_STATUS.CALL_CSTMER_CARE")}</a>
                </div>
            </div>
        </>
    )
}


export default ViewStatus;