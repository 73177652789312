import MasterService from '@src/services/MasterService';


export const InspectionService = {
    ...MasterService,

    getInspectionRequest(params) {
        return this.post('/inspectionRequest/getAllinspectionRequest', params)
    },
    getinspectionConfig(params) {
        return this.post('/inspectionRequest/inspectionConfig', params)
    },
    assignScheduler(params) {
        return this.post('/inspectionRequest/assignSceduler', params)
    },
    getCsvExport(params) {
        return this.post('/inspectionRequest/exportInspectionData', params)
    },
    updateRequestListing(params) {
        return this.post('/inspectionRequest/updateRequestListing', params)
    },
    updateRequest(params) {
        return this.post('/inspectionRequest/updateRequest', params)
    },
    timelineLog(params) {
        return this.post('/inspectionRequest/timelineLog', params)
    },
    getCancelRequestDataByID(params) {
        return this.post('/inspectionRequest/getCancelRequestDataByID', params)
    },
    requestUser(params) {
        return this.post('/inspectionRequest/requestUser', params)
    },
    ceList(params) {
        return this.post('/inspectionRequest/ceList', params)
    },
    inspectionCityList(params) {
        return this.post('/inspectionRequest/inspectionCityList', params)
    },
    requestCityList(params) {
        return this.post('/inspectionRequest/requestCityList', params)
    },
    assignTo(params) {
        return this.post('/inspectionRequest/assignTo', params)
    },
    getVehicleDataByID(params) {
        return this.post('/inspectionRequest/getVehicleDataByID', params)
    },
    saveInspectionRequest(params) {
        return this.post('/inspectionRequest/saveInspectionRequest', params)
    },
    searchByRegNumber(params) {
        return this.post('/inspectionRequest/searchByRegNumber', params)
    },
    inspectionBookingData(params) {
        return this.post('/inspectionRequest/inspectionBookingData', params)
    },
    getAssignCeData(params) {
        return this.post('/inspectionRequest/getAssignCeData', params)
    },
    updateConvence(params) {
        return this.post('/inspectionRequest/updateConvence', params)
    },
    assignCeForInspection(params) {
        return this.post('/inspectionRequest/assignCeForInspection', params)
    },
    inspectionCeData(params) {
        return this.post('/inspectionRequest/getCeData', params)
    },
    getCeBookingListOfAWekk(params) {
        return this.post('/inspectionRequest/getCeBookingListOfAWekk', params)
    },
    updateAddress(params) {
        return this.post('/inspectionRequest/updateAddress', params)
    },
    rescheduleSFARequest(params) {
        return this.post('/api/rescheduleSFARequest', params)
    },
    getInspectionRqstReason(params) {
        return this.post('/api/getInsRqstReason', params)
    },
    cancelInspectionRequest(params) {
        return this.post('/api/cancelRqst', params)
    }


}