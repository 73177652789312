import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import secureStorage from "@src/config/encrypt";
import VerifyOtp from "@src/view/auth/VerifyOtp";
import Captcha from "@src/view/common/Captcha";
import { UserSlice } from "@src/store/slices";
import { default as config } from '@src/config/config';
import { withTranslation } from 'react-i18next';

function RemoveInfoForm(props) {

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i); // eslint-disable-line

    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const [inputField, setInputField] = useState({
        email: "",
        captcha: "",
        hash: ""
    });
    const [errField, setErrField] = useState({});
    const [showVerify, setshowVerify] = useState(false);
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();
    const [reloadCaptcha, setReloadCaptcha] = useState(1);

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            captchErr: "",
            captcha: ""
        });
        if (inputField.email === "") {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                email: t('VALIDATION.EMAIL'),
            }));
        } else if (!validEmailRegex.test(inputField.email)) {

            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                email: t('VALIDATION.VALID_EMAIL'),
            }));
        }
        if (!inputField['captcha'].trim()) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                captcha: t('VALIDATION.CAPTCHA_FAILED'),
            }));
            setReloadCaptcha((prev) => prev + 1)
        }
        return formIsValid;
    };
    const inputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const loggedInUser = secureStorage.getItem("authUserInfo");
        if (loggedInUser && loggedInUser.user_data) {
            // navigate("/dashboard")
            navigate('/dashboard');
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const submitButton = async () => {
        setLoading(true);
        if (validForm()) {
            UserSlice.remove_info(inputField).then((res) => {

                setLoading(false)
                if (res && res.status === 200) {
                    //DELETE CAPTCHA HASH FROM CACHE 
                    setUserId(res.data.user_id);
                    setshowVerify(true);
                }
                setReloadCaptcha((prev) => prev + 1)
                setInputField({ ...inputField, email: inputField.email, captcha: '' })

            });
        } else {
            setReloadCaptcha((prev) => prev + 1)
            setLoading(false);
        }
    };

    // const handleKeyPress = (e) => {
    //     if (e.charCode === 13) submitButton();
    //     return true
    // }

    const captchaFiledHandler = (childData, captchaImg) => {
        if (captchaImg.value) {
            setInputField({ ...inputField, captcha: childData, hash: captchaImg.value })
        }
    }

    const changeLanguage = (lang) => {
        secureStorage.setItem('lang', lang);
        props.i18n.changeLanguage(lang)
    }
    const resetform=()=>{
        setshowVerify(false)
    }
    return (
        <div className="login-form">
            {showVerify ?
                (<VerifyOtp submitButton={submitButton} userId={userId} inputField={inputField} page={2}  resetform={resetform}/>)
                :
                <form autoComplete="on">
                    <fieldset className="">
                        <div className={"material " + (errField.email ? "error" : "")}    >
                            <input
                                type="text"
                                placeholder=" "
                                name="email"
                                value={inputField.email}
                                className="form-input"
                                onChange={inputHandler}
                            />
                            <i className="ic-email1 icn-login"></i>
                            <label data-label={t('FORM.EMAIL')} className="form-label"></label>
                            {errField.email && (
                                <span className="erroe-txt">
                                    {errField.email}
                                </span>
                            )}
                        </div>
                    </fieldset>
                    
                    <fieldset className=" captcha-filed">
                        <Captcha onKeyPressCaptcha={captchaFiledHandler} errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha} />

                    </fieldset>

                    <div className="language-selectors">

                        <button type="button" className="btn-primary" onClick={submitButton} disabled={loading}>
                            {t('INSPECTION.SUBMIT')}
                        </button>
                        <div className="langunage-txt">

                            <div className="m-xs-r">

                                {
                                    config.constants.language.length > 1 && config.constants.language.map((lang, index) => {
                                        return <span key={index} className="m-xs-l" onClick={() => changeLanguage(lang.iso_code)}>
                                            {lang.key}
                                        </span>
                                    })
                                }
                            </div>
                        </div>


                    </div>

                </form>
            }
        </div>
    );
}

export default withTranslation('common')(RemoveInfoForm);