import React, { useState, useEffect} from "react";
import Dateformat from 'dateformat';
import secureStorage from '@src/config/encrypt';
import CalenderBookingHtml from "@src/view/inspection/AssignCe/calenderHtml/CalenderBookingHtml";
import _ from 'lodash';

const CeCalender = (props) => {
    const [startArrow, setStartArrow] = useState(2);
    const [endArrow, setEndArrow] = useState(7);
    const [activeClasses, setActiveClasses] = useState([]);
    const [timeRangeStart, setTimeRangeStart] = useState();
    const [timeRangeEnd, setTimeRangeEnd] = useState();
    const [dateRange, setDateRange] = useState([]);
    const [ceBookingData, setCeBookingData] = useState([]);
    const is_inhouse = secureStorage.getItem('authUserInfo').user_data.is_inhouse;
    const  timeRange = _.range(8, 20);
    const timevalue={8:'8AM',9:'9AM',10:'10AM',11:'11AM',12:'12PM',13:'1PM',14:'2PM',15:'3PM',16:'4PM',17:'5PM',18:'6PM',19:'7PM',20:'8PM'}
   
    useEffect(() => {
        setTimeRangeStart(props.timeRangeStart);
        setTimeRangeEnd(props.timeRangeEnd);
        getDateRange(props.timeRangeStart,props.timeRangeEnd);
        setCeBookingData(props.ceBookingData)
    }, [props.timeRangeStart,props.timeRangeEnd,props.ceBookingData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(!activeClasses.length) {
            let defaultVisiblity = [];
            for(let num of timeRange) {
                defaultVisiblity.push( (9 < num && num < 16) ? true: false )
            }
            setActiveClasses(defaultVisiblity);
        }

    }, [timeRange]) // eslint-disable-line react-hooks/exhaustive-deps


    /*
    *Calculate Prev Week Date
    */
    const prevDate =(start) => {
       props.prevDate(start);
    }

    /*
    *Calculate Next Week Date
    */
    const nextDate =(end) => {
        props.nextDate(end);
    }

    /*
    *show previous Time Slot 
    */
    const prearrow =(start,end) => {
        if(start>0){
            let activeClass=activeClasses;
            activeClass[start-1]=true;
            activeClass[end]=false;
            setStartArrow(start-1);
            setEndArrow(end-1);
            setActiveClasses(activeClass);
        }
    }

    /*
    *show next Time Slot 
    */
    const nextarrow =(start,end) => {
        if(end<11){
            let activeClass=activeClasses;
            activeClass[start]=false;
            activeClass[end+1]=true;
            setStartArrow(start+1);
            setEndArrow(end+1);
            setActiveClasses(activeClass);
        }

    }
    
    /*
    *Get Date Range Between Two Dates
    */
    const getDateRange=(s,e)=>{
        for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}
        setDateRange([...a]);
    }

    return (
        <div className="tab-content p-md-t">
            <div className="calanderWraper">
                <div className="calDate">
                    <div className="datemover">
                        <span className="" onClick={() => prevDate(timeRangeStart)}><i className="ic-keyboard_arrow_left"></i></span>
                        <span id="curr-cal-date" className="currentdate">{Dateformat(timeRangeStart,'dd mmm, yyyy')}- {Dateformat(timeRangeEnd,'dd mmm, yyyy')}</span>
                        <span className="" onClick={() => nextDate(timeRangeEnd)}><i className="ic-keyboard_arrow_right"></i></span>
                    </div>
                </div>
                
                <div className="tableArea">
                    <div className="calanderTable" id="calanderTable">
                        <ul className="calanderRow calanderHeader">
                                <li className="prevarrow">
                                    <div className="text-left">
                                        <span className="card-head">Days</span>
                                    </div>
                                    <span className="tableArrow prearrow" onClick={()=>prearrow(startArrow,endArrow)} ><i className="ic-keyboard_arrow_left"></i></span>
                                </li>
                                {
                                    timeRange.map((num, key)=> {
                                        
                                        return <li className={activeClasses[key]?"timeSlot":"timeSlot colnone"} id={num} key={num}>{timevalue[num]}</li>
                                        
                                    })
                                }
                               
                                <li>
                                    {
                                    (is_inhouse!==0) && 
                                    <h3 className="card-head text-left">Dealer Visits</h3> 
                                    }
                                    <span className="tableArrow nextarrow" onClick={() => nextarrow(startArrow,endArrow)}><i className="ic-keyboard_arrow_right"></i></span>
                                </li>            
                        </ul>
                        { 
                           dateRange && dateRange.length?
                           dateRange.map((item, key) => {
                               let slotArrayData=[];
                              
                               let todayInspectionData=ceBookingData.filter(el=>Dateformat(new Date(el.inspection_schedule_time),'yyyy-mm-dd')===Dateformat(item,'yyyy-mm-dd'));

                               if(todayInspectionData.length>0){

                                    for(let data of todayInspectionData){
                                        let inspection_schedule_time=new Date(data.inspection_schedule_time);
                                        let timestampOne = new Date(data.inspection_schedule_time);
                                            timestampOne.setHours(timestampOne.getHours() + 1);
                                        let time= Dateformat(inspection_schedule_time,'h:MM tt');
                                        let timestamp= Dateformat(timestampOne,'h:MM tt');
                                        let time24= Dateformat(inspection_schedule_time,'HH');
                                        let timeMinute= Dateformat(inspection_schedule_time,'MM');
                                        let left="left:0%;width:100%;";
                        
                                        if(timeMinute==='15'){
                                            left="left:25%;width:100%;";
                                        }else if(timeMinute==='30'){
                                            left="left:50%;width:100%;";
                                        }else if(timeMinute==='45'){
                                            left="left:75%;width:100%;";
                                        }
                                        let html=`<div class="schedule-box" data-slot="${Dateformat(inspection_schedule_time,'HH:MM')} - ${Dateformat(timestampOne,'HH:MM')}" style="${left}"><div class="tagBox"><span class="tag-confirm">Scheduled</span></div><p><strong>${time} - ${timestamp}</strong></p><p>${data.regNo}</p><p class="address-line" title="${data.address}">${data.address}</p></div>`;

                                        slotArrayData[time24]=html;
                                    }
                                }
                            return (
                                <ul className="calanderRow dayExpand" key={key}>
                                    <li>
                                        <div className="daydate">
                                            <strong className="date">{Dateformat(item,'dd')}</strong>
                                            <div className="day">{Dateformat(item,'ddd')}</div>
                                        </div>
                                    </li>
                                    <CalenderBookingHtml
                                        slotArrayData={slotArrayData} activeClasses={activeClasses}
                                    />
                                    <li>
                                        <ol className="dealerVist">
                                        </ol>
                                    </li>
                                 </ul>
                            );
                           }):''
                        }
                    </div>     
                </div>
            </div> 
        </div>          
    );
}


export default CeCalender;