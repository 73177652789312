import React from 'react';

const InputField = (props) => {

    const changeHandel = (event) => {
        if (typeof props.onChange === 'function') {

            //ALLOW NUMBERS
            let pattern = /^\d+$/;
            let alphanumericPattern = /[^a-z\d]/i;
            // let alphanumericPatternForAddress = /^[A-Za-z0-9 ]+$/;
            // let alphabetivPatternWithSpace = /^[A-Za-z ]+$/;
            let alphabeticPattern = /^[A-Za-z]+$/;

            if (props.inputProps.id === 'mobile' || props.inputProps.id === "user_experience" || props.inputProps.id === "pin_code") {
                if (event.target.value && !pattern.test(event.target.value)) {
                    return false;
                }
            } else if (props.inputProps.id === 'reg_no') {
                if (alphanumericPattern.test(event.target.value)) {
                    return false;
                }
            } else if (props.inputProps.id === 'customer_address') {
                // if (event.target.value.trim() && !alphanumericPatternForAddress.test(event.target.value)) {
                    // return false;
                // }
            } else if (props.inputProps.id === 'firstname' || props.inputProps.id === 'lastname') {
                if (event.target.value.trim() && !alphabeticPattern.test(event.target.value)) {
                    return false;
                }
            } else if(props.inputProps.id === "customerName") {
                if (event.target.value && !/^([a-zA-Z]+\s?)*\s*$/.test(event.target.value)) {
                    return false;
                }
            }

            //CHECK DIGIT COUNT
            if (event.target.value.length > props.inputProps.maxLength) {
                return false;
            }

            props.onChange(event);
        }
    }


    const chkValFocus = (event) => {
        if (event.target.value) {
            props.onChange(event);
        }

    }

    const chkValBlur = (event) => {
        const { value, id, } = event.target;

        let errorElement = '';
        let formField = '';

        if ((document.getElementById(id))) {
            errorElement = document.getElementById(id).parentNode.querySelector('.error');
            formField = document.getElementById(id).closest('.form-field');
        }

        //this.validateFields(event, validationReq);
        if (value === '') {
            if (errorElement) errorElement.classList.remove('hide');
            if (formField) formField.classList.remove('active');
        } else {
            if (errorElement) errorElement.classList.add('hide');
            if (formField) formField.classList.add('active');
        }
    }

    return (
        <>
            {(props.inputProps.type === 'checkbox' || props.inputProps.type === 'radio') ?
                <label htmlFor={props.inputProps.id} className="gs_control gs_checkbox">{props.inputProps.label}
                    <input {...props.inputProps} className={props.inputProps.className || props.inputProps.className} autoComplete="off" onChange={changeHandel} />
                    <span className="gs_control__indicator"></span>
                </label>
                :
                <>
                    {props.inputProps.label_before_input === 'yes' ?
                        <label htmlFor={props.inputProps.id} className="">{props.inputProps.label}
                            {(props.inputProps.validationreq) ? <span className="required" style={{ color: "red" }}>*</span> : ''}
                        </label>
                        : ''}
                    {props.inputProps.label_before_input === undefined ?
                        <label htmlFor={props.inputProps.id} className="">{props.inputProps.label}
                        </label>
                        : ''}
                    {(props.inputProps.validationreq === 'yes') ? <span className="required" style={{ color: "red" }}>*</span> : null}
                    <input {...props.inputProps} onBlur={chkValBlur} onFocus={chkValFocus} onChange={changeHandel} className={props.inputProps.className} autoComplete="off" />
                    <span className="error show">{props.inputProps.dataerror}</span>
                    {props.inputProps.validation_error ? <span className="error show">{props.inputProps.validation_error}</span> : ''
                    }
                </>
            }
        </>
    );
}
export default InputField;