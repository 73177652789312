import { RosterService } from '../../services';

import { createSlice } from '@reduxjs/toolkit'
import { toast } from "react-toastify";


const roster = createSlice({
    name: 'roster',
    initialState: {
        inspection: {},
        cities: []
    },
    reducers: {
       
    },
});

export default roster.reducer;


export const generateRosterReport = (params) =>  {
    return new Promise((resolve, reject) => {
        RosterService.generateRosterReport(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data)
                }else {
                    if(response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

export const rosterTemplate = (params) =>  {
    return new Promise((resolve, reject) => {
        RosterService.rosterTemplate(params).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data)
                }else {
                    if(response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}

export const uploadCsv = (params,headers) =>  {
    return new Promise((resolve, reject) => {
        RosterService.uploadCsv(params,headers).then(
            response => {
                if (response && response.data && response.data.status === 200) {
                    resolve(response.data)
                }else {
                    if(response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}