import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import { getSignedImage } from "@src/store/slices/common";


function BannerPreview(props) {
    const [isOpen, setIsOpen] = useState("");
    const { element, defaultShow, title, deleteTitle, previewTitle,signedImage } = props;
    const [lightboxImages, setLightboxImages] = useState([]);

    useEffect(() => {
        setIsOpen(defaultShow)
    }, [defaultShow]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        let galleryImages = [];
        if(isOpen === 'preview'){
            galleryImages = [{src: element.image[0]['image_preview_path'], alt: 'preview'}]
        }else if(signedImage){
            galleryImages = element.image ? element.image.map(img=>{ return {src: img.image_path, alt: img['tag_name'], title: img['tag_name']} }) : [];
        }    
        setLightboxImages(galleryImages)
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    const openImage = async(type) => {
        if(isOpen!== 'preview'){
            await getSignedImageUrl();
        }
        setIsOpen(type)
    }
    const getSignedImageUrl=async()=>{
        let galleryImages=[]
        if(element.image[0]['image_path']){
            getSignedImage({ "imageurl": element.image[0]['image_path'] }).then((resp) => {
                if(resp.status===200 && resp.data){
                   let signedUrlData=resp.data;
                    if(signedUrlData.length){
                        galleryImages = signedUrlData.map(img=>({src: img.signed_url, alt: img['tag_name'], title: img['tag_name']}));
                        setLightboxImages(galleryImages)
                    }
                }
            })
        }
        
    }

    return (
        <React.Fragment>
            {
                (title || previewTitle) && <div className='banner-preview-outer'>
                    <span onClick={() => openImage('main')}>{title}</span>
                    {deleteTitle && <span className='m-md-l' onClick={() => props.deleteImageConfirm(true)}>{deleteTitle}</span>}
                    {previewTitle && <span className='m-md-l' onClick={() => openImage('preview')}>{previewTitle}</span>}
                </div>
            }

            <Lightbox
                open={isOpen? true: false}
                close={() => setIsOpen('')}
                plugins={[Captions, Zoom]}
                slides={lightboxImages}
                carousel={{
                    finite:true
                }}

               
            />

        </React.Fragment>
    );
}

export default withTranslation('common')(BannerPreview);
