import { useEffect, useRef, useState } from "react"

import ArrowLeftIcon from '@src/webroot/images/arrow-left.svg';
import ArrowRightIcon from '@src/webroot/images/arrow-right.svg';
import BlurIcon from '@src/webroot/images/blur-icon.svg';
import CancelIcon from '@src/webroot/images/cancel.svg';
import CropIcon from '@src/webroot/images/crop.svg';
import DoneIcon from '@src/webroot/images/done.svg';
import ResetIcon from '@src/webroot/images/reset.svg';
import RotateIcon from '@src/webroot/images/rotate.svg';
import ZoomInIcon from '@src/webroot/images/zoom-in.svg';
import ZoomOutIcon from '@src/webroot/images/zoom-out.svg';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from 'react-select';
import { uploadQcImages, modifyCerificationImage, setQcImageSliderData, updateQcPanelData } from "@src/store/slices/dealerQc";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"



const ImageSlider = (props) => {

    const { t } = useTranslation("common")
    let { imageTagList, callFrom } = props;

    const [slideIndex, setSlideIndex] = useState(1);
    const [leftCssAnimationForSlider, setLeftCssAnimationForSlider] = useState(0);
    const [seenImages, setSeenImages] = useState([]);
    const [completedCrop, setCompletedCrop] = useState();
    const [crop, setCrop] = useState()
    const [imageAction, setImageAction] = useState('normal'); //'normal', 'zoom', 'crop', 'rotate', 'blur'
    const aspect = 16 / 9;
    const imgRef = useRef(null)
    const [sliderImages, setSliderImages] = useState([]);
    const [zoomScale, setZoomScale] = useState(1);
    const [showTagDropdown, setShowTagDropdown] = useState(false);
    const [activeImageDimentions, setActiveImageDimentions] = useState({});
    const [mouseDownImage, setMouseDownImage] = useState(false);
    const [imgPositions, setImgPositions] = useState({ previousX: 0, previousY: 0 });
    const [tagValue, setTagValue] = useState({});
    const [showEditFlag, setShowEditFlag] = useState(true)

    const [visibleThumbImagesWidth, setVisibleThumbImagesWidth] = useState(0);

    const dispatch = useDispatch()

    const dealerQcState = useSelector(state => state.dealerQc);
    const queryParams = useParams();

    useEffect(() => {
        setSlideIndex(1);

        showHideHandler({ type: 'class', 'display': 'none', element: '.slide-btn.prev' })
        setSeenImages([...seenImages, 1]);

        return () => {
            dispatch(setQcImageSliderData({}))
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(setQcImageSliderData({ slideIndex }))

    }, [slideIndex]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dealerQcState.qcPanelData) {
            setSliderImages(dealerQcState.qcPanelData.imageData || [])
            let imageData = dealerQcState.qcPanelData.imageData
            let currentImageTagId = imageData && imageData[slideIndex - 1] && imageData[slideIndex - 1].tag_id

            if (imageTagList) {
                for (let tag of imageTagList) {
                    let options = tag.options
                    if (options) {
                        for (let data of options) {
                            if (data.id === currentImageTagId) {
                                setTagValue(data)
                                return
                            }
                        }
                    }
                }
            }

        }

    }, [dealerQcState]) // eslint-disable-line react-hooks/exhaustive-deps


    const setImgBoxProperty = (type) => {
        let imgBox = document.getElementById("scroll");

        if (type === "set") {
            //SET FOR ACTIVE IMG
            let activeImg = document.querySelector(".mySlides.active");


            //SET IMG BOX HEIGHT
            imgBox.style.cssText = `height: ${activeImg.height}px;`;

            setActiveImageDimentions({ mywidth: activeImg.width, myheight: activeImg.height })
        } else {

            //RESET IMAGE BOX CSS PROPERTY
            imgBox.style.cssText = ``;

            //RESET ACTIVE IMG DIMENSIONS
            setActiveImageDimentions({})
        }
    }

    const plusSlides = async (n) => {

        setImgBoxProperty('reset');

        if (n === -1) {
            thumbLeft(n);
        } else {
            //CHECK FORM VALIDATION BEFORE MOVE
            let getValidationStatus = await formValidation();
            if (getValidationStatus) {
                toast.error("Please fill all required fields.")
            } else {
                thumbRight(n);
            }
        }

        setImgBoxProperty('set');

    }

    /**
     * FORM VALIDAION
     */
    const formValidation = async () => {
        return new Promise((resolve) => {
            //GET CURRENT ACTIVE FORM
            let getActiveFormData = sliderImages[slideIndex - 1];
            let fieldError = false;

            if (getActiveFormData && getActiveFormData.formData) {

                for (let field of getActiveFormData.formData) {
                    if (!field.value && field.field_name !== 'fuel_type' && field.field_name !== 'transmission') {
                        fieldError = true;
                    }
                }

            }

            resolve(fieldError);

        })

    }

    const imageThumbClickHandler = (index) => {
        setImgBoxProperty('reset');

        if (seenImages && seenImages.includes(index + 1)) {
            let slides = document.getElementsByClassName("mySlides");

            for (let i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
                slides[i].classList.remove("active");
            }
            slides[index].classList.add('active');
            slides[index].style.display = "block";

            setSlideIndex(index + 1);
        }

    }


    const thumbLeft = (n) => {
        showHideHandler({ type: 'class', 'display': 'block', element: '.slide-btn.next' })

        let slides = document.getElementsByClassName("mySlides");

        let imgOutWid = document.querySelector('.img-thumbnails ul li') && document.querySelector('.img-thumbnails ul li').offsetWidth;
        let getLeftVal = leftCssAnimationForSlider

        getLeftVal = Math.abs(getLeftVal);

        if (getLeftVal > 0) {
            let activeThumbImg = document.querySelector('.thumbs-img');

            let updatedLeftCssAnimationForSlider = leftCssAnimationForSlider - imgOutWid;

            activeThumbImg.style.left = '-' + (updatedLeftCssAnimationForSlider) + 'px'; //'-' + imgOutWid + 'px';
            setLeftCssAnimationForSlider(updatedLeftCssAnimationForSlider);

            setVisibleThumbImagesWidth(updatedLeftCssAnimationForSlider);


        }


        let getVisImgIndex = slideIndex + n;

        if (getVisImgIndex === 1) {
            showHideHandler({ type: 'class', 'display': 'none', element: '.slide-btn.prev' })
        }

        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
            slides[i].classList.remove("active");
        }
        slides[getVisImgIndex - 1].classList.add('active')

        setSeenImages([...seenImages, getVisImgIndex])

        slides[getVisImgIndex - 1].style.display = "block";

        setSlideIndex((prev) => prev + n);
    }

    const showHideHandler = (params) => {
        let getElement = document.querySelector(params.element);

        if (getElement && getElement.style) {
            getElement.style.display = params.display;

        }
    }


    const thumbRight = (n) => {

        showHideHandler({ type: 'class', 'display': 'block', element: '.slide-btn.prev' })

        let slides = document.getElementsByClassName("mySlides");

        let imgOutWid = document.querySelector('.img-thumbnails ul li') && document.querySelector('.img-thumbnails ul li').offsetWidth;
        // var canvasW = document.querySelector('.img-thumbnails ul li') && document.querySelector('.img-thumbnails').offsetWidth;

        // let thumbnailContainerActualWidth = (sliderImages.length * imgOutWid) - leftCssAnimationForSlider;

        let totalImagesWidth = imgOutWid * (slides.length - 5);

        var getVisImgIndex = slideIndex

        let updatedLeftCssAnimationForSlider = leftCssAnimationForSlider;

        if (totalImagesWidth > updatedLeftCssAnimationForSlider) {
            let activeThumbImg = document.querySelector('.thumbs-img');
            if (imgOutWid) {
                updatedLeftCssAnimationForSlider += imgOutWid

                activeThumbImg.style.left = '-' + (updatedLeftCssAnimationForSlider) + 'px'; //'-' + imgOutWid + 'px';
                setLeftCssAnimationForSlider(updatedLeftCssAnimationForSlider);

                setVisibleThumbImagesWidth(updatedLeftCssAnimationForSlider);

            }
        }

        if (leftCssAnimationForSlider < imgOutWid) {

            if (getVisImgIndex === 0) {
                showHideHandler({ type: 'class', 'display': 'none', element: '.prev' })

            }
        }

        getVisImgIndex = slideIndex + n;

        if (getVisImgIndex > slides.length) { getVisImgIndex = 1 }
        if (getVisImgIndex < 1) { getVisImgIndex = slides.length };
        if (getVisImgIndex === slides.length) {
            showHideHandler({ type: 'class', 'display': 'none', element: '.slide-btn.next' })
        }

        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
            slides[i].classList.remove("active");
        }
        slides[getVisImgIndex - 1].classList.add('active')
        setSeenImages([...seenImages, getVisImgIndex])

        slides[getVisImgIndex - 1].style.display = "block";

        setSlideIndex((prev) => prev + n)


    }
    const thumbArrowClickHandler = (type) => {
        let imgOutWid = document.querySelector('.img-thumbnails ul li').offsetWidth;
        let canvasW = document.querySelector('.img-thumbnails').offsetWidth;
        let slides = document.getElementsByClassName("mySlides");
        let activeThumbImg = document.querySelector('.thumbs-img');

        let totalVisibleThumbsWidth = visibleThumbImagesWidth;

        let totalImagesWidth = imgOutWid * slides.length;

        let totalThumbImagesWidthWithoutInitialImagesWidth = totalImagesWidth - canvasW;

        // let hiddenImagesWidth = totalImagesWidth - canvasW ;        
        let updatedLeftCssAnimationForSlider = leftCssAnimationForSlider;


        if (type === 'left') {
            totalVisibleThumbsWidth -= canvasW;


            if (totalVisibleThumbsWidth > canvasW) {
                updatedLeftCssAnimationForSlider = totalVisibleThumbsWidth//updatedLeftCssAnimationForSlider - totalVisibleThumbsWidth
            } else {
                updatedLeftCssAnimationForSlider = 0//updatedLeftCssAnimationForSlider - hiddenImagesWidth
                totalVisibleThumbsWidth = 0
            }

        } else { //RIGHT
            totalVisibleThumbsWidth += canvasW;

            if (totalThumbImagesWidthWithoutInitialImagesWidth > totalVisibleThumbsWidth) {
                updatedLeftCssAnimationForSlider = totalVisibleThumbsWidth
            } else {
                updatedLeftCssAnimationForSlider = totalThumbImagesWidthWithoutInitialImagesWidth
                totalVisibleThumbsWidth = totalThumbImagesWidthWithoutInitialImagesWidth
            }

        }

        setVisibleThumbImagesWidth(totalVisibleThumbsWidth);

        activeThumbImg.style.left = '-' + (updatedLeftCssAnimationForSlider) + 'px'; //'-' + imgOutWid + 'px';
        setLeftCssAnimationForSlider(updatedLeftCssAnimationForSlider)
    }


    /**
     * IMAGE ACTION HANDLER
     * @param {DONE/CANCEL} action 
     */
    const imageActionHandler = async (action) => {
        if (imageAction === 'crop' || imageAction === 'blur') {
            if (action === 'done' && completedCrop) {
                props.setLoadingFromChild(true)

                setImageAction('normal');
                let img = document.querySelector('.mySlides.active');

                const currentImage = sliderImages[slideIndex - 1]
                const imageID = currentImage && currentImage[`image_id`]
                const tagID = currentImage && currentImage['tag_id']
                const imageName = currentImage && currentImage['image_name']

                const imageDetails = {
                    imageID,
                    tagID,
                    imageName
                }

                const vccID = queryParams.vccID

                const modifyImagePayload = {
                    imageDetails,
                    vccID,
                    'action': imageAction,
                    values: {
                        ...completedCrop, resizeW: img.width, resizeH: img.height
                    }
                }
                const modifyImage = await modifyCerificationImage(modifyImagePayload)

                if (modifyImage && modifyImage.status === 200) {
                    let getActiveImageDetails = sliderImages[slideIndex - 1];

                    let updatedSliderImages = JSON.parse(JSON.stringify(sliderImages))

                    for (let data of updatedSliderImages) {
                        if (data.tag_id === getActiveImageDetails.tag_id) {
                            data.image_path = modifyImage.data
                        }
                    }

                    //updating image data in redux state
                    updateImageDataInState(updatedSliderImages)
                }

                props.setLoadingFromChild(false)

            } else { //CACNEL
                setImageAction('normal');
            }
            //resetting crop or blur state
            setCrop()
            setCompletedCrop()
        }
    }

    const imageModifyIconHandler = async (type) => {
        setImageAction(type);

        //let img = document.querySelector('.mySlides.active');
        let imgBox = document.getElementById("scroll")
        let imgParent = document.querySelector('.mySlides.active');

        if (type === "zoomIn") {
            setZoomScale((val) => val + 1)
            zoomIn()
        } else if (type === "zoomOut") {
            if (zoomScale > 0) {
                setZoomScale((val) => val - 1)
            }
            zoomOut()
        } else if (type === 'rotate') {
            props.setLoadingFromChild(true)

            setImgBoxProperty('reset');


            const currentImage = sliderImages[slideIndex - 1]
            const imageID = currentImage && currentImage[`image_id`]
            const tagID = currentImage && currentImage['tag_id']
            const imageName = currentImage && currentImage['image_name']

            const imageDetails = {
                imageID,
                tagID,
                imageName
            }

            const vccID = queryParams.vccID
            const modifyImage = await modifyCerificationImage({ imageDetails, vccID, 'action': "rotate" })

            if (modifyImage && modifyImage.status === 200) {

                let getActiveImageDetails = sliderImages[slideIndex - 1];

                let updatedSliderImages = JSON.parse(JSON.stringify(sliderImages))

                for (let data of updatedSliderImages) {
                    if (data.tag_id === getActiveImageDetails.tag_id) {
                        data.image_path = modifyImage.data || getActiveImageDetails.image_path
                    }
                }

                //updating image data in redux state
                updateImageDataInState(updatedSliderImages)
                //SET IMG PROPERTY FOR SLIDER
                setImgBoxProperty('set');

            }

            props.setLoadingFromChild(false)

        } else if (type === 'reset') {

            imgParent.style.cssText = ''//`width: ${activeImageDimentions.myWidth}px; max-width: 'none'; height: ${activeImageDimentions.height}px`;

            imgBox.style.cssText = `height: ${activeImageDimentions.myheight}px`;
        }

    }

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget

        setCrop(centerAspectCrop(width, height, aspect));

        if (e.target.classList.contains('active') && imageAction === 'normal') {
            setActiveImageDimentions({ mywidth: width, myheight: height });
        }

        //SET STYLE PROPERTY TO CROP BOX
        if (["crop", "blur"].includes(imageAction)) {
            let cropperBox = document.getElementsByClassName("ReactCrop__child-wrapper");
            cropperBox[0].style.cssText = `height: ${activeImageDimentions.myheight}px`;

            if (imageAction === "blur") {
                let getCropperBox = document.getElementsByClassName('ReactCrop');

                getCropperBox[0].classList.add("ReactCrop--invisible-crop");
            }

            //case: user crops the images without resizing
            //picks cropperBox dimensions
            if (imageAction === 'crop' && !completedCrop) {

                let croppedDimension = {
                    width: (crop.width * activeImageDimentions.mywidth) / 100,
                    height: (crop.height * activeImageDimentions.myheight) / 100,
                    x: (crop.x * activeImageDimentions.mywidth) / 100,
                    y: (crop.y * activeImageDimentions.myheight) / 100
                }

                setCompletedCrop(croppedDimension)
            }
        }

    }

    const centerAspectCrop = (
        mediaWidth,
        mediaHeight,
        aspect,
    ) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }


    const zoomIn = () => {
        let imgParent = document.querySelector('.mySlides.active');
        let naturalW = imgParent.naturalWidth;
        let naturalH = imgParent.naturalHeight;

        naturalW = naturalW * 2;
        naturalH = naturalH * 2;


        var mywidth = imgParent.width;
        var myheight = imgParent.height;

        if (!Object.keys(activeImageDimentions).length) {
            setActiveImageDimentions({ mywidth, myheight, naturalH, naturalW })
        }

        let ratioCalH = parseFloat(naturalH / naturalW);

        document.querySelector('.mySlides.active').style.maxHeight = "none";

        let imgBox = document.getElementById("scroll")

        // IF IMAGE'S WIDTH IS GREATER
        if (mywidth < naturalW) {

            let calcHeight = Math.ceil((mywidth + 100) * ratioCalH);

            imgParent.style.cssText = `max-width: none; max-height: none; height: ${calcHeight}px; width: ${mywidth + 100}px;`;
            imgBox.style.cssText = `max-width: 'none'; height: ${calcHeight}px; `
        }
        // }
        // });
    }


    const zoomOut = () => {
        let imgParent = document.querySelector('.mySlides.active');
        let naturalW = document.querySelector('.mySlides.active').naturalWidth;
        let naturalH = document.querySelector('.mySlides.active').naturalHeight;

        if (naturalW !== '') {

            var mywidth = document.querySelector(".mySlides.active").width;

            let ratioCalH = parseFloat(naturalH / naturalW);
            let ratioCalW = parseFloat(naturalW / naturalH);

            var orgResizeW = Math.round(ratioCalW * 368);

            let imgBox = document.getElementById("scroll")

            if (orgResizeW !== '0') {
                mywidth = document.querySelector(".mySlides.active").width;

                if (mywidth > activeImageDimentions.mywidth) {
                    imgParent.style.cssText = `width: ${mywidth - 100}px; max-width: 'none'; height: ${(mywidth - 100) * ratioCalH}px`;
                    imgBox.style.cssText = `width: ${mywidth - 100}px; max-width: 'none'; height: ${(mywidth - 100) * ratioCalH}px`;
                }
            }
        }
    }

    const changeHandler = async (value) => {
        if (value) {
            props.setLoadingFromChild(true)

            let getActiveImageDetails = sliderImages[slideIndex - 1];

            let imageDetails = {
                image_id: (getActiveImageDetails && getActiveImageDetails.image_id) || '',
                tag_id: (value && value.id) || '',
                type: 'update'
            }

            let vccID = (window.location.pathname).split('/')[3] || '';

            let requestObj = {
                imageDetails: JSON.stringify([imageDetails]),
                vccID
            }

            const updateImageTag = await uploadQcImages(requestObj)

            if (updateImageTag && updateImageTag.status === 200) {

                let updatedSliderImages = JSON.parse(JSON.stringify(sliderImages))

                for (let data of updatedSliderImages) {
                    if (data.tag_id === getActiveImageDetails.tag_id) {
                        data.tag_id = value.id
                        data.tag_name = value.tag_name
                    }
                }

                //updating image data in redux state
                updateImageDataInState(updatedSliderImages)
                toast.success(t("DO_QC.TAG_UPDATED_SUCCESSFULLY"));

            }

            props.setLoadingFromChild(false)
            setShowTagDropdown(!showTagDropdown)
            setShowEditFlag(true)
        }
        else {
            setShowTagDropdown(!showTagDropdown)
            setShowEditFlag(true)
        }
    }


    const mouseMove = (e) => {

        let previousX = imgPositions.previousX;
        let previousY = imgPositions.previousY;

        let scrollElement = document.getElementById("scroll");

        if (mouseDownImage) {
            scrollElement.scrollLeft = (scrollElement.scrollLeft + (previousX - e.clientX));
            scrollElement.scrollTop = (scrollElement.scrollTop + (previousY - e.clientY));
            setImgPositions({ "previousX": e.clientX, "previousY": e.clientY });
        }
    }

    const updateImageDataInState = (newImageData) => {
        const getImageFormData = dealerQcState.qcPanelData.imageData[slideIndex - 1];
        const activeFormData = getImageFormData && getImageFormData.formData
        const qcPanelData = JSON.parse(JSON.stringify(dealerQcState.qcPanelData))
        qcPanelData.imageData = newImageData

        dispatch(updateQcPanelData({ "activeFormData": activeFormData, "qcPanelData": qcPanelData }))
    }

    return (

        <div className="vehicle-img-gallery">
            <div className="gallery-overlay"></div>
            <div className="tag-label">
                {


                    !showTagDropdown
                        ?
                        <span data-target="" className="tag-label-content">{sliderImages[slideIndex - 1] ? sliderImages[slideIndex - 1]['tag_name'] : ''}</span>
                        :
                        <Select
                            onChange={(val) => changeHandler(val)}
                            options={imageTagList}
                            className="react-select"
                            classNamePrefix='inspection-dropdown react-select'
                            isClearable={true}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.tag_name}
                            isSearchable={false}
                            value={tagValue}
                        />

                }
                {
                    (callFrom !== "ReQcPanel" && showEditFlag) ? <span className="tag-edit-btn" roll="button" onClick={() => {
                        setShowTagDropdown(!showTagDropdown)
                        setShowEditFlag(false)
                    }}>Edit</span> : ''
                }

            </div>
            <div className="tag-label tag-label-edit">
                <div className="pull-left select-div">
                    <select>

                    </select>
                </div>
            </div>
            <div className="control-btn docs-buttons">
                {
                    !['blur'].includes(imageAction)
                    &&
                    <div className="icon-crop imageEditContainerCropper" id="crop">
                        <img src={CropIcon} alt="Crop icon" title="Crop" onClick={() => imageModifyIconHandler('crop')} />
                    </div>
                }


                {
                    ['cropingDone', 'normal', 'zoomIn', 'zoomOut', 'rotate', 'reset'].includes(imageAction)
                    &&
                    <>
                        <div className="icon-plus">
                            <img src={ZoomInIcon} alt="Zoom in icon" title="Zoom in" onClick={() => imageModifyIconHandler('zoomIn')} />
                        </div>
                        <div className="icon-minus">
                            <img src={ZoomOutIcon} alt="Zoom out icon" title="Zoom out" onClick={() => imageModifyIconHandler('zoomOut')} />
                        </div>
                        <div className="icon-rotate">
                            <img src={RotateIcon} alt="Rotate icon" title="Rotate" onClick={() => imageModifyIconHandler('rotate')} />
                        </div>
                        <div className="icon-reset">
                            <img src={ResetIcon} alt="Reset icon" title="Reset" onClick={() => imageModifyIconHandler('reset')} />
                        </div>
                    </>

                }

                {
                    callFrom !== "ReQcPanel" && (!['crop'].includes(imageAction)) && <div className="icon-blur">
                        <img src={BlurIcon} alt="Blur icon" title="Blur" onClick={() => imageModifyIconHandler('blur')} />
                    </div>
                }



                {
                    (imageAction === 'crop' || imageAction === 'blur')
                    &&
                    <>
                        <div className="icon-done" id="ok" data-method="setDragMode" onClick={() => imageActionHandler('done')}>
                            <img src={DoneIcon} alt="Done icon" title="Done" />
                        </div>
                        <div className="icon-cancel" id="cancel" data-method="clear">
                            <img src={CancelIcon} alt="Cancel icon" title="Cancel" onClick={() => imageActionHandler('cancel')} />
                        </div>
                    </>
                }

            </div>

            <div id="main-pan" className="main-pan">
                <div id="scroll" className="main-pan-div" style={{ height: activeImageDimentions.myheight }} onMouseMove={(e) => mouseMove(e)} onMouseDown={(e) => { setMouseDownImage(true); setImgPositions({ "previousX": e.clientX, "previousY": e.clientY }) }} onMouseUp={() => setMouseDownImage(false)}>
                    {
                        sliderImages.map((val, key) => {
                            if (imageAction === 'normal') {
                                return (
                                    <img src={val.image_path} alt={val.tag_name} className={`mySlides ${slideIndex - 1 === key ? 'active' : ''}`} key={key} onLoad={onImageLoad} />
                                )
                            } else if (imageAction === 'crop') {
                                return <>
                                    {
                                        (slideIndex - 1) === key && <ReactCrop crop={crop} onChange={c => setCrop(c)} keepSelection={true}
                                            onComplete={(c) => setCompletedCrop(c)} aspect={aspect} minWidth="300" minHeight="170">
                                            <img src={val.image_path} onLoad={onImageLoad} ref={imgRef} className={`mySlides ${slideIndex - 1 === key ? 'active' : ''}`} key={key} alt="crop" />
                                        </ReactCrop>
                                    }


                                </>
                            } else if (imageAction === 'zoomIn' || imageAction === 'zoomOut') {
                                return <img src={val.image_path} className={`mySlides ${slideIndex - 1 === key ? 'active' : ''}`} key={key} onLoad={onImageLoad} alt="zoom" />
                            } else if (imageAction === 'blur') {
                                return <>
                                    {

                                        (slideIndex - 1) === key && <ReactCrop crop={crop} onChange={c => setCrop(c)}
                                            onComplete={(c) => setCompletedCrop(c)}
                                            className="blur-rectangle-box"
                                        >
                                            <img src={val.image_path} ref={imgRef} className={`mySlides ${slideIndex - 1 === key ? 'active' : ''}`} key={key} onLoad={onImageLoad} alt="blur" />
                                        </ReactCrop>
                                    }
                                </>

                            } else {

                                return <img src={val.image_path} className={`mySlides ${slideIndex - 1 === key ? 'active' : ''}`} key={key} onLoad={onImageLoad} alt="active car img" />
                            }
                        }

                        )
                    }


                </div>

                {(sliderImages && sliderImages.length > 1) && !["crop", "blur"].includes(imageAction) ?
                    <div className="gallery-control pull-left">
                        {(slideIndex && slideIndex > 1) ?
                            <div className="slide-btn prev" onClick={() => plusSlides(-1)}><img src={ArrowLeftIcon} alt="Slide left" title="Left" /></div>
                            : ''
                        }
                        <div className="slide-btn next" onClick={() => plusSlides(1)}><img src={ArrowRightIcon} alt="Slide right" title="Right" /></div>
                    </div> : ''
                }
            </div>


            {
                callFrom !== "ReQcPanel" &&
                <div className="img-thumbnails">

                    <ul className="thumbs-img" >
                        {
                            sliderImages.map((val, key) =>
                                <li className={`column ${slideIndex - 1 === key ? 'active' : ''}`} key={key} >

                                    <img className="demo cursor" src={val.image_path} onClick={() => imageThumbClickHandler(key)} alt="thumbnail" />
                                    <br />
                                    <span className="slider-tag-label">{val.tag_name}</span>


                                    {
                                        (seenImages.includes(key + 1)) && <span className="seen-tag">{t("DO_QC.SEEN")}</span>
                                    }


                                </li>
                            )
                        }

                    </ul>

                    <p className="thumb-control">
                        <span roll="button" className="thumb-prev" onClick={() => thumbArrowClickHandler('left')} ><img src={ArrowLeftIcon} alt="Right arrow" title="Left" /></span>
                        <span roll="button" className="thumb-next" onClick={() => thumbArrowClickHandler('right')}><img src={ArrowRightIcon} alt="Right arrow" title="Right" /></span>
                    </p>
                </div>
            }


        </div>

    )
}


export default ImageSlider