import React, { useState, useEffect } from 'react'
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { getCancelRequestDataByID } from "@src/store/slices/inspection"
import FullPageLoader from "@src/view/common/FullPageLoader"
import secureStorage from '@src/config/encrypt';

function UpdateForm(props) {

    const { t } = useTranslation("common")
    const [selectedFilters, setSelectedFilters] = useState(props || {})
    const [cancellChecked, setCancellChecked] = useState(true);
    const [revertChecked, setRevertChecked] = useState(false);
    const [errField, setErrField] = useState({});
    const [cancellData, SetCancellData] = useState('');
    const [loading, setLoading] = useState(true);
    const [activeField, setActiveField] = useState('');
    const [showCancell, setShowCanell] = useState(false);
    const [showRevert, setShowRevert] = useState(false);

    // ONLY CHECKBOX WILL SELECT AT A TIME OTHER WILL BE EMPTY
    const handleCheckboxChange1 = (event) => {
        if (selectedFilters.revertReason) {
            delete selectedFilters.revertReason;
        }
        setCancellChecked(event.target.checked);
        setRevertChecked(!event.target.checked)

    };

    // IF SECOND CHECKBOX SELECT THEN FIRST WILL BE EMPTY
    const handleCheckboxChange2 = (event) => {
        if (selectedFilters.cancelReason) {
            delete selectedFilters.cancelReason;
        }
        setRevertChecked(event.target.checked);
        setCancellChecked(!event.target.checked)

    };

    const changeHandler = (value, name) => {
        if (value) {
            let newVal = value.value;
            if (newVal) {
                setSelectedFilters({ ...selectedFilters, [name]: newVal });
            }
        } else {
            value = undefined;
            setSelectedFilters({ ...selectedFilters, [name]: value });
        }
    }

    // FOR VALIDATION
    const validForm = () => {
        let formIsValid = true;

        if ((!selectedFilters.cancelReason) && (!selectedFilters.revertReason)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                reasonCheck: t('VALIDATION.SELECT_REASON'),
            }));
        }

        return formIsValid

    };


    const submitUpdateRequest = () => {
        if (validForm()) {
            props.updateData({ ...selectedFilters, parentBookingID: props.updateAllData.parent_id })
        }
    }


    useEffect(() => {
        //IT WILL CHECK REUQEST IS UPDATED ALREADY OR NOT
        getCancelRequestDataByID({ booking_id: props.updateAllData.parent_id }).then((res) => {
            if (res.status === 200) {
                setLoading(false)
                SetCancellData(res.data[0]);
            } else {
                setLoading(true);
            }

        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

// here we write logic for revert and cancel button show hide during some condition in update page
    useEffect(() => {
        let authUserInfo = secureStorage.getItem('authUserInfo');
        // get params value all-new
        const path = window.location.pathname;
        const page = path.split('/').pop();

        let scheduleInt = 0;
        let scheduleTime = '';
        let inHouse = authUserInfo.is_inhouse;
        if (cancellData.inspection_schedule_time !== '') {
            scheduleTime = new Date(cancellData.inspection_schedule_time).getTime() / 1000;
            const datetime2 = Math.round(new Date().getTime() / 1000);
            const interval = Math.abs(scheduleTime - datetime2);
            scheduleInt = Math.round(interval / 60);
        }

        if (page === 'all' && ((scheduleInt > 60 && !inHouse) || inHouse)) {
            setShowRevert(true)
        }
        if ((page === 'all' || page === 'new') && ((scheduleInt > 60 && !inHouse) || inHouse)) {
            setShowCanell(true);
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>

            <FullPageLoader show={loading} />

            <div className="top-search-bar">
                {cancellData && cancellData.reg_no ?

                    (<div className="top-ribben-search">
                        <div>
                            <span> <b> {` ${t("FORM.REQUEST_ID")}  ${props.updateAllData.bookingID}`} </b> </span> &nbsp; &nbsp;
                            <span> <b> {` ${t("FORM.VEHICLE_REG")}  ${props.updateAllData.reg_no}`} </b> </span>
                        </div>

                        <div className='containerCheckBox'>
                            {showCancell &&
                                <div className="custom-control custom-checkbox CancelALLCheckBOX">
                                    {t("COMMON.CANCEL_ALL")}&nbsp;
                                    <input
                                        type="checkbox"
                                        checked={cancellChecked}
                                        onChange={handleCheckboxChange1}
                                        className="custom-control-input"
                                        id="cancelAll"
                                    />
                                    <label htmlFor="cancelAll" className="custom-control-label"></label>
                                </div>
                            }
                            {showRevert &&
                                <div className="custom-control custom-checkbox RevertALLCheckBOX">
                                    {t("COMMON.REVERT_ALL")}&nbsp;
                                    <input
                                        type="checkbox"
                                        checked={revertChecked}
                                        onChange={handleCheckboxChange2}
                                        className="custom-control-input"
                                        id="revertAll"
                                    />
                                    <label htmlFor="revertAll" className="custom-control-label"></label>
                                </div>
                            }

                        </div>


                        <div className="inspection-list-form">
                            {showCancell && cancellChecked &&
                                <fieldset className='seller-opt multiselect-dropDown'>
                                    <div className={(selectedFilters.cancelReason || activeField === 'cancelReason') ? "active-label material animation-effect" : "material animation-effect "}>

                                        <Select
                                            value={selectedFilters.cancelReason ? props.cancelReason.filter((ele) => '' + ele.value === '' + selectedFilters.cancelReason) : ''}
                                            onChange={(val) => changeHandler(val, "cancelReason")}
                                            options={props.cancelReason}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            isClearable={true}
                                            getOptionValue={(option) => option.value}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('cancelReason') }}

                                        />
                                        <label data-label={t("INSPECTION.CANCEL")} className="form-label"></label>
                                        {errField.reasonCheck && (
                                            <span className="erroe-txt" style={{ color: "red" }}>
                                                {errField.reasonCheck}
                                            </span>
                                        )}
                                    </div>
                                </fieldset>
                            }

                            {showRevert && revertChecked &&
                                <fieldset className='seller-opt multiselect-dropDown'>
                                    <div className={(selectedFilters.revertReason || activeField === 'revertReason') ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            value={selectedFilters.revertReason ? props.revertReason.filter((ele) => '' + ele.value === '' + selectedFilters.revertReason) : ''}
                                            onChange={(val) => changeHandler(val, "revertReason")}
                                            options={props.revertReason}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown react-select'
                                            isClearable={true}
                                            getOptionValue={(option) => option.value}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('revertReason') }}

                                        />
                                        <label data-label={t("INSPECTION.REVERT")} className="form-label"></label>
                                        {errField.reasonCheck && (
                                            <span className="erroe-txt" style={{ color: "red" }}>
                                                {errField.reasonCheck}
                                            </span>
                                        )}
                                    </div>
                                </fieldset>

                            }

                            <fieldset className='seller-opt btn-submit-reset'>
                                <button className="btn-primary" onClick={submitUpdateRequest}>{t("COMMON.SUBMIT_BTN")}</button>
                                {errField.reasonCheck &&
                                    <>
                                        <br />
                                        <br />
                                    </>
                                }
                            </fieldset>

                        </div>
                    </div>)

                    :
                    //IF ALREADY ISNPECTION UPDATE THEN IT WILL SHOW
                    (<div><h3>{t("INSPECTION.ALL_VEHICLE_DONE")}</h3></div>)
                }
            </div>
        </div>
    )
}

export default UpdateForm