import React, { useState, useEffect } from 'react'
import UpdateForm from "@src/view/inspection/common/UpdateForm"
import { updateRequestListing } from "@src/store/slices/inspection"
import { updateRequest } from "@src/store/slices/inspection"
import { useTranslation } from "react-i18next"
import PopupBox from '@src/view/common/PopupBox';
import { toast } from "react-toastify";
import FullPageLoader from "@src/view/common/FullPageLoader"
import secureStorage from '@src/config/encrypt';
import BulkHistoryTable from './common/TimeLine';

function UpdateInspection(props) {

    const [cancelReason, setCancelReason] = useState('');
    const [revertReason, setrevertReason] = useState('');
    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllUpdateList()
    }, []);


    //FETCH REASON LISTS
    const getAllUpdateList = () => {

        updateRequestListing().then((res) => {
            //            setLoading(false)
            if (res && res.status === 200) {

                if (res.data.CANCELL_ALL_REASON && res.data.CANCELL_ALL_REASON.length) {
                    setCancelReason(res.data.CANCELL_ALL_REASON);
                }
                if (res.data.REVERT_ALL_REASON && res.data.REVERT_ALL_REASON.length) {
                    setrevertReason(res.data.REVERT_ALL_REASON)
                }

            }
        }).catch(err => {
            ///           setLoading(false)
        })
    }


    // Finally This will Update the Data
    const updateData = (params = {}) => {

        let reason;
        if (params.cancelReason) {
            reason = params.cancelReason
            params.action = 'cancel'
        } else if (params.revertReason) {
            reason = params.revertReason
            params.action = 'revert'
        }

        //GET USER ID
        let fetchUserDetails = secureStorage.getItem('authUserInfo')
        let user_id = fetchUserDetails && fetchUserDetails.user_data

        params.name = 'updateRequest'
        params.reason = reason;
        params.user_id = user_id.user_id

        //REMOVE EXTRA PAYLOAD FROM OBJECT
        delete params.updateAllData;
        delete params.cancelReason;
        delete params.revertReason;
        delete params.bookingID;
        delete params.updateData;

        setLoading(true)

        updateRequest(params).then((res) => {
            setLoading(false)
            if (res && res.status === 200) {
                toast.success(res.message);
                props.setShowUpdatePopup(false);
            }
        }).catch(err => {
            setLoading(false)
        })
    }


    return (
        <div>
            <FullPageLoader show={loading} />

            <div className='update-request-popup-popup'>
                {/* FOR UPDATE MODAL */}
                <div className='updateModal'>
                    <PopupBox show={props.showUpdatePopup}
                        setShowUpdatePopup={props.setShowUpdatePopup}
                        updateAllData={props.updateData}
                    >
                        <div className="modal-header">

                        </div>
                        <div className="modal-body">
                            <div className='update-request-popup-content'>
                                <div className='bulk-upload-sec'>
                                    <h3>{t("COMMON.UPDATE_REQUEST")} </h3>

                                    <UpdateForm
                                        cancelReason={cancelReason}
                                        revertReason={revertReason}
                                        updateData={updateData}
                                        bookingID={props.bookingID}
                                        updateAllData={props.updateData}
                                    />
                                </div>
                                <BulkHistoryTable bookingID={props.updateData.parent_id} />
                            </div>
                        </div>

                    </PopupBox>
                </div>

            </div>
        </div>
    )
}

export default UpdateInspection