import { useEffect, useState } from "react";

import PopupBox from '@src/view/common/PopupBox';
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";
import Select from 'react-select';
import { uploadQcImages } from "@src/store/slices/dealerQc";
import { getImageTags } from "@src/store/slices/common";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import FullPageLoader from "@src/view/common/FullPageLoader";

const AddQcImageModal = (props) => {
    const { t } = useTranslation("common");
    let { show } = props;
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageTagList, setImageTagList] = useState([]);
    const [imageTags, setImageTags] = useState([]);

    const dispatch = useDispatch();
    const queryParams = useParams();

    useEffect(() => {
        setFiles([])
        setImageTags([])

        if (show) {
            getImageTagDropdown()
        }
    }, [show]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * GET IMAGE TAG DROPDOWN
     */
    const getImageTagDropdown = async () => {
        let payload = {
            "imageType": 'all',
            "vehicleType": "car"
        }
        dispatch(getImageTags(payload)).then((response) => {
            setImageTagList(response.data);
        })
    }


    /**
     * FILE UPLOAD
     */
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            let validreq = true;
            if (acceptedFiles.length > 6) {
                toast.error(t(`Maximum 6 files are allowed`));
                return false;
            }

            acceptedFiles.forEach((file) => {
                let image_size = parseInt(file.size, 10);
                if (!['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
                    toast.error(t(`VALIDATION.VALID_IMAGE_TYPE`));
                    validreq = false;
                }
                else if (image_size < 25000 || image_size > 8000000) {
                    toast.error(t(`VALIDATION.VALID_IMAGE_SIZE`));
                    validreq = false;
                }
            })
            let uploadFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            if (validreq) {
                setFiles([...files, ...uploadFiles]);
            }
        }, multiple: true
    });

    /**
     * HANDLE SUBMIT 
     * @returns 
     */
    const handleSubmitBtnClick = async () => {
        if (!files.length) {
            toast.error("Please select at least one valid image file.");
            return false;
        }

        if (files.length > 6) {
            toast.error("Maximum attachment limit exceed");
            return false;
        }

        if (imageTags.length !== files.length) {
            toast.error("Please select tags.");
            return false;
        }

        setLoading(true);
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`files`, file);
        });

        formData.append('vccID', queryParams.vccID);
        formData.append('imageTags', JSON.stringify(imageTags));
        formData.append('callonpage', 'qcpanel')

        //UPLOAD IMAGE API
        uploadQcImages(formData).then(res => {
            setLoading(false);
            if (res.status === 200) {
                //toast.success(res.message);
                //RESET STATE DATA
                setImageTags([]);
                setFiles([]);
                props.setShowUpdatePopup(false);

                let totalImageCnt = res.data.totalImage || ''
                let imageUploadCnt = res.data.uploaded || ''
                let addMsgInResp = (imageUploadCnt && totalImageCnt) ? `${imageUploadCnt} Out Of ${totalImageCnt} ` : ''

                toast.success(addMsgInResp + t(`COMMON.IMG_UPLOADED_SUCCESSFULLY`))
                props.setReloadFromChild(true)
            }
            else {
                toast.error(t("COMMON.IMG_UPLOAD_FAILED"));
            }

        }).catch(err => setLoading(false));


    }

    /**
     * DELETE SELECTED IMAGE
     * @param {*} index 
     */
    const delSelectedImage = (index) => {
        delete files[index];
        delete imageTags[index];

        let updatedFiles = files.filter(file => file);
        let updatedSelectedTags = imageTags.filter(tag => tag);

        setFiles(updatedFiles);
        setImageTags(updatedSelectedTags);
    }

    /**
     * IMAGE TAG UPDATE HANDLER
     * @param {*} value 
     * @param {*} index 
     */
    const handleImageTagChange = (value, index) => {
        let currentSelectedImageTags = imageTags
        currentSelectedImageTags[index] = value.id
        setImageTags(currentSelectedImageTags);
    }

    return (

        // showImageModal &&

        <div className='update-request-popup-popup'>
            <FullPageLoader show={loading} />

            <PopupBox show={show} setShowUpdatePopup={() => props.setShowUpdatePopup(false)}>
                <div className="modal-header">

                </div>
                <div className="modal-body">
                    <div className='update-request-popup-content'>
                        <div className='bulk-upload-sec'>
                            <h3>Add Files</h3>


                            <div className="dropzone-image-add" key={"new_img"}>
                                <div className="m-xl-b">
                                    {/* <div className= "dropzone-outer" {...getRootProps({ className: 'dropzone' })} className={`${!files.length && "add-new-image"}`}> */}
                                    <div className={!files.length ? 'dropzone-outer add-new-image' : "dropzone-outer"} {...getRootProps({ className: 'dropzone' })} >

                                        {!files.length && <span>{t("INSPECTION.DROP_FILES")}</span>}
                                        {!files.length && <p className="error-note">{"Maximum 6 files allowed."}</p>}

                                        <input type="file" accept="image/*" {...getInputProps()} className="upload-image-input" id="upload-image-input" />



                                    </div>

                                    {
                                        files.length > 0 && files.map((item, key) =>
                                            <div className="car-image-container" key={key}>
                                                <div className="car-gallery">
                                                    <i className="ic-clearclose delete-up-img" onClick={(val) => delSelectedImage(key)}></i>

                                                    <div className="img-outer car-image-box" style={{ "background": ` url(${item.preview}) no-repeat right 0 center / cover` }}>
                                                        {/* <img src={item.image_path} className="" /> */}
                                                    </div>

                                                    <fieldset className="single-select image-tag-select">
                                                        <div className={''}>
                                                            <Select
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                isSearchable={false}
                                                                options={imageTagList}
                                                                getOptionValue={(option) => option.id}
                                                                getOptionLabel={(option) => option.tag_name}
                                                                onChange={(val) => handleImageTagChange(val, key)}
                                                                menuPlacement="top"

                                                            />
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="col-md-12 btn-submit-cancel" key={"submit_btn"}>
                                {
                                    files.length && !loading
                                        ?
                                        <button type="button" className="btn-primary" onClick={(e) => handleSubmitBtnClick(e)}>{t("INSPECTION.SUBMIT")}</button>
                                        :
                                        <button type="button" className="btn-primary" disabled>{t("INSPECTION.SUBMIT")}</button>

                                }
                            </div>
                        </div>
                    </div>
                </div>

            </PopupBox>
        </div>



    )
}


export default AddQcImageModal;