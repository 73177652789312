import MasterService from '@src/services/MasterService';

export const RosterService = {
    ...MasterService,

    generateRosterReport(params) {
        return this.post('/roster/generateRosterReport', params)
    },

    rosterTemplate(params) {
        return this.post('/roster/rosterTemplate', params)
    },

    uploadCsv(params, headers) {
        return this.post('/roster/uploadCSV', params, headers)
    },

}