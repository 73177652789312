import Navigation from "@src/view/common/Navigation";

const Header = () => {

    let pathname = window.location.pathname;

    return (

        (!pathname.includes('login') &&
            !pathname.includes('/inspection/certification-report-html/') &&
            !pathname.includes('/inspection/view-report') &&
            !pathname.includes('/inspection/view-status') &&
            !pathname.includes('/inspection/raise-request') &&
            !pathname.includes('remove-info')

        )
            ?
            <header>
                <Navigation />
            </header>
            :
            ''

    )

}



export default Header;