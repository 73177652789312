import Login from '@src/view/auth/Login'
import Dashboard from '@src/view/dashboard/Dashboard'
import NotFoundPage from '@src/view/common/404Page';
import ManageUser from '@src/view/user/ManageUser';
import CeList from '@src/view/user/CeList';
import AddEditUser from '@src/view/user/AddEditUser';
import ManageInspection from '@src/view/inspection/ManageInspection';
import AddEditCE from '@src/view/user/AddEditCE';
import QcPanel from '@src/view/dealer-qc/qc/QcPanel';
import EditInspectedCar from '@src/view/back_office/EditInspectedCar'
import Dealerqc from '@src/view/back_office/Dealerqc'
import PrivateRoute from './privateRoute';
import BeforeLogin from './beforeLogin';
import { Routes, Route} from 'react-router-dom';
import Header from "@src/view/common/Header";
import Footer from "@src/view/common/Footer";
import AddInspection from '@src/view/inspection/AddInspection'
import CeAssignment from '@src/view/inspection/AssignCe/CeAssignment'
import CorrectionPanel from '@src/view/dealer-qc/sent-correction/CorrectionPanel';
import ReQcPanel from '@src/view/dealer-qc/re-qc/ReQcPanel';
import InspectionHtmlReport from '@src/view/back_office/reports/InspectionHtmlReport';
import InspectionReports from '@src/view/back_office/reports/InspectionReports';
import InspectionList from '@src/view/inspection/AssignCe/InspectionList';
import RosterReport from '@src/view/roster/Report';
import RosterTemplate from '@src/view/roster/RosterTemplate';
import { useLocation } from "react-router";
import ViewStatus from '@src/view/inspection/ViewStatus';
import RaiseRequest from '@src/view/inspection/RaiseRequest';
import RemoveInfo from '@src/view/auth/Remove_info';

const AppRoutes = () => {

    const location = useLocation();

    return (
        <>
            {/* Render the Header component */}
            <Header />

            {/* Main content area */}
            <div style={{ minHeight: 500 }}>
                {/* Define the routes */}
                <Routes location={location} key={location.pathname}>
                    {/* Public routes */}
                    <Route path="/login" element={<Login />} />

                    {/* Protected routes */}
                    <Route path="/dashboard" element={<PrivateRoute components={Dashboard} accessPageId="dashboard" />} />
                    <Route path="/user/list" element={<PrivateRoute components={ManageUser} />} />
                    <Route path="/user/add" element={<PrivateRoute components={AddEditUser} />} />
                    <Route path="/user/edit/:userId" element={<PrivateRoute components={AddEditUser} accessPageId="34" />} />
                    <Route path="/user/ce_list" element={<PrivateRoute components={CeList} />} />
                    <Route path="/user/ce_add" element={<PrivateRoute components={AddEditCE} />} />
                    <Route path="/user/ce_edit/:userId" element={<PrivateRoute components={AddEditCE} accessPageId="35" />} />
                    <Route path="/inspection/request" element={<PrivateRoute components={ManageInspection} accessPageId="57" />} />
                    <Route path="/qc/qc-panel/:vccID" element={<PrivateRoute components={QcPanel} accessPageId="46" />} />
                    <Route path="/inspection/add-request" element={<PrivateRoute components={AddInspection} />} />
                    <Route path="/cars/inspection/:vcc" element={<PrivateRoute components={EditInspectedCar} accessPageId="55" />} />
                    <Route path="/qc/dealer" element={<PrivateRoute components={Dealerqc} />} />
                    <Route path="/qc/reqcimageuploader/:vccID" element={<PrivateRoute components={CorrectionPanel} accessPageId="46" />} />
                    <Route path="/qc/reqcpanel/:vccID" element={<PrivateRoute components={ReQcPanel} accessPageId="46" />} />
                    <Route path="/inspection/ce-assignment/:bookingId/:type" element={<PrivateRoute components={CeAssignment} accessPageId="59" />} />
                    <Route path="/inspection/certification-report-html/:vcc" element={<BeforeLogin components={InspectionHtmlReport} />} />
                    <Route path="/inspection/inspection-list/:ceId" element={<PrivateRoute components={InspectionList} accessPageId="59" />} />
                    <Route path="/inspection/view-status" element={<PrivateRoute components={ViewStatus} />} />      
                    <Route path="/inspection/raise-request" element={<PrivateRoute components={RaiseRequest} />} />             
                    <Route path="/inspection/view-report" element={<BeforeLogin components={InspectionReports} />} />
                    <Route path="/" element={<PrivateRoute components={Dashboard} accessPageId="dashboard" />} />
                    <Route path="/roster/report" element={<PrivateRoute components={RosterReport} />} />
                    <Route path="/roster/roster-management" element={<PrivateRoute components={RosterTemplate} />} />
                    <Route path="/remove-info" element={<RemoveInfo />} />
                    {/* Catch-all route */}
                    <Route path="*" element={<BeforeLogin components={NotFoundPage} />} />
                </Routes>
            </div>

            {/* Render the Footer component */}
            <Footer />
        </>
    );
}

export default AppRoutes;
