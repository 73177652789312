import MasterService from '@src/services/MasterService';


export const DealerQcService = {
    ...MasterService,

    uploadQcImage(params) {
        return this.post('/vehicle/uploadQcImage', params);
    },
    getDocumentReportData(params) {
        return this.post('/report/getDocumentReport', params);
    },
    getCertificationReportPdf(params) {
        return this.get('/report/getCertificationReportPdf', params);
    },
    getInspectionReportPdf(params) {
        return this.post('/report/getCertificationReportPdf', params);
    },
    
    updateQcData(params) {
        return this.post('/vehicle/updateQcData', params);
    },

    getSentForCorrectionPanelData(params) {
        return this.post('/vehicle/getSentForCorrectionPanelData', params);
    },

    saveReQCData(params) {
        return this.post('/vehicle/saveReQCData', params);
    },
    
    reQcImgData(params) {
        return this.post('/vehicle/reQcImgData', params);
    },
    addFinishTime(params){
        return this.post('/vehicle/addFinishTime', params);
    },
    checkUploadFinishStatus(params){
        return this.post('/vehicle/checkUploadFinishStatus', params);
    },
    modifyCerificationImage(params) {
        return this.post('/backOffice/modifyCerificationImage', params)
    },
    getQcPanelData(params) {
        return this.post('/vehicle/getQcPanelData', params)
    },
    getAllReQcPanelData(params) {
        return this.post('/vehicle/getReQcRequiredPanelData', params)
    }


}