import secureStorage from '../../config/encrypt';
import { UserService } from '../../services';

import { createSlice } from '@reduxjs/toolkit'

import { toast } from "react-toastify";


const user = createSlice({
    name: 'user',
    initialState: {
        user: {},
        cities: []
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.authUser = action.payload;
            state.login = true;
            state.redirectTo = '/'

        },
        logoutSuccess: (state, action) => {
            state.loggedIn = false;
            state.loggedOut = true;
        },
        userRoles: (state, action) => {
            state.userRoles = action.payload;
        },
        userListing: (state, action) => {
            state.userListing = action.payload;
        },
        userSelectedFilter: (state, action) => {
            state.selectedFilters = action.payload
        }
    },
});

export default user.reducer;

const { loginSuccess, userRoles, userListing, userSelectedFilter } = user.actions


export const loadloginCaptcha = () => {
    return new Promise((resolve, reject) => {
        UserService.loginCaptcha()
            .then(
                captcha => {
                    resolve(captcha)
                },
                error => {
                    reject(error)
                }
            )
    })
}


export const createOtp = (params) => {

    return new Promise((resolve, reject) => {
        UserService.createOtp(params)
            .then(
                user => {
                    if (user && user.data && user.data.status === 200) {
                        toast.success(user.data.message);
                        return resolve(user.data)
                    }
                    else {
                        toast.error(user.data.message);
                        return resolve(user && user.data)
                    }

                },
                error => {
                    reject(error);
                }
            );
    });

}


export const VerifyOtpHandler = (params) => async dispatch => {

    return new Promise((resolve, reject) => {
        UserService.VerifyOtp(params)
            .then(
                user => {
                    if (user && user.data && user.data.status === 200) {

                        let ob = user.data.data;
                        secureStorage.setItem('authUserInfo', (ob));
                        toast.success(user.data.message);
                        dispatch(loginSuccess(ob));
                        resolve(user.data)
                    }
                    else {
                        toast.error(user.data.message);
                        resolve(user && user.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

/**
 * API call to fetch list of all users
 * @param {object} params 
 * @returns 
 */
export const getAllUsers = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        UserService.getAllUsers(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(userListing(response.data));
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

/**
 * API call to get roles available
 * @param {object} params 
 * @returns 
 */
export const getUserRoles = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        UserService.getUserRoles(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(userRoles(response.data))
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

/**
 * API call to get list of access available
 * @param {object} params 
 * @returns 
 */
export const getUserAccess = (params) => {
    return new Promise((resolve, reject) => {
        UserService.getUserAccess(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

/**
 * API call to update user data
 * @param {object} params 
 * @returns 
 */
export const updateUserData = (params) => {
    return new Promise((resolve, reject) => {
        UserService.updateUserData(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        toast.success(response.data.message);
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


/**
 * API call to add new user
 * @param {object} params 
 * @returns 
 */
export const addNewUser = (params) => {
    return new Promise((resolve, reject) => {
        UserService.addUser(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 201) {
                        toast.success(response.data.message);
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


/**
 * API call to get user details by id
 * @param {object} params 
 * @returns 
 */
export const getUserById = (params) => {
    return new Promise((resolve, reject) => {
        UserService.getUserById(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


/**
 * API call to export user data
 * @param {object} params 
 * @returns 
 */
export const exportUserData = (params) => {
    return new Promise((resolve, reject) => {
        UserService.exportUserData(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


/**
 * function to update Gallery flag on CE listing Page
 * @param {Object} filters 
 */
export const updateGallery = (params) => {
    return new Promise((resolve, reject) => {
        UserService.updateGalleryAccess(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


/**
 * function to update searchfilters object in Redux
 * @param {Object} filters 
 */
export const userFilters = (filters) => async dispatch => {
    if (filters) {
        dispatch(userSelectedFilter(filters))
    }
}

/**
 * function to update Gallery flag on CE listing Page
 * @param {Object} filters 
 */
export const removeUserImage = (params) => {
    return new Promise((resolve, reject) => {
        UserService.removeUserImage(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

export const viewBookingRqstData = (params) => {
    return new Promise((resolve, reject) => {
        UserService.viewBookingRqstData(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

export const checkAuth = () => {
    UserService.checkAuth();
}

export const remove_info = (params) => {

    return new Promise((resolve, reject) => {
        UserService.remove_info(params)
            .then(
                user => {
                    if (user && user.data && user.data.status === 200) {
                        toast.success(user.data.message);
                        return resolve(user.data)
                    }
                    else {
                        toast.error(user.data.message);
                        return resolve(user && user.data)
                    }

                },
                error => {
                    reject(error);
                }
            );
    });

}
export const RemoveVerifyOtpHandler = (params) => async dispatch => {

    return new Promise((resolve, reject) => {
        UserService.RemoveVerifyOtp(params)
            .then(
                user => {
                    if (user && user.data && user.data.status === 200) {
                        resolve(user.data)
                    }
                    else {
                        toast.error(user.data.message);
                        resolve(user.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}