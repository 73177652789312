import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

const DropZoneImageUpload = (props) => {
    const { t } = useTranslation("common");
    const [files, setFiles] = useState([]);

    const { title, imgSrc } = props;

    /**
     * FILE UPLOAD
     */
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            let validreq = true;
            acceptedFiles.forEach((file) => {
                let image_size = parseInt(file.size, 10);
                if (!['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
                    toast.error(t(`VALIDATION.VALID_IMAGE_TYPE`));
                    validreq = false;
                }
                else if (image_size < 25000 || image_size > 8000000) {
                    toast.error(t(`VALIDATION.VALID_IMAGE_SIZE`));
                    validreq = false;
                }
            })
            let uploadFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            if (validreq) {

                setFiles([...uploadFiles]);
            }
        }, multiple: true
    });

    useEffect(()=>{
        if(files.length){
            props.updateDropzoneSelectedImages(files);
        }
    }, [files]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div {...getRootProps({ className: 'dropzone' })} className="add-new-image">
                <input type="file" accept="image/*" {...getInputProps()} className="upload-image-input" id="upload-image-input" />
                {
                    title
                    ?
                        <>
                            <i className="ic-add"> </i>
                            <span>{title}</span>
                        </>
                    :
                        <img src={imgSrc} alt={imgSrc} />

                }
            </div>
        </>
    );

}

export default DropZoneImageUpload;
