import MasterService from '@src/services/MasterService';


export const UserService = {
    ...MasterService,

    VerifyOtp(params) {
        return this.post('/userAdminAuth/otpVerify', params)
    },
    createOtp(params) {
        return this.post('/userAdminAuth/otpCreate', params)
    },
    loginCaptcha() {
        return this.get('/userAdminAuth/getCaptchaImage')
    },
    getAllUsers(params) {
        return this.post('/user/getAllUsers', params)
    },
    getUserRoles(params) {
        return this.post('/user/getUserRoles', params)
    },
    getUserAccess(params) {
        return this.post('/user/getUserAccess', params)
    },
    updateUserData(params) {
        return this.post('/user/editUser', params)
    },
    getUserById(params) {
        return this.post('/user/getUserById', params)
    },
    addUser(params) {
        return this.post('/user/addUser', params)
    },
    exportUserData(params) {
        return this.post('/user/exportUserData', params)
    },
    updateGalleryAccess(params) {
        return this.post('/user/updateGalleryAccess', params)
    },
    removeUserImage(params) {
        return this.post('/user/removeUserImage', params)
    },
    viewBookingRqstData(params) {
        return this.post('/api/viewBookingRqstData', params)
    },
    checkAuth() {
        return this.post('/user/checkAuth', {})
    },
    remove_info(params) {
        return this.post('/userAdminAuth/remove-info', params)
    },
    RemoveVerifyOtp(params) {
        return this.post('/userAdminAuth/remove-otpVerify', params)
    },

}