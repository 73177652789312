import { useEffect, useState } from "react"

import ImageSlider from '@src/view/dealer-qc/common/ImageSlider';
import TopDetailsBar from '@src/view/dealer-qc/common/TopDetailsBar';
import QcPanelRightBar from '@src/view/dealer-qc/qc/QcPanelRightBar';
import { useNavigate } from 'react-router';
import FullPageLoader from "@src/view/common/FullPageLoader";
import { useDispatch } from "react-redux";
import TopActionBtn from "@src/view/common/TopActionBar";
import { getAllReQcPanelData } from "@src/store/slices/dealerQc";
import { useParams } from 'react-router-dom';

const ReQcPanel = (props) => {

    const [loading, setLoading] = useState(false);
    const [vehicleData, setVehicleData] = useState({});
    const imageTagList = [];
    const [imageRejectReasons, setImageRejectReasons] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { vccID } = useParams();

    useEffect(() => {
        // let vccID = props.match.params.vcc;
        setLoading(true);

        dispatch(getAllReQcPanelData({ certificationID: vccID })).then(res => {
            //preparing imageRejectReasonsArray
            if (res && res.status === 200 && res.data && res.data.imageData) {
                setLoading(false);
                setVehicleData(res.data);
                const imageData = res.data.imageData

                let updatedArray = []

                for (let data of imageData) {
                    const isTagExist = updatedArray && updatedArray.findIndex((e) => e.tag_id === data.tag_id)

                    if (isTagExist === -1) {
                        let obj = {
                            tag_id: data.tag_id,
                            imageStatus: 'Satisfactory',
                        }
                        updatedArray.push(obj)
                    }
                }

                setImageRejectReasons(updatedArray)
            }
            // if RE-QC is already done then show message and then go back one page.
            else{
                setLoading(true);
                setTimeout(function() {
                    navigate(-1);
                    //history.goBack();
                  }, 5000);
            }

        }).catch(err => setLoading(false))

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const setLoadingFromChild = (loading) => {
        setLoading(loading)
    }


    return (
        <div className="container m-xl-t correction-panel">

            <FullPageLoader show={loading} />

            <div className="top-sec">
                <TopActionBtn heading={("Re-QC Panel QC Checklist")}

                />
            </div>

            <TopDetailsBar vehicleData={vehicleData} imageTagList={imageTagList} callFrom={"ReQcPanel"} />

            {
                Object.keys(vehicleData).length > 0 &&
                <div className="col-md-12 edit-user-sec">
                    <div className="col-md-8">
                        <ImageSlider vehicleData={vehicleData} imageTagList={imageTagList} setLoadingFromChild={setLoadingFromChild} callFrom={"ReQcPanel"} />
                    </div>

                    <div className="col-md-4">
                        <QcPanelRightBar vehicleData={vehicleData} callFrom={"ReQcPanel"} imageRejectReasons={imageRejectReasons} setLoadingFromChild={setLoadingFromChild} />
                    </div>
                </div>
            }


        </div >

    )
}


export default ReQcPanel;