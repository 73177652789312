import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import Pagination from '@src/view/common/Pagination'
import { setSchedulerData } from '@src/store/slices/common';
import { useSelector, useDispatch } from 'react-redux';
import UpdateInspection from '@src/view/inspection/UpdateInspection';
import moment from 'moment';
import Select from "react-select"
import { updateRequestListing, updateRequest } from "@src/store/slices/inspection"
import BannerPreview from "@src/view/cards/BannerPreview"
import secureStorage from '@src/config/encrypt';
import { toast } from "react-toastify";
import FullPageLoader from "@src/view/common/FullPageLoader";
import * as HELPER from "@src/config/helper"

const InspectionRequestTable = (props) => {

    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState([])
    const [paginationData, setPaginationData] = useState({})
    const page = 1
    const totalLengthWithoutPagination = 0
    const [isChecked, setIsChecked] = useState(false);
    const [selectedSchedulers, setSelectedSchedulers] = useState([]);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false)
    const isReschedule = ["Fresh", "Reschedule"];
    const [tableShow, setTableShow] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const dispatch = useDispatch();
    const [updateData, setUpdateData] = useState('');
    const [subData, setSubData] = useState({});
    const commonState = useSelector(state => state.common);
    const authUserInfo = secureStorage.getItem('authUserInfo');
    const admin_id = authUserInfo && authUserInfo.user_data ? authUserInfo.user_data.admin_id : null;
    const [cancelbooking, setCancelbooking] = useState(0);
    const [selectedFilters, setSelectedFilters] = useState({})


    /**
     * calls parent component for fetching updated user listing
     * @param {object} params 
     */

    const handleRequestData = (params) => {
        props.getInspectionListing(params)
    }


    /**
     * Setting table data and paging data and updating component when Inspection lisiting updates
     */
    useEffect(() => {
        dispatch(setSchedulerData([]));
        setTableData(props.apiResponse.data)
        setPaginationData(props.apiResponse.pagination)
        // if common state is empty than select all is unchecked and if its leght equal to table data then it checked
        if (props.apiResponse && props.apiResponse.data) {
            if (commonState.schedulerData && commonState.schedulerData.length && commonState.schedulerData.length === props.apiResponse.data) {
                setIsChecked(true);
            }
            else {
                setIsChecked(false);
            }
        }

    }, [props.apiResponse]) // eslint-disable-line react-hooks/exhaustive-deps

    const runPaginationFunc = () => { };



    // Multicheckbox Logic
    const handleToggleAll = (e) => {
        setIsChecked(!isChecked);
        setTableData(
            tableData && tableData.map((row) => ({
                ...row,
                checkbox_status: !isChecked,
            }))
        );

        let updatedSchedulers = e.target.checked ? tableData && tableData.map(v => v.bookingID) : [];

        setSelectedSchedulers(updatedSchedulers)
        dispatch(setSchedulerData(updatedSchedulers));

        if (updatedSchedulers && updatedSchedulers.length === tableData && tableData.length) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    };


    // For SINGLE checkBOX
    const handleToggleRow = (event, id) => {
        let updatedSchedulers = event.target.checked ? [...selectedSchedulers, id] : selectedSchedulers.filter(v => v !== id);
        setSelectedSchedulers(updatedSchedulers)
        dispatch(setSchedulerData(updatedSchedulers));
        let UpdateTableData = tableData.map((row) => ({
            ...row,
            checkbox_status: +row.bookingID === +id ? !row.checkbox_status : row.checkbox_status,
        }))

        setTableData(UpdateTableData);

        // Count Length If Equal To Array Then Check Else Uncheck

        const filteredtableDate = UpdateTableData.filter((num) => num.checkbox_status !== undefined);
        const finalTblDataArr = filteredtableDate.map((val) => val.checkbox_status)
        const countTrueVal = finalTblDataArr.reduce((count, val) => val ? count + 1 : count, 0);
        const countFalseVal = finalTblDataArr.reduce((count, val) => !val ? count + 1 : count, 0);

        /* ARRAY LENGTH equal to true then SELECT ALL checked 
        *  AND if LENGTH equal to false then Assign Input field hide
        *  ELSE  ALL SELECT is unchecked
        */

        if (UpdateTableData.length === countTrueVal) {
            setIsChecked(true)
        }
        else if (UpdateTableData.length === countFalseVal) {
            dispatch(setSchedulerData([]));
        }
        else {
            setIsChecked(false)
        }


    };


    const ShowModal = (data) => {
        if (data) {
            setUpdateData(data)
        }
        setShowUpdatePopup(true)

    }

    /**
     * Handle Vehicle Detail Click Toggle
     * @param {*} item 
     */
    const toggleTable = (item) => {
        setTableShow(0);
        setCancelbooking(0);

        if (!tableShow || (item.bookingID !== tableShow)) {
            setTableShow(item.bookingID);
            setSubData(item);
            getAllUpdateList();
        }
    };

    /**
     * Handle Cancel Btn Click
     * @param {*} item 
     */

    const cancelRequestBooking = (bookingID, action) => {

        if (action === "open") {
            setCancelbooking(bookingID)
        } else if (action === "submit") {
            if (validForm()) {
                setLoading(true);

                let params = {};
                let fetchUserDetails = secureStorage.getItem('authUserInfo')
                let user_id = fetchUserDetails && fetchUserDetails.user_data
                params.name = 'updateRequest'
                params.action = 'cancel'
                params.reason = selectedFilters && selectedFilters.cancelReason ? selectedFilters.cancelReason : '';
                params.user_id = user_id.user_id
                params.parentBookingID = bookingID
                updateRequest(params).then((res) => {
                    setLoading(false);

                    if (res && res.status === 200) {
                        toast.success(res.message);

                        setCancelbooking(0)

                        //REFRESH LISTING
                        props.getInspectionListing(props.filterData)
                    }
                }).catch(err => {
                    setLoading(false);
                })

            }
        } else {
            setCancelbooking(0)
        }


    }


    //Fetch Reason Lists
    const getAllUpdateList = () => {
        updateRequestListing().then((res) => {
            if (res && res.status === 200) {
                if (res.data.CANCELL_ALL_REASON && res.data.CANCELL_ALL_REASON.length) {
                    setCancelReason(res.data.CANCELL_ALL_REASON);
                }

            }
        }).catch(err => {
        })
    }

    const updateCE = (id, type) => {
        let redirectTo = `/inspection/ce-assignment/${id}/${type}`;
        window.open(redirectTo, '_blank');

    }

    //Handle input field
    const changeHandler = (value, name) => {
        if (value) {
            let newVal = value.value;
            if (newVal) {
                setSelectedFilters({ ...selectedFilters, [name]: newVal });
            }
        } else {
            value = undefined;
            setSelectedFilters({ ...selectedFilters, [name]: value });
        }
    }


    // FOR VALIDATION
    const validForm = () => {
        let formIsValid = true;
        if (!selectedFilters.cancelReason) {
            formIsValid = false;
            toast.error(t('VALIDATION.SELECT_REASON'))
        }

        return formIsValid

    };



    return (
        <>
            <div className='inspection-detail-table'>
                <FullPageLoader show={loading} />

                <div className='data-table'>
                    <table>
                        <thead>
                            <tr>
                                {props.newInspectionRequest &&
                                    <th>
                                        <li className="custom-control custom-checkbox">
                                            <input type="checkbox"
                                                checked={isChecked}
                                                onChange={(e) => handleToggleAll(e)}
                                                className="custom-control-input"
                                            />
                                            <label htmlFor="sli0" className="custom-control-label"></label>
                                        </li>
                                    </th>
                                }

                                <th>{t("FORM.REQUEST_DETAIL")}</th>
                                <th>{t("FORM.CLIENT_DETAIL")}</th>
                                <th>{t("FORM.SCHEDULE_DATETIME")}</th>
                                <th>{t("FORM.VEHICLE_DETAILS")}</th>
                                <th>{t("FORM.ACTION")}</th>
                                <th>{t("FORM.DURATION")}</th>
                                {props.allInspectionRequest &&
                                    <th>{t("FORM.CE_DETAIL")} </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {

                                tableData && tableData.length
                                    ?
                                    tableData.map((item, key) => {
                                        let formattedDate;
                                        let ReqSource;
                                        let formattedDateSc;
                                        let loc_type = item.inspection_location_type;
                                        let pinCode = '';

                                        loc_type = loc_type.charAt(0).toUpperCase() + loc_type.substring(1);

                                        if (item.ReqSource) {
                                            ReqSource = item.ReqSource.toUpperCase();

                                        }

                                        if (item.inspection_prefered_time) {
                                            formattedDate = moment(item.inspection_prefered_time).format('DD, MMM YYYY h:mm a');

                                        }
                                        if (item.inspection_schedule_time) {
                                            formattedDateSc = moment(item.inspection_schedule_time).format('DD, MMM YYYY h:mm a');
                                        }
                                        if (item.toPinCode) {
                                            pinCode = item.toPinCode;
                                        } else {
                                            pinCode = '';
                                        }
                                        let allStatus = (item.all_status).split(",").map(Number);
                                        let assignData = '';
                                        let scheduler_name = (item.scheduler_name) ? item.scheduler_name : ''
                                        /**Assign button ce condition**/
                                        if ((allStatus.includes(1) || allStatus.includes(2)) && (item.scheduler_id !== admin_id) && item.scheduler_id) {
                                            assignData = `Assigned to ${scheduler_name}`;
                                        } else if (allStatus.includes(1) && (item.scheduler_id === admin_id) && HELPER.isUserHasAccessPage('59')) {
                                            assignData = <button className='btn-small m-xl-b' onClick={() => { updateCE(item.hashed_bookingId, "assign") }}>{t("COMMON.ASSIGN")}
                                            </button>;
                                        } else if (allStatus.includes(2) && (item.scheduler_id === admin_id) && HELPER.isUserHasAccessPage('59')) {
                                            assignData = <button className='btn-small m-xl-b' onClick={() => { updateCE(item.hashed_bookingId, "reassign") }}>{t("COMMON.RE_ASSIGN")}</button>;
                                        }


                                        return (
                                            <Fragment key={`parent_${key}`}>
                                                <tr key={key}>
                                                    {props.newInspectionRequest &&
                                                        <td>
                                                            <li className='custom-control custom-checkbox'>

                                                                <input
                                                                    id="sli0"
                                                                    type="checkbox"
                                                                    checked={(item.checkbox_status && commonState.schedulerData.length) ? true : false}
                                                                    onChange={(e) => handleToggleRow(e, item.bookingID)}
                                                                    className="custom-control-input"

                                                                />
                                                                <label
                                                                    htmlFor="sli0"
                                                                    className="custom-control-label"
                                                                ></label>
                                                            </li>
                                                        </td>
                                                    }
                                                    <td>
                                                        {item.fromCityName ? `${item.fromCityName}` : ''} <br /> <b>{item.parent_id ? `${item.parent_id}` : ''}</b><br /> {item.reqByName ? `${item.reqByName}` : ''}<br />  {item.reqByMobile ? `${item.reqByMobile}` : ''}<br />  {item.productName ? `${item.productName}` : ''}<br /> <b>{isReschedule[item.is_rescheduled]}</b> <br /> {ReqSource}
                                                    </td>

                                                    <td>
                                                        <b>{item.ReqData ? `${item.ReqData}` : ''}</b> <br /> {loc_type ? `${loc_type}` : ''}<br /> {item.toName || item.toMobile ? `${item.toName} - ${item.toMobile}` : ''}<br />  {item.toAddress ? `${item.toAddress} ${pinCode}` : ''}<br />  <b> {item.city_name}</b>
                                                    </td>

                                                    <td>
                                                        {formattedDate ? <b>Pref : {formattedDate} </b> : `NA`}<br />
                                                        {formattedDateSc ? <b>Sch : {formattedDateSc} </b> : ``}
                                                    </td>
                                                    <td className='cursor-pointer' onClick={() => toggleTable(item)}>
                                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                                            <b>{t("FORM.VEHICLE_DETAILS")}</b> <br /> {+item.total_vehicle === 1 && item.reg_no ? <b>Reg no. {item.reg_no} </b> : ''}
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <button className='btn-small m-xl-b' onClick={() => { ShowModal(item) }} >{t("COMMON.UPDATE")}
                                                        </button>
                                                        {assignData}
                                                    </td>

                                                    <td >
                                                        {item.duration}
                                                    </td>

                                                    {props.allInspectionRequest &&
                                                        <td>

                                                            {item.ceName} <br /> {item.ceMobile}
                                                        </td>
                                                    }

                                                </tr>

                                                {
                                                    //SUB TABLE HTML
                                                    tableShow === item.bookingID ? (
                                                        <tr className="subtable" key={"sub" + item.bookingID}>
                                                            <td colSpan="12">
                                                                <div className="wrapper">
                                                                    <table>

                                                                        <tbody>

                                                                            {

                                                                                <tr key={item.bookingID} >
                                                                                    <td>
                                                                                        {subData ? subData.inspection_type && subData.inspection_type : ''}<br />
                                                                                        {subData && subData.make && subData.model && subData.version ? `${subData.make} ${subData.model} ${subData.version}` : ''}


                                                                                    </td>
                                                                                    <td>
                                                                                        {/* rc_doc_path */}
                                                                                        {subData && subData.rc_doc_path ? <BannerPreview element={{ image: subData.rc_doc_path }} title={subData.rc_doc_path ? t("INSPECTION.RC_PREVIEW") : ""} /> : ''}
                                                                                        {subData ? `${subData.reg_no} (${subData.vehicle_type})` : ''}

                                                                                    </td>
                                                                                    <td id={`bookingStatus_${item.bookingID}`}>
                                                                                        {(+subData.ceAssignementStatus === 5 && +subData.upload_done_status !== 1 && +subData.is_verified !== 1) ? 'In Process' : subData.inspectionStatus}
                                                                                        {/* <span className='inspection-request-add-stock'>Add Stock</span>	 */}
                                                                                        {/* {(item &&  item.status && +item.status === 5) ? 'Cancel' : 'Pending'} */}

                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            !cancelbooking && <span className="inspection-request-cancel-btn" id={`bookingCancelBtn_${item.bookingID}`} onClick={() => cancelRequestBooking(item.bookingID, 'open')}>Cancel</span>
                                                                                        }
                                                                                        {
                                                                                            cancelbooking > 0
                                                                                            &&
                                                                                            <>
                                                                                                <Select
                                                                                                    onChange={(val) => changeHandler(val, "cancelReason")}
                                                                                                    options={cancelReason}
                                                                                                    placeholder="Select Reason"
                                                                                                    className="react-select"
                                                                                                    classNamePrefix='inspection-dropdown react-select'
                                                                                                    isClearable={true}
                                                                                                />

                                                                                                <ul className="action-btn m-xs-t">
                                                                                                    <li onClick={() => cancelRequestBooking(item.bookingID, 'submit')} >
                                                                                                        <i className="ic-check"></i>
                                                                                                    </li>
                                                                                                    <li onClick={() => cancelRequestBooking(item.bookingID, 'cancel')}>
                                                                                                        <i className="ic-clearclose"></i>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </>



                                                                                        }



                                                                                    </td>
                                                                                    <td >
                                                                                        Requested On ::  {formattedDate = moment(subData.bookingDate).format('DD, MMM YYYY H:mm')} <br />
                                                                                        Preferred on ::   {formattedDate = moment(subData.inspection_prefered_time).format('DD, MMM YYYY H:mm')}

                                                                                    </td>
                                                                                </tr>


                                                                            }

                                                                        </tbody >
                                                                    </table >
                                                                </div >
                                                            </td >
                                                        </tr >
                                                    ) : (
                                                        <tr key={"sub" + item.bookingID}></tr>
                                                    )
                                                }
                                            </Fragment >
                                        )


                                    })

                                    :
                                    <tr><td className='t-center' colSpan={6}>{t("COMMON.NO_DATA")}</td></tr>
                            }
                        </tbody >
                    </table >
                </div >



                <div className="pagination-outer">
                    {!loading && paginationData && paginationData.total ?
                        <Pagination
                            paginateData={{
                                page,
                                runPaginationFunc,
                                loading,
                                tableData: props.getInspectionListing,
                                totalLengthWithoutPagination,
                                paginationData,
                                filteredData: props.searchFilters
                            }}
                            sendRequestDataToParent={handleRequestData}
                        /> : ''}
                </div>

            </div >

            <div>
                {
                    showUpdatePopup ?
                        <UpdateInspection
                            showUpdatePopup={showUpdatePopup}
                            setShowUpdatePopup={setShowUpdatePopup}
                            updateData={updateData}

                        /> : null
                }
            </div>

        </>
    )
}



export default InspectionRequestTable;