import { createSlice } from '@reduxjs/toolkit'
import { toast } from "react-toastify";
import { BackOfficeService } from '@src/services/BackOfficeService';

const backoffice = createSlice({
    name: 'backoffice',
    initialState: {
        backoffice: {}
    },
    reducers: {
        setBackOfficeFilters: (state, action) => {
            state.backOfficeFilters = action.payload;
        },
        setBackOfficeListing: (state, action) => {
            state.backOfficeListing = action.payload;
        }
    },
});

export default backoffice.reducer;

const { setBackOfficeFilters, setBackOfficeListing } = backoffice.actions

/**
 * API call to fetch static lists (Dealer Evaluation Status list)
 * @param {Object} params 
 * @returns 
 */

export const getBackOfficeFilters = () => async dispatch => {
    return new Promise((resolve, reject) => {
        BackOfficeService.getbackOfficeConfig().then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data.data)
                    dispatch(setBackOfficeFilters(response.data.data));
                }
                else {
                    let errorMsg = (response && response.data && response.data.message) || ''
                    if (errorMsg) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}


/**
 * API call to fetch Dealer Evaluation List
 * @param {object} params 
 * @returns 
 */
export const getBackOfficeListing = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        BackOfficeService.getbackOfficeListing(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data)
                    dispatch(setBackOfficeListing(response.data.data));
                }
                else {
                    if(response && response.data){
                        toast.error(response.data.message);
                    }
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}


export const getCsvExportBackOffice = (params) => {
    return new Promise((resolve, reject) => {
        BackOfficeService.getCsvExport(params).then(
            response => {
                if ((response && response.data && response.data.status) === 200) {
                    resolve(response.data)
                } else {
                    if(response && response.data) toast.error(response.data.message);
                    resolve(response && response.data)
                }
            },
            error => {
                reject(error);
            }
        );
    });
}
