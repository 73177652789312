import { useTranslation } from "react-i18next";
const Footer = () => {
    const { t } = useTranslation('common');
    let pathname = window.location.pathname;

    return (
        (!pathname.includes('login') && !pathname.includes('/inspection/certification-report-html/') && !pathname.includes('/inspection/view-report'))
            ?
            <footer>
                <div className="container-fluid">
                    <div className="footer-txt">
                        <span className="copyright-txt">{t('PAGES.FOOTER.INSPECTION_RESERVED').replace('{?}', new Date().getFullYear())} </span>
                    </div>
                </div>
            </footer>
            :
            ""
    )
}


export default Footer;