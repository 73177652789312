import CryptoJS from "crypto-js";
import Dateformat from 'dateformat';
import secureStorage from '@src/config/encrypt';
var SECRET_KEY = "girnarsoftInternationalLoan";

export const encrypt = (data) => {
  data = CryptoJS.AES.encrypt(data, SECRET_KEY);
  data = data.toString();
  return data;
}

export const isUserHasAccessPage = (permissionID, routerPath='', stringData='') => {
    let currentPath = [];
    let authUserInfo = secureStorage.getItem('authUserInfo');
    let userDetails = authUserInfo && authUserInfo.user_data
    let stringMenuData = stringData ? stringData : ((authUserInfo && authUserInfo['headerMenu']) || '') //else get auth user menus
    
    //CHECK FROM URL
    if(routerPath === ''){
      currentPath = window.location.pathname.split('/');
      currentPath = currentPath.filter(path=> path) //FILTER URL PATH

    }else{ //CHECK MANUAL ROUTE
      currentPath = [routerPath]
    }

    let isAuthorized = true
    if(currentPath.length && stringMenuData){

      //MATCH URL WITH NAME OR ACTION FOR ACTIVE CLASS
      if( stringMenuData.indexOf('"action":"'+currentPath[0]+'"') === -1){
        isAuthorized = false
      }

    }

    if (permissionID) {
      const accessParams = userDetails && userDetails.access_param ? JSON.parse(userDetails.access_param) : {}
      const access = accessParams && accessParams.access ? accessParams.access : []

      //if logged-in user do not have access to permission-id, return false
      if(!access || !access.length || !access.includes(`${permissionID}`)){
          isAuthorized = false
      }
    }

    return isAuthorized;
}

/**
 * function to detect media type of base 64 buffer/array
 * @param {Array} b64 
 * @returns {String} match media type
 */
export const detectMimeType = (b64) => {
    return new Promise((resolve, reject) => {
        if(!b64) resolve() 

        b64 = b64.split(',')[1]

        const signatures = {
            iVBORw0KGgo: "image/png",
            "/9j/": "image/jpg",
            ffd8ffe0: "image/jpeg",
            ffd8ffe1: "image/jpeg",
            ffd8ffe2: "image/jpeg",
            ffd8ffe3: "image/jpeg",
            ffd8ffe8: "image/jpeg"
        }

        for (const s in signatures) {
            if (b64.indexOf(s) === 0) {
                resolve(signatures[s])
            }
        }
        resolve()
    })
}


 //function to convert file into base 64 for previewing image
export const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
   })
}

export const DateFormat = (d, format="") => {
    let finalDate = ''
    switch (format) {
      case '1':
        finalDate = Dateformat(d, "dd mmm yyyy HH:MM")
        break;
    
      default:
        finalDate = d.getFullYear() + '-' + ((d.getMonth() < 9) ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + ((d.getDate() < 10) ? '0' + (d.getDate()) : (d.getDate()))
        break;
    }

    return finalDate;
}

export const getDiffOfDaysInTwoDate = (from,to) => {
  var timeDiff = Math.abs(from.getTime() - to.getTime());
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
  return diffDays;
}

export const StartEndDateofAWeek = (from,to) => {
  var curr = new Date();
  var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()+1));
  var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
  let data={
    start:Dateformat(firstday,'yyyy-mm-dd'),
    end:Dateformat(lastday,'yyyy-mm-dd')
  };
  return data;
}

export const CurrentWeek = () => {
  var curr = new Date();
  var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()+1));
  var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
  let data=Dateformat(firstday,'dd mmm yyyy') +' to '+ Dateformat(lastday,'dd mmm yyyy')
  return data;
}

export const NextWeek = () => {
  var curr = new Date();
  var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()+8));
  var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+7));
  let data=Dateformat(firstday,'dd mmm yyyy') +' to '+ Dateformat(lastday,'dd mmm yyyy')
  return data;
}


