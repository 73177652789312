import { useState, useEffect } from 'react';

import DentDipsImg from "@src/webroot/images/dents-dips.png";
import RegNoImg from "@src/webroot/images/ic-regno.jpg";
import OwnerImg from "@src/webroot/images/ic-owner.jpg";
import OdometerImg from "@src/webroot/images/ic-odometer.jpg";
import FuelImg from "@src/webroot/images/ic-fuel.jpg";
import DateImg from "@src/webroot/images/ic-date.jpg";
import TransmissionImg from "@src/webroot/images/ic-transmission.jpg";
import DefaultUserImg from "@src/webroot/images/defaultuser.png";
import WatermarkImg from "@src/webroot/images/watermark-inspection-image@3x.png";
import { getCertificationReportPdf } from "@src/store/slices/dealerQc";
import BannerPreview from "@src/view/cards/BannerPreview";
import FullPageLoader from "@src/view/common/FullPageLoader"
import Dateformat from 'dateformat';
import { useParams } from 'react-router-dom';

function InspectionHtmlReport(props) {

    const [reportData, setReportData] = useState({});
    const [loading, setLoading] = useState({});
    const [imageSliderHtml, setImageSliderHtml] = useState([]);
    const [dataPointsView, setDataPointsView] = useState("");
    const [showImageSlider, setShowImageSlider] = useState(false);

    const queryParams = new URLSearchParams(props && props.location && props.location.search);
    const reportType = queryParams.get('reportType');
    const [errMsg, setErrMsg] = useState("");
    const { vcc } = useParams();

    useEffect(() => {
        setLoading(true)

        //OVERFLOW HIDDEN FOR POPUP
        if (document.getElementsByTagName('body') && document.getElementsByTagName('body')[0]) {

            document.getElementsByTagName('body')[0].style.cssText = "overflow-x: hidden;"
        }

        getCertificationReportPdf({ vccID:vcc , reportType: reportType }).then((res) => {
            setLoading(false)

            if (res && Object.keys(res).length) {
                setReportData(res)

                if (res.imageSliderData) {
                    loadImageSliderData(res.imageSliderData);
                }

                getDataPointsHTML(res.dataPoints)
            } else {
                setErrMsg((res && res.message) || "Certification ID not exists")
            }
        }).catch(err => {
            setLoading(false)
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadImageSliderData = (imagesArray) => {

        const totalCount = imagesArray.length;

        if (imagesArray.length)
            imagesArray = imagesArray.slice(0, 4);

        let html = []

        for (let [index, img] of imagesArray.entries()) {

            html.push(<li className={(!index) ? "gal-imgLarge " : "gal-imgSmall "} onClick={() => setShowImageSlider(Math.random(0, 99))} key={index}>
                <div className="gal-img" style={{ "position": "relative" }}>
                    <img src={img.image_path} alt={img.tag_name} title={img.tag_name} className="img-responsive" />
                    <span style={{ "position": "absolute", "bottom": "5px", zIndex: "999", "right": 0, "width": "20%" }}>
                        <img src={WatermarkImg} alt="inspection-oto-watermark" className="cdwatermark-large" />
                    </span>
                </div>
                {
                    (index === imagesArray.length - 1) && <div className="gal-overlay">
                        <span className="tm_sprite gal-icon"></span>
                        <span className="gal-txt">View {totalCount} Photos</span>
                    </div>
                }
            </li>

            );


        }
        setImageSliderHtml([...html]);

    }




    const getDataPointsHTML = async (dataPoints) => {

        let html = `<div class="season_tabs">`;
        let i = 0;
        if (dataPoints) {


            for (let dataPoint of Object.keys(dataPoints)) {


                html += ` <div class="season_tab" key={${i}}>
                    <input type="radio" id="tab-${i + 1}" name="tab-group-1" ${!i ? 'checked' : ''} />
                        <label for="tab-${i + 1}">${dataPoint}</label>
    
                        <div class="season_content">
                        <div class="panel-heading">
                            <h3 class="reportTitle">${dataPoint}</h3>
                        </div>
                        <div class="col-md-12"><ul class="clearfix ir-list good-condition">`;
                for (let data of Object.keys(dataPoints[dataPoint])) {
                    if (typeof dataPoints[dataPoint][data] !== "object") {
                        continue;
                    }
                    let feature = "ir-bad";

                    if (dataPoints[dataPoint][data] && dataPoints[dataPoint][data]['value']) {
                        if (['dented', 'repaired', 'scratched'].includes(dataPoints[dataPoint][data]['value'].trim().toLowerCase())) {
                            feature = "ir-bad"
                        } else if (dataPoints[dataPoint][data]['value'] !== dataPoints[dataPoint][data]['cons']) {
                            feature = "ir-good"
                        }
                    }


                    html += `<li><span class="tm_sprite ${feature} mrg-R5"></span>${dataPoints[dataPoint][data]['title']} : ${dataPoints[dataPoint][data]['value'] || ''}</li>`;
                }

                html += `</ul></div>
                        </div>
                </div>`;
                i++;
            }

        }




        html += `</div > `;

        setDataPointsView(html);

    }


    let { MMV, vehicleCategory, accidentalImageLabel,  basicDetails, ceImage, ceName, ceExp, ceInspectedCars, bonnetDent, frontWindShieldDent, rearWindShieldDent, frontBumperDent, roofDent, rearBumperDent, dickyDent, APillerRHDent, BPillerRHDent, CPillerRHDent, APillerLHDent, BPillerLHDent, CPillerLHDent, rightFenderDent, rightFrontDoorDent, rightRearDoorDent, rightQuarterPanelDent, leftFenderDent, leftFrontDoorDent, leftRearDoorDent, leftQuarterPanelDent, inspectionDate } = reportData;


    return (

        <div className='inspection-report-html-content'>
            <FullPageLoader show={loading} />

            {
                reportData && Object.keys(reportData).length ?

                    <>
                        <section className="clearfix mrg-B25">
                            <div className="container">
                                <div className="car-details white_box">

                                    <div className="row mrg-MB">
                                        <div className="col-md-9">
                                            <h1 className="carTitle largeTxt">{MMV}</h1>
                                            <div className="row clearfix">
                                                <div className="col-sm-2"><span className='report-type'>{vehicleCategory}</span> </div>
                                                <div className="col-sm-10">
                                                    <div className="{accidentalClass}">{accidentalImageLabel}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="overall-rate">
                                                <div className="oRating label-excellent">
                                                    <span className="rateBox">{ratingFinalText}</span>
                                                </div>
                                                <div className="gain-rate">{overAllRating}/10</div>
                                            </div>

                                        </div> */}
                                        {/* COMMENTED AS LOGIC NOT CLEAR */}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="gal-wrapper">
                                                <div className="row">
                                                    <div className="col-md-12" >
                                                        <ul className="clearfix hidden-xs cur-zoom"  >
                                                            {imageSliderHtml}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 newfeature-padL">
                                            <div className="topfeature-wrapper">
                                                <ul className="clearfix">
                                                    {/* {basicDetails} */}
                                                    {
                                                        basicDetails && Object.keys(basicDetails).map((elm, key) => {



                                                            // $data['title'] = ($data['title'] == 'Odometer Reading') ? 'Odometer Reading #' : $data['title'];
                                                            let imgIcon = "";
                                                            // if (elm === "body_type") {
                                                            //     imgIcon = BodyTypeImg
                                                            // } else 
                                                            if (elm === "color_id") {
                                                                imgIcon = ""

                                                            } else if (elm === "fuel_type") {
                                                                imgIcon = FuelImg

                                                            } else if (elm === "mfg_date") {
                                                                imgIcon = DateImg

                                                            } else if (elm === "odometer_reading") {
                                                                imgIcon = OdometerImg

                                                            } else if (elm === "owner") {
                                                                imgIcon = OwnerImg

                                                            } else if (elm === "reg_date") {
                                                                imgIcon = DateImg

                                                            } else if (elm === "reg_number") {
                                                                imgIcon = RegNoImg

                                                            }
                                                            else if (elm === "transmission") {
                                                                imgIcon = TransmissionImg

                                                            }

                                                            return !["body_type", "rto"].includes(elm) && <li key={key}>

                                                                {
                                                                    ["reg_number", "mfg_date", "reg_date", "owner", "odometer_reading", "fuel_type", "transmission", "color_id"].includes(elm) &&
                                                                    <>

                                                                        {
                                                                            elm === "color_id"
                                                                                ?

                                                                                <span className="colorcode icons" style={{backgroundColor: (basicDetails[elm].value || "#fff")}}>
                                                                                </span>
                                                                                :

                                                                                <span>
                                                                                    <img src={imgIcon} alt={elm} />
                                                                                </span>
                                                                        }
                                                                        <span className="featuretxt-large" title={basicDetails[elm].value}>{basicDetails[elm].value && basicDetails[elm].value.length > 15 ? basicDetails[elm].value.substr(0, 14) + '...' : basicDetails[elm].value}
                                                                            <span className="featuretxt-small">{basicDetails[elm].title}</span>
                                                                        </span>
                                                                    </>
                                                                }

                                                            </li>
                                                        })
                                                    }

                                                </ul>
                                            </div>

                                            <div className="tm-expert-wrapper">
                                                <div className="tm-expert">
                                                    <div className="expert-pic">
                                                        <div className="expert-img">
                                                            <img src={ceImage || DefaultUserImg} alt="Inspection Expert" title="Inspection Expert"
                                                                className="img-responsive" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="expert-details">
                                                    <h3 className="nameTitle col-tm-orange">{ceName}</h3>
                                                    <div className="ex-tag"><span className="tm_sprite tm-checked"></span> Inspection Expert</div>
                                                    <div className="smallTxt">{ceExp}</div>
                                                    <div className="smallTxt">{ceInspectedCars}</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                        {/* COMMENTED AS LOGIC NOT CLEAR */}

                        {/* <section className="clearfix mrg-B25">
                            <div className="container">
                                <div className="white_box">
                                    <div className="row mrg-B15">
                                        <div className="col-md-12">
                                            <h2 className="titleTxt">Summary</h2>
                                        </div>

                                    </div>

                                    <hr className="seprator" />

                                    <div className="row pad-T15 pad-B15">
                                        {

                                            dataPoints && Object.keys(dataPoints).map((elm, key) => {

                                                return <div className="col-md-6" key={key}>
                                                    <div className="tm-summary">
                                                        <div className="sm-right">
                                                            <h4 className="smTitle">{elm}</h4>
                                                            <div className="progressBox">
                                                                <div className="progress">
                                                                    <div className="progress-bar label-blue" role="progressbar" aria-valuenow="40" aria-valuemin="0"
                                                                        aria-valuemax="100" style={{ width: `${(dataPoints[elm]['rating'] * 10)}%` }}>
                                                                        <span className="sr-only">{`${(dataPoints[elm]['rating'] * 10)} % Complete (success)`}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="sm-ratingbox">
                                                                <div className="sm-gain">{dataPoints[elm]["rating"]}</div>
                                                                <div className="sm-total">10</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            })
                                        }


                                    </div>

                                </div>
                            </div>
                        </section> */}



                        <section className="clearfix mrg-B25">
                            <div className="container">
                                <div className="white_box">
                                    <div className="row mrg-B15">
                                        <div className="col-md-4">
                                            <h2 className="titleTxt">Dents &amp; Dips</h2>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="tm-label-box">
                                                <span className="dd-label col-dented mrg-L0"><span className="dd-icon dd-dented"></span>
                                                    Dented</span>
                                                <span className="dd-label col-rusted"><span className="dd-icon dd-rusted"></span> Rusted</span>
                                                <span className="dd-label col-scratched"><span className="dd-icon dd-scratched"></span>
                                                    Scratched</span>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="seprator" />

                                    <div className="row pad-T15 pad-B15">
                                        <div className="col-md-12">
                                            <div className="dd-wrapper">
                                                <div className="dd-imgBox">
                                                    <img src={DentDipsImg} alt="Dents & Dips" title=""
                                                        className="img-responsive" />
                                                </div>

                                                <div className="dd-bonnet" title="Bonnet">
                                                    {bonnetDent}
                                                </div>

                                                <div className="dd-fr-screen" title="Front Windscreen">
                                                    {frontWindShieldDent}
                                                </div>

                                                <div className="dd-rr-screen" title="Rear Windscreen">
                                                    {rearWindShieldDent}
                                                </div>

                                                <div className="dd-fr-bumper" title="Front Bumper">
                                                    {frontBumperDent}
                                                </div>

                                                <div className="dd-roof" title="Roof">
                                                    {roofDent}
                                                </div>

                                                <div className="dd-Rr-bumper" title="Rear Bumper">
                                                    {rearBumperDent}
                                                </div>

                                                <div className="dd-dicky" title="Dicky">
                                                    {dickyDent}
                                                </div>

                                                <div className="dd-a-rh-pillar" title="Right A Pillar ">
                                                    {APillerRHDent}
                                                </div>

                                                <div className="dd-b-rh-pillar" title="Right B Pillar">
                                                    {BPillerRHDent}
                                                </div>

                                                <div className="dd-c-rh-pillar" title="Right C Pillar">
                                                    {CPillerRHDent}
                                                </div>

                                                <div className="dd-a-lh-pillar" title="Left A Pillar ">
                                                    {APillerLHDent}
                                                </div>

                                                <div className="dd-b-lh-pillar" title="Left B Pillar">
                                                    {BPillerLHDent}
                                                </div>

                                                <div className="dd-c-lh-pillar" title="Left C Pillar">
                                                    {CPillerLHDent}
                                                </div>

                                                <div className="dd-Rh-fender" title="Right Fender">
                                                    {rightFenderDent}
                                                </div>

                                                <div className="dd-Rh-fr-door" title="Right Front Door">
                                                    {rightFrontDoorDent}
                                                </div>

                                                <div className="dd-Rh-Rr-door" title="Right Rear Door">
                                                    {rightRearDoorDent}
                                                </div>

                                                <div className="dd-Rh-qtr-panel" title="Right Quarter Panel">
                                                    {rightQuarterPanelDent}
                                                </div>

                                                <div className="dd-Lh-fender" title="Left Fender">
                                                    {leftFenderDent}
                                                </div>

                                                <div className="dd-Lh-fr-door" title="Left Front Door">
                                                    {leftFrontDoorDent}
                                                </div>

                                                <div className="dd-Lh-Rr-door" title="Left Rear Door">
                                                    {leftRearDoorDent}
                                                </div>

                                                <div className="dd-Lh-qtr-panel" title="Left Quarter Panel">
                                                    {leftQuarterPanelDent}
                                                </div>

                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>



                        <section className="clearfix mrg-B25">
                            <div className="container">
                                <div className="white_box">
                                    <div className="row mrg-B15">
                                        <div className="col-md-4">
                                            <h2 className="titleTxt">Inspection Report</h2>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="tm-label-box">
                                                <span className="dd-label mrg-L0"><span className="tm_sprite ir-good mrg-R5"></span>
                                                    Good/Original/Replaced</span>
                                                <span className="dd-label mob-mrg"><span className="tm_sprite ir-bad mrg-R5"></span>
                                                    Dented/Repaired/Scratched</span>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="seprator" />

                                    <div className="row pad-T15 pad-B15" dangerouslySetInnerHTML={{ __html: dataPointsView }} >

                                    </div>

                                </div>
                            </div>
                        </section>


                        <section className="clearfix mrg-B25">
                            <div className="container">
                                <div className=" font-12">
                                    <p className='m-xl-b'>
                                        <span className="font-med font-14">Disclaimer:</span> This inspection summary report is compiled based
                                        on information provided to us including title documents, MMV, year, condition, odometer reading and
                                        external examination of the vehicle/components. The report will not tell you about hidden defects or
                                        those problems which cannot be identified on a visual inspection. This report provides information
                                        on the observed condition of the vehicle as on the inspection date ({Dateformat(inspectionDate, 'dd/mm/yyyy')}) and CarDekho
                                        does neither guarantee nor take liability for the correctness or completeness thereof.
                                    </p>
                                    <p className='m-md-b'><span className="font-med font-14">Note:</span> To check the genuinity of condition report please scan
                                        the QR code on 1st page of report.</p>
                                    <p ><span className="font-med font-14">#</span> The odometer reading is at the time of inspection of car,
                                        actual odometer reading might vary</p>

                                </div>
                            </div>
                        </section>

                        {showImageSlider ? <BannerPreview signedImage={true} element={{ image: reportData.imageSliderData }} defaultShow={showImageSlider} /> : ''}

                    </>
                    :
                    <section className="clearfix mrg-B25">
                        <div className="container">
                            <h3 className="">{errMsg}</h3>
                        </div>
                    </section>



            }

        </div>
    )
}

export default InspectionHtmlReport