import secureStorage from '@src/config/encrypt';
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllUsers, getUserRoles } from "@src/store/slices/user"
import { getCityList, getInspectionFilters } from "@src/store/slices/common"
import UserListTable from "@src/view/user/common/UserListTable"
import FullPageLoader from "@src/view/common/FullPageLoader"
import { useTranslation } from "react-i18next"
import UserListFilter from "@src/view/user/common/UserListFilter"
import TopActionBtn from "@src/view/common/TopActionBar";



const ManageUser = (props) => {

    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(false)
    const [userlistResponse, setUserlistResponse] = useState([])
    const [userlistFilter, setUserListFilter] = useState(props.userlistFilter)
    const [cityList, setCityList] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const [editAccess, setEditAccess] = useState(false)
    const [statusList, setStatusList] = useState([])

    const fetchUserDetails = secureStorage.getItem('authUserInfo')
    const userDetails = fetchUserDetails && fetchUserDetails.user_data

    const dispatch = useDispatch()

    const commonState = useSelector(state => state.common);
    const userState = useSelector(state => state.auth);



    /**
     * Loading city list, user roles and setting user access on component mount
     */
    useEffect(() => {
        if (userState && !userState.userRoles) {
            const userID = userDetails && userDetails.hash_id
            getUserRoleListing({ userID })
        }
        else {
            //adding roles fom redux 
            const roles = userState && userState.userRoles && userState.userRoles.data

            const updatedRoles = roles && roles.map((ele) => {
                return { label: ele.role_title, id: ele.id, param: ele.param }
            })

            setUserRoles(updatedRoles)
        }

        //function call to set user permissions
        setUserAccess()

        if (commonState && !commonState.inspectionFilters) {
            getConfig()
        }
        else {
            //adding status list from redux
            const config = commonState && commonState.inspectionFilters

            if (config) {
                const statusList = config.STATUSLIST
                setStatusList(statusList)
            }

        }

        if (commonState && !commonState.cityList) {
            getCityListing(props)
        }
        else {
            //adding city list from redux
            const cityList = commonState && commonState.cityList
            const data = cityList && cityList.data

            const updatedCities = data && data.map((ele) => {
                return { label: ele.name, id: ele.id, name: ele.name }
            })

            setCityList(updatedCities)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Calling listing API, and updating component if listing updates
     */
    useEffect(() => {
        getUserListing(props)
    }, [userlistFilter]) // eslint-disable-line react-hooks/exhaustive-deps


    /**
     * fetching user details from storage and setting permissions in state
     */

    const setUserAccess = () => {

        if (userDetails) {
            const accessParams = userDetails && userDetails.access_param ? JSON.parse(userDetails.access_param) : {}
            const access = accessParams && accessParams.access ? accessParams.access : ''
            
            //checking if user has access to edit Users
            if (access && access.includes('34')) {
                setEditAccess(true)
            }
        }
    }


    /**
     * fetches list of users
     * @param {object} params 
     */
    const getUserListing = (params) => {
        setLoading(true)

        let filterObj = {
            ...params.searchFilter,
            ...userlistFilter,
            page_no: params.page_no ? params.page_no : 1
        }

        dispatch(getAllUsers(filterObj)).then((res) => {
            setLoading(false)
            if (res && res.status === 200) {
                const userlistResponse = res
                setUserlistResponse(userlistResponse)

            }
        }).catch(err => {
            setLoading(false)
        })
    }

    /**
     * function for child component for updating user list
     * @param {object} params 
     */
    const fetchUserListing = (params) => {
        getUserListing(params)
    }

    /**
     * fetches list of cities
     * @param {object} params 
     */
    const getCityListing = (params) => {
        dispatch(getCityList(params.searchFilters)).then((res) => {
            if (res && res.status === 200) {

                const updatedCities = res.data.map((ele) => {
                    return { label: ele.name, id: ele.id, name: ele.name }
                })

                setCityList(updatedCities)
            }
        }).catch(err => {

        })
    }

    /**
     * function for child component to update search filters used for user listing
     * @param {object} params 
     */
    const updateFilter = (params) => {
        setUserListFilter(params)
    }

    /**
     * fetches list of user roles
     * @param {object} params 
     */
    const getUserRoleListing = (params) => {
        dispatch(getUserRoles(params)).then((res) => {
            if (res && res.status === 200) {

                const updatedRoles = res.data.map((ele) => {
                    return { label: ele.role_title, id: ele.id, param: ele.param }
                })

                setUserRoles(updatedRoles)
            }
        })
    }

    /**
      * fetches list of static dropdowns
      * @param {object} params 
      */
    const getConfig = async (params) => {
        try {
            const response = await dispatch(getInspectionFilters(params))

            if (response) {
                setStatusList(response.STATUSLIST)
            }
        } catch (error) {
        }
    }

    return (
        <>

            <div className={"container-fluid-full"}>

                <div className="top-sec">
                    <TopActionBtn heading={t("USER_MODULE.MANAGE_USERS_DASHBOARD")}
                        callonpage={'1'}
                        searchFilterList={{ exportType: 1 }}
                        pageType={'UserListing'}
                        List={userlistResponse.data} />
                </div>

                <div className="vehicle-listing-box">
                    <UserListFilter
                        cityListing={cityList}
                        userRoles={userRoles}
                        statusList={statusList}
                        updateFilter={updateFilter}
                        hiddenFilters={['ceType']}
                        userData={userlistResponse.data}
                    />
                </div>

                <FullPageLoader show={loading} />

                <div className="vechile-table-data">
                    <UserListTable callFrom={"manageUser"} apiResponse={userlistResponse} getUserListing={fetchUserListing} loading={loading} searchFilters={userlistFilter} filterData={userlistResponse.data} editAccess={editAccess} />
                </div>
            </div>
        </>
    )
}


export default ManageUser