import InputField from '@src/view/element/InputField';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { allVersionInfoById } from "@src/store/slices/common";
import { updateQCData, updatedFormfields, updateQcPanelData, saveReQCData } from "@src/store/slices/dealerQc";
import { useNavigate } from 'react-router';
import FinalSubmitModal from '@src/view/dealer-qc/common/FinalSubmitModal'
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from 'moment';



const QcPanel = (props) => {
    const { t } = useTranslation("common")


    let { vehicleData, callFrom } = props;

    const dealerQcState = useSelector(state => state.dealerQc)

    const [activeImageFormData, setActiveImageFormData] = useState({});
    const [activeImageQuestionData, setActiveImageQuestionData] = useState([]);
    const [versionList, setVersionList] = useState([]);
    const [updatedFormFields, setUpdatedFormFields] = useState([]);
    const [loadingVersion, setLoadingVersion] = useState(false);
    const [activeImageIndex, setActiveImageIndex] = useState('');
    const [imageRejectReAasons, setImageRejectReAasons] = useState([]);
    const [activeImageTagId, setActiveImageTagId] = useState('');
    const [currentImageRejectReason, setCurrentImageRejectReason] = useState({});
    const [fuelType, setFuelType] = useState('');
    const [transmission, setTransmission] = useState('');
    const [showFinishPopup, setShowFinishPopup] = useState(false);
    const [satisfactoryReasons, setSatisfactoryReasons] = useState([]);
    const [isSatisfactoryCheck, setIsSatisfactoryChecked] = useState(true);


    const dispatch = useDispatch()
    const navigate = useNavigate();

    const changeHandler = (value, key) => {

        let activeFormData = JSON.parse(JSON.stringify(activeImageFormData));
        let activeQuestionData = JSON.parse(JSON.stringify(activeImageQuestionData));

        let editedField = {}

        for (let field of activeFormData) {
            if (field.field_name === key) {

                if (key === 'color_id') {
                    field.value = (value && value.value) || null
                }

                else if (field.input_type === 'text') {
                    field.value = value.target.value || ''
                }

                else if (key === 'version_id') {
                    field.value = value.value
                }

                else if(key === 'stnk_registration_date'){
                    field.value = moment(value).format('YYYY-MM-DD')
                }

                else {
                    field.value = value ? (["radio", "select"].includes(field.input_type) ? value.key : value) : null;
                }

                field.isError = (!field.value && field.is_required) ? true : false;

                editedField = {
                    id: field.id,
                    name: field.field_name,
                    value: field.value
                }

            }
        }

        //when any formfield is edited, then store it in updatedFormfields state in redux
        let formFields = (dealerQcState && JSON.parse(JSON.stringify(dealerQcState.updatedFormfields))) || ''
        const isKeyAlreadyPresentIndex = formFields && formFields.findIndex((e) => e.name === editedField.name)

        if (isKeyAlreadyPresentIndex !== -1) {
            formFields[isKeyAlreadyPresentIndex] = editedField
        }
        else {
            formFields = [...formFields, editedField]
        }

        //updating vehicleData shared across same tag and pushing it in qcPanelData redux state
        let data = props.vehicleData
        let updatedVehicleData = JSON.parse(JSON.stringify(data))
        let imageData = updatedVehicleData && updatedVehicleData.imageData

        for (let ele of imageData) {
            if (ele.formData) {
                let formData = ele.formData

                for (let data of formData) {
                    if (data.field_name === key) {
                        data.value = editedField.value
                    }
                }
            }
        }

        dispatch(updateQcPanelData({ "activeFormData": activeFormData, "qcPanelData": updatedVehicleData }))
        dispatch(updatedFormfields(formFields))

        setActiveImageFormData([...activeFormData]);

        setActiveImageQuestionData([...activeQuestionData]);

    }

    useEffect(() => {

        if (dealerQcState && dealerQcState.qcImageSliderData && dealerQcState.qcPanelData && dealerQcState.qcPanelData.imageData) {
            let imgIndex = dealerQcState.qcImageSliderData.slideIndex - 1;

            setActiveImageIndex(imgIndex);

            let getImageFormData = dealerQcState.qcPanelData.imageData[imgIndex];

            if (getImageFormData) {

                if (getImageFormData.formData) {
                    setActiveImageFormData(getImageFormData.formData)

                    let getVersionKey = getImageFormData.formData.filter(form => form.field_name === "version_id");
                    if (getVersionKey && getVersionKey.length && getVersionKey[0]['model_id'] && !versionList.length) {
                        versionInfoById(getVersionKey[0]['model_id'])
                    }
                }

                setActiveImageTagId(getImageFormData.tag_id)
                setImageRejectReAasons(props.imageRejectReasons)

                const currentImageRejectReason = props.imageRejectReasons.filter((e) => e.tag_id === getImageFormData.tag_id)
                setCurrentImageRejectReason(currentImageRejectReason[0])

            }

            if (getImageFormData && getImageFormData.panelQuestions) {
                let satisfactoryReasons = []
                let panelQuestions = getImageFormData.panelQuestions.filter((e) => {
                    if (e.ques_input_type === '1') {
                        satisfactoryReasons.push(e)
                    }

                    return e.ques_input_type === '5'
                })

                setActiveImageQuestionData(panelQuestions);
                setSatisfactoryReasons(satisfactoryReasons)
            }

            if (dealerQcState.updatedFormfields) {
                setUpdatedFormFields(dealerQcState.updatedFormfields)
            }
        }

    }, [dealerQcState]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * GET VERSIONS
     * @param {*} model_id 
     */
    const versionInfoById = async (model_id) => {
        setLoadingVersion(true);
        dispatch(allVersionInfoById({ "md_id": model_id })).then((resp) => {
            setLoadingVersion(false);
            setVersionList(resp && resp.data ? resp.data : []);
            setFuelType(resp.data[0].f_type)
            setTransmission(resp.data[0].tms)
        }).catch(err => setLoadingVersion(false))
    }

    const changeHandlerQuestions = (e, qstnId, key) => {
        let updatedReasons = currentImageRejectReason;

        if (!key) {
            if (e.target.checked) {
                updatedReasons['reason'].push(qstnId);
            } else {
                updatedReasons['reason'] = updatedReasons['reason'].filter(v => v !== qstnId);

                if (qstnId === 6) {
                    updatedReasons['otherReason'] = "";

                }
            }
        } else {
            updatedReasons['otherReason'] = e.target.value;
        }

        const findIndex = imageRejectReAasons && imageRejectReAasons.findIndex((e) => e.tag_id === activeImageTagId)
        imageRejectReAasons[findIndex] = updatedReasons
        setCurrentImageRejectReason({ ...updatedReasons })
    }

    const changeHandlerImgStatus = (e, val) => {
        let updatedReasonsIndex = imageRejectReAasons && imageRejectReAasons.findIndex((e) => e.tag_id === activeImageTagId);

        if (val && val === 'notSatisfactory') {
            if (imageRejectReAasons && imageRejectReAasons[updatedReasonsIndex]) {
                imageRejectReAasons[updatedReasonsIndex]['imageStatus'] = 'Not Satisfactory'
            }
            setCurrentImageRejectReason({ tag_id: activeImageTagId, imageStatus: 'Not Satisfactory', reason: [] })
            setIsSatisfactoryChecked(false)
        }

        if (val && val === 'satisfactory') {
            if (imageRejectReAasons && imageRejectReAasons[updatedReasonsIndex]) {
                imageRejectReAasons[updatedReasonsIndex]['imageStatus'] = 'Satisfactory'
            }
            setCurrentImageRejectReason({ tag_id: activeImageTagId, imageStatus: 'Satisfactory' })
            setIsSatisfactoryChecked(true)
        }


    }

    const filteredOptions = (item) => {
        let options = [];
        if (item.field_name === "color_id") {
            options = props.colorList || [];

            //CHECK IF VALUE NOT EXISTS THEN INSERT IN ARRAY
            let checkItemExists = props.colorList.filter(clr => clr.value === item.value);
            if (!checkItemExists.length) {
                options = [...options, { label: item.value, value: item.value }]
            }

        } else {
            options = (!item.input_value || item.input_value !== "local") ? JSON.parse(item.input_value) : [];
        }
        return options;

    }

    const handleSubmitBtnClick = async () => {
        props.setLoadingFromChild(true)

        let vccID = (window.location.pathname).split('/')[3] || '';

        let params = {
            requestData: updatedFormFields,
            vccID,
            vehicleType: vehicleData.vehicleType || '',
            bookingID: vehicleData.booking_id || '',
            ImageSatisfaction: imageRejectReAasons
        }

        if (params) {
            Object.keys(params).forEach((e) => {
                if (!params[e] || params[e] === '') {
                    delete params[e]
                }
            })
        }

        if (callFrom === 'qcPanel') {

            if (vehicleData && vehicleData.notUploadedImageTags && vehicleData.notUploadedImageTags.length > 0) {
                toast.error(t("INSPECTION.MANDATORY_FIELD_ERR"))
                props.setLoadingFromChild(false)
                return
            }

            const updateData = await updateQCData(params)
            if (updateData && updateData.status === 200) {
                navigate('/qc/dealer')
            }
        }

        else if (callFrom === 'ReQcPanel') {
            finalSubmitModal(true)
        }

        props.setLoadingFromChild(false)
    }


    const finalSubmitModal = (flag) => {
        setShowFinishPopup(flag)
    }

    const handleFinishClick = async (response) => {
        if (response.response) {
            props.setLoadingFromChild(true)

            if (callFrom === 'ReQcPanel') {
                let vccID = (window.location.pathname).split('/')[3] || '';

                const updateData = await saveReQCData({ vccID, ImageSatisfaction: imageRejectReAasons })
                if (updateData && updateData.status === 200) {
                    toast.success(t("DO_QC.RE_QC_COMPLETED_SUCCESSFULLY"))
                    navigate('/qc/dealer')
                }
            }
            props.setLoadingFromChild(false)
            setShowFinishPopup(false)

        }
        else {
            setShowFinishPopup(false)
        }
    }

    return (
        <div className="qc-details-form">
            {
                (callFrom !== 'ReQcPanel') &&
                <div className="tab-details">

                    {
                        activeImageFormData && activeImageFormData.length > 0 &&
                        activeImageFormData.map(item => {

                            if (["radio", "select"].includes(item.input_type)) {

                                if (item.field_name === "version_id") {
                                    return (
                                        <div className="car-field-box" key={item.field_name} >
                                            <fieldset className={`form-select-bx ${item.isError ? 'error-field' : ''}`}>
                                                <div className={item.value ? "active-label material animation-effect" : "material animation-effect"}>
                                                    <Select
                                                        id={item.id}
                                                        value={versionList ? versionList.filter((e) => e.vn_id === item.value) : []}
                                                        onChange={(val) => changeHandler(val, item.field_name)}
                                                        options={versionList}
                                                        placeholder=''
                                                        className="react-select"
                                                        classNamePrefix='inspection-dropdown react-select'
                                                        isClearable={true}
                                                        getOptionValue={(option) => option.value}
                                                        getOptionLabel={(option) => option.label}
                                                        isSearchable={false}
                                                        required={item.isRequired}
                                                        isLoading={loadingVersion}
                                                    />
                                                    <label data-label={item.field_title} className="form-label"></label>

                                                </div>
                                            </fieldset>
                                        </div>
                                    )
                                } else {
                                    return <fieldset className={`form-select-bx ${item.isError ? 'error-field' : ''}`} key={item.field_name}>
                                        <div className={item.value ? "active-label material animation-effect" : "material animation-effect"}>
                                            <Select
                                                id={item.id}
                                                value={item.value && filteredOptions(item).filter((e) => item.field_name === "color_id" ? (e.value === item.value || '' + e.key === '' + item.value) : '' + e.key.trim() === '' + item.value.trim())}
                                                onChange={(val) => changeHandler(val, item.field_name)}
                                                options={filteredOptions(item)}
                                                placeholder=''
                                                className="react-select"
                                                classNamePrefix='inspection-dropdown react-select'
                                                isClearable={true}
                                                getOptionValue={(option) => option.key}
                                                getOptionLabel={(option) => option.value}
                                                isSearchable={false}
                                                required={item.isRequired}
                                            />
                                            <label data-label={item.field_title} className="form-label"></label>
                                        </div>
                                    </fieldset>
                                }


                            } else if (item.input_type === "text" || (item.field_name === 'fuel_type' || item.field_name === 'transmission')) {
                                return <fieldset className="datepicer" key={item.field_name}>
                                    <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                                id: item.field_name,
                                                type: "text",
                                                name: "firstname",
                                                value: (item.field_name === 'fuel_type' ? fuelType : item.field_name === 'transmission' ? (transmission || "") : item.value || ""),
                                                placeholder: " ",
                                                className: 'form-input',
                                                maxLength: "50",
                                                disabled: (item.field_name === 'fuel_type' || item.field_name === 'transmission')
                                            }}
                                            onChange={(val) => changeHandler(val, item.field_name)}
                                        />
                                        <label data-label={item.field_title} className="form-label"></label>
                                    </div>
                                </fieldset>
                            } 
                            else if (item.input_type === 'date') {
                                return (
                                    <fieldset className="create-date">
                                        <div className={item.value ? "active-label material animation-effect" : "material animation-effect "}>
                                            <div className="datepicker">
                                                <DatePicker
                                                    selected={item.value && item.value !== '0000-00-00' ? new Date(item.value) : null}
                                                    onChange={(val) => changeHandler(val, item.field_name)}
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText=""
                                                    value={item.value ? item.value : ''}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    required={item.isRequired}
                                                />
                                                <label data-label={item.field_title} className="form-label"></label>
                                                
                                            </div>
                                        </div>
                                    </fieldset>

                                )
                            }
                            return null;
                        })
                    }

                </div>
            }


            <div className="img-satisfy-blk-main" >
                <div className="img-satisfy-blk">
                    <div className="pull-left">
                        {/* <div className="radio clip-radio radio-primary no-margin">
                            <input type="radio" id="imageSatisfactionY250" className="required" name="imgStatusHandler" value="Satisfactory" checked={currentImageRejectReason && currentImageRejectReason.imageStatus === 'Satisfactory' ? true : false} onChange={(e) => changeHandlerImgStatus(e, 'satisfactory')} />
                            <label >Image Satisfactory</label>
                        </div> */}

                        <div className="checkbox clip-check check-primary custom-control custom-radio">
                            <input id="imageSatisfactionY" type="checkbox" className="custom-control-input " name="imgStatusHandler" value="Satisfactory" checked={currentImageRejectReason && currentImageRejectReason.imageStatus === 'Satisfactory' ? true : false} onChange={(e) => changeHandlerImgStatus(e, 'satisfactory')} />
                            <label htmlFor="imageSatisfactionY" className="custom-control-label">{t("DO_QC.IMG_SATISFACTORY")}</label>
                        </div>

                    </div>
                    <div className="pull-left m-xl-l">
                        {/* <div className="radio clip-radio radio-primary no-margin">
                            <input type="radio" id="imageSatisfactionN250" className="required" name="imgStatusHandler" value="Not Satisfactory" onChange={(e) => changeHandlerImgStatus(e, 'notSatisfactory')} checked={currentImageRejectReason && currentImageRejectReason.imageStatus === 'Satisfactory' ? false : true} />
                            <label >Image Not Satisfactory</label>
                        </div> */}

                        <div className="checkbox clip-check check-primary custom-control custom-radio">
                            <input id="imageSatisfactionN" type="checkbox" className="custom-control-input " name="imgStatusHandler" value="Not Satisfactory" checked={currentImageRejectReason && currentImageRejectReason.imageStatus === 'Satisfactory' ? false : true} onChange={(e) => changeHandlerImgStatus(e, 'notSatisfactory')} />
                            <label htmlFor="imageSatisfactionN" className="custom-control-label">{t("DO_QC.IMG_NOT_SATISFACTORY")}</label>
                        </div>

                    </div>
                </div>

                {(callFrom === 'ReQcPanel' && isSatisfactoryCheck && satisfactoryReasons) ?
                    satisfactoryReasons.map((rsn, idx) => {
                        return (
                            <div className="satisfy" key={idx}>
                                <div className="checkbox clip-check check-primary">
                                    <label>{rsn.question}</label>
                                </div>
                            </div>
                        )
                    }) : ''}
                {
                    imageRejectReAasons && currentImageRejectReason && currentImageRejectReason.imageStatus === "Not Satisfactory" && activeImageQuestionData && activeImageQuestionData.length > 0 && activeImageQuestionData.map((qst, idx) =>
                        <div className="notsatisfy" key={idx}>
                            <div className="checkbox clip-check check-primary custom-control custom-checkbox">
                                <input id={`sli${idx}`} type="checkbox" className="custom-control-input " value={qst.id} onChange={(e) => changeHandlerQuestions(e, qst.id)} checked={currentImageRejectReason['reason'] && currentImageRejectReason['reason'].includes(+qst.id)} />
                                <label htmlFor={`sli${idx}`} className="custom-control-label">{qst.question}</label>
                            </div>
                            <br />
                            {
                                imageRejectReAasons && currentImageRejectReason && currentImageRejectReason['reason'] && currentImageRejectReason['reason'].includes(6) && qst.id === 6 ?
                                    <fieldset className="form-filed">
                                        <div className="material">
                                            <textarea placeholder=" " className="form-input"
                                                onChange={(e) => changeHandlerQuestions(e, qst.id, 'otherReason')}
                                                maxLength="250"
                                                value={currentImageRejectReason['otherReason']}
                                            ></textarea>

                                            {/* <textarea id="notsatResonMsg" className="notsatResonMsg" onChange={(e) => changeHandlerQuestions(e, qst.id, 'otherReason')} ></textarea>  */}

                                            <label data-label={t("INSPECTION.COMMENT")} className="form-label"></label>

                                        </div>
                                    </fieldset>
                                    : ""
                            }
                        </div>

                    )
                }
                {
                    showFinishPopup ?
                        <FinalSubmitModal confirmationData={{
                            show: showFinishPopup,
                            confirmationText: t("BACK_OFFICE.FINISH_MSG")
                        }}
                            confirmationModalResponse={(data) => {
                                handleFinishClick(data)
                            }
                            }
                        /> : null
                }

            </div>

            {
                activeImageIndex === (vehicleData && vehicleData.imageData && vehicleData.imageData.length - 1) ?
                    <div className="btn-submit-cancel">
                        <button type="button" className="btn-primary" onClick={(e) => handleSubmitBtnClick(e)}>{t("COMMON.FINISH")}</button>
                    </div> :
                    ''
            }

        </div>

    )
}


export default QcPanel