import MasterService from '@src/services/MasterService'


export const CommonService = {
    ...MasterService,
    
    getCityList(params){
        return this.post('/common/getCityList',params)
    },
   
    getMenus(params){
        return this.post('/menu/getMenus', params)
    },

    getDealers(params){
        return this.post('/common/getDealerList', params)
    },

    getStateCityList(params){
        return this.get('/common/getStateCityList',params)
    },

    getConfigDetails(params){
        return this.get('/common/getConfigDetails',params)
    },

    getAllClients(params){
        return this.post('/client/getAllClients',params)
    },
    getItemMakeModel(params){
        return this.post('/common/getItemMake',params)
    },

    getModelById(params){
        return this.post('/common/getModelById',params)
    },
    getVersionById(params){
        return this.post('/common/getVersionById',params)
    },

    getCertificationDataById(params) {
        return this.post('/vehicle/getCertificationDataById', params)
    },
    getImageTags(params){
        return this.post('/vehicle/getImageTags', params)
    },
    getColorList(params){
        return this.post('/vehicle/getColorList', params)
    },
    allModelMakeInfo(params){
        return this.post('/common/allModelMakeInfo', params)
    },
    submitCertificationData(params){
        return this.post('/vehicle/updateCertificationData', params)
    },
    allMakeInfo(params){
        return this.post('/common/getItemMake', params)
    },
    allModelInfoById(params){
        return this.post('/common/getModelById', params)
    },
    allVersionInfoById(params){
        return this.post('/common/getVersionById', params)
    },
    getSignedImage(params){
        return this.post('/common/getSignedImage', params)
    }
}

