import AppRoutes from "@src/routes/routes";
import '@src/App.scss';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter} from 'react-router-dom';


function App() {
    return (

        <div className="App">
            <BrowserRouter>
                <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000}  icon={false}/>
                <AppRoutes />
            </BrowserRouter>
        </div>


    );
}

export default App;
