import React, { useEffect, useState } from 'react'
import InputField from '@src/view/element/InputField';
import { default as Select } from "react-select"
import { useTranslation } from "react-i18next"
import { getinspectionConfig, saveInspectionRequest, searchByRegNumber } from "@src/store/slices/inspection"
import {getModelById, getVersionById, getDealers,allModelMakeInfo,getCityList } from "@src/store/slices/common"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch,useSelector } from "react-redux"
import secureStorage from '@src/config/encrypt';
import { toast } from "react-toastify";
import BannerPreview from "@src/view/cards/BannerPreview"
import * as HELPER from "@src/config/helper"
import FullPageLoader from "@src/view/common/FullPageLoader"
import { useNavigate } from 'react-router';
import moment from 'moment';


function AddInspectionForm(props) {
    const { t } = useTranslation("common")
    const [selectedFilters, setSelectedFilters] = useState({});
    const [inspectionFilterList, setInspectionFilterList] = useState([]);
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [version, setVersion] = useState([]);
    const [dealerDetail, setDealerDetail] = useState([]);
    const [cityList, setCityList] = useState([]);
    const dispatch = useDispatch()
    const [dealerDiv, SetDealerDiv] = useState(true);
    const [customerDiv, SetCustomerDiv] = useState(false);
    const [isClassifyYes, setIsClassifyYes] = useState(false);
    const [isClassifyNo, setIsClassifyNo] = useState(true);
    const [singleDealerInfo, setSingleDealerInfo] = useState({});
    const [inspectionDealer, setInspectionDealer] = useState({});
    const [addFormShow, setAddFormShow] = useState(false);
    const [searchBtn, setSearchBtn] = useState(false);
    const [errField, setErrField] = useState({});
    const [file, setFile] = useState([])
    const [previewImg, setPreViewImg] = useState([]);
    const [loading, setLoading] = useState(false)
    const [activeField, setActiveField] = useState('');
    const fetchUserDetails = secureStorage.getItem('authUserInfo')
    const userDetails = fetchUserDetails && fetchUserDetails.user_data
    const navigate = useNavigate();
    const [timeslot, setTimeslot] = useState(null);
    const [timeslots, setTimeslots] = useState([
        { label: '10:00-11:00', value: '10:00' },
        { label: '11:00-12:00', value: '11:00' },
        { label: '12:00-13:00', value: '12:00' },
        { label: '13:00-14:00', value: '13:00' },
        { label: '14:00-15:00', value: '14:00' },
        { label: '15:00-16:00', value: '15:00' },
        { label: '16:00-17:00', value: '16:00' },
        { label: '17:00-18:00', value: '17:00' },
    ]);

    const [locationDealerArr, setlocationDealerArr] = useState([]);
    const commonState = useSelector(state => state.common)

    useEffect(() => {
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        // if current date and select date is same then this condition run
        if (selectedFilters && selectedFilters.scheduleDate && selectedFilters.scheduleDate !== '') {
            if (selectedFilters && selectedFilters.scheduleDate && new Date(selectedFilters.scheduleDate).getDate() === currentDay) {

                // Disabled current time+2 hr , is visible only , ex:- if time is 12 then 12+2 = 14 is visible rest previous is disabled

                const currentHour = currentDate.getHours() + 2;
                const currentMinutes = currentDate.getMinutes();

                // Disable time slots that have already passed based on current time
                const updatedTimeslots = timeslots.map(timeSlot => {
                    const [startTime] = timeSlot.value.split('-');
                    const [startHour, startMinutes] = startTime.split(':');
                    const isDisabled = Number(startHour) < currentHour || (Number(startHour) === currentHour && Number(startMinutes) < currentMinutes);
                    return { ...timeSlot, isDisabled };
                });

                setTimeslots(updatedTimeslots);
            }
            else {
                // If selected day is not equal to current day, display all time slots
                const updatedTimeslots = timeslots.map(timeSlot => ({ ...timeSlot, isDisabled: false }));
                setTimeslots(updatedTimeslots);
            }

        }

    }, [selectedFilters.scheduleDate]); // eslint-disable-line react-hooks/exhaustive-deps


    // All Input - Checkbox - Dropdown Field Value Store Here
    const changeHandler = (value, name) => {
        let id;
        if (name === 'product_type' || name === 'requestedBy' || name === 'priority' || name === 'schedule_fix') {
            let check = value.target.checked;
            setSelectedFilters({ ...selectedFilters, [name]: check })
        }
        else if (name === 'isClassifiedYes') {

            let check = value.target.checked;
            setSelectedFilters({ ...selectedFilters, [name]: check })
            setIsClassifyYes(check)
            setIsClassifyNo(!check)
        }
        else if (name === 'isClassifiedNo') {
            let check = value.target.checked;
            setSelectedFilters({ ...selectedFilters, [name]: check })
            setIsClassifyNo(check)
            setIsClassifyYes(!check)
        }
        else if (name === 'inspection_location_type') {   
            if (value) {
                let inspectionVal;

                if (value.target.value === 'dealer') {
                    let check = value.target.checked;
                    inspectionVal = 1;
                    SetDealerDiv(check);
                    SetCustomerDiv(!check)

                }
                else if (value.target.value === 'consumer') {
                    let check = value.target.checked;
                    inspectionVal = 2;
                    SetCustomerDiv(check)
                    SetDealerDiv(!check);
                    setInspectionDealer({});

                }
                setSelectedFilters({ ...selectedFilters, [name]: value.target.value, inspection: inspectionVal })
            }

        }

        else if (name === 'make') {
            if (value) {
                id = value.value;
            } else {
                id = undefined;
                VersionById('');
                delete selectedFilters['model_id']
                delete selectedFilters['version_id']

            }
            ModelById(id);

            setSelectedFilters({ ...selectedFilters, [name]: id })

        }
        else if (name === 'model_id') {
            if (value) {
                id = value.value;
            } else {
                id = undefined;
                delete selectedFilters['version_id']
            }
            VersionById(id);

            setSelectedFilters({ ...selectedFilters, [name]: id })
        }
        else if (name === 'version_id') {
            if (value) {
                value = value.value;
            } else {
                value = undefined;
            }

            setSelectedFilters({ ...selectedFilters, [name]: value })
        }
        // For All Dropdown Value
        else if (name === 'mmvcategory' || name === 'vehicle_type' || name === 'city_id') {
            if (value) {
                value = value.value;
            } else {
                value = undefined;
            }
            setSelectedFilters({ ...selectedFilters, [name]: value })

        }
        else if (name === 'request_owner_id') {
            if (value && typeof value === 'object') {
                setSingleDealerInfo(value);
                setSelectedFilters({ ...selectedFilters, [name]: value.value });
            } else if (!value) {
                setSelectedFilters({ ...selectedFilters, [name]: undefined });
                setSingleDealerInfo({});
            }

            if (value && typeof value == 'string' && value.length > 2 && !loading) {
                setLoading(true)
                let dealerBody = { "filter": {} };
                dealerBody['filter']['search_by_text'] = value;
                getDealers(dealerBody).then((dealers) => {
                    if (dealers.status === 200 && dealers.data.length > 0) {
                        setDealerDetail(dealers.data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setDealerDetail([]);
                    }
                });
            }
        }

        else if (name === 'location_dealer') {

            if (value && typeof value === 'object') {
                setSelectedFilters({ ...selectedFilters, [name]: value.value });
                setInspectionDealer(value);
            } else if (!value) {
                setSelectedFilters({ ...selectedFilters, [name]: undefined });
                setInspectionDealer({});
            }
            if (value && typeof value == 'string' && value.length > 2 && !loading) {
                setLoading(true)
                let dealerBody = { "filter": {} };
                dealerBody['filter']['search_by_text'] = value;
                getDealers(dealerBody).then((dealers) => {
                    if (dealers.status === 200 && dealers.data.length > 0) {
                        setlocationDealerArr(dealers.data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setlocationDealerArr([]);
                    }
                });
            }

        }

        else if (name === 'reg_no' || name === 'customerName' || name === 'customerloc_contact' || name === 'customer_address' || name === 'pin_code' || name === 'comments' || name === 'price') {
            if (value) {
                value = value.target.value;
            } else {
                value = undefined;
            }
            setSelectedFilters({ ...selectedFilters, [name]: value })
        }
        else if (name === 'scheduleDate') {
            setTimeslot('');
            setSelectedFilters({ ...selectedFilters, [name]: value })
        }

        else {
            value = value && value.value ? value['value'] : undefined;
            setSelectedFilters({ ...selectedFilters, [name]: value })
        }

    }


    //Static Filter Listing
    const getFilterListing = () => {
        // setLoading(true)
        getinspectionConfig().then((res) => {
            if (res) {
                setInspectionFilterList(res);
            }
        })
    }

    //All Itme-make Model Info
    const getItemDetailInfo = async () => {
        dispatch(allModelMakeInfo()).then((response) => {
            if (response && response.status===200 &&  response.data && response.data.make.length) {
                const make =response?.data?.make.map((el)=>({value:el.id,label:el.make}));
                setLoading(false)
                setMake(make);

            }
        })
    }

    //Get Item Model
    const ModelById = async (id) => {
        setModel([]);
        if (id) {
            setLoading(true)
            if (commonState && commonState.mmvList && commonState.mmvList.data
               && commonState.mmvList.data.model.length) {
                const model =commonState.mmvList.data.model.filter((el)=> el.mk_id===+id).map((el)=>({value:el.id,label:el.m}));
                setModel(model); 
            }else{
                getModelById({ mk_id: id }).then((response) => {
                    if (response && response.data) {
                        setModel(response.data); 
                    }
                })
            }
            setLoading(false);
        }
    }

    //Get Item Version Name
    const VersionById = async (id) => {
        setVersion([]);
        if (id) {
            setLoading(true)
            if (commonState && commonState.mmvList && commonState.mmvList.data
                && commonState.mmvList.data.version.length) {
                const version =commonState.mmvList.data.version.filter((el)=> el.md_id===+id).map((el)=>({value:el.vn_id,label:el.vn}));
                setVersion(version);
            }else{
                getVersionById({ md_id: id }).then((response) => {
                    if (response && response.data) {
                        setVersion(response.data);
                    }
                })
            }
            setLoading(false);  
        }
    }


    useEffect(() => {
        const scheduler_assign_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

        getFilterListing();
        getItemDetailInfo();
        getCityListing();
        setSelectedFilters({
            ...selectedFilters,
            source: "inspection",
            request_by: 2,
            inspection_type: 'inspection',
            reqfor: 1,
            inspection: 1,
            request_source: 'Dealer',
            status: 1,
            scheduler_assign_date: scheduler_assign_date,
            inspection_location_type: 'dealer',
            user_name: userDetails.first_name + ' ' + userDetails.last_name,
            mobile: userDetails.mobile,
            user_role: userDetails.role_title,
            city_list: userDetails.city_id,
            user_id: userDetails.user_id,
            inspection_product: 1

        })

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    // For Validation During Add Form
    const validForm = () => {
        let formIsValid = true;
        setErrField({});

        if ((selectedFilters.make === "" || selectedFilters.make === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                make: t('INSPECTION.SELECT_MAKE'),
            }));
        }

        if ((selectedFilters.model_id === "" || selectedFilters.model_id === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                model_id: t('INSPECTION.SELECT_MODEL'),
            }));
        }
        if ((selectedFilters.version_id === "" || selectedFilters.version_id === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                version_id: t('INSPECTION.SELECT_VARIANT'),
            }));
        }

        if ((selectedFilters.request_owner_id === "" || selectedFilters.request_owner_id === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                request_owner_id: t('INSPECTION.SELECT_DEALER'),
            }));
        }

        if (dealerDiv && (selectedFilters.location_dealer === "" || selectedFilters.location_dealer === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                location_dealer: t('INSPECTION.SELECT_INSPECTION_DEALER'),
            }));
        }

        if ((selectedFilters.scheduleDate === "" || selectedFilters.scheduleDate === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                scheduleDate: t('INSPECTION.SELECT_INSPECTION_TIME'),
            }));
        }

        if (customerDiv && (selectedFilters.customerName === "" || selectedFilters.customerName === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                customerName: t('INSPECTION.SELECT_CUSTOMER_NAME'),
            }));
        }
        if (customerDiv && (!selectedFilters.customerloc_contact || selectedFilters.customerloc_contact.length < 8 || selectedFilters.customerloc_contact.length > 12 || !((/^[8]\d{7,11}$/).test(selectedFilters.customerloc_contact)))) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                customerloc_contact: t('VALIDATION.MOBILE_VALIDATION'),
            }));

        }
        if (customerDiv && (selectedFilters.customer_address === "" || selectedFilters.customer_address === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                customer_address: t('INSPECTION.SELECT_ADDRESS'),
            }));
        }
        if (customerDiv && (!selectedFilters.pin_code || (selectedFilters.pin_code && (selectedFilters.pin_code.length < 6 || selectedFilters.pin_code.length > 6)))) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                pin_code: t('INSPECTION.SELECT_PINCODE'),
            }));
        }

        if (file.length === 0 || file === undefined) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                rc_doc_path: t('INSPECTION.SELECT_RC_DOCUMENT'),
            }));
        }

        if (customerDiv && (selectedFilters.city_id === "" || selectedFilters.city_id === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                city_id: t('INSPECTION.SELECT_CITY'),
            }));
        }
        
        if (isClassifyYes && (selectedFilters.price === "" || selectedFilters.price === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                price: t('INSPECTION.SELECT_PRICE'),
            }));
        }
        if(isClassifyYes &&  selectedFilters.price < 20000){
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                price: t('INSPECTION.SELECT_MIN_PRICE'),
            }));
        }
        return formIsValid;

    };


    //Convert Object To Formdata
    const objectToFormData = (object) => {
        let formData = new FormData();
        if (file.length > 0) {
            for (const RCData of file) {
                formData.append('rc_doc_path', RCData);
            }
        } else {
            formData.append('rc_doc_path', file);
        }

        for (const key in object) {
            if (object.hasOwnProperty(key)) {

                const value = object[key];
                formData.append(key, value);

            }
        }
        return formData;
    };


    // Save All Inspection Request 
    const SaveInspection = () => {
        if (validForm()) {
            setLoading(true)
            setErrField({});

            let completeData;
            // here separate Date And Time merge together

            if (selectedFilters.scheduleDate && selectedFilters.scheduleDate !== '') {

                const dateStr = selectedFilters.scheduleDate;
                const timeStr = timeslot.value;
                const datetime = moment(dateStr);
                const datetimeWithMinute = datetime.hours(timeStr.split(':')[0]).minutes(timeStr.split(':')[1]);
                completeData = datetimeWithMinute.format('DD MMM YYYY HH:mm');

            }

            selectedFilters.scheduleDate = completeData;

            const finalData = objectToFormData(selectedFilters);
            saveInspectionRequest(finalData).then((res) => {
                setLoading(false)
                if (res && res.status === 200) {
                    if (props && props.callFrom && props.callFrom === `raise-request`) {
                        navigate('/dashboard')
                    }
                    else {
                        navigate('/inspection/request')
                    }
                    toast.success(res.message);
                }
            })
        }
    }


    const getCityListing = () => {
        dispatch(getCityList()).then((res) => {
            if (res && res.status === 200) {
                setCityList(res.data);

            }
        })
    }

    /*
        First Time It Call
     Check Request Is Already Created Or Not If Not Then It Will Create New Reuqest
   */
    const SearchRegNoForAdd = () => {
        if (ValidateSearchRegNo()) {
            setLoading(true);
            setErrField({});
            searchByRegNumber(selectedFilters).then((res) => {
                if (res && res.status === 200) {
                    setSearchBtn(true);
                    toast.error(res.message);
                }
                setLoading(false);

            }).catch(err => setLoading(false))
        }

    }


    // When Inspection Request is created Form will show
    const fillAddForm = () => {
        setAddFormShow(true);
    }


    //Validation During Search Reg No.
    const ValidateSearchRegNo = () => {
        let formIsValid = true;
        setErrField({});
        if ((selectedFilters.mmvcategory === "" || selectedFilters.mmvcategory === undefined)) {
            formIsValid = false;

            setErrField((prevState) => ({
                ...prevState,
                mmvcategory: t('INSPECTION.SELECT_CATEGORY'),
            }));

        }

        if ((selectedFilters.vehicle_type === "" || selectedFilters.vehicle_type === undefined)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                vehicle_type: t('INSPECTION.SELECT_SUB_CATEGORY'),
            }));
        }

        if ((selectedFilters.reg_no === '' || selectedFilters.reg_no === undefined || selectedFilters.reg_no.length < 3)) {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                reg_no: t('INSPECTION.SELECT_REG_NO'),
            }));
        }

        return formIsValid
    }


    // Multiple Image Handle
    const handlefileInput = async (event) => {
        let base64PreviewImg;
        let preViewArr = [];
        if (event.target.files.length === 1) {
            // If only one file is selected, set multiple to false
            let selectedFile = event.target.files[0]
            base64PreviewImg = await HELPER.toBase64(selectedFile);
            preViewArr.push(base64PreviewImg)
            setPreViewImg([...preViewArr]);
            setFile(event.target.files[0]);

        }
        else {
            const newImages = Array.from(event.target.files);
            for (const imgSrc of newImages) {
                base64PreviewImg = await HELPER.toBase64(imgSrc);
                preViewArr.push(base64PreviewImg)
            }
            setPreViewImg([...preViewArr]);

            setFile(newImages);
        }

        setSelectedFilters({ ...selectedFilters, rc_doc_path: file })
    };

    const disabledSearchButton = () => {
        setSearchBtn(false);
    }

    //show only coming 4 days rest is freeze
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    
    return (
        <div className="container-fluid-full">

            {/* FOR LOADER */}
            <FullPageLoader show={loading} />

            <div className="top-heading-action-sec">
                <h1>{t("COMMON.INSPECTION_REQUEST")} </h1>
            </div>
            <div className=''>
                <div className="edit-user-sec">
                    <div className="edit-user-list">
                        <div className="form">
                            <div className="row">

                                <fieldset className="form-select-bx col-md-6">
                                    <div className={(selectedFilters.mmvcategory || activeField === 'mmvcategory') ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            value={selectedFilters.mmvcategory ? inspectionFilterList.category.filter((ele) => ele.value === selectedFilters.mmvcategory) : ''}
                                            onChange={(val) => changeHandler(val, "mmvcategory")}
                                            options={inspectionFilterList.category}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown'
                                            isClearable={true}
                                            getOptionValue={(option) => option.value}
                                            isSearchable={false}
                                            isDisabled={searchBtn ? true : false}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('mmvcategory') }}

                                        />
                                        <label data-label={t("INSPECTION.CATEGORY") + " *"} className="form-label"></label>
                                        {errField.mmvcategory && (
                                            <span className="error-txt">
                                                {errField.mmvcategory}
                                            </span>
                                        )}

                                    </div>
                                </fieldset>
                            </div>

                            <div className="row">
                                <fieldset className="col-md-6">
                                    <div className={(selectedFilters.vehicle_type || activeField === 'vehicle_type') ? "active-label material animation-effect" : "material animation-effect "}>
                                        <Select
                                            value={selectedFilters.vehicle_type ? inspectionFilterList.vehicleType.filter((ele) => ele.value === selectedFilters.vehicle_type) : ''}
                                            onChange={(val) => changeHandler(val, "vehicle_type")}
                                            options={inspectionFilterList.vehicleType}
                                            placeholder=""
                                            className="react-select"
                                            classNamePrefix='inspection-dropdown'
                                            isClearable={true}
                                            getOptionValue={(option) => option.value}
                                            isSearchable={false}
                                            isDisabled={searchBtn ? true : false}
                                            onMenuClose={() => setActiveField('')}
                                            onInputChange={() => { setActiveField('vehicle_type') }}

                                        />
                                        <label data-label={t("INSPECTION.VEHICLE_TYPE") + " *"} className="form-label"></label>
                                        {errField.vehicle_type && (
                                            <span className="error-txt">
                                                {errField.vehicle_type}
                                            </span>
                                        )}
                                    </div>
                                </fieldset>
                            </div>

                            <h3> {t("INSPECTION.PRODUCT_TYPE")}    </h3> <br />
                            <div className="custom-control custom-checkbox m-xl-b">
                                {t("INSPECTION.FINANCE")}&nbsp;
                                <input
                                    type="checkbox"
                                    onChange={(val) => changeHandler(val, "product_type")}
                                    className="custom-control-input"
                                    id="product_type"
                                    checked={true}
                                />
                                <label htmlFor="product_type" className="custom-control-label"></label>
                            </div>

                            <div className='row'>
                                <fieldset className="datepicer col-md-6 inspection-reg-no">
                                    <div className={"material"}>
                                        <InputField
                                            inputProps={{
                                                id: "reg_no",
                                                type: "text",
                                                name: "reg_no",
                                                placeholder: " ",
                                                value: selectedFilters.reg_no || '',
                                                className: 'form-input',
                                                maxLength: "9",
                                                disabled: searchBtn ? true : false
                                            }}
                                            onChange={(val) => changeHandler(val, "reg_no")}
                                        />
                                        <label data-label={t("INSPECTION.SEARCH_BY_REGNO") + " *"} className="form-label"></label>
                                        {(searchBtn && addFormShow === false) ? <span className='edit-click' onClick={disabledSearchButton}> {t("INSPECTION.EDIT")} </span> : ''}
                                        {errField.reg_no && (
                                            <span className="error-txt">
                                                {errField.reg_no}
                                            </span>
                                        )}
                                    </div>
                                </fieldset>

                            </div>

                            {addFormShow ? '' :
                                <div>
                                    {searchBtn
                                        ?
                                        <button type="button" className="btn-primary" onClick={fillAddForm}>{t("INSPECTION.REQUEST_INSPECTION")}</button>
                                        :
                                        <button type="button" className="btn-primary" onClick={SearchRegNoForAdd}>{t("COMMON.SEARCH_BTN")} </button>
                                    }


                                </div>
                            }


                            {/* If Inspection is ready to create then form show otherwise not */}
                            {addFormShow &&
                                <>

                                    <div className="row">

                                        <fieldset className="form-select-bx col-md-6">
                                            <div className={(selectedFilters.make || activeField === 'make') ? "active-label material animation-effect" : "material animation-effect "}>
                                                <Select
                                                    value={selectedFilters.make ? make.filter((ele) => ele.value === selectedFilters.make) : ''}
                                                    onChange={(val) => changeHandler(val, "make")}
                                                    options={make}
                                                    placeholder=""
                                                    className="react-select"
                                                    classNamePrefix='inspection-dropdown'
                                                    isClearable={true}
                                                    getOptionValue={(option) => option.value}
                                                    isSearchable={true}
                                                    onMenuClose={() => setActiveField('')}
                                                    onInputChange={() => { setActiveField('make') }}

                                                />
                                                <label data-label={t("INSPECTION.MAKE") + " *"} className="form-label"></label>
                                                {
                                                    errField && errField.make
                                                        ?
                                                        <span className="error-txt">{errField.make}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className="row">

                                        <fieldset className="form-select-bx col-md-6">
                                            <div className={(selectedFilters.model_id || activeField === 'model_id') ? "active-label material animation-effect" : "material animation-effect "}>
                                                <Select
                                                    value={selectedFilters.model_id ? model.filter((ele) => ele.value === selectedFilters.model_id) : ''}
                                                    onChange={(val) => changeHandler(val, "model_id")}
                                                    options={model}
                                                    placeholder=""
                                                    className="react-select"
                                                    classNamePrefix='inspection-dropdown'
                                                    isClearable={true}
                                                    getOptionValue={(option) => option.value}
                                                    isSearchable={true}
                                                    onMenuClose={() => setActiveField('')}
                                                    onInputChange={() => { setActiveField('model_id') }}

                                                />
                                                <label data-label={t("INSPECTION.MODEL") + " *"} className="form-label"></label>
                                                {errField.model_id && (
                                                    <span className="error-txt">
                                                        {errField.model_id}
                                                    </span>
                                                )}
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className="row">
                                        <fieldset className="col-md-6">
                                            <div className={(selectedFilters.version_id || activeField === 'version_id') ? "active-label material animation-effect" : "material animation-effect "}>
                                                <Select
                                                    value={selectedFilters.version_id ? version.filter((ele) => ele.value === selectedFilters.version_id) : ''}
                                                    onChange={(val) => changeHandler(val, "version_id")}
                                                    options={version}
                                                    placeholder=""
                                                    className="react-select"
                                                    classNamePrefix='inspection-dropdown'
                                                    isClearable={true}
                                                    getOptionValue={(option) => option.value}
                                                    isSearchable={true}
                                                    onMenuClose={() => setActiveField('')}
                                                    onInputChange={() => { setActiveField('version_id') }}

                                                />
                                                <label data-label={t("INSPECTION.VARIANT") + " *"} className="form-label"></label>
                                                {errField.version_id && (
                                                    <span className="error-txt">
                                                        {errField.version_id}
                                                    </span>
                                                )}
                                            </div>
                                        </fieldset>
                                    </div>

                                    <h3>{t("INSPECTION.REQUESTED_BY")} </h3>
                                    <br />
                                    <div className="custom-control custom-checkbox m-xl-b">
                                        {t("INSPECTION.DEALER")}&nbsp;
                                        <input
                                            type="checkbox"
                                            checked={true}
                                            onChange={(val) => changeHandler(val, "requestedBy")}
                                            className="custom-control-input"
                                            id="requestedBy"
                                        />
                                        <label htmlFor="requestedBy" className="custom-control-label"></label>
                                    </div>

                                    <div className="row">
                                        <fieldset className="col-md-6">
                                            <div className={(selectedFilters.request_owner_id || activeField === 'request_owner_id') ? "active-label material animation-effect" : "material animation-effect "}>
                                                <Select
                                                    value={selectedFilters && selectedFilters.request_owner_id ? dealerDetail.filter((ele) => +ele.value === +selectedFilters.request_owner_id) : ''}
                                                    onKeyDown={

                                                        (e) => setTimeout(() => { changeHandler(e.target.value, "request_owner_id"); setActiveField('request_owner_id') }, 100)
                                                    }
                                                    options={dealerDetail}
                                                    placeholder=""
                                                    className="react-select"
                                                    classNamePrefix='inspection-dropdown'
                                                    isClearable={true}
                                                    getOptionValue={(option) => option.value}
                                                    getOptionLabel={(option) => option.labelGCD}
                                                    isSearchable={true}
                                                    onMenuClose={() => setActiveField('')}
                                                    onChange={(val) => changeHandler(val, "request_owner_id")}
                                                />

                                                <label data-label={t("INSPECTION.DEALER") + " *"} className="form-label"></label>
                                                {errField.request_owner_id && (
                                                    <span className="error-txt">
                                                        {errField.request_owner_id}
                                                    </span>
                                                )}
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className='row'>
                                        <fieldset className="datepicer col-md-6">
                                            <div className={"material"}>
                                                <InputField
                                                    inputProps={{
                                                        id: "dealership_contact",
                                                        type: "text",
                                                        name: "dealership_contact",
                                                        placeholder: " ",
                                                        value: (singleDealerInfo && singleDealerInfo.dealership_contact) ? `${singleDealerInfo.dealership_contact}` : '',
                                                        className: 'form-input',
                                                        disabled: true
                                                    }}
                                                />
                                                <label data-label={t("INSPECTION.CONTACT_NO")} className="form-label"></label>

                                            </div>
                                        </fieldset>

                                    </div>

                                    {selectedFilters.request_owner_id &&
                                        <>
                                            <h3> {t("INSPECTION.IS_CLASSIFIED")}  </h3>
                                            <br />
                                            <div className='inspection-location-selector'>

                                                <div className="custom-control custom-checkbox">
                                                    {t("Yes")}&nbsp;
                                                    <input
                                                        type="checkbox"
                                                        checked={isClassifyYes}
                                                        onChange={(val) => changeHandler(val, "isClassifiedYes")}
                                                        className="custom-control-input"
                                                        id="is_classified_yes"
                                                    />
                                                    <label htmlFor="is_classified_yes" className="custom-control-label"></label>
                                                </div>

                                                <div className="custom-control custom-checkbox">
                                                    {t("INSPECTION.NO")}&nbsp;
                                                    <input
                                                        type="checkbox"
                                                        checked={isClassifyNo}
                                                        onChange={(val) => changeHandler(val, "isClassifiedNo")}
                                                        className="custom-control-input"
                                                        id="is_classified_no"
                                                    />
                                                    <label htmlFor="is_classified_no" className="custom-control-label"></label>
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                    }

                                    {(isClassifyYes && selectedFilters.request_owner_id) &&
                                        <div className='row'>
                                            <fieldset className="datepicer col-md-6">
                                                <div className={"material"}>
                                                    <InputField
                                                        inputProps={{
                                                            id: "price",
                                                            type: "text",
                                                            name: "price",
                                                            placeholder: " ",
                                                            value: selectedFilters.price || '',
                                                            className: 'form-input',
                                                            maxLength: "50"
                                                        }}
                                                        onChange={(val) => changeHandler(val, "price")}
                                                    />
                                                    <label data-label={t("INSPECTION.PRICE")+' *'} className="form-label"></label>
                                                    {errField.price && (
                                                        <span className="error-txt">
                                                            {errField.price}
                                                        </span>
                                                    )}
                                                </div>
                                            </fieldset>

                                        </div>

                                    }

                                    <h3> {t("INSPECTION.INSPECTION_DEALER")}  </h3>
                                    <br />
                                    <div className='inspection-location-selector'>

                                        <div className="custom-control custom-checkbox">
                                            {t("INSPECTION.DEALER")}&nbsp;
                                            <input
                                                type="checkbox"
                                                checked={dealerDiv}
                                                onChange={(val) => changeHandler(val, "inspection_location_type")}
                                                className="custom-control-input"
                                                id="dealers"
                                                value="dealer"
                                            />
                                            <label htmlFor="dealers" className="custom-control-label"></label>
                                            {errField.inspectionDealer && (
                                                <span className="error-txt">
                                                    {errField.inspectionDealer}
                                                </span>
                                            )}
                                        </div>

                                        <div className="custom-control custom-checkbox">
                                            {t("INSPECTION.CUSTOMER")}&nbsp;
                                            <input
                                                type="checkbox"
                                                checked={customerDiv}
                                                onChange={(val) => changeHandler(val, "inspection_location_type")}
                                                className="custom-control-input"
                                                id="customer"
                                                value="consumer"
                                            />
                                            <label htmlFor="customer" className="custom-control-label"></label>
                                            {errField.inspectionCustomer && (
                                                <span className="error-txt">
                                                    {errField.inspectionCustomer}
                                                </span>
                                            )}
                                        </div>

                                    </div>
                                    <br />
                                    {/* --------------------- Dealer form  ---------------------------*/}
                                    {dealerDiv &&
                                        (<>
                                            <div className="row">
                                                <fieldset className="form-select-bx col-md-6">
                                                    <div className={(selectedFilters.location_dealer || activeField === 'location_dealer') ? "active-label material animation-effect" : "material animation-effect "}>

                                                        <Select
                                                            value={selectedFilters && selectedFilters.location_dealer ? locationDealerArr.filter((ele) => +ele.value === +selectedFilters.location_dealer) : ''}
                                                            onKeyDown={

                                                                (e) => setTimeout(() => { changeHandler(e.target.value, "location_dealer"); setActiveField('location_dealer') }, 100)
                                                            }
                                                            options={locationDealerArr}
                                                            placeholder=""
                                                            className="react-select"
                                                            classNamePrefix='inspection-dropdown'
                                                            isClearable={true}
                                                            getOptionValue={(option) => option.value}
                                                            getOptionLabel={(option) => option.labelGCD}
                                                            isSearchable={true}
                                                            onMenuClose={() => setActiveField('')}
                                                            onChange={(val) => changeHandler(val, "location_dealer")}
                                                        />



                                                        <label data-label={t("INSPECTION.DEALER") + " *"} className="form-label"></label>
                                                        {
                                                            errField && errField.location_dealer
                                                                ?
                                                                <span className="error-txt">{errField.location_dealer}</span>
                                                                :
                                                                ''
                                                        }
                                                    </div>
                                                </fieldset>
                                            </div>

                                            {(selectedFilters.location_dealer && selectedFilters.location_dealer !== '') &&
                                                <div>
                                                    <p>{(inspectionDealer && inspectionDealer.city_name) ? `Dealer City : ${inspectionDealer.city_name}` : ''}</p>
                                                    <p>{(inspectionDealer && inspectionDealer.dealership_contact) ? `Contact : ${inspectionDealer.dealership_contact}` : ''}</p>
                                                    <p>{(inspectionDealer && inspectionDealer.address) ? `Address: ${inspectionDealer.address}` : ''}</p><br />
                                                </div>
                                            }
                                        </>)
                                    }



                                    {/* -------------customer form  - ----------------------*/}
                                    {customerDiv &&
                                        (<>
                                            <div className='row'>
                                                <fieldset className="datepicer col-md-6">
                                                    <div className={"material"}>
                                                        <InputField
                                                            inputProps={{
                                                                id: "customerName",
                                                                type: "text",
                                                                name: "customerName",
                                                                placeholder: " ",
                                                                value: selectedFilters.customerName || '',
                                                                className: 'form-input',
                                                                maxLength: "50"
                                                            }}
                                                            onChange={(val) => changeHandler(val, "customerName")}
                                                        />
                                                        <label data-label={t("INSPECTION.CUSTOMER_NAME") + " *"} className="form-label"></label>
                                                        {errField.customerName && (
                                                            <span className="error-txt">
                                                                {errField.customerName}
                                                            </span>
                                                        )}
                                                    </div>
                                                </fieldset>

                                            </div>


                                            <div className='row'>
                                                <fieldset className="datepicer col-md-6">
                                                    <div className={"material"}>
                                                        <InputField
                                                            inputProps={{
                                                                id: "customerloc_contact",
                                                                type: "number",
                                                                name: "customerloc_contact",
                                                                placeholder: " ",
                                                                value: selectedFilters.customerloc_contact || '',
                                                                className: 'form-input',
                                                                maxLength: "12"
                                                            }}
                                                            onChange={(val) => changeHandler(val, "customerloc_contact")}
                                                        />
                                                        <label data-label={t("INSPECTION.CONTACT_NO") + " *"} className="form-label"></label>
                                                        {errField.customerloc_contact && (
                                                            <span className="error-txt">
                                                                {errField.customerloc_contact}
                                                            </span>
                                                        )}
                                                    </div>
                                                </fieldset>

                                            </div>


                                            <div className='row'>
                                                <fieldset className="datepicer col-md-6">
                                                    <div className={"material"}>
                                                        <InputField
                                                            inputProps={{
                                                                id: "customer_address",
                                                                type: "text",
                                                                name: "customer_address",
                                                                placeholder: " ",
                                                                value: selectedFilters.customer_address || '',
                                                                className: 'form-input',
                                                                maxLength: "50"
                                                            }}
                                                            onChange={(val) => changeHandler(val, "customer_address")}
                                                        />
                                                        <label data-label={t("INSPECTION.ADDRESS") + " *"} className="form-label"></label>
                                                        {errField.customer_address && (
                                                            <span className="error-txt">
                                                                {errField.customer_address}
                                                            </span>
                                                        )}
                                                    </div>
                                                </fieldset>

                                            </div>

                                            <div className='row'>
                                                <fieldset className="datepicer col-md-6">
                                                    <div className={"material"}>
                                                        <InputField
                                                            inputProps={{
                                                                id: "pin_code",
                                                                type: "text",
                                                                name: "pin_code",
                                                                placeholder: " ",
                                                                value: selectedFilters.pin_code || '',
                                                                className: 'form-input',
                                                                maxLength: "6",
                                                            }}
                                                            onChange={(val) => changeHandler(val, "pin_code")}
                                                        />
                                                        <label data-label={t("INSPECTION.PINCODE") + " *"} className="form-label"></label>
                                                        {errField.pin_code && (
                                                            <span className="error-txt">
                                                                {errField.pin_code}
                                                            </span>
                                                        )}
                                                    </div>
                                                </fieldset>

                                            </div>

                                            <div className='row'>
                                                <fieldset className="datepicer col-md-6">
                                                    <div className={(selectedFilters.city_id || activeField === 'city_id') ? "active-label material animation-effect" : "material animation-effect "}>
                                                        <Select
                                                            value={selectedFilters.city_id ? cityList.filter((ele) => ele.value === selectedFilters.city_id) : ''}
                                                            onChange={(val) => changeHandler(val, "city_id")}
                                                            options={cityList}
                                                            placeholder=""
                                                            className="react-select"
                                                            classNamePrefix='inspection-dropdown'
                                                            isClearable={true}
                                                            getOptionValue={(option) => option.value}
                                                            isSearchable={true}
                                                            onMenuClose={() => setActiveField('')}
                                                            onInputChange={() => { setActiveField('city_id') }}

                                                        />
                                                        <label data-label={t("FORM.CITY")+ ' *'} className="form-label"></label>
                                                        {errField.city_id && (
                                                            <span className="error-txt">
                                                                {errField.city_id}
                                                            </span>
                                                        )}
                                                    </div>
                                                </fieldset>

                                            </div>

                                        </>
                                        )}

                                    {/* end here customer div */}


                                    <div className='row'>
                                        <fieldset className="create-date col-md-3" >
                                            <div className={selectedFilters.scheduleDate ? "active-label material animation-effect" : "material animation-effect "}>
                                                <div className="datepicker">

                                                    <DatePicker
                                                        selected={selectedFilters.scheduleDate ? new Date(selectedFilters.scheduleDate) : null}
                                                        onChange={(val) => changeHandler(val, "scheduleDate")}
                                                        dateFormat="d MMMM yyyy"
                                                        placeholderText=""
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}

                                                        minDate={new Date()}
                                                        maxDate={maxDate}
                                                    />

                                                </div>
                                                <label data-label={t("INSPECTION.SCHEDULE_DATE") + " *"} className="form-label"></label>
                                                {errField.scheduleDate && (
                                                    <span className="error-txt">
                                                        {errField.scheduleDate}
                                                    </span>
                                                )}
                                            </div>
                                        </fieldset>


                                        {/* start here  */}

                                        <fieldset className="create-date col-md-2" >
                                            <div className={(timeslot && timeslot.value) ? "active-label material animation-effect" : "material animation-effect "}>
                                                <div className="datepicker" >
                                                    <Select
                                                        options={timeslots}
                                                        value={timeslot}
                                                        onChange={setTimeslot}
                                                        className="react-select"
                                                        classNamePrefix='inspection-dropdown'
                                                        placeholder=""
                                                        id="timeslot"
                                                        isOptionDisabled={option => option.isDisabled}
                                                        isDisabled={(selectedFilters && selectedFilters.scheduleDate) ? false : true}
                                                    />

                                                </div>
                                                <label data-label={t("INSPECTION.SCHEDULE_TIME") + " *"} className="form-label"></label>
                                            </div>
                                        </fieldset>

                                        {/* end here */}


                                    </div>

                                    <div className='row'>
                                        <fieldset className="form-select-bx col-md-4">
                                            <div className={"material animation-effect "}>
                                                <span className="label">{t("INSPECTION.ATTACH_RC") + " *"} </span>
                                                <input
                                                    type="file"
                                                    name="rc_doc_path"
                                                    id="rc_doc_path"
                                                    className="upload-box"
                                                    accept="image/*"
                                                    onChange={(e) => handlefileInput(e)}
                                                    multiple
                                                />
                                                {selectedFilters && selectedFilters.rc_doc_path ? <BannerPreview element={{ image: [{ "image_preview_path": previewImg }] }}  previewTitle='Preview RC' /> : ''}
                                                {
                                                    errField && errField.rc_doc_path
                                                        ?
                                                        <span className="error-txt">{errField.rc_doc_path}</span>
                                                        :
                                                        ''
                                                }
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className="inspection-location-selector m-xl-b">
                                        <div className="custom-control custom-checkbox">
                                            {t("INSPECTION.PRIORITY")}&nbsp;
                                            <input
                                                type="checkbox"
                                                //  checked={cancellChecked}
                                                onChange={(val) => changeHandler(val, "priority")}
                                                className="custom-control-input"
                                                id="priority"
                                            />
                                            <label htmlFor="priority" className="custom-control-label"></label>
                                        </div>

                                        <div className="custom-control custom-checkbox">
                                            {t("INSPECTION.SCHEDULE_FIXED")}&nbsp;
                                            <input
                                                type="checkbox"
                                                //  checked={cancellChecked}
                                                onChange={(val) => changeHandler(val, "schedule_fix")}
                                                className="custom-control-input"
                                                id="schedule_fix"
                                            />
                                            <label htmlFor="schedule_fix" className="custom-control-label"></label>
                                            {/* {errField.schedule_fix && (
                                            <span className="error-txt">
                                                {errField.schedule_fix}
                                            </span>
                                        )} */}
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <fieldset className="form-filed">
                                                <div className="material">
                                                    <textarea placeholder=" " className="form-input" rows="3"
                                                        onChange={(val) => changeHandler(val, "comments")}
                                                        maxLength="250"
                                                    ></textarea>
                                                    <label data-label={t("INSPECTION.COMMENT")} className="form-label"></label>

                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="btn-submit-cancel">
                                        <button type="button" className="btn-primary" onClick={SaveInspection}>{t("COMMON.SUBMIT_BTN")} </button>

                                    </div>

                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddInspectionForm