import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"
import Dateformat from 'dateformat';
import { StartEndDateofAWeek } from "@src/config/helper";
import FullPageLoader from "@src/view/common/FullPageLoader";
import CeCalender from "@src/view/inspection/AssignCe/calenderHtml/CeCalender"
import { inspectionCeData, getCeBookingListOfAWekk } from "@src/store/slices/inspection";




const InspectionList = (props) => {
    const queryParams = useParams();
    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(false);
    const [ceDetail, setCeDetail] = useState({});
    const [ceLastLocation, setCeLastLocation] = useState({});
    const [timeRangeStart, setTimeRangeStart] = useState();
    const [timeRangeEnd, setTimeRangeEnd] = useState();
    const [ceBookingData, setCeBookingData] = useState([]);
    const [certificationCount, setCertificationCount] = useState();
    const [totalRequest, setTotalRequest] = useState();
    const [inspectionCount, setInspectionCount] = useState();
    const [ceId, setCeId] = useState();

    useEffect(() => {
        setLoading(true);
        getCeData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /*
    *Get Inspection Ce Data
    */
    const getCeData = async () => {
        

        let ce_id = (queryParams.ceId) ? queryParams.ceId : '';
        setCeId(ce_id);
        ceInspectionData({ ceId: ce_id });
    }

    /*
    * CE Inspection Details
    */
    const ceInspectionData = async (params) => {
        let ce_id = (queryParams.ceId) ? queryParams.ceId : '';
        let StartEndDate = StartEndDateofAWeek();
        setTimeRangeStart(StartEndDate.start);
        setTimeRangeEnd(StartEndDate.end);

        inspectionCeData(params).then((response) => {
            if (response.status === 200) {
                setLoading(false);

                if (Object.keys(response.data).length > 0) {
                    setCeDetail(response.data.detail);
                    setCeLastLocation(response.data.last_location);
                    ceInspectionBookingList({ ce_id: ce_id, timeRangeStart: StartEndDate.start, timeRangeEnd: StartEndDate.end });
                }
            }else{
                setTimeout(() => {
                    window.close()
                }, 5000);
            }
        }).catch((error) => {
            setLoading(false);
        })
    }

    /*
    * CE Inspection Booking Data
    */
    const ceInspectionBookingList = async (params) => {
        getCeBookingListOfAWekk(params).then((response) => {
            if (response.status === 200) {
                let data = response.data;
                setCeBookingData([...data.data]);
                setCertificationCount(data.certificationCount);
                setTotalRequest(data.totalRequest);
                setInspectionCount(data.inspectionCount);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        })
    }

    /*
    *Calculate Prev Week Date
    */
    const prevDate = (start) => {
        setLoading(true);
        let sd = new Date(start);
        let ed = new Date(start)
        let newStartDate = Dateformat(sd.setDate(sd.getDate() - 7), 'yyyy-mm-dd');
        let newEndDate = Dateformat(ed.setDate(ed.getDate() - 1), 'yyyy-mm-dd');
        setTimeRangeStart(newStartDate);
        setTimeRangeEnd(newEndDate);
        ceInspectionBookingList({ ce_id: ceId, timeRangeStart: newStartDate, timeRangeEnd: newEndDate });
    }

    /*
     *Calculate Next Week Date
    */
    const nextDate = (end) => {
        setLoading(true);
        let sd = new Date(end);
        let ed = new Date(end)
        let newStartDate = Dateformat(sd.setDate(sd.getDate() + 1), 'yyyy-mm-dd');
        let newEndDate = Dateformat(ed.setDate(ed.getDate() + 7), 'yyyy-mm-dd');
        setTimeRangeStart(newStartDate);
        setTimeRangeEnd(newEndDate);
        ceInspectionBookingList({ ce_id: ceId, timeRangeStart: newStartDate, timeRangeEnd: newEndDate });
    }

    return (
        <div className='container p-md-t ceinspection'>
            <FullPageLoader show={loading} />
            <div className="ceinspection p-md-b" id="ceinspection">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab-content p-md-t">
                            <h2 className="card-head">{ceDetail.name}<span className="text-light">(Id-{ceDetail.id})</span></h2>
                            <div className="dtList">
                                <ul className="list-unstyled row">
                                    <li className="col-md-2">
                                        <label>{t("INSPECTION_LIST.LAST_LOC")}</label>
                                        <p>{ceLastLocation.gps_location}</p>
                                    </li>
                                    <li className="col-md-2">
                                        <label>{t("INSPECTION_LIST.BASE_LOC")}</label>
                                        <p>{ceDetail.gps_location}</p>
                                    </li>
                                    <li className="col-md-2">
                                        <label>{t("INSPECTION_LIST.CONTACT_NO")}</label>
                                        <p>{ceDetail.mobile}</p>
                                    </li>
                                    <li className="col-md-2">
                                        <label>{t("INSPECTION_LIST.TOTAL_REQ")}</label>
                                        <p id="total_request">{totalRequest}</p>
                                    </li>

                                    <li className="col-sm-2">
                                        <label>{t("INSPECTION_LIST.INSPECTION")}</label>
                                        <p id="ins_request">{inspectionCount}</p>
                                    </li>
                                    <li className="col-sm-2">
                                        <label>{t("INSPECTION_LIST.CERTIFICATE")}</label>
                                        <p id="certificationn_req">{certificationCount}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="calanderBox" id='calanderBox'>
                <CeCalender
                    timeRangeStart={timeRangeStart}
                    timeRangeEnd={timeRangeEnd}
                    ceBookingData={ceBookingData}
                    prevDate={prevDate}
                    nextDate={nextDate}
                />
            </div>


        </div>
    );

}



export default InspectionList;