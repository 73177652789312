import React, {useState } from 'react';
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import InputField from '@src/view/element/InputField';
import { DateFormat } from "../../config/helper";
import { toast } from "react-toastify";
import { generateRosterReport } from "@src/store/slices/roster";
import FullPageLoader from "@src/view/common/FullPageLoader"

const Report = () => {
    const { t } = useTranslation("common");
    const [reportData, setReport] = useState({});
    const [errField, setErrField] = useState();
    const [loading, setLoading] = useState(false)

    /*
    *Reset report Data
    */
    const resetRosterData = () => {
        setReport({});
    }

    const changeHandler = (value, name) => {
        let newVal = ""
        if (name === "email_id") {
            newVal = value.target.value;
        } else if (name === "from" || name === "to") {
            newVal = DateFormat(value);
        } else {
            newVal = value && value.value ? value['value'] : undefined;       
        }
        
        if(!newVal){
            delete reportData[name];
            setReport({ ...reportData});
        }else{
            setReport({ ...reportData, [name]: newVal });
        }
    }
    
    /*
    *Generate Report and send Mail
    */
    const sendRosterReport=()=>{
        if (ValidateFields()) {
            setLoading(true)
            setErrField('');

            if(!reportData.to){
                reportData['to'] = reportData['from']
            }
            generateRosterReport(reportData).then((response) =>{
                if(response.status===200 && response.data!==undefined){
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = response.data;
                    a.download = 'roster_report.xlsx';
                    document.body.appendChild(a);
                    a.click();
                   toast.success(response.message);
                }else{
                    toast.error(response.message);
                }
               setLoading(false); 
            }).catch((error) => {
                setLoading(false);
            });
        }else{
            toast.error(errField);
        }
    }

    const ValidateFields=()=>{
        let formIsValid = true;
        if(reportData.from && reportData.email_id){
            if(reportData.email_id){
                let emailVal = reportData.email_id.replace(/(^,)|(,$)/g, "");
                let filter = /^\s*((\s*[a-zA-Z0-9\._%-]+@[a-zA-Z0-9\.-]+\.[a-zA-Z]{2,4}\s*[,]{1}\s*){1,100}?)?([a-zA-Z0-9\._%-]+@[a-zA-Z0-9\.-]+\.[a-zA-Z]{2,4})\s*$/; // eslint-disable-line
                if (!filter.test(emailVal)) {
                    formIsValid=false;
                    setErrField(t("ROSTER.ERRORS.ENTER_VALID_EMAIL"));
                }
            }
            
        }else{
            formIsValid=false;
            setErrField(t("ROSTER.ERRORS.ENTER_VALID"))
        }
        return formIsValid;
    }

    return (
        <div className="container-fluid-full">
            <FullPageLoader show={loading} />
            <div className="top-heading-action-sec  p-md-t">
                <h1>{t("ROSTER.HEADING.REPORT")} </h1>
            </div>
            <div className='payout-summary-tabs p-md roster-temp'>
                <div className="form">
                    <div className="row">
                        <fieldset className="create-date col-md-2" >
                            <div className={reportData.from ? "active-label material" : "material"}>
                                <div className="datepicker">
                                    <DatePicker
                                        selected={reportData.from ? new Date(reportData.from) : null}
                                        onChange={(val) => changeHandler(val, "from")}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={t("ROSTER.FIELD.FROM") + " *"}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        maxDate={reportData.to ? new Date(reportData.to) : null}

                                    />
                                </div>
                                <label data-label={t("ROSTER.FIELD.FROM")+'*'} className="form-label"></label>

                            </div>
                        </fieldset>

                        <fieldset className="create-date col-md-2" >
                            <div className={(reportData.to || reportData.from) ? "active-label material animation-effect" : "material animation-effect "}>
                                <div className="datepicker">
                                    
                                        <DatePicker
                                            selected={(reportData.to || reportData.from) ? ( (reportData.from && !reportData.to) ? new Date(reportData.from) : new Date(reportData.to)) : null}
                                            onChange={(val) => changeHandler(val, "to")}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={t("ROSTER.FIELD.To") + " *"}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            minDate={reportData.from ? new Date(reportData.from) : null}
                                        />

                                </div>
                                <label data-label={t("ROSTER.FIELD.To")+'*'} className="form-label"></label>

                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className={reportData.email_id ? "active-label material animation-effect" : "material animation-effect "}>
                            <InputField
                                inputProps={{
                                    id: "email_id",
                                    type: "text",
                                    name: "email_id",
                                    placeholder:t("ROSTER.FIELD.EMAIL")+'*' ,
                                    value: reportData.email_id || '',
                                    className: 'form-input'
                                }} 
                                onChange={(val) => changeHandler(val, "email_id")}

                            />         
                            </div>
                        </fieldset>

                        <div className="col-md-4 btn-submit-reset">
                            <button type="button" className="btn-primary p-md-l p-md-r" id="report" onClick={sendRosterReport} >{t("ROSTER.BTN.REPORT")}</button>
                            <button type="button" className="btn-reset" id="reset" onClick={resetRosterData} >{t("ROSTER.BTN.RESET")}</button>
                        </div>

                        
                    </div>
                </div>        
            </div>
        </div>
         
    );
}



export default Report;