import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { getCsvExportBackOffice } from "@src/store/slices/backOffice";
import FullPageLoader from "@src/view/common/FullPageLoader";
import { exportUserData } from "@src/store/slices/user";
import { useSelector } from "react-redux";
import { getCsvExport } from "@src/store/slices/inspection"


function DownloadExcelButton(props) {
    const { t } = useTranslation("common");
    const [loading, setLoading] = useState(false);

    const disableButton = (props && props.List && props.List.length > 0) ? false : true;

    const userRedux = useSelector(state => state.auth);
    const inspectionState = useSelector(state => state.inspection);

    const handleDownloadClick = () => {
        let newObj;
        let params = props.searchFilterList;
        if (props?.pageType === 'DealerQc') {
            backOfficeCsv(params);
        }

        if (props && (props.pageType === 'UserListing' || props.pageType === 'CeListing')) {
            let params = props.searchFilterList;
            UserCeListCsv(params)
        }
        // IF page type is inspection
        if (props && props.pageType === 'Inspection') {
            // let params;
            // if filter selected data searched and store in redux storage then it go as params else all param hit
            if (inspectionState.inspectionFilters && Object.keys(inspectionState.inspectionFilters).length) {
                newObj = inspectionState.inspectionFilters;
            }
            else {
                newObj = JSON.parse(JSON.stringify(props));
                delete newObj.List;
            }

            InspectionListCsv(newObj)
        }
    }

    /*BackOffice csv Data */
    const backOfficeCsv = (params) => {
        setLoading(true);
        getCsvExportBackOffice(params).then((res) => {
            setLoading(false)
            if (res && res.status === 200) {
                window.location.href = res.data;
            }
        }).catch(err => {
            setLoading(false)
        })
    }


    const UserCeListCsv = async (params) => {
        setLoading(true)

        const filters = userRedux && userRedux.selectedFilters

        let filterObj = {
            ...filters,

            //adding filters from props
            ...props.searchFilterList,
        }

        //deleting empty/undefined keys
        Object.keys(filterObj).forEach((ele) => {
            if (!filterObj[ele]) {
                delete filterObj[ele]
            }
        })

        const response = await exportUserData(filterObj)
        const url = response && response.data

        if (url) {
            window.location.assign(url);
        }
        setLoading(false)
    }


    const InspectionListCsv = (params) => {
        setLoading(true);

        getCsvExport(params).then((res) => {
            setLoading(false)
            if (res && res.status === 200) {
                window.location.href = res.data;

            }
        }).catch(err => {
            setLoading(false)
        })
    }


    return (
        <div>
            <FullPageLoader show={loading} />
            <button className="btn-line" disabled={disableButton}
                onClick={handleDownloadClick}>
                <i className="ic-pdf-file"></i>
                <span>{t("COMMON.DOWNLOAD_EXCEL")} </span>
            </button>
        </div>
    );
}


export default DownloadExcelButton;