import { createSlice } from '@reduxjs/toolkit'
import { toast } from "react-toastify";
import { CommonService } from '@src/services/CommonService';


const common = createSlice({
    name: 'common',
    initialState: {
        common: {},
        schedulerData: []
    },
    reducers: {
        getSchedulerData: (state, action) => {
            state.schedulerData = action.payload;
        },

        setInspectionFilters: (state, action) => {
            state.inspectionFilters = action.payload;
        },

        stateCityList: (state, action) => {
            state.stateCityList = action.payload;
        },

        cityList: (state, action) => {
            state.cityList = action.payload;
        },
        setImageTags: (state, action) => {
            state.imageTags = action.payload;
        },

        setColorList: (state, action) => {
            state.colorList = action.payload;
        },

        certificationData: (state, action) => {
            state.certificationData = action.payload;
        },

        setMakeList: (state, action) => {
            state.mmvList = action.payload;
        },
        setModelList: (state, action) => {
            state.mmvList = action.payload;
        },
        setVersionList: (state, action) => {
            state.mmvList = action.payload;
        },
        setMMVList: (state, action) => {
            state.mmvList = action.payload;
        }

    },
});

export default common.reducer;

const { setInspectionFilters, getSchedulerData, stateCityList, cityList, certificationData, setImageTags, setColorList, setMMVList, setMakeList, setModelList , setVersionList} = common.actions

/**
 * API call to get City List
 * @param {Object} params 
 * @returns 
 */
export const getCityList = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        CommonService.getCityList(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(cityList(response.data))
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

/**
 * API call to fetch menu List
 * @param {Object} params 
 * @returns 
 */
export const getMenus = (params) => {
    return new Promise((resolve, reject) => {
        CommonService.getMenus(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

/**
 * API call to get Dealer listing
 * @param {Object} params 
 * @returns 
 */
export const getDealers = (params) => {
    return new Promise((resolve, reject) => {
        CommonService.getDealers(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

/**
 * API call to return state and city list
 * @param {Object} params 
 * @returns 
 */
export const getStateCityList = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        CommonService.getStateCityList(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                        dispatch(stateCityList(response.data))

                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

/**
 * API call to fetch static lists (statuslist, userfor list, etc...)
 * @param {Object} params 
 * @returns 
 */
export const getInspectionFilters = (params) => async dispatch => {
    return new Promise((resolve, reject) => {
        CommonService.getConfigDetails(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data.data)
                        dispatch(setInspectionFilters(response.data.data));

                    }
                    else {
                        let errorMsg = (response && response.data && response.data.message) || ''
                        if (errorMsg) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


/**
 * API call to get Clients listing
 * @param {Object} params 
 * @returns 
 */
export const getAllClientsData = (params) => {
    return new Promise((resolve, reject) => {
        CommonService.getAllClients(params).then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

//getItemMakeModel
export const getItemMakeModel = (params) => {
    return new Promise((resolve, reject) => {
        CommonService.getItemMakeModel(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

//getModelById
export const getModelById = (params) => {
    return new Promise((resolve, reject) => {
        CommonService.getModelById(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


//getVersionById
export const getVersionById = (params) => {
    return new Promise((resolve, reject) => {
        CommonService.getVersionById(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}


export const setSchedulerData = (params) => dispatch => {
    dispatch(getSchedulerData(params))
}

export const getCertificationData = (params) => dispatch => {

    return new Promise((resolve, reject) => {
            CommonService.getCertificationDataById(params)
            .then(
                response => {
                   resolve(response && response.data);
                   dispatch(certificationData(response && response.data))
                },
                error => {
                    reject(error);
                }
            );
    });

}

export const getImageTags = (params) => dispatch => {
    return new Promise((resolve, reject) => {
            CommonService.getImageTags(params)
            .then(
                response => {
                   resolve(response && response.data);
                   dispatch(setImageTags((response && response.data) || []));
                },
                error => {
                    reject(error);
                }
            );
    });
}

export const getColorList = (params) => dispatch => {
    return new Promise((resolve, reject) => {
            CommonService.getColorList(params)
            .then(
                response => {
                   resolve(response && response.data);
                   dispatch(setColorList((response && response.data) || []));
                },
                error => {
                    reject(error);
                }
            );
    });
}

export const allModelMakeInfo = (params) => dispatch => {
    return new Promise((resolve, reject) => {
            CommonService.allModelMakeInfo(params)
            .then(
                response => {
                   resolve(response.data);
                   dispatch(setMMVList(response.data));
                },
                error => {
                    reject(error);
                }
            );
    });
}

export const submitCertificationData = (params) => {
    return new Promise((resolve, reject) => {
        CommonService.submitCertificationData(params)
            .then(
                response => {
                    if ((response && response.data && response.data.status) === 200) {
                        toast.success(response.data.message);
                        resolve(response.data)
                    }
                    else {
                        if(response && response.data) toast.error(response.data.message);
                        resolve(response && response.data)
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

export const allMakeInfo = (params) => dispatch => {
    return new Promise((resolve, reject) => {
            CommonService.allMakeInfo(params)
            .then(
                response => {
                   resolve(response && response.data);
                   dispatch(setMakeList(response && response.data));
                },
                error => {
                    reject(error);
                }
            );
    });
}
export const allModelInfoById = (params) => dispatch => {
    return new Promise((resolve, reject) => {
            CommonService.allModelInfoById(params)
            .then(
                response => {
                   resolve(response && response.data);
                   dispatch(setModelList(response && response.data));
                },
                error => {
                    reject(error);
                }
            );
    });
}
export const allVersionInfoById = (params) => dispatch => {
    return new Promise((resolve, reject) => {
            CommonService.allVersionInfoById(params)
            .then(
                response => {
                   resolve(response && response.data);
                   dispatch(setVersionList(response && response.data));
                },
                error => {
                    reject(error);
                }
            );
    });
}

export const getSignedImage = (params) => {
    return new Promise((resolve, reject) => {
            CommonService.getSignedImage(params)
            .then(
                response => {
                   resolve(response && response.data);
                },
                error => {
                    reject(error);
                }
            );
    });
}
