import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router';
import AddInspectionForm from '@src/view/inspection/common/AddInspectionForm'
import { useTranslation } from "react-i18next";
import secureStorage from '../../config/encrypt';




const RaiseRequest = () => {
    const { t } = useTranslation("common")
    const navigate = useNavigate();
    const [isVerified, setIsVerified] = useState(true)
    const [isTokenSet, setIsTokenSet] = useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search)
        const queryString = searchParams && searchParams.get('q')

        if (queryString) {
            let decodedData = window.atob(queryString)

            let keysTocheck = ['user_id', 'userName', 'source', 'role', 'city_id', 'mobile_number', 'clientID', 'token']
            let isVerified = true

            let decodedValues = decodedData && decodedData.split('&')
            let decodedParams = []

            //pushing all decoded values in array
            if (decodedValues && decodedValues.length) {
                for (let data of decodedValues) {
                    let key = (data && data.split('=')[0]) || ''
                    let value = (data && data.split('=')[1]) || ''
                    decodedParams.push({ key, value })

                }
            }

            //validating keys
            if (decodedParams.length) {
                for (let data of decodedParams) {
                    if (!keysTocheck.includes(data.key)) {
                        setIsVerified(false)
                    }
                }
            }

            if (!isVerified) {
                toast.error(t('VIEW_STATUS.INVALID_CREDENTIALS'))
                navigate("/dashboard")
            }


            //set token
            let sessionObj = {
                token: decodedParams[7].value,
                user_data: {
                    first_name: decodedParams[1].value,
                    user_id: decodedParams[0].value
                }
            }


            secureStorage.setItem('authUserInfo', sessionObj);
            setIsTokenSet(true)

        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        (isVerified && isTokenSet) ?
            <div>
                <AddInspectionForm callFrom={'raise-request'} />
            </div> : ''
    )
}


export default RaiseRequest;