import React, { useState } from 'react'
import Select from "react-select"
import { useSelector, useDispatch } from 'react-redux';
import { getAssignScheduler } from "@src/store/slices/inspection"
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next"
import { setSchedulerData } from '@src/store/slices/common';
import FullPageLoader from "@src/view/common/FullPageLoader"

function AssignScheduler(props) {
    const [selectedFilters, setSelectedFilters] = useState(props.assignTo || {})
    const [loading, setLoading] = useState(false)
    const changeHandler = (value, name) => {
        let newVal = ""
        newVal = value && value.value ? value['value'] : undefined;
        setSelectedFilters({ ...selectedFilters, [name]: newVal })
    }
    const dispatch = useDispatch();

    const { t } = useTranslation("common")

    const commonState = useSelector(state => state.common);
    const assignScheduler = () => {
        getAssignSchedulers(commonState.schedulerData)

    }


    const getAssignSchedulers = (params) => {
        setLoading(true)
        let newParms = {
            booking_id: params, scheduler_id: selectedFilters.assignTo
        }

        getAssignScheduler(newParms).then((res) => {
            setLoading(false)
            if (res && res.status === 200) {
                toast.success(res.message);
                // Redux will empty after success and uncheck the selectbox
                dispatch(setSchedulerData([]));
                props.searchFilter()
            }
        })
    }



    return (
        <>
            {/* FOR LOADER */}
            <FullPageLoader show={loading} />
            <fieldset className='search-bx'>
                <div className="material">
                    <Select
                        value={props.assignTo.find(obj => obj.options === selectedFilters.assignTo)}
                        onChange={(val) => changeHandler(val, "assignTo")}
                        options={props.assignTo}
                        placeholder="Select Scheduler"
                        className="react-select"
                        classNamePrefix='inspection-dropdown react-select'
                        isClearable={true}
                        getOptionValue={(option) => option.value}
                    />

                </div>
            </fieldset>

            <div className="btn-submit-reset">
                <button className="btn-success" onClick={assignScheduler}> {t("COMMON.ASSIGN_BTN")}</button>
            </div>
        </>

    )
}

export default AssignScheduler