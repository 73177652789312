import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import Pagination from '@src/view/common/Pagination'
//import { setSchedulerData } from '@src/store/slices/common';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import * as HELPER from "@src/config/helper";
import ReportDocumentModal from '@src/view/back_office/reports/ReportDocumentModal';

import FinalSubmitModal from '@src/view/dealer-qc/common/FinalSubmitModal'
import { addFinishTime, checkUploadFinishStatus} from "@src/store/slices/dealerQc";
import { toast } from "react-toastify";

const DealerQcTable = (props) => {
    const { t } = useTranslation("common")
    const [loading, setLoading] = useState(props.loading)
    const [tableData, setTableData] = useState([])
    const [paginationData, setPaginationData] = useState({})
    const page = 1
    const totalLengthWithoutPagination = 0
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showGalleryPopup, setShowGalleryPopup] = useState('');
    
    // const [showDocumentReportPopup, setShowDocumentReportPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedReportType, setSelectedReportType] = useState("");

    /**
    * Setting table data and paging data and updating component when Inspection lisiting updates
    */
    useEffect(() => {
        setTableData(props.apiResponse)
        setPaginationData(props.apiResponsepagination)

    }, [props.apiResponse]) // eslint-disable-line react-hooks/exhaustive-deps

    const runPaginationFunc = () => { };

    // calls parent component for fetching Dealer Evaluation listing
    const handleRequestData = (params) => {
        props.getbackOfficeListing(params)
    }

    const handleEditCertificationBtnClick = (data) => {
        let redirectTo = `/cars/inspection/${data.hashed_vccID}`;
        window.open(redirectTo, '_blank');

    }

    const handleDoQcIconClick = (data) => {

        if (data.imageCount === 0) {
            toast.error(t("COMMON.IMG_NOT_AVAILABLE"))
            return
        }

        let { searchFilterList } = props;

        let redirectTo = "";
        if (searchFilterList && ''+searchFilterList.list_flag === '3') { //SENT FOR CORRECTION
            redirectTo = "/qc/reqcimageuploader/";
        } else if (searchFilterList && ''+searchFilterList.list_flag === '0') { //PENDING LIST 
            redirectTo = "/qc/qc-panel/";
        } else if (searchFilterList && ''+searchFilterList.list_flag === '4') { //RE-QC PANEL 
            redirectTo = "/qc/reqcpanel/";
        }
        redirectTo += `${data.hashed_vccID}`;
        // redirectTo += `${data.id}`;
        navigate(redirectTo, data);
    }

    const viewDocumentReport = (status, reportType, params, source) => {

        setSelectedItem({})

        if(reportType === "pdf") {
            // let redirectUrl = `${API_URL.GETWAY_DOCS_API}report/getCertificationReportPdf?vccID=${params.hashed_vccID}&reportType=pdf`;
            // let redirectUrl = `/inspection/view-report?vccID=${params.hashed_vccID}&reportType=${reportType}&source=${source}`;
            let redirectUrl = params['certification_url'];
            window.open(redirectUrl, '_blank');
        } else {

            setSelectedReportType(reportType);
            
            if(status === "show"){
                setSelectedItem(params);
            }
        } 
    }

    const handleClick = (response) => {
        if (response.response) 
        {   
            let newParam = {}
            newParam.vccID = showGalleryPopup

            dispatch(addFinishTime(newParam)).then(response => {
                if(response.status === 200){
                    toast.success(response.message);
                    //update listing
                    props.getbackOfficeListing({})
                }
                 setLoading(false);
                 setShowGalleryPopup('')
                }).catch(err => setLoading(false))
        }
        else {
            setShowGalleryPopup('')
        }
    }

    // final submit Popup Modal function 
    const finalSubmitPopUp = (param) => {
        let newParam  = {}
        newParam.vccID = param.hashed_vccID;
     
        //check modal is open for that id or not if status 1 only then modal open
        dispatch(checkUploadFinishStatus(newParam)).then(response => {
         if(response.status === 200){
            setShowGalleryPopup(param.hashed_vccID)
         }
         setLoading(false);
        }).catch(err => setLoading(false))
          
    }

    return (
        <>
            <div className='user-list-table'>
                <div className='data-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>{t("BACK_OFFICE.TABLE_HEADER.dealer")}</th>
                                <th>{t("BACK_OFFICE.TABLE_HEADER.ce")}</th>
                                <th>{t("BACK_OFFICE.TABLE_HEADER.city")}</th>
                                <th>{t("BACK_OFFICE.TABLE_HEADER.reg_no")}</th>
                                {
                                    (''+props.callonpage === '3') ? <th>{t("BACK_OFFICE.TABLE_HEADER.unsat_count")}</th> : ''
                                }
                                <th>{t("BACK_OFFICE.TABLE_HEADER.req_date")} <br />(DD:HH:MM)</th>
                                <th>{t("BACK_OFFICE.TABLE_HEADER.product")}</th>
                                <th>{t("BACK_OFFICE.TABLE_HEADER.report")}</th>
                                <th>{t("BACK_OFFICE.TABLE_HEADER.action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData && tableData.length ?
                                    tableData.map((item, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{item.dealerName}</td>
                                                <td>{item.ceName}<br />{item.ceMobile}</td>
                                                <td>{item.city_name}</td>
                                                <td>{item.reg_number}<br />({item.vehicle_type})</td>
                                                {
                                                    (''+props.callonpage === '3') ? <td>{item.manddata}</td> : ''
                                                }
                                                <td>{item.ce_end_date}<br />({item.ce_time_diff})</td>
                                                <td>{item.product_name}</td>
                                                <td>
                                                    <ul className='ulaction-btn'>
                                                        <li title="View Report" onClick={() => viewDocumentReport('show', 'html', item, 'inspection')}>
                                                            <i className='ic-insert_drive_file'></i>
                                                        </li>
                                                        <li title="View Document" onClick={() => viewDocumentReport('show', 'documentImages', item, 'inspection')}>
                                                            <i className='ic-word-file'></i>
                                                        </li>
                                                        {
                                                            (''+props.callonpage === '1' && item.certification_url) ?
                                                                <li title="View Inspection Report" onClick={() => viewDocumentReport('show', 'pdf', item, 'inspection')}>
                                                                    <i className='ic-pdf-file'></i>
                                                                </li> : ''
                                                        }
                                                    </ul>
                                                </td>
                                                <td>
                                                    <ul className='ulaction-btn'>
                                                        {HELPER.isUserHasAccessPage('55') ?
                                                            <li title="Edit Certification Data" onClick={() => { handleEditCertificationBtnClick(item) }}>
                                                                <i className='ic-createmode_editedit'></i>
                                                            </li> : ''
                                                        }

                                                        {HELPER.isUserHasAccessPage('46') &&
                                                            (['0', '3', '4'].includes(''+props.callonpage)) ?
                                                            <li title="Do Qc">
                                                                <i className='ic-settings' onClick={() => { handleDoQcIconClick(item) }}></i>
                                                            </li> : ''
                                                        }

                                                        {

                                                            (''+props.callonpage === '5') ?
                                                                <li>
                                                                    <i className='ic-date_range' onClick = { () => { finalSubmitPopUp(item) } }></i>
                                                                </li> : ''
                                                        }


                                                    </ul>
                                                </td>
                                            </tr>
                                        )

                                    })
                                    :
                                    <tr>
                                        <td className='t-center' colSpan={8}>No Data Found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="pagination-outer">
                    {!loading && paginationData && paginationData.total ?
                        <Pagination
                            paginateData={{
                                page,
                                runPaginationFunc,
                                loading,
                                tableData: props.getInspectionListing,
                                totalLengthWithoutPagination,
                                paginationData,
                                filteredData: props.searchFilterList
                            }}
                            sendRequestDataToParent={handleRequestData}
                        /> : ''}
                </div>

                <div>
                    {
                        Object.keys(selectedItem).length ?
                            <ReportDocumentModal
                                showDocumentReportPopup={Object.keys(selectedItem).length}
                                updateDocumentReportPopupStatus={viewDocumentReport}
                                itemData={selectedItem}
                                reportType={selectedReportType}

                            /> : null
                    }
                </div>
                {/* modal show */}
                {
                showGalleryPopup && showGalleryPopup.length ?
                    <FinalSubmitModal confirmationData={{
                        show: showGalleryPopup,
                        confirmationText: t("BACK_OFFICE.CONFIRMATION_MSG")
                    }}
                        confirmationModalResponse={(data) => {
                            handleClick(data)
                        }
                        }
                    /> : null
                }

            </div>
        </>
    )
}

export default DealerQcTable;