import { useEffect, useState } from "react"
import TopDetailsBar from '@src/view/dealer-qc/common/TopDetailsBar';
import { getSentForCorrectionPanelData, saveReQCData } from "@src/store/slices/dealerQc";
import FullPageLoader from "@src/view/common/FullPageLoader";
import { toast } from "react-toastify";
import DropHereImg from "@src/webroot/images/drophere.png"
import TopActionBtn from "@src/view/common/TopActionBar";
import FinalSubmitModal from '@src/view/dealer-qc/common/FinalSubmitModal'
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router';
import { uploadQcImages } from "@src/store/slices/dealerQc";
import { useParams } from "react-router-dom";
import DropZoneImageUpload from "@src/view/element/DropZoneImageUpload";

const CorrectionPanel = (props) => {

    const [loading, setLoading] = useState(false);
    const [vehicleData, setVehicleData] = useState({});
    const [vehicleDataTagName, setVehicleDataTagName] = useState([]);

    const [currentActiveIndex, setCurrentActiveIndex] = useState(0);

    const [activeImageTagData, setActiveImageTagData] = useState([]);
    const [mandtTags, setMandtTags] = useState([]);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const { t } = useTranslation("common")
    const [files, setFiles] = useState([]);
    const navigate = useNavigate();;
    // const queryParams = useParams();
    const [deleteConfirm, setDeleteConfirm] = useState(0);
    const { vccID } = useParams();


    //All data like reg no, CE Name , Image count , tag Name list show in this api
    useEffect(() => {
        setLoading(true);
        getSentForCorrectionPanelData({ certificationID: vccID }).then(res => {
            setVehicleData(res);
            
            setVehicleDataTagName(res.ImagetagName)
            setActiveImageTagData(res.ImagetagName[0])
            // store all tag id into array so that final all mandatory tag id send in payload
            const updatedTagIds = res && res.ImagetagName && res.ImagetagName.map(val => {
                return val.tag_id;
            });

            setMandtTags(updatedTagIds);

            setLoading(false);

        }).catch(err => setLoading(false))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    //common Function to update image data in state 
    const updateImage = (params, index = currentActiveIndex, type = '') => {

        setLoading(true);
        let updatedActiveTagImages = activeImageTagData.imageData;

        if (type === "delete") {

            updatedActiveTagImages = updatedActiveTagImages.filter(imgData => imgData.id !== params.image_id)

            updatedActiveTagImages = updatedActiveTagImages.filter(elm => elm);
        } else {
            updatedActiveTagImages = vehicleDataTagName[index]['imageData']
        }

        setActiveImageTagData({ ...vehicleDataTagName[index], imageData: updatedActiveTagImages })

        setLoading(false);


    }


    // Save and next button click
    const SaveDataOfReQC = (params) => {
        setLoading(true);
        let newParam = {};
        newParam.vccID = vccID

        newParam.tagId = vehicleDataTagName && vehicleDataTagName[currentActiveIndex] && vehicleDataTagName[currentActiveIndex].tag_id ? vehicleDataTagName[currentActiveIndex].tag_id : '';
        saveReQCData(newParam).then(response => {
            if (response.status === 400) {
                setLoading(false);
                return false;
            }
            if (response.status === 200) {
                // next index if  array length is small then current index , means if it is not last element then element increase by one
                let currentActiveIndexPosition = currentActiveIndex;

                if (vehicleDataTagName.length - 1 > currentActiveIndex) {
                    currentActiveIndexPosition = currentActiveIndex + 1
                    setCurrentActiveIndex(currentActiveIndexPosition);
                }

                // if finish alert submit
                if (params === "finish") {
                    submitFinalData();
                    return false;
                }

                updateImage(newParam, currentActiveIndexPosition);
                setLoading(false);
            }
            setLoading(false);

        }).catch(err => setLoading(false))

    }

    // during previous button , other button (previous,next,finish) handle here
    const previousButton = () => {
        setLoading(true);
        let previousIndex;
        //handle previous -next -finish button
        previousIndex = currentActiveIndex - 1;
        setCurrentActiveIndex(previousIndex);
        updateImage({}, previousIndex);
    }


    // when image tag  click this function perform
    const CurrentTagColumn = (params, index) => {

        //set active Index
        setCurrentActiveIndex(index);

        updateImage(params, index);

    }

    const submitFinalData = () => {
        toggleModal();
    }

    const toggleModal = () => {
        setLoading(false);
        setShowConfirmationPopup(true);

    }

    const handleClick = (response) => {
        if (response.response) updateFinalData()
        else {
            setShowConfirmationPopup(false)
        }
    }

    // Update Image
    const updateFinalData = () => {
        setLoading(true);
        setShowConfirmationPopup(false);
        let newParam = {}

      //  let vccID = vcc;
        newParam.vccID = vccID
        newParam.finishFlag = '1'
        newParam.mandtTags = mandtTags
        newParam.tagId = 'all'

        saveReQCData(newParam).then((response) => {
            setLoading(false);

            if (response.status === 200) {
                toast.success(response.message)
                navigate("/qc/dealer")
            }
        })
    }

    // DELETE IMAGES
    const deleteImage = (params) => {
        setLoading(true);
        let newParam = {};
        newParam.vccID = vccID;
        newParam.imageDetails = [{}]
        newParam.imageDetails[0].image_id = params.id
        newParam.imageDetails[0].tag_id = params.tag_id
        newParam.imageDetails[0].type = "delete"

        newParam.imageDetails = JSON.stringify(newParam.imageDetails);
        uploadQcImages(newParam).then(res => {

            if (res.status === 200) {
                toast.success(t("BACK_OFFICE.DELETE_IMAGES"));
                //RESET STATE DATA
                setFiles([]);
                let obj = {};
                obj.vccID = vccID;
                obj.tagId = params.tag_id
                updateImage({ image_id: params.id }, currentActiveIndex, 'delete');
                setLoading(false)
            }
        });

        setDeleteConfirm(0);
    }

    const toggleDeleteConfirm = (imgId) => {
        setDeleteConfirm(imgId);
    }


    const updateDropzoneSelectedImages = (dropzoneFiles) => {
        if (dropzoneFiles.length) {
            setLoading(true)
            const formData = new FormData();
            dropzoneFiles.forEach((file, index) => {
                    formData.append(`files`, file);
                });

                const tagIds = [];
                // loop over tag id, because in mutliple images we have to send each image tag id, means if image 3 then tag id 3 
                for (let i = 0; i < dropzoneFiles.length; i++) {
                    tagIds.push(vehicleDataTagName && vehicleDataTagName[currentActiveIndex] && vehicleDataTagName[currentActiveIndex].tag_id);
                }
                formData.append('imageTags', JSON.stringify(tagIds));
                formData.append('vccID', vccID);
                //for mandatry images
                formData.append('status', '5');

                uploadQcImages(formData).then(res => {
                    setLoading(false);

                    if (res.status === 200) {
                        toast.success(res.message);
                        //RESET STATE DATA
                        setFiles([]);
                       let updatedActiveTagImages;
                       if (activeImageTagData && activeImageTagData.imageData) {
                         updatedActiveTagImages = [...activeImageTagData.imageData, ...res.data.details];
                       } else {
                         updatedActiveTagImages = [...res.data.details];
                       } 
                      
                        setActiveImageTagData({ ...activeImageTagData, imageData: updatedActiveTagImages });

                        let updatedVehicleTagData = vehicleDataTagName;
                        // delete updatedVehicleTagData[currentActiveIndex];

                        updatedVehicleTagData[currentActiveIndex]['imageData'] = updatedActiveTagImages;

                        setVehicleDataTagName(updatedVehicleTagData)

                    }
                }).catch(err=> setLoading(false))
        }

    }

    const status1ImagesArray = activeImageTagData && activeImageTagData.imageData ? activeImageTagData.imageData.filter(img => +img.status === 1) : [];
    const status5ImagesArray = activeImageTagData && activeImageTagData.imageData ? activeImageTagData.imageData.filter(img => +img.status === 5) : [];

    return (
        <div className="container m-xl-t correction-panel">

            <FullPageLoader show={loading} />
            <div className="top-sec">
                <TopActionBtn heading={t("BACK_OFFICE.RE_QC_PANEL_IMAGE")} />
            </div>

            <TopDetailsBar vehicleData={vehicleData} imageTagList={[]} callFrom={"correctionPanel"} />

            <div className="col-md-12 edit-user-sec">
                <div className="row">
                    <div className="col-md-6">
                        {status1ImagesArray && status1ImagesArray.length > 0 &&
                            <h3>
                                {t("BACK_OFFICE.PREVIOUS_QC_IMAGES")}
                            </h3>
                        }

                        <div className="row" id="previousImgDiv">

                            <div className="previousImgDivSection col-md-12" id="">
                                <label><strong>{vehicleDataTagName && vehicleDataTagName[currentActiveIndex] && vehicleDataTagName[currentActiveIndex]['tag_name']}</strong></label><br />
                            </div>


                        </div>
                        <div className="col-md-12">
                            {/* Render the priviously images first which has 1 status */}
                            {status1ImagesArray && status1ImagesArray.map((val, i) => {
                                return (
                                    <div className="car-image-container col-md-4" key={i}>
                                        <div className="car-gallery" >
                                            <div className="img-outer" >
                                                <img src={val.imgPath} alt="car-img" />

                                            </div>
                                        </div>
                                    </div>

                                )
                            })}

                        </div>

                        <div className="re-qc-upload-box">

                            {/* Render the new upload images  which hase 5 status next */}

                            {status5ImagesArray && status5ImagesArray.map((val, i) => {
                                return (
                                    <div className="car-image-container" key={i}>
                                        <div className="car-gallery" >
                                            <div className="img-outer" /*style={{ backgroundImage: `url('${val.imgPath}')`, backgroundPosition: 'right 0px center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}*/>
                                                
                                                <img src={val.imgPath} alt="img" />
                                                
                                                <i className="ic-clearclose" onClick={() => toggleDeleteConfirm(val.id)}></i>

                                                {deleteConfirm === val.id &&
                                                    <div className="del">
                                                        <div className="del-txt clearfix">
                                                            <p>{t("BACK_OFFICE.DELETE_MESSAGE")}</p>
                                                            <ul>
                                                                <li>
                                                                    <div className="delbox del-yes" onClick={() => { deleteImage(val) }}>
                                                                        <b> {t("COMMON.YES")}</b>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="delbox del-no" onClick={() => { toggleDeleteConfirm(0) }}>
                                                                        <b> {t("COMMON.NO")} </b>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}

                            {
                                files.map((image, i) =>
                                    <div className="car-image-container" key={i}>
                                        <div className="car-gallery">
                                            <div className="img-outer car-image-box" style={{ backgroundImage: `url('${image.preview}')`, backgroundPosition: 'right 0px center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                                <i className="ic-clearclose" onClick={() => { deleteImage() }}></i>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="car-image-container" key={"new_img"}>

                                <DropZoneImageUpload title={""} updateDropzoneSelectedImages={updateDropzoneSelectedImages} imgSrc={DropHereImg}  />

                            </div>

                        </div>
                        {/* PREVIOUS NEXT FINISH BUTTONS */}
                        <div className="col-md-12 panel-btns">
                            {currentActiveIndex > 0 && (
                                <button className="btn btn-primary m-xs-r" type="button" onClick={previousButton} >
                                    <i className="fa fa-arrow-circle-left"></i>
                                    {t("COMMON.PREVIOUS")}
                                </button>
                            )}
                            {/* {showNext && (
                              <button className="btn btn-primary m-xs-r" type="button" onClick={SaveDataOfReQC}>
                                  {t("INSPECTION.NEXT")}
                                  <i className="fa fa-arrow-circle-right"></i>
                              </button>
                          )} */}
                            {
                                currentActiveIndex === (vehicleDataTagName && vehicleDataTagName.length - 1) ? (
                                    <button className="btn btn-primary" type="button" onClick={() => { SaveDataOfReQC('finish') }} >
                                        {t("COMMON.FINISH")}
                                        <i className="fa fa-arrow-circle-right"></i>
                                    </button>
                                )
                                    :
                                    <button className="btn btn-primary m-xs-r" type="button" onClick={SaveDataOfReQC}>
                                        {t("INSPECTION.NEXT")}
                                        <i className="fa fa-arrow-circle-right"></i>
                                    </button>

                            }

                        </div>
                    </div>

                    {/*Tag Name of getAllMandatry Tags Not In QC*/}
                    <div className="col-md-6">
                        <h3> {t("BACK_OFFICE.IMAGE_CONDITIONS")}  </h3>
                        {vehicleDataTagName && vehicleDataTagName.map((val, i) => {
                            return (
                                <div className={`col-md-12 reqc_imageupload_taglist ${(vehicleDataTagName && vehicleDataTagName[currentActiveIndex] ? vehicleDataTagName[currentActiveIndex].tag_id === val.tag_id : '') ? "re-qc-list-active" : "re-qc-list-default"}`} id={`tag_${val.tag_id}`} label="" key={i} onClick={() => { CurrentTagColumn(val, i) }}>
                                    <span >* </span>
                                    {val.tag_name} {val && val.reason ? `--- ${val.reason} ${val.otherStatusReason ? `(${val.otherStatusReason})`:''}` : ''}
                                    <div className="pull-right">
                                        <span id="image_upload_check_" className={ (val && val.CheckColumn) ? "ic-check" : " "}></span>
                                    </div>
                                </div>
                            )

                        })}

                    </div>
                </div>
            </div>

            {/* modal show */}
            {
                showConfirmationPopup ?
                    <FinalSubmitModal confirmationData={{
                        show: showConfirmationPopup,
                        confirmationText: t("BACK_OFFICE.CONFIRMATION_MSG")
                    }}
                        confirmationModalResponse={(data) => {
                            handleClick(data)
                        }
                        }
                    /> : null
            }
        </div >

    )
}


export default CorrectionPanel;